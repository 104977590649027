import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem
} from "@progress/kendo-react-layout";
import moment from 'moment';

import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { orderBy } from '@progress/kendo-data-query';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { useTranslation } from 'react-i18next';
import { generateUpdatedDate } from '../common/datadate';
import PQIData from '../pqi/pqidata';

const PQI = ({ positiondata, order, blockH }) => {

    const [visibleDialog, setVisibleDialog] = useState(true);
    const [sort, setSort] = useState([{}]);
    const { t, i18n } = useTranslation();


    const data = PQIData();

    const pqi = data.pqi;
    const pqi_v = data.pqi_v;



    const ToggleButton = styled(MuiToggleButton)(() => ({
        '&.Mui-selected, &.Mui-selected:hover': {
            color: 'black',
            backgroundColor: '#F5DF4D',
        },
        '&.MuiToggleButton-root': {
            fontSize: '0.7rem',
            fontFamily: 'Pretendard-R',
            padding: 0,
            width: 50,
            borderRadius: '0px 0px 0px 0px!important',
            border: '1px solid rgba(0, 0, 0, 0.12)!important'

        }
    }));



    const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
        '&.MuiToggleButtonGroup-root': {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "& > *:not(:last-child)": {
                marginRight: theme.spacing(0)
            }
        }
    }));


    const theme = createTheme({
        palette: {
            text: {
                primary: '#00ff00',
            },
        },
    });

    const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
    };

    const currentYear = new Date().getFullYear();
    // 12개월 객체 배열 생성
    const months = [];
    for (let i = 1; i <= 12; i++) {
        // 0을 채워서 두 자리로 만들어주기
        const monthString = String(i).padStart(2, '0');
        // 객체 배열에 월 추가
        months.push({ month: `${currentYear.toString().slice(2, 4)}년 ${monthString}월` });
    }


    pqi_v.forEach((item) => {
        item[item.month] = item['PQI'];

    });

    pqi.forEach((item) => {
        item[item.month] = item['PQI'];

    });


    const vendor_pqi_table = Object.values(pqi_v.reduce((acc, cur) => {
        const vendor_month = cur.vendor;
        if (acc[vendor_month]) {
            // 같은 vendor_month가 이미 존재하면 데이터를 통합한다.
            acc[vendor_month] = {
                ...acc[vendor_month],
                ...(cur || {}),
            };
        } else {
            // 새로운 vendor_month이면 새로운 오브젝트를 만든다.
            acc[vendor_month] = { ...cur };
        }
        return acc;
    }, {}));



    const regex = /^\d+년 \d+월$/;
    const modifiedData = vendor_pqi_table.map(obj => {
        let totalSum = 0;
        let count = 0;

        for (let key in obj) {
            if (regex.test(key)) {
                totalSum += obj[key];
                count++;
            }
        }

        const average = count ? totalSum / count : 0;  // 나누는 수가 0이 아닌 경우에만 평균 계산
        return {
            ...obj,
            averagePqi: average
        };
    });


    const project_pqi_table = [pqi.reduce((acc, curr) => {
        return { ...acc, ...curr };
    }, {})];


    const project_pqi_table_n = project_pqi_table.map((com) => {

        delete com["CAR 발행건수"];
        delete com["NCR 지연"];
        delete com["NCR/Punch 발행건수"];
        delete com["PAR 발행건수"];
        delete com["PQI"];
        delete com["month"];
        delete com["시공검사 신청"];
        delete com["시공검사 실패율"];
        delete com["시공검사 완료"];
        delete com["자재검사 신청"];
        delete com["자재검사 실패율"];
        delete com["자재검사 완료"];
        return com;

    })

    let sum = 0;
    let count = 0;

    for (let key in project_pqi_table_n[0]) {
        if (typeof project_pqi_table_n[0][key] === 'number') {
            sum += project_pqi_table_n[0][key];
            count++;
        }
    }


    // project_pqi_table[0].average = sum / count;

    // const vendor_pqi_a = _(pqi_v)
    //     .groupBy('month')
    //     .map((objs, key) => ({
    //         month: key,
    //         pqi_a: _.sumBy(objs, 'PQI') / objs.length
    //     }))
    //     .value();

        const vendor_pqi_a = _(pqi_v)
    .groupBy('month')
    .map((objs, key) => {
        const filteredObjs = _.filter(objs, o => _.isNumber(o.PQI)); // 숫자 타입인 PQI 값만 필터링
        return {
            month: key,
            pqi_a: filteredObjs.length > 0 ? _.sumBy(filteredObjs, 'PQI') / filteredObjs.length : 0 // 평균 계산, 요소가 없다면 0 반환
        };
    })
    .value();


    const [selectedValue, setSelectedValue] = React.useState("SKEE");

    // const handleChange = React.useCallback(
    //     (e) => {
    //         setSelectedValue(e.value);
    //     },
    //     [setSelectedValue]
    // );
    const handleChange = (event, selectedValue) => {
        if (selectedValue !== null) {
            setSelectedValue(selectedValue);
        }
    };


    const [BoxHeight, setBoxHeight] = useState(250);

    useEffect(() => {
        const boxHeight = positiondata[(order - 1) < 0 ? 0 : order - 1];
        setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
    }, [positiondata, order, blockH]);

    const [last_pqi, setlastpqi] = useState(0)
    const [last_pqi1, setlastpqi1] = useState(0)

    const [last_pqi_v, setlastpqi_v] = useState(0)
    const [last_pqi1_v, setlastpqi1_v] = useState(0)


    useEffect(() => {
        if (pqi.length > 0) {
            setlastpqi(_.sortBy(pqi, 'month').reverse()[0]['PQI']);
            setlastpqi1(_.sortBy(pqi, 'month').reverse()[1] && _.sortBy(pqi, 'month').reverse()[1]['PQI']);
        }
    }, [pqi]);

    useEffect(() => {
        if (vendor_pqi_a.length > 0) {
            setlastpqi_v(_.sortBy(vendor_pqi_a, 'month').reverse()[0]['pqi_a']);
            setlastpqi1_v(_.sortBy(vendor_pqi_a, 'month').reverse()[1] && _.sortBy(vendor_pqi_a, 'month').reverse()[1]['pqi_a']);
        }
    }, [vendor_pqi_a]);


    const pqidev = last_pqi - last_pqi1
    const pqlaststring = last_pqi && last_pqi.toFixed(1)
    const pqidevstring = pqidev && Math.abs(pqidev).toFixed(2)


    const pqidev_v = last_pqi_v - last_pqi1_v
    const pqlaststring_v = last_pqi_v && last_pqi_v.toFixed(1)
    const pqidevstring_v = pqidev_v && Math.abs(pqidev_v).toFixed(2)



    const today = moment(new Date()).format('MMM YY')

    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };



    const gridcell = (props) => {
        const field = props.field || "";
        const value = props.dataItem[field];

        const max =
            _.maxBy(pqi.filter(v => v.month.includes(moment().format('YY년'))), 'PQI')['PQI']

        const min =
            _.minBy(pqi.filter(v => v.month.includes(moment().format('YY년'))), 'PQI')['PQI']



        return (
            <td style={{ textAlign: 'center', color: max === value ? '#0072B5' : min === value ? '#CD212A' : 'black' }}
            >
                {typeof value !== 'undefined' ? value.toFixed(2) : '-'}
            </td>
        );
    };



    const gridcellv = (props) => {
        const field = props.field || "";
        const value = props.dataItem[field];
    
        const vendor = props.dataItem['vendor']
    
        const values = vendor_pqi_table.filter(v => v.month.includes(moment().format('YY년'))).filter(v => v.vendor === vendor).flatMap(obj =>
            Object.keys(obj).filter(key => regex.test(key)).map(key => obj[key])
        );
    
        const max = Math.max(...values);
        const min = Math.min(...values);
    
        const filteredData = modifiedData.filter((v) => v.month.includes(moment().format("YY년")));
        const averageMax = (filteredData.length > 0 ? (_.maxBy(filteredData, 'averagePqi') || {})['averagePqi'] : 0);
        const averageMin = (filteredData.length > 0 ? (_.minBy(filteredData, 'averagePqi') || {})['averagePqi'] : 0);
    
        return (
            <td style={{
                textAlign: 'center',
                color:
                    typeof value === 'undefined' ? 'black' : // value가 undefined인 경우 바로 검은색으로 설정
                        field !== 'averagePqi' && max === value ? '#0072B5' :
                            field !== 'averagePqi' && min === value ? '#CD212A' :
                                field === 'averagePqi' && averageMax === value ? '#0072B5' :
                                    field === 'averagePqi' && averageMin === value ? '#CD212A' :
                                        'black'
            }}>
                {typeof value === 'number' ? value.toFixed(2) : '-'}
            </td>
        );
    };
    
    const _export = useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const _export2 = useRef(null);

    const excelExport2 = () => {
        if (_export2.current !== null) {
            _export2.current.save();
        }
    };


    return (
        <>
            <GridLayout style={{ height: BoxHeight }}
                rows={[
                    {
                        height: '20%',
                    },
                    {
                        height: '60%',
                    },
                    {
                        height: '20%',
                    }
                ]}

                cols={[
                    {
                        width: '33%',
                    },
                    {
                        width: '33%',
                    },
                    {
                        width: '33%',
                    }
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>

                <div style={{ position: "absolute", top: "22px", right: "15px", zIndex: "100", color: 'snow', fontSize: "0.7rem" }}>
                    {last_pqi && generateUpdatedDate(moment().add(-1, "days").format("YYYY-MM-DD"))}
                </div>
                <GridLayoutItem row={1} col={1} rowSpan={1} colSpan={3} style={{ position: 'relative' }}>
                    <div style={{ position: "absolute", right: "0px", top: "0px", zIndex: 999999, width: 100 }}>
                        <ThemeProvider theme={theme}>
                            <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="baseunit">
                                <ToggleButton value="SKEE" aria-label='공종' >
                                    SKEE
                                </ToggleButton>
                                <ToggleButton value="Biz" aria-label="직종">
                                    Biz.P
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </ThemeProvider>
                    </div>

                    <div style={{ position: "absolute", left: "0px", top: "0px", zIndex: 999999 }}>
                        <Button onClick={toggleDialog} size='small' rounded={null} style={{ fontSize: 12 }}>
                            {t("연간 PQI")}
                        </Button>
                    </div>
                </GridLayoutItem>
                <GridLayoutItem row={2} col={1} rowSpan={1} colSpan={3} style={{ position: 'relative' }}>

                    {selectedValue === 'SKEE' ?

                        last_pqi < 70 ?
                            <div style={{ fontSize: '3rem', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#CD212A' }}>
                                <div style={{ position: 'relative' }}>{pqlaststring}
                                    {pqidev < 0 ?
                                        <span style={{ color: "#FF6E6E", fontSize: '0.7rem' }}> ▼ {isNaN(parseFloat(pqidev)) ? '' : pqidevstring}</span>
                                        :
                                        pqidev > 0 ?
                                            <span style={{ color: "#34568B", fontSize: '0.7rem' }}> ▲ {isNaN(parseFloat(pqidev)) ? '' : pqidevstring}</span>
                                            :
                                            <span style={{ color: "lightgray", fontSize: '0.7rem' }}> - {isNaN(parseFloat(pqidev)) ? '' : pqidevstring}</span>
                                    }

                                </div>
                                <div style={{ fontSize: '16px', color: '#707070', textAlign: 'center' }}>{today}</div>

                            </div> :
                            last_pqi <= 80 && last_pqi >= 70 ?
                                <div style={{ fontSize: '3rem', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#FA7A35' }}>
                                    <div style={{ position: 'relative' }}>{pqlaststring}
                                        {pqidev < 0 ?
                                            <span style={{ color: "#FF6E6E", fontSize: '0.7rem' }}> ▼ {isNaN(parseFloat(pqidev)) ? '' : pqidevstring}</span>
                                            :
                                            pqidev > 0 ?
                                                <span style={{ color: "#34568B", fontSize: '0.7rem' }}> ▲ {isNaN(parseFloat(pqidev)) ? '' : pqidevstring}</span>
                                                :
                                                <span style={{ color: "lightgray", fontSize: '0.7rem' }}> - {isNaN(parseFloat(pqidev)) ? '' : pqidevstring}</span>
                                        }

                                    </div>
                                    <div style={{ fontSize: '16px', color: '#707070', textAlign: 'center' }}>{today}</div>
                                </div> :
                                <div style={{ fontSize: '3rem', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#56C6A9' }}>
                                    <div style={{ position: 'relative' }}>{pqlaststring}
                                        {pqidev < 0 ?
                                            <span style={{ color: "#FF6E6E", fontSize: '0.7rem' }}> ▼ {isNaN(parseFloat(pqidev)) ? '' : pqidevstring}</span>
                                            :
                                            pqidev > 0 ?
                                                <span style={{ color: "#34568B", fontSize: '0.7rem' }}> ▲ {isNaN(parseFloat(pqidev)) ? '' : pqidevstring}</span>
                                                :
                                                <span style={{ color: "lightgray", fontSize: '0.7rem' }}> - {isNaN(parseFloat(pqidev)) ? '' : pqidevstring}</span>
                                        }

                                    </div>
                                    {/* <div style={{ fontSize: '16px', color: '#707070', textAlign: 'center' }}>{today}</div> */}
                                </div>


                        :

                        last_pqi_v < 70 ?
                            <div style={{ fontSize: '3rem', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#CD212A' }}>
                                <div style={{ position: 'relative' }}>{pqidevstring_v}
                                    {pqidev_v < 0 ?
                                        <span style={{ color: "#FF6E6E", fontSize: '0.7rem' }}> ▼ {isNaN(parseFloat(pqidev_v)) ? '' : pqidevstring_v}</span>
                                        :
                                        pqidev_v > 0 ?
                                            <span style={{ color: "#34568B", fontSize: '0.7rem' }}> ▲ {isNaN(parseFloat(pqidev_v)) ? '' : pqidevstring_v}</span>
                                            :
                                            <span style={{ color: "lightgray", fontSize: '0.7rem' }}> - {isNaN(parseFloat(pqidev_v)) ? '' : pqidevstring_v}</span>
                                    }

                                </div>
                                {/* <div style={{ fontSize: '16px', color: '#707070', textAlign: 'center' }}>{today}</div> */}

                            </div> :
                            last_pqi_v <= 80 && last_pqi_v >= 70 ?
                                <div style={{ fontSize: '3rem', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#FA7A35' }}>
                                    <div style={{ position: 'relative' }}>{pqlaststring_v}
                                        {pqidev_v < 0 ?
                                            <span style={{ color: "#FF6E6E", fontSize: '0.7rem' }}> ▼ {isNaN(parseFloat(pqidev_v)) ? '' : pqidevstring_v}</span>
                                            :
                                            pqidev_v > 0 ?
                                                <span style={{ color: "#34568B", fontSize: '0.7rem' }}> ▲ {isNaN(parseFloat(pqidev_v)) ? '' : pqidevstring_v}</span>
                                                :
                                                <span style={{ color: "lightgray", fontSize: '0.7rem' }}> - {isNaN(parseFloat(pqidev_v)) ? '' : pqidevstring_v}</span>
                                        }

                                    </div>
                                    {/* <div style={{ fontSize: '16px', color: '#707070', textAlign: 'center' }}>{today}</div> */}
                                </div> :
                                <div style={{ fontSize: '3rem', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#56C6A9' }}>
                                    <div style={{ position: 'relative' }}>{pqlaststring_v}
                                        {pqidev_v < 0 ?
                                            <span style={{ color: "#FF6E6E", fontSize: '0.7rem' }}> ▼ {isNaN(parseFloat(pqidev_v)) ? '' : pqidevstring_v}</span>
                                            :
                                            pqidev_v > 0 ?
                                                <span style={{ color: "#34568B", fontSize: '0.7rem' }}> ▲ {isNaN(parseFloat(pqidev_v)) ? '' : pqidevstring_v}</span>
                                                :
                                                <span style={{ color: "lightgray", fontSize: '0.7rem' }}> - {isNaN(parseFloat(pqidev_v)) ? '' : pqidevstring_v}</span>
                                        }

                                    </div>
                                    {/* <div style={{ fontSize: '16px', color: '#707070', textAlign: 'center' }}>{today}</div> */}
                                </div>

                    }
                </GridLayoutItem>

                <GridLayoutItem row={3} col={1} rowSpan={1} colSpan={1} style={{ position: 'relative', textAlign: 'center', width: '100%', fontSize: '0.7rem' }}>
                    <div style={{ position: 'relative', left: '-0.5rem' }}> <span className='k-icon k-font-icon k-i-circle' style={{ fontSize: '1.5rem', color: '#56C6A9' }}></span><span>Good</span></div>
                    <div>PQI &gt; 80</div>
                </GridLayoutItem>
                <GridLayoutItem row={3} col={2} rowSpan={1} colSpan={1} style={{ position: 'relative', textAlign: 'center', width: '100%', fontSize: '0.7rem' }}>
                    <div style={{ position: 'relative', left: '-0.5rem' }}> <span className='k-icon k-font-icon k-i-circle' style={{ fontSize: '1.5rem', color: '#FA7A35' }}></span><span>Avg.</span></div>
                    <div>70 ≤ PQI ≤ 80 </div>
                </GridLayoutItem>
                <GridLayoutItem row={3} col={3} rowSpan={1} colSpan={1} style={{ position: 'relative', textAlign: 'center', width: '100%', fontSize: '0.7rem' }}>
                    <div style={{ position: 'relative', left: '-0.5rem' }}> <span className='k-icon k-font-icon k-i-circle' style={{ fontSize: '1.5rem', color: '#CD212A' }}></span><span>Bad</span></div>
                    <div>PQI &lt; 70 </div>
                </GridLayoutItem>

            </GridLayout>


            {!visibleDialog && (

                <Dialog title={t("연간 PQI")} onClose={toggleDialog} width='80%' height='700px'>
                    <TabStrip className='qtab' selected={selected} onSelect={handleSelect}>
                        <TabStripTab title="PJT">
                            <ExcelExport data={project_pqi_table} ref={_export}>


                                <Grid

                                    data={orderBy(project_pqi_table, sort)}
                                    sortable={true}
                                    sort={sort}
                                    onSortChange={(e) => {
                                        setSort(e.sort);
                                    }}
                                // className='vendorpqi'

                                >
                                    <GridToolbar>
                                        <button
                                            title="Export Excel"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={excelExport}
                                            style={{ position: 'absolute', right: '5px' }}
                                        >
                                            <span className="k-icon k-font-icon k-i-file-excel"></span>
                                        </button>
                                    </GridToolbar>
                                    {months.map((v, index) =>
                                        <GridColumn key={index} field={v.month} title={i18n.language === "ko" ? v.month : moment("20" + v.month.slice(0, 2) + "-" + v.month.slice(4, 6) + "-01").format("MMM YYYY")} cell={gridcell} />
                                    )}
                                    <GridColumn field='average' title={t('평균')} cell={gridcell} />
                                </Grid>
                            </ExcelExport>
                        </TabStripTab>
                        <TabStripTab title="Biz.P">
                            <ExcelExport data={modifiedData} ref={_export2}>

                                <Grid

                                    data={orderBy(modifiedData, sort)}
                                    sortable={true}
                                    sort={sort}
                                    onSortChange={(e) => {
                                        setSort(e.sort);
                                    }}
                                    className='vendorpqi'
                                    style={{ height: 580 }}

                                >
                                    <GridToolbar>
                                        <button
                                            title="Export Excel"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={excelExport2}
                                            style={{ position: 'absolute', right: '5px' }}
                                        >
                                            <span className="k-icon k-font-icon k-i-file-excel"></span>
                                        </button>
                                    </GridToolbar>
                                    <GridColumn field="vendor" title={t("업체명")} />
                                    {months.map((v, index) =>
                                        <GridColumn key={index} field={v.month} title={i18n.language === "ko" ? v.month : moment("20" + v.month.slice(0, 2) + "-" + v.month.slice(4, 6) + "-01").format("MMM YYYY")} cell={gridcellv} />
                                    )}
                                    <GridColumn field='averagePqi' title={t('평균')} cell={gridcellv} />

                                </Grid>
                            </ExcelExport>
                        </TabStripTab>
                    </TabStrip>

                </Dialog>

            )}
        </>
    );

}

export default PQI