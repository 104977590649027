import axios from 'axios';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const MY_SERVER = `${REACT_APP_URI2}/api`;

export const getFileFolderList = async (path,setState) => {
    const res = await axios.get(MY_SERVER+'/getFileFolderList?path='+path)
    setState(res.data)


}

export const getFolderList = async (path, setState) => {
    const res = await axios.get(MY_SERVER+'/getFileFolderList?path='+path)
    setState(res.data.folderList.map((list => list.name)))
}
export const image_folder = `/${REACT_APP_PATH2}/image`

export const json_folder = `/${REACT_APP_PATH2}/file`


export const getImageFolderList = async (setState) => {
    const res = await axios.get(MY_SERVER+'/getFileFolderList?path='+image_folder)
    setState(res.data.folderList.map(({name}) => ({value:name, label:name})))
}

export const getImageList = async (path, setState) => {
    const res = await axios.get(MY_SERVER+'/getFileFolderList?path='+path)
    setState(res.data.fileList.map(({name}) => ({value:name, label:name})))
}

export const getFile = async (path,setState) => {
    const res = await axios.get(MY_SERVER+'/getFile?path='+path, {
        responseType: 'arraybuffer'
      });
    setState(window.URL.createObjectURL(new Blob([res.data])))

}

export const getJSON =  (path,setState) => {
    fetch(`${MY_SERVER}/getJSON?path=${path}`)
    .then((response) => response.json())
    .then((data) => setState(data));
}

export const getIMG = async (path,setState) => {
    const res = await axios.get(MY_SERVER+'/getlatest?path='+path, {
        responseType: 'arraybuffer'
      });
    setState(window.URL.createObjectURL(new Blob([res.data])))

}