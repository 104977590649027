import React, { useEffect, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import EQCTable from "./EQCTable";
import BarChart100 from "../Component/BarChart100";
import { Dialog } from "@progress/kendo-react-dialogs";
import styled from "styled-components";
import { Button } from "@progress/kendo-react-buttons";
import axios from "axios";
import { useParams } from "react-router-dom";
import _ from "lodash";

const { REACT_APP_URI2 } = process.env;

const url = `/api`;

const EQC = ({ positiondata, blockH, order }) => {
  const { project_code } = useParams();
  const [BoxHeight, setBoxHeight] = useState(250);
  const [visible, setVisible] = useState(false);
  const [barChartData, setBarChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const toggleDialog = () => {
    setVisible(!visible);
  };
  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

  useEffect(() => {
    const fetchData = async () => {
      let eqc_result_body = {
        bpname: "EQC",
        lineitem: "yes",
        // filter_condition: "status=Active",
        filter_criteria: {
          join: "AND",

          filter: [
            {
              field: "status",
              value: "Terminated",
              condition_type: "neq",
            },
          ],
        },
      };

      const fetch_eqc_result_body = await axios
        .post(`${url}/getemsbprecord?path=${project_code}`, eqc_result_body)
        .then((c) => {
          return c.data.data.data;
        });

      const total_lineitems = fetch_eqc_result_body
        .filter((com) => com._bp_lineitems)
        .map((com) => ({
          _bp_lineitems: com._bp_lineitems
            .filter((com2) => com2.tab_id === 0)
            .map((c) => ({ ...c, EQCDisc_spd: com.EQCDisc_spd })),
        }))
        .flat();

      const lineitem = [];
      total_lineitems.forEach((c) => {
        if (c._bp_lineitems) {
          lineitem.push(...c._bp_lineitems);
        }
      });

      const categories = _.uniqBy(lineitem, "EQCDisc_spd").map(
        (com) => com.EQCDisc_spd
      );

      const uniq_disc = categories.sort().map((com) => {
        const filter_table_data_arr = lineitem.filter(
          (com2) => com2.EQCDisc_spd === com
        );

        const obj = {
          disc: com,
          open: filter_table_data_arr.filter(
            (com) => com.QCConfirm_srb !== "Closed"
          ).length,
          closed: filter_table_data_arr.filter(
            (com) => com.QCConfirm_srb === "Closed"
          ).length,
        };

        return { ...obj };
      });
      setBarChartData(uniq_disc);

      const data = [
        {
          title: "EQC 등록",
          ...closedValues(),
        },
        {
          title: "남은 작업",
          ...openValues(),
        },
      ];
      function openValues() {
        const categoryValues = {};

        uniq_disc.forEach((category) => {
          categoryValues[category.disc] = category.open;
        });

        return categoryValues;
      }

      function closedValues() {
        const categoryValues = {};

        uniq_disc.forEach((category) => {
          categoryValues[category.disc] = category.closed;
        });

        return categoryValues;
      }
      setTableData(data);
    };
    fetchData();
  }, [project_code]);
  return (
    <>
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          {
            height: "100%",
          },
        ]}
        cols={[
          {
            width: "100%",
          },
        ]}
        gap={{
          rows: 10,
          cols: 0,
        }}
      >
        <GridLayoutItem row={1} col={1}>
          <ButtonDiv>
            <CustomButton
              title="블록 온오프"
              iconClass="k-icon k-font-icon k-i-grid"
              onClick={toggleDialog}
            ></CustomButton>
          </ButtonDiv>
          <BarChart100 height={BoxHeight} data={barChartData} />
          {/* <EQCTable /> */}
          {visible && (
            <Dialog title={"EQC"} onClose={toggleDialog}>
              <EQCTable data={tableData} />
            </Dialog>
          )}
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default EQC;

const ButtonDiv = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
`;

const CustomButton = styled(Button)`
  background-color: #7f84ab;
  border: none;
  box-shadow: none;
  color: white;
`;
