import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from 'axios';
import {
  Card,
  CardTitle,
  CardBody,
  GridLayout, GridLayoutItem
} from "@progress/kendo-react-layout";
import moment from 'moment';
import { ListView } from '@progress/kendo-react-listview';
import './bootstrap.min.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Dialog } from "@progress/kendo-react-dialogs";
import { orderBy } from '@progress/kendo-data-query';
import { useTranslation } from 'react-i18next';

am4core.addLicense("CH363108325");
am4core.addLicense("MP363108325");
am4core.addLicense("TL363291176");

const { REACT_APP_URI2, REACT_APP_PATH2 } = process.env;


// am4core.unuseTheme(am4themes_animated);
const CHART_ID = 'linechart';


const PQI = ({ filter, projeclist, BoxHeight }) => {
  const { t, i18n } = useTranslation();

  const [pqidataall, setpqidataall] = useState([]);
  const [pqidatadivision, setpqidatadivision] = useState([]);
  const [pqidataproject, setpqidataproject] = useState([]);
  const [pqidataproject2, setpqidataproject2] = useState([]);
  const [pqidataproject3, setpqidataproject3] = useState([]);

  useEffect(() => {
    const fetchData = async () => {

      const res = await axios.get(`/api/getJSONtotal?path=/${REACT_APP_PATH2}/file/pqi/all_pqi.json`)
      const res1 = await axios.get(`/api/getJSONtotal?path=/${REACT_APP_PATH2}/file/pqi/division_pqi.json`)
      const res2 = await axios.get(`/api/getJSONtotal?path=/${REACT_APP_PATH2}/file/pqi/project_pqi.json`)


      setpqidataall(res.data)
      setpqidatadivision(res1.data.filter(v => projeclist.some(vf => vf.division === v.division)))
      setpqidataproject(res2.data.filter(v => projeclist.some(vf => vf.projectcode === v.project)))


    }

    fetchData();
  }, [projeclist]);


  useEffect(() => {
    const fetchData = async () => {
      const res3 = await axios.get(`/api/getJSONtotal?path=/${REACT_APP_PATH2}/file/pqi/project_all_pqi.json`)
      const res4 = await axios.get(`/api/getJSONtotal?path=/${REACT_APP_PATH2}/file/pqi/project_vendor_all_pqi.json`)
      setpqidataproject2(filter ===''?res3.data: res3.data.filter(v=>v.division === filter))
      setpqidataproject3(filter ===''?res4.data: res4.data.filter(v=>v.division === filter))
      // setpqidataproject3(res3.data)

    }
      fetchData();
    }, [filter]);

  
  const pqi = filter === '' ? pqidataall : pqidatadivision.filter(v => v.division === filter)

  const chartmonthsAxes = Array.apply(0, Array(12)).map(function (_, i) { return moment().month(i).format('YY년 MM월') }).map((v) => ({ month: v }))

  const pqichart = chartmonthsAxes.map((v) => ({ ...v, ...pqi.find(vf => vf.month === v.month) }))

  const month_l = _.sortBy(_.reject(pqichart, function (item) { return (typeof item.pqi === 'undefined'); }), 'month').reverse()[0] && _.sortBy(_.reject(pqichart, function (item) { return (typeof item.pqi === 'undefined'); }), 'month').reverse()[0]['month']

  const pqichart3 = pqichart.map((v) => ({ ...v, month: v.month.slice(4, 7), color: v.pqi > 80 ? '#00A170' : v.pqi < 70 ? '#E13D43' : '#F49B42' }))
  const pqilistdata = filter === '' ? pqidatadivision.map((v) => ({ ...v, type: v.division })) : pqidataproject.map(v => ({ ...v, ...projeclist.find(sp => sp.ugenProjectNumber === v.project) })).filter(v => v.division === filter).map((v) => ({ ...v, type: v.projectname }))

  const pqilistdata_filter = pqilistdata.filter(v => v.month === month_l)

  const { useRef } = React;
  const chartRef = useRef(null);
  const monthList = {
    "01월": "Jan",
    "02월": "Feb",
    "03월": "Mar",
    "04월": "Apr",
    "05월": "May",
    "06월": "Jun",
    "07월": "Jul",
    "08월": "Aug",
    "09월": "Sep",
    "10월": "Oct",
    "11월": "Nov",
    "12월": "Dec"
  }

  React.useEffect(() => {
    if (!chartRef.current) {
      chartRef.current = am4core.create(CHART_ID, am4charts.XYChart);

      
      chartRef.current.data =
      // i18n.language==="ko"?
      pqichart3
      // : pqichart3.map((v)=>({...v, month:monthList[v.month]}));


      var categoryAxis = chartRef.current.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "month";
      categoryAxis.renderer.minGridDistance = 1;

      var valueAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.axisFills.template.disabled = true;
      valueAxis.renderer.ticks.template.disabled = true;
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.max = 110;
      valueAxis.min = 60;
      var series = chartRef.current.series.push(new am4charts.LineSeries());
      series.dataFields.categoryX = "month";
      series.dataFields.valueY = "pqi";
      series.strokeWidth = 2;
      series.tooltipText = "PQI: {pqi}";

      series.propertyFields.stroke = "color";

      var bulletLabel = series.bullets.push(new am4charts.LabelBullet());
      // bulletLabel.label.padding(4, 8, 4, 8);
      bulletLabel.locationY = -1;
      bulletLabel.label.text = "{pqi}";
      bulletLabel.label.verticalCenter = 'top'

      bulletLabel.label.adapter.add("fill", function (fill, target) {
        if (target.dataItem && target.dataItem.valueY > 80) {
          return am4core.color("#00A170");
        } else if (target.dataItem && target.dataItem.valueY < 70) {
          return am4core.color("#E13D43");
        }
        else { return am4core.color("#F49B42"); }
      });

      // Add a guide
      let range = valueAxis.axisRanges.create();
      range.value = 80;
      range.grid.stroke = am4core.color("#F49B42");
      range.grid.strokeWidth = 1;
      range.grid.strokeOpacity = 0.6;
      range.grid.strokeDasharray = "3,3";
      range.label.inside = true;
      range.label.text = "";
      range.label.fill = range.grid.stroke;
      range.label.verticalCenter = "bottom";

      // Add a guide
      let range2 = valueAxis.axisRanges.create();
      range2.value = 70;
      range2.grid.stroke = am4core.color("#F49B42");
      range2.grid.strokeWidth = 1;
      range2.grid.strokeOpacity = 0.6;
      range2.grid.strokeDasharray = "3,3";
      // range2.label.inside = true;
      range2.label.text = "Avg. 70 ≤ PQI ≤ 80";
      range2.label.y = 50;
      range2.label.fontSize = 10;
      range2.label.locationY = 1;
      range2.label.fill = am4core.color("#363945");
      range2.label.verticalCenter = "middle";

      chartRef.current.cursor = new am4charts.XYCursor();

      return () => {
        chartRef.current && chartRef.current.dispose();
      };

    }

  }, []);

  React.useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = i18n.language==="ko"?pqichart3: pqichart3.map((v)=>({...v, month:monthList[v.month]}));
    }
  }, [pqichart3, i18n.language, monthList]);



  const MyItemRender = props => {
    let item = props.dataItem;
    return <div className='row p-2 border-bottom align-middle' style={{
      margin: 0, padding: '0.1rem', borderBottom: '1px solid lightgray'
    }}>

      <div className='col-6'>
        <span style={{
          fontSize: 12,
          color: '#707070', margin: 0
        }} >{item.type==="수소친환경"? "H2/Recycling":item.type}</span>

      </div>
      <div className='col-3'>
        <span style={{ fontSize: 12, color: item.pqi > 80 ? '#00A170' : item.pqi < 70 ? '#E13D43' : '#F49B42' }}>{item.pqi}</span>
      </div>
      <div className='col-3'>
        <span style={{
          fontSize: 12,
          color: '#707070', margin: 0
        }} > {i18n.language === "ko"?item.month:moment("20"+item.month.slice(0,2)+"-"+item.month.slice(4,6)+"-01").format("MMM YYYY") } </span>
      </div>
    </div>;
  };



  const [visibleDialog, setVisibleDialog] = useState(true);
  const [sort, setSort] = useState([{}]);


  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
  };



  const [selected, setSelected] = React.useState(0);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };



  const gridcell = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];

    const project = props.dataItem['projectname']

    const objWithoutPqi = _.omit(
      pqidataproject2.filter(
        (v) => v.month.includes(moment().format("YY년")) && v.projectname === project
      )[0],
      ["pqi", "averagePqi", "projectname", "projectcode", "division", "month"]
    );
    
    const max = _.max(Object.values(objWithoutPqi));
    const min = _.min(Object.values(objWithoutPqi));

    const averageMax = _.maxBy(  pqidataproject2.filter((v) => v.month.includes(moment().format("YY년"))) , 'averagePqi')['averagePqi']
    const averageMin = _.minBy(  pqidataproject2.filter((v) => v.month.includes(moment().format("YY년"))) , 'averagePqi')['averagePqi']


    return (
      <td style={{ textAlign: 'center', 
       color:
       field !== 'averagePqi' && max === value ? '#0072B5' :
       field !== 'averagePqi' &&min === value ? '#CD212A' : 
       field === 'averagePqi' && averageMax === value? '#0072B5': field === 'averagePqi' && averageMin === value? '#CD212A': 
      'black' }}
      >
      {isNaN(value)? typeof value === 'undefined'? '-':value:value.toFixed(2)}
      </td>
    );
  };


  const gridcellv = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];

    const vendor = props.dataItem['vendor']
    const project = props.dataItem['projectname']

    const objWithoutPqi = _.omit(
      pqidataproject3.filter(
        (v) => v.month.includes(moment().format("YY년")) && v.projectname === project && v.vendor === vendor
      )[0],
      ["pqi", "averagePqi", "projectname", "projectcode", "division", "month", "group", "vendor"]
    );
    

    const max = _.max(Object.values(objWithoutPqi));
    const min = _.min(Object.values(objWithoutPqi));

    const averageMax = _.maxBy(  pqidataproject3.filter((v) => v.month.includes(moment().format("YY년"))) , 'averagePqi')['averagePqi']
    const averageMin = _.minBy(  pqidataproject3.filter((v) => v.month.includes(moment().format("YY년"))) , 'averagePqi')['averagePqi']

    return (
      <td style={{ textAlign: 'center', 
       color:
       field !== 'averagePqi' && max === value ? '#0072B5' :
       field !== 'averagePqi' &&min === value ? '#CD212A' : 
       field === 'averagePqi' && averageMax === value? '#0072B5': field === 'averagePqi' && averageMin === value? '#CD212A': 
      'black' }}
      >
      {isNaN(value)? typeof value === 'undefined'? '-':value:value.toFixed(2)}
      </td>
    );
  };



  const _export = useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const _export2 = useRef(null);

  const excelExport2 = () => {
    if (_export2.current !== null) {
      _export2.current.save();
    }
  };


  const currentYear = new Date().getFullYear();
  // 12개월 객체 배열 생성
  const months = [];
  for (let i = 1; i <= 12; i++) {
    // 0을 채워서 두 자리로 만들어주기
    const monthString = String(i).padStart(2, '0');
    // 객체 배열에 월 추가
    months.push({ month: `${currentYear.toString().slice(2, 4)}년 ${monthString}월` });
  }


  return (
    <>
      <div style={{ position: "absolute", right: "20px", top: "50px", zIndex: 999999 }}>
        <Button onClick={toggleDialog} size='small' rounded={null} style={{ fontSize: 12 }}>
          {t("연간 PQI")}
        </Button>
      </div>
      <Card style={{ height: (BoxHeight - 70) / 2, margin: '12px', fontSize: '12px' }}>
        <CardTitle style={{ height: '35px', margin: '6px 0px 0px 12px', fontSize: '0.9rem', fontWeight: '600', color: '#363945', zIndex: '9999999' }}>
          <span style={{ float: 'left' }}>PQI</span>
        </CardTitle>
        <CardBody style={{ height: ((BoxHeight - 70) / 2) - 47, margin: '12px' }}>
          <GridLayout style={{ width: '100%', height: ((BoxHeight - 70) / 2) - 47 }} rows={[
            {
              height: '15%',
            },
            {
              height: '35%',
            }, {
              height: '42%',
            }, {
              height: '8%',
            },
          ]}
            cols={[
              {
                width: '100%',
              },


            ]}
            gap={{
              rows: 0,
              cols: 0,
            }}>
            <GridLayoutItem row={1} col={1} style={{ position: 'relative', textAlign: 'center', marginTop: '-12px' }}>
              <span style={{ fontSize: '1.8rem', color: pqi && pqi[pqi.length - 1] && pqi[pqi.length - 1]['pqi'] > 80 ? '#00A170' : pqi && pqi[pqi.length - 1] && pqi[pqi.length - 1]['pqi'] < 70 ? '#E13D43' : '#F49B42' }}>
                {pqi[pqi.length - 1] && pqi[pqi.length - 1]['pqi']}
              </span>
            </GridLayoutItem>
            <GridLayoutItem row={2} col={1} style={{ position: 'relative' }}>
              <div
                id={CHART_ID}
                style={{ width: "100%", height: (((BoxHeight - 62) / 2) - 40) * 0.4, zIndex: '999', margin: '0 auto' }}
              ></div>



            </GridLayoutItem>
            <GridLayoutItem row={3} col={1} style={{ position: 'relative' }}>
              <ListView data={pqilistdata_filter} item={MyItemRender} style={{ overflow: 'auto', height: '100%', width: "100%", textAlign: 'center' }} />

            </GridLayoutItem>
            <GridLayoutItem row={4} col={1} style={{ position: 'relative', textAlign: 'center' }}>

              <span className='k-icon k-font-icon k-i-circle' style={{ fontSize: '1.5rem', color: '#E13D43' }}></span><span>Bad </span><span> PQI &lt; 70 </span>

              <span className='k-icon k-font-icon k-i-circle' style={{ fontSize: '1.5rem', color: '#F49B42' }}></span><span>Avg. </span><span> 70 ≤ PQI ≤ 80 </span>
              <span className='k-icon k-font-icon k-i-circle' style={{ fontSize: '1.5rem', color: '#00A170' }}></span><span>Good </span><span> PQI &gt; 80</span>
            </GridLayoutItem>
          </GridLayout>

        </CardBody>
      </Card>

      {!visibleDialog && (

        <Dialog title={"연간 PQI"} onClose={toggleDialog} width='80%' height='700px'>
          <TabStrip className='qtab' selected={selected} onSelect={handleSelect}>
            <TabStripTab title="PJT">
              <ExcelExport data={pqidataproject2} ref={_export}>


                <Grid

                  data={orderBy(pqidataproject2, sort)}
                  sortable={true}
                  sort={sort}
                  onSortChange={(e) => {
                    setSort(e.sort);
                  }}
                  className='vendorpqi'
                  style={{height:580}}
                >
                  <GridToolbar>
                    <button
                      title="Export Excel"
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={excelExport}
                      style={{ position: 'absolute', right: '5px' }}
                    >
                      <span className="k-icon k-font-icon k-i-file-excel"></span>
                    </button>
                  </GridToolbar>
                  <GridColumn field='projectname' title={t('프로젝트')} />

                  {months.map((v) =>
                    <GridColumn field={v.month} title={i18n.language === "ko"?v.month:moment("20"+v.month.slice(0,2)+"-"+v.month.slice(4,6)+"-01").format("MMM YYYY") }  cell={gridcell} />
                  )}
                  <GridColumn field='averagePqi' title={t('평균')} cell={gridcell} />
                </Grid>
              </ExcelExport>
            </TabStripTab>
            <TabStripTab title="Biz.P">
              <ExcelExport data={pqidataproject3} ref={_export2}>
                <Grid

                  data={orderBy(pqidataproject3, sort)}
                  sortable={true}
                  sort={sort}
                  onSortChange={(e) => {
                    setSort(e.sort);
                  }}
                  className='vendorpqi2'
                  style={{height:580}}
                >
                  <GridToolbar>
                    <button
                      title="Export Excel"
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={excelExport2}
                      style={{ position: 'absolute', right: '5px' }}
                    >
                      <span className="k-icon k-font-icon k-i-file-excel"></span>
                    </button>
                  </GridToolbar>
                  <GridColumn field="projectname" title={t("프로젝트")} />
                  <GridColumn field="vendor" title={t("업체명")} />
                  {months.map((v) =>
                    <GridColumn field={v.month} title={i18n.language === "ko"?v.month:moment("20"+v.month.slice(0,2)+"-"+v.month.slice(4,6)+"-01").format("MMM YYYY") }  cell={gridcellv} />
                  )}
                  <GridColumn field='averagePqi' title={t('평균')} cell={gridcellv} />

                </Grid>

              </ExcelExport>
            </TabStripTab>
          </TabStrip>

        </Dialog>
      )}

    </>
  );
}

export default PQI;