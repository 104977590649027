import React, { useEffect, useState, useLayoutEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
 import axios from "axios";import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import { orderBy } from "@progress/kendo-data-query";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useTranslation } from 'react-i18next';
import { generateUpdatedDate } from '../common/datadate';

am5.addLicense("AM5C363108325");

const CHART_ID = 'radar_chart';
const { REACT_APP_URI2} = process.env;

const ProjectNDE = ({ positiondata, order, blockH, data }) => {
    const [BoxHeight, setBoxHeight] = useState(250);
    const { project_code } = useParams();

    const [criteria, setcriteria] =useState(3);
    const [calcriteria, setcalcriteria] =useState("Joints");

    const [projectName, setprojectName] = useState("");
    const { t } = useTranslation();


useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/api/getproject`,);

      setprojectName(res.data.data.data.filter((com) => com.shell_number === project_code)[0]["ugenProjectName"])
    }
    fetchData();

  }, [project_code]);

    
    
  useEffect(() => {
    const fetchData = async () => {
        let body = {
            bpname: "Project Setting",
            "record_fields": "DefectRateCalculate;d_name;DefectRateStandard",
            lineitem: "no",
            filter_condition: "status=Active",
          };
          const res = await axios.post(`/api/getbprecord?path=${project_code}`, body);

          setcriteria(res.data.data.data[0]['DefectRateStandard'])
          setcalcriteria(res.data.data.data[0]['DefectRateCalculate'])

    };
    fetchData();
  }, [project_code]);


    const lineItems = data&&data.reduce((c, v) => c.concat(v._bp_lineitems.filter(f=>f.NDEType !== null).map(o => Object.assign(o, {
        "WelderID": v.WelderID,
        "WelderName": v.d_name,
        "CompanyName": v.d_subcontractor_bpk,
        "rejectJoint": o.TotalFilmQuantities > 0 && o.RejectFilmQuantities > 0 ? 1 : 0,
        "Joint": o.TotalFilmQuantities > 0 ? 1 : 0,
    }))), [])

    const projectweldingrate =
        calcriteria === "Joints" ?
            100 * (_.sumBy(lineItems, "rejectJoint") / _.sumBy(lineItems, "Joint")) 
            :
            100 * (_.sumBy(lineItems, "RejectFilmQuantities") / _.sumBy(lineItems, "TotalFilmQuantities"));


    function isDateInThisWeek(dateStr) {
        const date = moment(dateStr, 'MM-DD-YYYY');
        const now = moment();
      
        // 주의 시작일과 종료일 계산
        const startOfWeek = now.clone().startOf('week');
        const endOfWeek = now.clone().endOf('week');
      
        // 날짜가 현재 주에 있는지 확인
        return date.isBetween(startOfWeek, endOfWeek, null, '[]');
    }
    

    var groupbytype =
        lineItems &&
        _(lineItems)
            .groupBy('NDEType')
            .map((objs, key) => ({
                'type': key,
                'PassJoint': _.sumBy(objs, "Joint") - _.sumBy(objs, "rejectJoint"),
                'NonPassJoint': _.sumBy(objs, "rejectJoint"),
                'PassFlim': _.sumBy(objs, "TotalFilmQuantities") - _.sumBy(objs, "RejectFilmQuantities"),
                'NonPassFilm': _.sumBy(objs, "RejectFilmQuantities"),
                'PassJointRate': (_.sumBy(objs, "rejectJoint") / _.sumBy(objs, "Joint")) * 100,
                'PassFilmRate': (_.sumBy(objs, "RejectFilmQuantities") / _.sumBy(objs, "TotalFilmQuantities")) * 100,

                'weekPassJoint': _.sumBy(objs.filter(v=>isDateInThisWeek(v.NDEDate.slice(0,10))), "Joint") - _.sumBy(objs.filter(v=>isDateInThisWeek(v.NDEDate.slice(0,10))), "rejectJoint"),
                'weekNonPassJoint': _.sumBy(objs.filter(v=>isDateInThisWeek(v.NDEDate.slice(0,10))), "rejectJoint"),
                'weekPassFlim': _.sumBy(objs.filter(v=>isDateInThisWeek(v.NDEDate.slice(0,10))), "TotalFilmQuantities") - _.sumBy(objs.filter(v=>isDateInThisWeek(v.NDEDate.slice(0,10))), "RejectFilmQuantities"),
                'weekNonPassFilm': _.sumBy(objs.filter(v=>isDateInThisWeek(v.NDEDate.slice(0,10))), "RejectFilmQuantities"),
                'weekPassJointRate': (_.sumBy(objs.filter(v=>isDateInThisWeek(v.NDEDate.slice(0,10))),"rejectJoint") / _.sumBy(objs.filter(v=>isDateInThisWeek(v.NDEDate.slice(0,10))),"Joint")) * 100,
                'weekPassFilmRate': (_.sumBy(objs.filter(v=>isDateInThisWeek(v.NDEDate.slice(0,10))),"RejectFilmQuantities") / _.sumBy(objs.filter(v=>isDateInThisWeek(v.NDEDate.slice(0,10))),"TotalFilmQuantities")) * 100,

            }))
            .value()





    useEffect(() => {
        const boxHeight = positiondata[order];
        setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
    }, [positiondata, order, blockH]);

    useLayoutEffect(() => {

        let root = am5.Root.new(CHART_ID);
        root.setThemes([am5themes_Animated.new(root)]);

        var chart = root.container.children.push(
            am5radar.RadarChart.new(root, {
                panX: false,
                panY: false,
                startAngle: 180,
                endAngle: 360
            })
        );

        chart.getNumberFormatter().set("numberFormat", "#'%'");

        var axisRenderer = am5radar.AxisRendererCircular.new(root, {
            strokeOpacity: 0.1,
            minGridDistance: 30
        });


        axisRenderer.ticks.template.setAll({
            visible: true,
            strokeOpacity: 0.5
        });

        axisRenderer.grid.template.setAll({
            visible: false
        });

        var axis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                maxDeviation: 0,
                min: 0,
                max: 10,
                strictMinMax: true,
                renderer: axisRenderer
            })
        );

        //첫번째
        var rangeDataItem = axis.makeDataItem({
            value: 0,
            endValue: criteria
        });

        var range = axis.createAxisRange(rangeDataItem);

        rangeDataItem.get("axisFill").setAll({
            visible: true,
            // fill: am5.color(0x19d228),
            // fillOpacity: 0.8,
            strokeOpacity: 1,
            innerRadius: -10,
            strokeWidth: 15,
            strokeGradient: am5.LinearGradient.new(root, {
                rotation: 0,
                stops: [
                    { color: am5.color(0x19d228) },
                    { color: am5.color(0xf4fb16) },

                ]
            })
        });

        rangeDataItem.get("tick").setAll({
            visible: false
        });

        rangeDataItem.get("label").setAll({
            text: criteria ===0? "":t("기준 불량률"),
            inside: true,
            radius: -15 / -2 - 5,
            fontSize: "0.9em",
            fill: "black"
        });

        //두번째
        var rangeDataItem2 = axis.makeDataItem({
            value: criteria,
            endValue: 10
        });

        var range2 = axis.createAxisRange(rangeDataItem2);

        rangeDataItem2.get("axisFill").setAll({
            // visible: true,
            // fill: am5.color(0x19d228),
            // fillOpacity: 0.8,
            innerRadius: -10,
            strokeOpacity: 1,
            strokeWidth: 15,
            strokeGradient: am5.LinearGradient.new(root, {
                rotation: 0,
                stops: [
                    // { color: am5.color(0x19d228) },
                    { color: am5.color(0xf4fb16) },
                    { color: am5.color(0xf6d32b) },
                    { color: am5.color(0xfb7116) }
                ]
            })
        });

        rangeDataItem2.get("tick").setAll({
            visible: false
        });

        rangeDataItem2.get("label").setAll({
            text: "",
            inside: true,
            radius: -10 / -2 - 5,
            fontSize: "0.9em",
            fill: am5.color(0xffffff)
        });


        //세번째
        var rangeDataItem3 = axis.makeDataItem({
            value: criteria - 0.02,
            endValue: criteria + 0.02
        });

        var range3 = axis.createAxisRange(rangeDataItem3);

        rangeDataItem3.get("axisFill").setAll({
            visible: true,
            fill: "#9E1030",
            fillOpacity: 0.8,
            innerRadius: -20
        });

        rangeDataItem3.get("tick").setAll({
            visible: false
        });


        var axisDataItem = axis.makeDataItem({});
        axisDataItem.set("value", 0);


        var clockHand = am5radar.ClockHand.new(root, {
            pinRadius: am5.percent(12),
            radius: am5.percent(85),
        })


        clockHand.pin.setAll({
            fill: isNaN(projectweldingrate)? '#ffffff': projectweldingrate < criteria ? am5.color(0x19d228):  am5.color(0xfb7116) ,

        });

        clockHand.hand.setAll({
            fill: isNaN(projectweldingrate)? '#ffffff':projectweldingrate < criteria ? am5.color(0x19d228):  am5.color(0xfb7116) ,
        });


        var bullet = axisDataItem.set("bullet", am5xy.AxisBullet.new(root, {
            sprite: clockHand
        }));

        axis.createAxisRange(axisDataItem);

        var label = chart.radarContainer.children.push(am5.Label.new(root, {
            fill: am5.color(0xffffff),
            centerX: am5.percent(50),
            textAlign: "center",
            centerY: am5.percent(50),
            fontSize: "1em"
        }));



        bullet.get("sprite").on("rotation", function () {
            var value = axisDataItem.get("value");
            var text = Math.round(axisDataItem.get("value")).toString();

            label.set("text",(value).toFixed(1) + "%");

        });


        axisDataItem.animate({
            key: "value",
            to: projectweldingrate,
            // to: 2.7,
            duration: 800,
            easing: am5.ease.out(am5.ease.cubic)
        });

        return () => {
            root && root.dispose();
        };

    }, [projectweldingrate, criteria]);

    const [visibleDialog, setVisibleDialog] = useState(true);

    const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
    };

    const [sort, setSort] = useState([{}]);



    const gridcell = (props) => {
        const field = props.field || "";
        const value = props.dataItem[field];

    
        return (
          <td
            style={{
              font: "normal normal normal 0.8rem Pretendard",
              textAlign:"right",
            }}
          >
            { isNaN(value) && field !== "type"?"-" :field.includes("Rate") ?value && value.toFixed(2) + "%" : value}
          </td>
        );
      };

    return (
        <>

<div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>
{groupbytype && generateUpdatedDate(new Date())}
</div>
            <div
                style={{
                    position: "absolute",
                    top: "50px",
                    right: "10px",
                    zIndex: "100",
                }}
            >
                <Button
                    title={t("상세내용 테이블로 보기")}
                    iconClass="k-icon k-font-icon k-i-grid"
                    fillMode="flat"
                    onClick={toggleDialog}
                ></Button>
            </div>
            <div
                id={CHART_ID}
                style={{ width: "100%", height: BoxHeight, zIndex: '999', margin: '0 auto' }}
            ></div>


            {!visibleDialog && (
                <Dialog
                    title={`${projectName} ${t("프로젝트 용접불량률")}`}
                    onClose={toggleDialog}
                    width={"60%"}
                    // height={"80%"}
                >               
            
                <Grid
                    data={orderBy(groupbytype, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                        setSort(e.sort);
                    }}
                    style={{ overflowY:'auto' }}
                    className='centertable'
                >

                        <GridColumn field="type" title={t("비파괴 종류")} cell={gridcell} />
                        <GridColumn title={t("금주현황")} >
                        <GridColumn title={t("총 비파괴 시험현황")} >
                                <GridColumn title={t("합격")} >
                                    <GridColumn field="weekPassJoint" title="Joints" cell={gridcell}/>
                                    <GridColumn field="weekPassFlim" title="Film"cell={gridcell} />
                                </GridColumn>
                                <GridColumn title={t("불합격")} >
                                    <GridColumn field="weekNonPassJoint" title="Joints" cell={gridcell}/>
                                    <GridColumn field="weekNonPassFilm" title="Film" cell={gridcell}/>
                                </GridColumn>

                            </GridColumn>
                            <GridColumn title="" >
                            <GridColumn title={t("불량률")} >
                                <GridColumn field="weekPassJointRate" title="Joints" cell={gridcell}/>
                                <GridColumn field="weekPassFilmRate" title="Film " cell={gridcell}/>
                            </GridColumn>
                            </GridColumn>
                        </GridColumn>

                        <GridColumn title={t("누적현황")} >
                            <GridColumn title={t("총 비파괴 시험현황")} >
                                <GridColumn title={t("합격")} >
                                    <GridColumn field="PassJoint" title="Joints"cell={gridcell} />
                                    <GridColumn field="PassFlim" title="Film" cell={gridcell}/>
                                </GridColumn>
                                <GridColumn title={t("불합격")} >
                                    <GridColumn field="NonPassJoint" title="Joints" cell={gridcell}/>
                                    <GridColumn field="NonPassFilm" title="Film" cell={gridcell}/>
                                </GridColumn>

                            </GridColumn>
                            <GridColumn title="" >
                            <GridColumn title={t("불량률")} >
                                <GridColumn field="PassJointRate" title="Joints" cell={gridcell}/>
                                <GridColumn field="PassFilmRate" title="Film " cell={gridcell}/>
                            </GridColumn>
                            </GridColumn>
                        </GridColumn>

                    </Grid>

                </Dialog>
            )}
        </>

    );

}

export default ProjectNDE