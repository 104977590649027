import React, { useState ,useEffect} from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Input } from "@progress/kendo-react-inputs";
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Hint } from "@progress/kendo-react-labels";
import Swal from 'sweetalert2';


const { REACT_APP_URI2 } = process.env;


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const ToggleButton = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'snow!important',
        backgroundColor: 'black',
    },
    '&.MuiToggleButton-root': {
        color: "black",
        fontSize: '0.7rem',
        fontFamily: 'Pretendard-R',
        padding: 0,
        width: 50,
        borderRadius: '0px 0px 0px 0px!important',
        border: '1px solid black!important'

    }
}));


const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(0)
        }
    }
}));

const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
});



export default function SignIn() {

    const location = useLocation();

    const history = useHistory();  // useHistory 훅 추가
    const { t, i18n } = useTranslation();


    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        // URL 쿼리 파라미터에서 리다이렉션 경로 저장
        const queryParams = new URLSearchParams(location.search);
        const redirectPath = queryParams.get('redirect') || '/';
        location.state = {...location.state, redirectPath };
    }, [location]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const language = i18n.language;
    
        // 로그인 시도 시작 알림
        let loginAttemptAlert = Swal.fire({
            title: t('로그인 시도 중...'),
            text: t('로그인을 시도하고 있습니다. 잠시만 기다려주세요.'),
            icon: 'info',
            allowOutsideClick: false, // 사용자가 창 밖을 클릭하여 알림을 닫지 못하게 함
            showConfirmButton: false // 확인 버튼을 표시하지 않음
        });
    
        try {
            const url = new URL(window.location.href);
            const pathSegments = url.pathname.split('/').filter(segment => segment !== '');
            const project_code = pathSegments[0];
            const owner = pathSegments[1];
    
            console.log(location.state)
            const tr = location && location.state && location.state.from.pathname.split("/")[2];
    
            let project;
            if (pathSegments[0] === '/total') {
                project = 'total';
            } else if (tr === "clientTR") {
                project = pathSegments[0] + "/clientTR";
            } else {
                project = pathSegments[0];
            }
    
            const response = await axios.post(`/api/login`, { username, password, project_code, language, owner }, { withCredentials: true });
    
            // 로그인 시도 알림 닫기
            Swal.close();
    
            if (response.data.success) {
                const redirectPath = owner === "client" ? `/${project}/${owner}` : `/${project}`;
                Swal.fire({
                    title: t("로그인 성공!"),
                    text: response.data.message,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    history.push(location.state.redirectPath, { ...location.state, username });

                });
    
            } else {
                Swal.fire({
                    title: t('문제가 있습니다!'),
                    text: response.data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } catch (err) {

            console.log(err)
            Swal.close(); // 로그인 시도 알림 닫기
            const errorMessage = err.response?.data?.error || t('로그인 중 오류가 발생했습니다.');
            Swal.fire({
                title: t('문제가 있습니다!'),
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };
    
    const [selectedValue, setSelectedValue] = React.useState("ko");

    const handleChange = (event, selectedValue) => {
        if (selectedValue !== null) {
            setSelectedValue(selectedValue);
            i18n.changeLanguage(selectedValue);
        }
    };

    function Copyright(props) {
        return (
            <>

                <Typography variant="body2" color="text.secondary" align="center" {...props}>
                    {t("S.CON 문의")}: <Link color="inherit" href="mailto:sconhelp@partner.skecoengineering.com">sconhelp@partner.skecoengineering.com</Link><br />
                    {'Copyright © '}
                    <Link color="inherit" href="https://www.skecoengineering.com/">
                        SK Ecoengineering
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </>
        );
    }
    return (
        <>


            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Grid >
                            <img src='/image/sconlogoH.png' width={200} />
                        </Grid>
                        {/* <br/> */}
                        <Typography component="h1" variant="h5">
                            {t("DashBoard Sign in")}
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} style={{width:350}}>

                            <div style={{ fontSize: '0.8rem' }}>{t("S.CON Login Name")}</div>
                            <Input
                                name="username"
                                style={{
                                    width: "100%",
                                }}
                                // label="S.CON Login Name"
                                placeholder={t("loginplaceholder")}
                                // pattern={"[A-Za-z]+"}
                                minLength={2}
                                required={true}
                                onChange={handleUsernameChange}
                                autoComplete="off"
                            />

                            <br />
                            <br />
                            <div style={{ fontSize: '0.8rem' }}>{t("DashBoard Password")}</div>
                            <Input
                                name="password"
                                type="password"
                                style={{
                                    width: "100%",
                                }}
                                // label="S.CON Login Name"
                                placeholder={t("passwordplaceholder")}
                                // pattern={"[A-Za-z]+"}
                                minLength={2}
                                required={true}
                                onChange={e => setPassword(e.target.value)}
                                autoComplete="off"

                            />
                            <Hint> {t("대시보드암호힌트")}</Hint>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={username.length > 0 && password.length > 0 ? false : true}
                            >
                                {t("Sign In")}
                            </Button>
                            <Grid container>

                                <Grid item>
                                    <Link
                                        component={RouterLink}
                                        to={{
                                            pathname: "/signup",
                                            state: { language: i18n.language }
                                        }}
                                        variant="body2"
                                    >
                                        {t("passwordquestion")}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Container>
            </ThemeProvider>

            <div style={{ position: 'absolute', left: "50%", transform: "translate(-50%)", zIndex: '999', textAlign: 'center' }}>

                <ThemeProvider theme={theme}>
                    <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="언어">
                        <ToggleButton value="ko" aria-label='한국어' >
                            한국어
                        </ToggleButton>
                        <ToggleButton value="en" aria-label="english">
                            ENG
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ThemeProvider>
            </div>
        </>
    );
}
