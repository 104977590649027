import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { generateUpdatedDate } from "../common/datadate";

const { REACT_APP_URI2, REACT_APP_PATH2 } = process.env;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
const EnvIssue = ({ positiondata, order, blockH }) => {
  const [BoxHeight, setBoxHeight] = useState(250);
  const [issueDate, setIssueDate] = useState("");
  const [issueText, setIssueText] = useState("");
  const { height, width } = useWindowDimensions();
  const { project_code } = useParams();
  const { t, i18n } = useTranslation();

  // useEffect(() => {
  //   if (positiondata.length > 0) {
  //     const boxHeight = positiondata[3];
  //     setBoxHeight(200 + 140 * (boxHeight.rowSpan - 2));
  //   }
  // }, [positiondata]);

  useEffect(() => {
    const boxHeight = positiondata[order];

    // setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 35);
  }, [positiondata, order, blockH]);

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        bpname: "환경 이슈사항",
        lineitem: "yes",
      };
      const res = await axios.post(
        `/api/getbprecord?path=${project_code}`,
        body
      );

      const unifierData = res.data.data.data;

      if (unifierData.length > 0) {
        const dataSorting = unifierData
          .map((com) => {
            return {
              ...com,
              date: new Date(com.genSubmitDO),
            };
          })
          .sort((a, b) => new Date(a.date) - new Date(b.date));

        setIssueDate(
          moment(dataSorting[dataSorting.length - 1].genSubmitDO).format(
            i18n.language === "ko" ? "YY년 MM월 DD일" : "MMM YYYY"
          )
        );
        setIssueText(dataSorting[dataSorting.length - 1].genIssueDescRT);
      } else {
        setIssueDate("");
        setIssueText("");
      }
    };
    fetchData();
  }, [project_code, i18n.language]);

  function createMarkup() {
    return { __html: issueText };
  }

  function MyComponent() {
    return <div dangerouslySetInnerHTML={createMarkup()} />;
  }

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "22px",
          right: "15px",
          zIndex: "100",
          color: "snow",
          fontSize: "0.7rem",
        }}
      >
        {issueDate && generateUpdatedDate(new Date())}
      </div>
      <div style={{ overflow: "auto", height: BoxHeight }}>
        {issueDate !== "" ? (
          <>
            <div style={{ marginBottom: "10px", font: "0.8rem pretendard-R" }}>
              {t("발생일자")}: {issueDate}
            </div>
            <div style={{ font: "0.8rem pretendard-R" }}>{MyComponent()}</div>
          </>
        ) : (
          <div style={{ marginBottom: "10px", font: "0.8rem pretendard-R" }}>
            {t("특이사항 없음")}
          </div>
        )}
      </div>
    </>
  );
};

export default EnvIssue;
