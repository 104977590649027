import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import krMessages from "./kr.json";
import CustomCalendar from "../ALL/common/customcalendar";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
} from "@progress/kendo-react-charts";
import "hammerjs"
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import { DatePicker, DateRangePicker } from "@progress/kendo-react-dateinputs";
import moment from "moment";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
import { Button } from "@progress/kendo-react-buttons";
import { orderBy } from "@progress/kendo-data-query";
import { Dialog } from "@progress/kendo-react-dialogs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import axios from "axios"; import Cookies from 'js-cookie';
import { ComboBox, DropDownList } from "@progress/kendo-react-dropdowns";
import { useTranslation } from 'react-i18next';
import { generateUpdatedDate } from '../common/datadate';
import PQIData from "../pqi/pqidata";
import { Circles } from 'react-loader-spinner';
import { filterBy } from "@progress/kendo-data-query";
load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);
loadMessages(krMessages, "ko-KR");

const ToggleButton = styled(MuiToggleButton)(() => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "black",
    backgroundColor: "#F5DF4D",
  },
  "&.MuiToggleButton-root": {
    fontSize: "0.7rem",
    fontFamily: "Pretendard-R",
    padding: 0,
    width: 50,
    borderRadius: "0px 0px 0px 0px!important",
    border: "1px solid rgba(0, 0, 0, 0.12)!important",
  },
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
  "&.MuiToggleButtonGroup-root": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(0),
    },
  },
}));

const theme = createTheme({
  palette: {
    text: {
      primary: "#00ff00",
    },
  },
});

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const { REACT_APP_URI2, REACT_APP_PATH2 } = process.env;;

const MonthPQI = ({
  positiondata,
  order,
  blockH,
}) => {
  const { height, width } = useWindowDimensions();
  const [selectedValue, setSelectedValue] = React.useState("SKEE");
  const { project_code } = useParams();

  const [vendordata, setvendordata] = React.useState([]);
  const { t, i18n } = useTranslation();



  const data1 = PQIData();



  const data = {
    pqi:[...data1.pqi],
    pqi_v:[...data1.pqi_v],
    checkpoint:[...data1.checkpoint],
    issuerate:[...data1.issuerate],
    ncrdata_point:[...data1.ncrdata_point],
    cardata_point:[...data1.cardata_point],
    punchdata_point:[...data1.punchdata_point],
    ncr_pendig_point:[...data1.ncr_pendig_point],
    car_pendig_point_v:[...data1.car_pendig_point_v],
    ncr_pendig_point_v:[...data1.ncr_pendig_point_v],
  }


  const ncr_pendig_point_v = data && data.ncr_pendig_point_v
  const pqi = data && data.pqi
  const pqi_v = data && data.pqi_v.filter(v=>v.PQI !== "N/A")
  // .map(v=>({ ...v, month:i18n.language ==="ko"? v.month: moment("20"+v.month.slice(0,2) +"-"+v.month.slice(4,6) +"-01").format("MMM YYYY")}))

  const checkpoint = data && data.checkpoint
  const issuerate = data && data.issuerate
  const ncrdata_point = data && data.ncrdata_point
  const cardata_point = data && data.cardata_point
  const punchdata_point = data && data.punchdata_point
  const ncr_pendig_point = data && data.ncr_pendig_point
  const car_pendig_point_v = data && data.car_pendig_point_v

  useEffect(() => {
    const fetchData = async () => {

      let body =
      {
        "bpname": "Business Partner 계약현황 (WF)",
        "lineitem": "no",
      }

      const res = await axios.post(`/api/getbprecord?path=${project_code}`, body)
      setvendordata(
        res.data.data.data.map((v) => ({
          vendor: v.d_subcontractor_name, discipline: v.d_subcon_discipline_spd
        }))
      )

    };

    fetchData();
  }, [project_code]);

  const [selectedVendor, setSelectedVendor] = React.useState(null);

  const VendorChange = (event) => {
    setSelectedVendor(event.target.value);
  };



  const pqi_vendor_solo = pqi_v && pqi_v.filter(v => v.vendor === selectedVendor?.vendor)
    .map((v) => ({
      month: v.month,
      "자재검사 신청": v["자재검사 신청"],
      "자재검사 실패율": v["자재검사 실패율"],
      "자재검사 완료": v["자재검사 완료"],
      "시공검사 신청": v["시공검사 신청"],
      "시공검사 실패율": v["시공검사 실패율"],
      "시공검사 완료": v["시공검사 완료"],
      "NCR 발행건수": v["NCR 발행건수"],
      "NCR 지연": v["NCR 지연"],
      "CAR 발행건수": v["CAR 발행건수"],
      "CAR 지연": v["CAR 지연"],
      PQI: v["PQI"],
    }))


    const pqi_vendor_avg = _(pqi_v)
    .groupBy("month")
    .map((objs, key) => {
      // "NCR 지연"의 값이 숫자가 아닌 경우 "N/A"로 설정
      const validNCRDelay = objs.filter(obj => !isNaN(obj["NCR 지연"])).map(obj => obj["NCR 지연"]);
      const ncrDelayAvg = validNCRDelay.length > 0 ? _.sum(validNCRDelay) / validNCRDelay.length : "N/A";

      const validCARDelay = objs.filter(obj => !isNaN(obj["CAR 지연"])).map(obj => obj["CAR 지연"]);
      const carDelayAvg = validCARDelay.length > 0 ? _.sum(validCARDelay) / validCARDelay.length : "N/A";
  
      return {
        month: key,
        "자재검사 신청": _.sumBy(objs, "자재검사 신청") / objs.length,
        "자재검사 실패율": _.sumBy(objs, "자재검사 실패율") / objs.length,
        "자재검사 완료": _.sumBy(objs, "자재검사 완료") / objs.length,
        "시공검사 신청": _.sumBy(objs, "시공검사 신청") / objs.length,
        "시공검사 실패율": _.sumBy(objs, "시공검사 실패율") / objs.length,
        "시공검사 완료": _.sumBy(objs, "시공검사 완료") / objs.length,
        "NCR 발행건수": _.sumBy(objs, "NCR 발행건수") / objs.length,
        "NCR 지연": ncrDelayAvg,
        "CAR 발행건수": _.sumBy(objs, "CAR 발행건수") / objs.length,
        "CAR 지연": carDelayAvg,
        // // "Punch 발행건수": _.sumBy(objs, "Punch 발행건수") / objs.length,
        // PQI: ncrDelayAvg ==="N/A" && carDelayAvg === "N/A"? : _.sumBy(objs, "PQI") / objs.length,
      };
    })
    .value()
    .map((v) => {
      let pqi = v["자재검사 신청"] + v["자재검사 실패율"] + v["자재검사 완료"] +
                v["시공검사 신청"] + v["시공검사 실패율"] + v["시공검사 완료"] +
                v["NCR 발행건수"] + (isNaN(v["NCR 지연"]) ? 0 : v["NCR 지연"]) +
                v["CAR 발행건수"] + (isNaN(v["CAR 지연"]) ? 0 : v["CAR 지연"]);
  
      if (v["NCR 지연"] === "N/A" && v["CAR 지연"] === "N/A") {
        pqi *= 100 / 80;
      } else if (v["NCR 지연"] === "N/A" || v["CAR 지연"] === "N/A") {
        pqi *= 100 / 90;
      } else {
        pqi = pqi
      }
  
      return {...v, PQI: pqi};
    });

  const [BoxHeight, setBoxHeight] = useState(250);
  // const [pqislice, setpqislice] = useState(pqi);
  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

  const defaultvalue = {
    start: new Date(moment().subtract(2, "months")),
    end: new Date(moment().add(-1, "days")),
  };
  const [value, setValue] = useState(defaultvalue);

  function dateRange(startDate, endDate) {
    var start = startDate.split("-");
    var end = endDate.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? "0" + month : month;
        dates.push([i + "년", displayMonth + "월"].join(" "));
      }
    }
    return dates;
  }

  var daylist = dateRange(
    moment(new Date(value.start)).format("YY-MM-DD"),
    moment(new Date(value.end)).format("YY-MM-DD")
  );

  const pqislice = _.filter(pqi, function (o) {
    return daylist.includes(o.month);
  });

  const pqislice_v = _.filter(pqi_vendor_avg, function (o) {
    return daylist.includes(o.month);
  });

  const pqislice_v_solo = _.filter(pqi_vendor_solo, function (o) {
    return daylist.includes(o.month);
  });


  const checkpoint_slice = _.filter(checkpoint, function (o) {
    return daylist.includes(o.month);
  }).map((v) => ({
    ...v,
    d_inspection_date: moment(v.d_inspection_date).format("YYYY-MM-DD HH:mm"),
    RFIEndDate: moment(v.RFIEndDate).format("YYYY-MM-DD HH:mm"),
    InspReqDP: moment(v.InspReqDP).format("YYYY-MM-DD HH:mm"),
    d_actual_insp_date: moment(v.d_actual_insp_date).format("YYYY-MM-DD HH:mm"),
  }));

  const ncr_pendig_point_slice = _.filter(ncr_pendig_point, function (o) {
    return daylist.includes(o.month);
  }).map((v) => ({
    ...v,
    NCPIssueDP: moment(v.NCPIssueDP).format("YYYY-MM-DD HH:mm"),
    Mig_Due_Date: moment(v.Mig_Due_Date).format("YYYY-MM-DD HH:mm"),
    NCPActionCompDP: moment(v.NCPActionCompDP).format("YYYY-MM-DD HH:mm"),
  }));

  const ncr_pendig_point_slice_v = _.filter(ncr_pendig_point_v, function (o) {
    return daylist.includes(o.month);
  }).map((v) => ({
    ...v,
    NCPIssueDP: moment(v.NCPIssueDP).format("YYYY-MM-DD HH:mm"),
    Mig_Due_Date: moment(v.Mig_Due_Date).format("YYYY-MM-DD HH:mm"),
    NCPActionCompDP: moment(v.NCPActionCompDP).format("YYYY-MM-DD HH:mm"),
  }));

  const car_pendig_point_slice_v = _.filter(car_pendig_point_v, function (o) {
    return daylist.includes(o.month);
  }).map((v) => ({
    ...v,
    NCPIssueDP: moment(v.NCPIssueDP).format("YYYY-MM-DD HH:mm"),
    Mig_Due_Date: moment(v.Mig_Due_Date).format("YYYY-MM-DD HH:mm"),
    NCPActionCompDP: moment(v.NCPActionCompDP).format("YYYY-MM-DD HH:mm"),
  }));


  const ncrdata_point_slice_v = _.filter(ncrdata_point, function (o) {
    return daylist.includes(o.month);
  }).map((v) => ({
    ...v,
    NCPIssueDP: moment(v.NCPIssueDP).format("YYYY-MM-DD HH:mm"),
    Mig_Due_Date: moment(v.Mig_Due_Date).format("YYYY-MM-DD HH:mm"),
    NCPActionCompDP: moment(v.NCPActionCompDP).format("YYYY-MM-DD HH:mm"),
  }));

  const punchdata_point_slice_v = _.filter(punchdata_point, function (o) {
    return daylist.includes(o.month);
  }).map((v) => ({
    ...v,
    d_punch_issue_date: moment(v.d_punch_issue_date).format("YYYY-MM-DD HH:mm"),
  }));

  const cardata_point_slice_v = _.filter(cardata_point, function (o) {
    return daylist.includes(o.month);
  }).map((v) => ({
    ...v,
    NCPIssueDP: moment(v.NCPIssueDP).format("YYYY-MM-DD HH:mm"),
    Mig_Due_Date: moment(v.Mig_Due_Date).format("YYYY-MM-DD HH:mm"),
    NCPActionCompDP: moment(v.NCPActionCompDP).format("YYYY-MM-DD HH:mm"),
  }));


  const issuerate_slice = _.filter(issuerate, function (o) {
    return daylist.includes(o.month);
  }).reduce(
    (c, v) =>
      c.concat(
        v.issuepoint_detail.map((o) =>
          Object.assign(o, { month: v.month, issuepoint: v.issuepoint })
        )
      ),
    []
  );

  const pqislice2 = pqislice.map((curr, idx, array) => {
    let count = 0;
    for (const key in curr) {
      if (
        Object.prototype.hasOwnProperty.call(curr, key) &&
        key !== "month" &&
        key !== "pqi" &&
        key !== "lesscount"
      ) {
        if (idx === 0) {
          count = 0;
        } else if (curr[key] < array[idx - 1][key]) {
          count++;
        }
      }
    }
    curr["lesscount"] = count;
    return curr;
  })
    .map(v => ({ ...v, month: i18n.language === "ko" ? v.month : moment("20" + v.month.slice(0, 2) + "-" + v.month.slice(4, 6) + "-01").format("MMM YYYY") }))

  const pqislice2_v = pqislice_v.map((curr, idx, array) => {
    let count = 0;
    for (const key in curr) {
      if (
        Object.prototype.hasOwnProperty.call(curr, key) &&
        key !== "month" &&
        key !== "PQI" &&
        key !== "lesscount"
      ) {
        if (idx === 0) {
          count = 0;
        } else if (curr[key] < array[idx - 1][key]) {
          count++;
        }
      }
    }
    curr["lesscount"] = count;
    return curr;
  })
    .map(v => ({ ...v, month: i18n.language === "ko" ? v.month : moment("20" + v.month.slice(0, 2) + "-" + v.month.slice(4, 6) + "-01").format("MMM YYYY") }))

  const pqislice2_v_solo = pqislice_v_solo.map((curr, idx, array) => {
    let count = 0;
    for (const key in curr) {
      if (
        Object.prototype.hasOwnProperty.call(curr, key) &&
        key !== "month" &&
        key !== "PQI" &&
        key !== "lesscount"
      ) {
        if (idx === 0) {
          count = 0;
        } else if (curr[key] < array[idx - 1][key]) {
          count++;
        }
      }
    }
    curr["lesscount"] = count;
    return curr;
  })
    .map(v => ({ ...v, month: i18n.language === "ko" ? v.month : moment("20" + v.month.slice(0, 2) + "-" + v.month.slice(4, 6) + "-01").format("MMM YYYY") }))

  const notes = {
    label: {
      position: "inside",
    },
    line: {
      length: 0,
      width: 0,
    },
    icon: {
      type: "circle",
      background: "#CD212A",
    },
    position: "right",
  };

  const max = new Date(moment().add(-1, "days"));

  const SharedTooltip = (props) => {
    const { points } = props;

    // 이전 월의 pqi 데이터를 가져오는 함수
    const getPrevMonthPqi = (points, pqiSlice) => {
      const prevMonthIndex =
        _.findIndex(pqiSlice, (o) => o.month === points[0].category) - 1;
      return prevMonthIndex >= 0 ? pqiSlice[prevMonthIndex] : null;
    };

    // 포인트를 렌더링하는 함수
    const renderPoint = (point, index, prevMonthPqi, field) => {
      const value = typeof point?.value === 'number' ? point.value.toFixed(2) : null;

      // 이전 월과 비교하여 값이 변한 방향과 변화량을 계산
      let changed = null;
      let diff = null;
      if (prevMonthPqi) {
        const prevValue = prevMonthPqi[field];
        if (Number(value) > Number(prevValue)) {
          changed = "increased";
          diff = Number(value) - Number(prevValue);
        } else if (Number(value) < Number(prevValue)) {
          changed = "decreased";
          diff = Number(prevValue) - Number(value);
        }

      }


      // 변경된 값에 따라 적절한 스타일 적용
      const style = {};
      if (changed === "increased") {
        style.color = "#0072B5";
      } else if (changed === "decreased") {
        style.color = "#CD212A";
      }

      return (
        <div key={index}>
          {point.series.name} : {isNaN(value) || value === null || value === 0 ? "N/A" : value}
          {changed && diff !== null && (
            <span style={style}>
              {" "}
              {changed === "increased"
                ? `▲ ${diff.toFixed(2)}`
                : `▼ ${diff.toFixed(2)}`}{" "}
            </span>
          )}
        </div>
      );
    };

    // 포인트 배열을 렌더링하는 함수
    const renderPoints = () => {
      const formattedCurrentMonth = moment().format("YY년 MM월");
      const isCurrentMonth = points[0].category === formattedCurrentMonth;

      const prevMonthPqi = getPrevMonthPqi(points, pqislice.map(v => ({
        ...v,
        month: i18n.language === "ko" ? v.month : moment("20" + v.month.slice(0, 2) + "-" + v.month.slice(4, 6) + "-01").format("MMM YYYY")
      })));

      const renderedPoints = [];

      for (let index = 0; index < points.length; index++) {
        const point = points[index];
        renderedPoints.push(renderPoint(point, index, prevMonthPqi, point.series.field));

        // "시공검사 완료"를 찾았고 현재 달이라면 추가 정보 렌더링
        if (point.series.name === "시공검사 완료" && isCurrentMonth) {
          const currentWeekIssuePointValue = data.currentWeekIssuePoint !== null ? (data.currentWeekIssuePoint * 0.15).toFixed(2) : "N/A";
          renderedPoints.push(
            <div key="currentWeek">
              {t("NCR/Punch 1주간 발행점수")}  : {currentWeekIssuePointValue}
            </div>
          );
        }
      }

      return renderedPoints;
    };

    return (
      <div>
        <div>{points[0].category}</div>
        {renderPoints()}
      </div>
    );
  };





  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const SharedTooltip2 = (props) => {
    const { point } = props;

    const { category, categoryIndex, series, dataItem } = point;


    const handle_count = (field) => {
      const series_data = series.data;

      const this_data = series_data[categoryIndex][field];

      if (categoryIndex > 0) {
        const prev_data = series_data[categoryIndex - 1][field];

        const diff_data = this_data - prev_data;

        const diff_data_2 = diff_data < 0 ? diff_data * -1 : diff_data;

        return (
          <span>
            {this_data.toFixed(2)}{" "}
            {diff_data !== 0 &&
              (this_data >= prev_data
                ? "▲ " + diff_data_2.toFixed(2)
                : "▼ " + diff_data_2.toFixed(2))}
          </span>
        );
      } else {
        return <span>{this_data.toFixed(2)}</span>;
      }
    };

    return (
      <div>
        <div>{t(category)}</div>
        {Object.keys(dataItem).map((com, idx) => {
          return (
            <div key={idx}>
              {com === "vendor" ? t("업체명") : series.name} :{" "}
              {typeof dataItem[com] === "number"
                ? handle_count(com) : dataItem[com]}
            </div>
          );
        })}
      </div>
    );
  };

  const sharedTooltipRender2 = (context) => <SharedTooltip2 {...context} />;

  const SharedTooltip3 = (props) => {
    const { points } = props;

    // 이전 월의 pqi 데이터를 가져오는 함수
    const getPrevMonthPqi = (points, pqiSlice) => {
      const prevMonthIndex =
        _.findIndex(pqiSlice, (o) => t(o.month) === points[0].category) - 1;
      return prevMonthIndex >= 0 ? pqiSlice[prevMonthIndex] : null;
    };

    // 포인트를 렌더링하는 함수
    const renderPoint = (point, index, prevMonthPqi, field) => {
      const value = isNaN(point.value) || point.value === null ? "N/A" : point.value.toFixed(2);

      // 이전 월과 비교하여 값이 변한 방향과 변화량을 계산
      let changed = null;
      let diff = null;
      if (prevMonthPqi) {
        const prevValue = prevMonthPqi[field];
        if (Number(value) > Number(prevValue)) {
          changed = "increased";
          diff = Number(value) - Number(prevValue);
        } else if (Number(value) < Number(prevValue)) {
          changed = "decreased";
          diff = Number(prevValue) - Number(value);
        }
      }
      // 변경된 값에 따라 적절한 스타일 적용
      const style = {};
      if (changed === "increased") {
        style.color = "#0072B5";
      } else if (changed === "decreased") {
        style.color = "#CD212A";
        
      }

      return (
        <div key={index}>
          {point.series.name} : {value}
          {changed && diff !== null && (
            <span style={style}>
              {" "}
              {changed === "increased"
                ? `▲ ${diff.toFixed(2)}`
                : `▼ ${diff.toFixed(2)}`}{" "}
            </span>
          )}
        </div>
      );
    };

    // 포인트 배열을 렌더링하는 함수

    const renderPoints = () => {
      const prevMonthPqi = getPrevMonthPqi(points, pqislice_v.map(v => ({ ...v, month: i18n.language === "ko" ? v.month : moment("20" + v.month.slice(0, 2) + "-" + v.month.slice(4, 6) + "-01").format("MMM YYYY") })));
      return points.map((point, index) =>
        renderPoint(point, index, prevMonthPqi, point.series.field)
      );
    };


    return (
      <div>
        <div>{points[0].category}</div>
        {renderPoints()}
      </div>
    );
  };

  const sharedTooltipRender3 = (context) => <SharedTooltip3 {...context} />;

  const SharedTooltip4 = (props) => {
    const { points } = props;

    // 이전 월의 pqi 데이터를 가져오는 함수
    const getPrevMonthPqi = (points, pqiSlice) => {
      const prevMonthIndex =
        _.findIndex(pqiSlice, (o) => t(o.month) === points[0].category) - 1;
      return prevMonthIndex >= 0 ? pqiSlice[prevMonthIndex] : null;
    };

    // 포인트를 렌더링하는 함수
    const renderPoint = (point, index, prevMonthPqi, field) => {
      const value = isNaN(point.value) || point.value === null ? "N/A" : point.value.toFixed(2);
      // 이전 월과 비교하여 값이 변한 방향과 변화량을 계산
      let changed = null;
      let diff = null;
      if (prevMonthPqi) {
        const prevValue = prevMonthPqi[field];
        if (Number(value) > Number(prevValue)) {
          changed = "increased";
          diff = Number(value) - Number(prevValue);
        } else if (Number(value) < Number(prevValue)) {
          changed = "decreased";
          diff = Number(prevValue) - Number(value);
        }
      }
      // 변경된 값에 따라 적절한 스타일 적용
      const style = {};
      if (changed === "increased") {
        style.color = "#0072B5";
      } else if (changed === "decreased") {
        style.color = "#CD212A";
      }

      return (
        <div key={index}>
                    {point.series.name} : {value}

          {changed && diff !== null && (
            <span style={style}>
              {" "}
              {changed === "increased"
                ? `▲ ${diff.toFixed(2)}`
                : `▼ ${diff.toFixed(2)}`}{" "}
            </span>
          )}
        </div>
      );
    };

    // 포인트 배열을 렌더링하는 함수

    const renderPoints = () => {
      const prevMonthPqi = getPrevMonthPqi(points, pqislice_v_solo.map(v => ({ ...v, month: i18n.language === "ko" ? v.month : moment("20" + v.month.slice(0, 2) + "-" + v.month.slice(4, 6) + "-01").format("MMM YYYY") })));
      return points.map((point, index) =>
        renderPoint(point, index, prevMonthPqi, point.series.field)
      );
    };


    return (
      <div>
        <div>{points[0].category}</div>
        {renderPoints()}
      </div>
    );
  };

  const sharedTooltipRender4 = (context) => <SharedTooltip4 {...context} />;

  const labelContent2 = (e) =>
    e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : e.value.toFixed(2);
  const seriesLabels2 = {
    visible: true,
    // Note that visible defaults to false
    padding: 3,
    font: "bold 0.8rem Arial, sans-serif",
    position: "above",
    background: "none",
    content: labelContent2,
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChange2 = (event, selectedValue) => {
    if (selectedValue !== null) {
      setSelectedValue(selectedValue);
    }
  };

  const [visibleDialog, setVisibleDialog] = useState(true);

  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
  };

  const [visibleDialog_v, setVisibleDialog_v] = useState(true);

  const toggleDialog_v = () => {
    setVisibleDialog_v(!visibleDialog_v);
  };

  const [sort, setSort] = useState([{}]);

  const [selected, setSelected] = React.useState(1);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const [selected_v, setSelected_v] = React.useState(1);
  const handleSelect_v = (e) => {
    setSelected_v(e.selected);
  };


  const _export = useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };



  const _export2 = useRef(null);

  const excelExport2 = () => {
    if (_export2.current !== null) {
      _export2.current.save();
    }
  };

  const _export3 = useRef(null);

  const excelExport3 = () => {
    if (_export3.current !== null) {
      _export3.current.save();
    }
  };


  const _export_1 = useRef(null);

  const excelExport_1 = () => {
    if (_export_1.current !== null) {
      _export_1.current.save();
    }
  };

  const _export_2 = useRef(null);

  const excelExport_2 = () => {
    if (_export_2.current !== null) {
      _export_2.current.save();
    }
  };

  const _export_3 = useRef(null);

  const excelExport_3 = () => {
    if (_export_3.current !== null) {
      _export_3.current.save();
    }
  };

  const _export_4 = useRef(null);

  const excelExport_4 = () => {
    if (_export_4.current !== null) {
      _export_4.current.save();
    }
  };

  const _export_5 = useRef(null);

  const excelExport_5 = () => {
    if (_export_5.current !== null) {
      _export_5.current.save();
    }
  };



  return (
    <>
      <div style={{ position: "absolute", top: "-10px", right: "235px" }}>
        <LocalizationProvider language={i18n.language === "ko" ? "ko-KR" : "en"}>
          <IntlProvider locale={i18n.language === "ko" ? "ko-KR" : "en"}>
            <div className="datepick">
              <DateRangePicker
                //  calendar={CustomCalendar}
                max={max}
                format={i18n.language === "ko" ? "yy년 MM월 dd일" : "MMM dd yyyy"}
                defaultValue={defaultvalue}
                defaultShow={false}
                onChange={handleChange}
                value={value}
              />
            </div>
          </IntlProvider>
        </LocalizationProvider>
      </div>
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "250px",
          zIndex: "100",
        }}
      >
        <Button
          title={t("상세내용 테이블로 보기")}
          iconClass="k-icon k-font-icon k-i-grid"
          fillMode="flat"
          onClick={selectedValue === 'SKEE' ? toggleDialog : toggleDialog_v}
        ></Button>
      </div>

      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          {
            height: "100%",
          },
        ]}
        cols={[
          {
            width: "100%",
          },
        ]}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >

        {/* <div style={{ position: "absolute", top: "22px",right: "285px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>
{pqislice2_v && pqislice2 && generateUpdatedDate(moment().add(-1,"days"))}
</div> */}
        <GridLayoutItem row={1} col={1} style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              right: "0px",
              top: "0px",
              zIndex: 999999,
              width: 100,
            }}
          >
            <ThemeProvider theme={theme}>
              <ToggleButtonGroup
                size="small"
                onChange={handleChange2}
                value={selectedValue}
                exclusive
                aria-label="baseunit"
              >
                <ToggleButton value="SKEE" aria-label="SKEE">
                  SKEE
                </ToggleButton>
                <ToggleButton value="Biz" aria-label="Biz">
                  Biz.P
                </ToggleButton>
              </ToggleButtonGroup>
            </ThemeProvider>
          </div>
          <div
            style={{
              position: "absolute",
              left: "0px",
              top: "0px",
              zIndex: 999999,
            }}
          >
            <ComboBox
              style={{
                width: 180,
                display: selectedValue === "SKEE" ? "none" : "flex",
              }}
              data={_.uniqBy(pqi_v, "vendor")}
              textField="vendor"
              onChange={VendorChange}
              value={selectedVendor}

            />

          </div>
          {selectedValue === "SKEE" ? (
            <Chart>
              <ChartArea height={BoxHeight} margin={{ top: 60 }} />
              <ChartLegend visible={false} position="bottom" orientation="horizontal" />
              <ChartTooltip shared={true} render={sharedTooltipRender} />
              <ChartSeries>
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2}
                  name={t("자재검사 신청")}
                  field="자재검사 신청"
                  categoryField="month"
                  color="#f7fbff"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2}
                  name={t("자재검사 실패율")}
                  field="자재검사 실패율"
                  categoryField="month"
                  color="#deebf7"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2}
                  name={t("자재검사 완료")}
                  field="자재검사 완료"
                  categoryField="month"
                  color="#c6dbef"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2}
                  name={t("시공검사 신청")}
                  field="시공검사 신청"
                  categoryField="month"
                  color="#9ecae1"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2}
                  name={t("시공검사 실패율")}
                  field="시공검사 실패율"
                  categoryField="month"
                  color="#6baed6"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2}
                  name={t("시공검사 완료")}
                  field="시공검사 완료"
                  categoryField="month"
                  color="#4292c6"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2}
                  name={t("NCR/Punch 발행건수")}
                  field="NCR/Punch 발행건수"
                  categoryField="month"
                  color="#2171b5"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2}
                  name={t("NCR 지연")}
                  field="NCR 지연"
                  categoryField="month"
                  color="#d0d1e6"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2}
                  name={t("CAR 발행건수")}
                  field="CAR 발행건수"
                  categoryField="month"
                  color="#08519c"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2}
                  name={t("PAR 발행건수")}
                  field="PAR 발행건수"
                  categoryField="month"
                  color="#a8ddb5"
                />
                <ChartSeriesItem
                  type="line"
                  data={pqislice2}
                  field="PQI"
                  name="PQI"
                  categoryField="month"
                  labels={seriesLabels2}
                  noteTextField="lesscount"
                  notes={notes}
                />
              </ChartSeries>

              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  labels={{
                    rotation: "auto",
                  }}
                  majorGridLines={{ step: 100 }}
                  minorGridLines={{ step: 100 }}
                />
              </ChartCategoryAxis>

              <ChartValueAxis>
                <ChartValueAxisItem
                  visible={false}
                  majorGridLines={{ step: 10 }}
                  minorGridLines={{ step: 10 }}
                ></ChartValueAxisItem>
              </ChartValueAxis>
            </Chart>
          ) : selectedValue !== "SKEE" && selectedVendor === null ? (
            <Chart>
              <ChartArea height={BoxHeight} margin={{ top: 60 }} />
              <ChartLegend visible={false} position="bottom" orientation="horizontal" />
              <ChartTooltip shared={true} render={sharedTooltipRender3} />
              <ChartSeries>
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v}
                  name={t("자재검사 신청")}
                  field="자재검사 신청"
                  categoryField="month"
                  color="#f7fbff"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v}
                  name={t("자재검사 실패율")}
                  field="자재검사 실패율"
                  categoryField="month"
                  color="#deebf7"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v}
                  name={t("자재검사 완료")}
                  field="자재검사 완료"
                  categoryField="month"
                  color="#c6dbef"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v}
                  name={t("시공검사 신청")}
                  field="시공검사 신청"
                  categoryField="month"
                  color="#9ecae1"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v}
                  name={t("시공검사 실패율")}
                  field="시공검사 실패율"
                  categoryField="month"
                  color="#6baed6"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v}
                  name={t("시공검사 완료")}
                  field="시공검사 완료"
                  categoryField="month"
                  color="#4292c6"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v}
                  name={t("NCR 발행건수")}
                  field="NCR 발행건수"
                  categoryField="month"
                  color="#2171b5"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v}
                  name={t("NCR 지연")}
                  field="NCR 지연"
                  categoryField="month"
                  color="#d0d1e6"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v}
                  name={t("CAR 발행건수")}
                  field="CAR 발행건수"
                  categoryField="month"
                  color="#08519c"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v}
                  name={t("CAR 지연")}
                  field="CAR 지연"
                  categoryField="month"
                  color="#a8ddb5"
                />
                <ChartSeriesItem
                  type="line"
                  data={pqislice2_v}
                  field="PQI"
                  name="PQI"
                  categoryField="month"
                  labels={seriesLabels2}
                  noteTextField="lesscount"
                  notes={notes}
                />
              </ChartSeries>

              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  labels={{
                    rotation: "auto",
                  }}
                  majorGridLines={{ step: 100 }}
                  minorGridLines={{ step: 100 }}
                />
              </ChartCategoryAxis>

              <ChartValueAxis>
                <ChartValueAxisItem
                  visible={false}
                  majorGridLines={{ step: 10 }}
                  minorGridLines={{ step: 10 }}
                ></ChartValueAxisItem>
              </ChartValueAxis>
            </Chart>
          ) : (
            <Chart>
              <ChartArea height={BoxHeight} margin={{ top: 60 }} />
              <ChartLegend visible={false} position="bottom" orientation="horizontal" />
              <ChartTooltip shared={true} render={sharedTooltipRender4} />
              <ChartSeries>
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v_solo}
                  name={t("자재검사 신청")}
                  field="자재검사 신청"
                  categoryField="month"
                  color="#f7fbff"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v_solo}
                  name={t("자재검사 실패율")}
                  field="자재검사 실패율"
                  categoryField="month"
                  color="#deebf7"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v_solo}
                  name={t("자재검사 완료")}
                  field="자재검사 완료"
                  categoryField="month"
                  color="#c6dbef"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v_solo}
                  name={t("시공검사 신청")}
                  field="시공검사 신청"
                  categoryField="month"
                  color="#9ecae1"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v_solo}
                  name={t("시공검사 실패율")}
                  field="시공검사 실패율"
                  categoryField="month"
                  color="#6baed6"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v_solo}
                  name={t("시공검사 완료")}
                  field="시공검사 완료"
                  categoryField="month"
                  color="#4292c6"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v_solo}
                  name={t("NCR 발행건수")}
                  field="NCR 발행건수"
                  categoryField="month"
                  color="#2171b5"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v_solo}
                  name={t("NCR 지연")}
                  field="NCR 지연"
                  categoryField="month"
                  color="#d0d1e6"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v_solo}
                  name={t("CAR 발행건수")}
                  field="CAR 발행건수"
                  categoryField="month"
                  color="#08519c"
                />
                <ChartSeriesItem
                  type="column"
                  stack={true}
                  data={pqislice2_v_solo}
                  name={t("CAR 지연")}
                  field="CAR 지연"
                  categoryField="month"
                  color="#a8ddb5"
                />
                <ChartSeriesItem
                  type="line"
                  data={pqislice2_v_solo}
                  field="PQI"
                  name="PQI"
                  categoryField="month"
                  labels={seriesLabels2}
                  noteTextField="lesscount"
                  notes={notes}
                />
              </ChartSeries>

              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  labels={{
                    rotation: "auto",
                  }}
                  majorGridLines={{ step: 100 }}
                  minorGridLines={{ step: 100 }}
                />
              </ChartCategoryAxis>

              <ChartValueAxis>
                <ChartValueAxisItem
                  visible={false}
                  majorGridLines={{ step: 10 }}
                  minorGridLines={{ step: 10 }}
                ></ChartValueAxisItem>
              </ChartValueAxis>
            </Chart>

          )}
        </GridLayoutItem>

        {!visibleDialog && (
          <Dialog
            title={t("PQI 상세")}
            onClose={toggleDialog}
            width={"60%"}
            height={"80%"}
          >
            <TabStrip
              className="qtab"
              selected={selected}
              onSelect={handleSelect}
            >
              <TabStripTab title={t("검사 상세")}>
                <ExcelExport data={checkpoint_slice} ref={_export}>
                  <Grid
                    data={orderBy(checkpoint_slice, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                      setSort(e.sort);
                    }}
                    style={{ height: height * 0.7 }}
                    className="pqidetail"
                  >
                    <GridToolbar>
                      <button
                        title="Export Excel"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={excelExport}
                        style={{ position: "absolute", right: "5px" }}
                      >
                        <span className="k-icon k-font-icon k-i-file-excel"></span>
                      </button>
                    </GridToolbar>
                    <GridColumn field="record_no" title={t("레코드 넘버")} />

                    <GridColumn field="month" title={t("레코드 해당월")} />
                    <GridColumn field="InspectionWFType" title={t("검사구분")} />
                    <GridColumn field="InspItem250" title={t("검사명")} />

                    <GridColumn field="d_inspection_date" title={t("검사요청일")} />
                    <GridColumn field="InspReqDP" title={t("검사신청일")} />
                    <GridColumn field="d_actual_insp_date" title={t("검사 시간")} />
                    <GridColumn field="RFIEndDate" title={t("검사 종결 시간")} />

                    <GridColumn field="point1" title={t("검사 신청 점수")} />
                    <GridColumn field="point2" title={t("검사 완료 점수")} />
                    <GridColumn field="point4" title={t("실패율 점수")} />
                    <GridColumn field="status" title={t("레코드 상태")} />
                  </Grid>
                </ExcelExport>
              </TabStripTab>
              <TabStripTab title={t("NCR 지연 상세")}>
                <ExcelExport data={ncr_pendig_point_slice} ref={_export2}>
                  <Grid
                    data={orderBy(ncr_pendig_point_slice, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                      setSort(e.sort);
                    }}
                    style={{ height: height * 0.7 }}
                    className="pqidetail"
                  >
                    <GridToolbar>
                      <button
                        title="Export Excel"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={excelExport2}
                        style={{ position: "absolute", right: "5px" }}
                      >
                        <span className="k-icon k-font-icon k-i-file-excel"></span>
                      </button>
                    </GridToolbar>
                    <GridColumn field="month" title={t("해당 월")} />
                    <GridColumn field="record_no" title={t("레코드 넘버")} />
                    <GridColumn field="NCRTitle" title={t("NCR 제목")} />
                    <GridColumn field="NCPIssueDP" title={t("발행일")} />
                    <GridColumn field="Mig_Due_Date" title={t("Due Date")} />
                    <GridColumn field="NCPActionCompDP" title={t("조치일")} />
                    <GridColumn field="diffdays" title={t("조치 기간")} />
                    <GridColumn field="ncr_pending_point" title={t("조치 점수")} />
                  </Grid>
                </ExcelExport>
              </TabStripTab>
              <TabStripTab title={t("NCR/Punch 이슈 상세")}>
                <ExcelExport data={issuerate_slice} ref={_export3}>
                  <Grid
                    data={orderBy(issuerate_slice, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                      setSort(e.sort);
                    }}
                    style={{ height: height * 0.7 }}
                    className="pqidetail"
                  >
                    <GridToolbar>
                      <button
                        title="Export Excel"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={excelExport3}
                        style={{ position: "absolute", right: "5px" }}
                      >
                        <span className="k-icon k-font-icon k-i-file-excel"></span>
                      </button>
                    </GridToolbar>
                    <GridColumn field="month" title={t("해당 월")} />
                    <GridColumn field="issuepoint" title={t("월별 발행점수")} />
                    <GridColumn field="weeknumber" title={t("주차(YYYYWW)")} />
                    <GridColumn field="weekdate" title={t("주차별 날짜 기간")} />
                    <GridColumn field="issue_count_ncr" title={t("NCR 발행건수")} />
                    <GridColumn
                      field="issue_count_punch"
                      title={t("Punch 발행건수")}
                    />
                    <GridColumn
                      field="issuepoint_week"
                      title={t("주차별 발행점수")}
                    />
                  </Grid>
                </ExcelExport>
              </TabStripTab>
            </TabStrip>
          </Dialog>
        )}

        {!visibleDialog_v && (
          <Dialog
            title={t("Biz.Patner PQI 상세")}
            onClose={toggleDialog_v}
            width={"60%"}
            height={"80%"}
          >
            <TabStrip
              className="qtab"
              selected={selected_v}
              onSelect={handleSelect_v}
            >
              <TabStripTab title={t("검사 상세")}>
                <ExcelExport data={checkpoint_slice} ref={_export_1}>
                  <Grid
                    data={orderBy(checkpoint_slice, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                      setSort(e.sort);
                    }}
                    style={{ height: height * 0.7 }}
                    className="pqidetail"
                  >
                    <GridToolbar>
                      <button
                        title="Export Excel"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={excelExport_1}
                        style={{ position: "absolute", right: "5px" }}
                      >
                        <span className="k-icon k-font-icon k-i-file-excel"></span>
                      </button>
                    </GridToolbar>
                    <GridColumn field="record_no" title={t("레코드 넘버")} />
                    <GridColumn field="Subcontractor_dpk" title={t("업체명")} />

                    <GridColumn field="month" title={t("해당 월")} />
                    <GridColumn field="InspectionWFType" title={t("검사구분")} />
                    <GridColumn field="InspItem250" title={t("검사명")} />

                    <GridColumn field="d_inspection_date" title={t("검사요청일")} />
                    <GridColumn field="InspReqDP" title={t("검사신청일")} />
                    <GridColumn field="d_actual_insp_date" title={t("검사 시간")} />
                    <GridColumn field="RFIEndDate" title={t("검사 종결 시간")} />

                    <GridColumn field="point1" title={t("검사 신청 점수")} />
                    <GridColumn field="point2" title={t("검사 완료 점수")} />
                    <GridColumn field="point4" title={t("실패율 점수")} />
                    <GridColumn field="status" title={t("레코드 상태")} />
                  </Grid>
                </ExcelExport>
              </TabStripTab>
              <TabStripTab title={t("NCR 지연 상세")}>
                <ExcelExport data={ncr_pendig_point_slice_v} ref={_export_2}>
                  <Grid
                    data={orderBy(ncr_pendig_point_slice_v, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                      setSort(e.sort);
                    }}
                    style={{ height: height * 0.7 }}
                    className="pqidetail"
                  >
                    <GridToolbar>
                      <button
                        title="Export Excel"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={excelExport_2}
                        style={{ position: "absolute", right: "5px" }}
                      >
                        <span className="k-icon k-font-icon k-i-file-excel"></span>
                      </button>
                    </GridToolbar>
                    <GridColumn field="month" title={t("해당 월")} />
                    <GridColumn field="record_no" title={t("레코드 넘버")} />
                    <GridColumn field="Subcontractor_dpk" title={t("업체명")} />
                    <GridColumn field="NCRTitle" title={t("NCR 제목")} />
                    <GridColumn field="NCPGrade" title={t("NCR 등급")} />
                    <GridColumn field="NCPIssueDP" title={t("발행일")} />
                    <GridColumn field="Mig_Due_Date" title={t("Due Date")} />
                    <GridColumn field="NCPActionCompDP" title={t("조치일")} />
                    <GridColumn field="diffdays" title={t("조치 기간")} />
                    <GridColumn field="ncr_pending_point" title={t("조치 점수")} />
                  </Grid>
                </ExcelExport>
              </TabStripTab>
              <TabStripTab title={t("NCR 이슈 상세")}>
                <ExcelExport data={ncrdata_point_slice_v} ref={_export_3}>
                  <Grid
                    data={orderBy(ncrdata_point_slice_v, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                      setSort(e.sort);
                    }}
                    style={{ height: height * 0.7 }}
                    className="pqidetail"
                  >
                    <GridToolbar>
                      <button
                        title="Export Excel"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={excelExport_3}
                        style={{ position: "absolute", right: "5px" }}
                      >
                        <span className="k-icon k-font-icon k-i-file-excel"></span>
                      </button>
                    </GridToolbar>
                    <GridColumn field="month" title={t("해당 월")} />
                    <GridColumn field="record_no" title={t("레코드 넘버")} />
                    <GridColumn field="Subcontractor_dpk" title={t("업체명")} />
                    <GridColumn field="NCRTitle" title={t("NCR 제목")} />
                    <GridColumn field="NCPIssueDP" title={t("발행일")} />
                    <GridColumn field="Mig_Due_Date" title={t("Due Date")} />
                    <GridColumn field="NCPActionCompDP" title={t("조치일")} />
                  </Grid>
                </ExcelExport>
              </TabStripTab>

              <TabStripTab title={t("CAR 이슈 상세")}>
                <ExcelExport data={cardata_point_slice_v} ref={_export_5}>
                  <Grid
                    data={orderBy(cardata_point_slice_v, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                      setSort(e.sort);
                    }}
                    style={{ height: height * 0.7 }}
                    className="pqidetail"
                  >
                    <GridToolbar>
                      <button
                        title="Export Excel"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={excelExport_5}
                        style={{ position: "absolute", right: "5px" }}
                      >
                        <span className="k-icon k-font-icon k-i-file-excel"></span>
                      </button>
                    </GridToolbar>
                    <GridColumn field="month" title={t("해당 월")} />
                    <GridColumn field="record_no" title={t("레코드 넘버")} />
                    <GridColumn field="Subcontractor_dpk" title={t("업체명")} />
                    <GridColumn field="NCRTitle" title={t("CAR 제목")} />
                    <GridColumn field="NCPIssueDP" title={t("발행일")} />
                    <GridColumn field="Mig_Due_Date" title={t("Due Date")} />
                    <GridColumn field="NCPActionCompDP" title={t("조치일")} />
                  </Grid>
                </ExcelExport>
              </TabStripTab>
              <TabStripTab title={t("CAR 지연 상세")}>
                <ExcelExport data={car_pendig_point_slice_v} ref={_export_4}>
                  <Grid
                    data={orderBy(car_pendig_point_slice_v, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                      setSort(e.sort);
                    }}
                    style={{ height: height * 0.7 }}
                    className="pqidetail"
                  >
                    <GridToolbar>
                      <button
                        title="Export Excel"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={excelExport_5}
                        style={{ position: "absolute", right: "5px" }}
                      >
                        <span className="k-icon k-font-icon k-i-file-excel"></span>
                      </button>
                    </GridToolbar>
                    <GridColumn field="month" title={t("해당 월")} />
                    <GridColumn field="record_no" title={t("레코드 넘버")} />
                    <GridColumn field="Subcontractor_dpk" title={t("업체명")} />
                    <GridColumn field="NCRTitle" title={t("CAR 제목")} />
                    <GridColumn field="NCPIssueDP" title={t("발행일")} />
                    <GridColumn field="Mig_Due_Date" title={t("Due Date")} />
                    <GridColumn field="NCPActionCompDP" title={t("조치일")} />
                    <GridColumn field="diffdays" title={t("조치 기간")} />
                    <GridColumn field="ncr_pending_point" title={t("조치 점수")} />
                  </Grid>
                </ExcelExport>
              </TabStripTab>
            </TabStrip>
          </Dialog>
        )}
      </GridLayout>
    </>
  );
};

export default MonthPQI;

const pqi_biz_chart_field_list = [
  { field: "자재검사 신청", color: "#f7fbff" },
  { field: "자재검사 실패율", color: "#deebf7" },
  { field: "자재검사 완료", color: "#c6dbef" },
  { field: "시공검사 신청", color: "#9ecae1" },
  { field: "시공검사 실패율", color: "#6baed6" },
  { field: "시공검사 완료", color: "#4292c6" },
  { field: "NCR 발행건수", color: "#2171b5" },
  { field: "NCR 지연", color: "#d0d1e6" },
  { field: "CAR 발행건수", color: "#08519c" },
  { field: "Punch 발행건수", color: "#a8ddb5" },
];