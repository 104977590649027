import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '../projectLayout';
import { useParams } from 'react-router-dom';
import { TileLayout } from "@progress/kendo-react-layout";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import axios from 'axios';
import _ from 'lodash';
import moment from "moment";
import useBreakpoint from "use-breakpoint";
import "../kendocustom.css";
import '@progress/kendo-theme-default/dist/all.css';
import { useTranslation } from 'react-i18next';
import { logout } from '../common/logout';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Circles } from 'react-loader-spinner';
import html2canvas from "html2canvas";
import EMilestone from './common/milestone';
import SCurveChart from './common/EMSSCurveChart';
import PartMilestone from './common/milestone';
import EWP from './eng/discipline';
import EngIssue from './eng/engIssue';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };


const ETotalProgress = () => {

    const exportAsImage = async () => {
        const canvas = await html2canvas(document.querySelector(".k-tilelayout"));
        const image = canvas.toDataURL("image/png", 1.0);
        downloadImage(image, project_code+'_공정율.png');
        };
      
      const downloadImage = (blob, fileName) => {
          const fakeLink = window.document.createElement("a");
          fakeLink.style = "display:none;";
          fakeLink.download = fileName;
          
          fakeLink.href = blob;
          
          document.body.appendChild(fakeLink);
          fakeLink.click();
          document.body.removeChild(fakeLink);
          
          fakeLink.remove();
          };

    const { t, i18n } = useTranslation();
    const { project_code } = useParams();
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')

    const [grid, setgrid] = useState({
        mobile:
        [
          {
            col: 1,
            colSpan: 16,
            rowSpan: 3,
            status: true,
          },
          {
              col: 1,
              colSpan: 16,
              rowSpan: 4,
              status: true,
            },
            {
              col: 1,
              colSpan: 16,
              rowSpan: 3,
              status: true,
            },
            {
              col: 1,
              colSpan: 16,
              rowSpan: 3,
              status: true,
            },

          ]
        ,
    
        tablet:
        [
          {
            col: 1,
            colSpan: 16,
            rowSpan: 4,
            status: true,
          },
          {
              col: 1,
              colSpan: 16,
              rowSpan: 4,
              status: true,
            },
            {
              col: 1,
              colSpan: 16,
              rowSpan: 3,
              status: true,
            },
            {
              col: 1,
              colSpan: 16,
              rowSpan: 3,
              status: true,
            },
          ]
        ,
    
        desktop:
          [
            {
              col: 1,
              colSpan: 16,
              rowSpan: 4,
              status: true,
            },
            {
                col: 1,
                colSpan: 16,
                rowSpan: 4,
                status: true,
              },
              {
                col: 1,
                colSpan: 16,
                rowSpan: 3,
                status: true,
              },
              {
                col: 1,
                colSpan: 16,
                rowSpan: 3,
                status: true,
              },

          ]
      });

      const [emsData, setEmsData] = useState([]);
      const [emsWeightValue, setEmsWeightValue] = useState([]);

      useEffect(() => {
        const fetchData = async () => {
          let engineering_progress_body = {
            bpname: "Engineering Progress",
            lineitem: "no",
            filter_criteria: {
              join: "AND",
              filter: [
                {
                  field: "PJT_CD",
                  value: project_code,
                  condition_type: "eq",
                },
              ],
            },
          };
    
          const engineering_progress_ratio_body = {
            bpname: "Engineering Progress Ratio",
            lineitem: "yes",
            filter_criteria: {
              join: "AND",
              filter: [
                {
                  field: "ugenProjectNumber",
                  value: project_code,
                  condition_type: "eq",
                },
              ],
            },
          };
    
          const fetch_engineering_progress_ratio_body = await axios
            .post(
              `/api/getbprecord?path=SKEC0001`
              ,
              engineering_progress_ratio_body
            )
            .then((com) =>
              com.data.data.data
                .filter((com2) => com2._bp_lineitems !== undefined)
                .reduce(
                  (acc, cur) =>
                    acc.concat(
                      Object.assign(cur._bp_lineitems).map((com2) => ({
                        ...com2,
                        ugenProjectNumber: cur.ugenProjectNumber,
                      }))
                    ),
                  []
                )
            );
    
          setEmsWeightValue(fetch_engineering_progress_ratio_body);
    
          const fetch_engineering_progress_body_body = await axios
            .post(`/api/getbprecord?path=SKEC0001`, engineering_progress_body)
            .then((com) =>
              com.data.data.data.filter(
                (com2) => com2.PROG_TYPE === "E" || com2.PROG_TYPE === "R"
              )
            );
    
          const sum_date_engineering_progress = _.sortBy(
            fetch_engineering_progress_body_body
              .map((com) => ({
                ...com,
                date: new Date(
                  `${com.CUT_OFF_DT.slice(0, 4)}-
                ${com.CUT_OFF_DT.slice(4, 6)}-
                ${com.CUT_OFF_DT.slice(6, 8)}`
                ),
                dev: Number(
                  (
                    (com.PLAN_PROG === null ? 0 : com.PLAN_PROG) -
                    (com.ACTUAL_PROG === null ? 0 : com.ACTUAL_PROG)
                  ).toFixed(2)
                ),
              }))
              .map((com) => ({
                ...com,
                month_date: moment(com.date).format("YYYY-MM"),
              })),
            "date"
          );
    
          setEmsData(sum_date_engineering_progress);
        };
        fetchData();
      }, [project_code]);
    
      
  useEffect(() => {
    setPositionData(grid[breakpoint])
  }, [breakpoint, grid]);

  const [fixblock, setfixblock] = useState(false);

  const togglefix = () => {
    setfixblock(!fixblock);
  };



  // CUT_OFF_DT 값이 가장 큰 객체를 찾습니다.
const maxCutoff = _.maxBy(emsData.filter(v=>v.ACTUAL_PROG > 0).map((v)=>({...v, value:Number(v.CUT_OFF_DT)})), "value")&& 
_.maxBy(emsData.filter(v=>v.ACTUAL_PROG > 0).map((v)=>({...v, value:Number(v.CUT_OFF_DT)})), "value").CUT_OFF_DT;
const maxCutoff2 = moment(maxCutoff).add(-7,"days").format("YYYYMMDD");


// maxCutoff 값과 동일한 CUT_OFF_DT 값을 가진 객체들로 필터링합니다.
const filteredData = emsData.filter(item => item.CUT_OFF_DT === maxCutoff);
const filteredData2 = emsData.filter(item => item.CUT_OFF_DT === String(maxCutoff2));


const tiles = [
    {
      header: (<div style={{ position: 'relative' }}>Engineering Milestone</div>),
      body: <PartMilestone part ={"Engineering"} />,
      resizable: fixblock,
      reorderable: fixblock,
  

    },
    {
        header: (<div style={{ position: 'relative' }}>Engineering Progress</div>),
        body: <SCurveChart 
        part={"Engineering"}
        emsData={emsData}
        emsWeightValue={emsWeightValue}
        height={350}
        />,
        resizable: fixblock,
        reorderable: fixblock,
    
  
      },
      {
        header: (<div style={{ position: 'relative' }}>Engineering Progress(by Disc.)</div>),
        body: <EWP data={filteredData} pdata={filteredData2} />,
        resizable: fixblock,
        reorderable: fixblock,
    
  
      },
      {
        header: (<div style={{ position: 'relative' }}>Engineering Issue</div>),
        body: <EngIssue/>,
        resizable: fixblock,
        reorderable: fixblock,
    
  
      },
]

const location = useLocation();
const usernameFromState = location.state?.username;
const [isLoading, setIsLoading] = useState(false);
const [positiondata, setPositionData] = useState(grid['desktop']);


const handleReposition = e => {
  setPositionData(e.value);
  // setPositionData(e.value.map((v)=>{return {col:v.col, row:v.row, rowSpan:v.rowSpan, colSpan:v.colSpan, status: v.status,}}));
};

const handleLogoutClick = async () => {
    setIsLoading(true); // 요청이 시작될 때 로딩 상태를 true로 설정

    try {
      const res = await axios.get(`/api/getcheckowner?project=${project_code}`);
      setIsLoading(false);
      
      if (res.data.success) {
        const client = res.data.client; // 예를 들어, API 응답에서 'client' 데이터를 기대한다고 가정
        logout({
          username: usernameFromState,
          project: project_code,
          client: client
        });
      } else {
        logout({
          username: usernameFromState,
          project: project_code,
          client: false
        });
      }
    } catch (error) {
      setIsLoading(false);

      // 에러가 발생했을 때 SweetAlert를 사용하여 에러 메시지를 표시
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred. Please contact the system administrator.',
        confirmButtonText: 'OK'
      });
    }
  };

  const isNotIframe = window.self === window.top;


return (
    <>
  {isNotIframe? (
    <DashboardLayout>
    <div>
      <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
        <ButtonGroup>
          <Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
          <Button title={t("이미지로 다운로드")} iconClass="k-icon k-font-icon k-i-image" togglable={false} onClick={exportAsImage}></Button>
          <Button title={t("고정하기")} iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
        </ButtonGroup>
      </div>
      <TileLayout
        columns={12}
        rowHeight={100}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tiles}
        autoFlow="row dense"
        onReposition={handleReposition}
        style={{marginTop:20}}
      />
    </div>
    </DashboardLayout>)
    :
    <div>
      <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
        <ButtonGroup>
          <Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
          <Button title={t("이미지로 다운로드")} iconClass="k-icon k-font-icon k-i-image" togglable={false} onClick={exportAsImage}></Button>
          <Button title={t("고정하기")} iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
        </ButtonGroup>
      </div>
      <TileLayout
        columns={12}
        rowHeight={100}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tiles}
        autoFlow="row dense"
        onReposition={handleReposition}
        style={{marginTop:20}}
      />
    </div>
       }
    </>
  )

}

export default ETotalProgress;
