import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from "axios"; 
import { Circles } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import ClientTable from './table/clientTable';


const ClientData = () => {
  const { project_code } = useParams();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [options, setOptions] = useState([]);



  useEffect(() => {
    const fetchData = async () => {
      if (project_code) {
        const res = await axios.get(`/api/getTR?path=${project_code}`);
        const res2 = await axios.get(`/api/getreturncode?path=${project_code}`);
        if (res.data.success && res2.data.success) {
          setTableData(
            res.data.data.map((vf) => ({
              ...vf,
              title: vf.uuu_name !== undefined && vf.uuu_name && (vf.title === "" || vf.title === null || vf.title === undefined) ? (vf.uuu_name.lastIndexOf('.') !== -1 ? vf.uuu_name.substring(0, vf.uuu_name.lastIndexOf('.')) : vf.uuu_name) : vf.title,
              DueDate: vf.DueDate ? new Date(vf.DueDate.slice(0, 10)) : null,
              ReceivedDate: vf.ReceivedDate ? new Date(vf.ReceivedDate.slice(0, 10)) : null,
              SubmittedDate: vf.SubmittedDate ? new Date(vf.SubmittedDate.slice(0, 10)) : null,

            }))
          )
          setOptions(res2.data.data.data.map((vf) => vf.SKDMApprovalDecision));
          setLoading(false);

        }
      }
    }
    fetchData()
  }, [project_code])


  if (loading) {
    return <div className="loadingContainer">
      <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
    </div>;;
  }


  return (
    <>

        {tableData.length > 0 && 
          <ClientTable projectCode={project_code} options={options} tableData={tableData} newkey={'record_no'} />
        }

    </>
  );
};
export default ClientData;