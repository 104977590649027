import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem,
    Card,
    CardTitle,
    CardBody,
    CardSubtitle,
} from "@progress/kendo-react-layout";
import moment from 'moment';
import { Chart, ChartCategoryAxisItem, ChartCategoryAxis, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip, exportVisual, ChartTitle } from "@progress/kendo-react-charts";
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { exportImage } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import 'hammerjs';
 import axios from "axios";
import { Tooltip } from "@progress/kendo-react-tooltip";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { RangeSlider, SliderLabel } from "@progress/kendo-react-inputs";
import { useTranslation } from 'react-i18next';
import { ComboBox } from "@progress/kendo-react-dropdowns";

const { REACT_APP_PATH2 } = process.env;

const ToggleButton = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#4B5335',
    },
    '&.MuiToggleButton-root': {
        fontSize: '0.7rem',
        fontFamily: 'Pretendard-R',
        padding: 0,
        width: 75,
        borderRadius: '5px 5px 5px 5px!important',
        border: '1px solid rgba(0, 0, 0, 0.12)!important'

    }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    }
}));

const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
});


const OverallProgressChart = ({ progressSource ,cprogressData,emsData,emsWeightValue }) => {

    const { project_code } = useParams();
    const [result, setresult] = useState([]);
    const [lastData, setLastData] = useState([]);
    const [cutOff, setCutOff] = useState([]);
    const [datevalue, setDateValue] = useState([]);


    const today = moment(new Date()).format("YYYY-MM-DD");

    const [selectedValue, setSelectedValue] = React.useState("monthly");
    const { t, i18n } = useTranslation();

 
    const handleChangeChart = (event, selectedValue) => {
        if (selectedValue !== null) {
            setSelectedValue(selectedValue);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            let body =
            {
                "bpname": "ESG 대시보드 프로젝트 관리",
                "lineitem": "no",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        },
                        {
                            "field": "ugenProjectNumber",
                            "value": project_code,
                            "condition_type": "eq"
                          },
                    ]
                }
            }


            let body2 =
            {
                "bpname": "EPC Progress Ratio",
                "lineitem": "no",
            }

            const resVersion = await axios.post(`/api/getbprecord?path=SKEC0001`, body)
            const res = await axios.post(`/api/getbprecord?path=${project_code}`, body2)

            const version = resVersion.data.data.data.filter(v => v.ugenProjectNumber === project_code)[0]['DRversion']

            const ratio = res.data.data.data[0]


            const datafrom_source = progressSource === '작업일보 기반' ? cprogressData.actual : cprogressData.actual.map((v) => ({
                ...v,
                d_evm_actual_start: typeof v.ManualActualStart !== 'undefined' ? v.ManualActualStart : v.d_evm_actual_start,
                d_evm_actual_finish: typeof v.ManualActualFinish !== 'undefined' ? v.ManualActualFinish : v.d_evm_actual_finish,
                d_actual_progress: typeof v.ManualActualProgress !== 'undefined' ? v.ManualActualProgress : v.d_actual_progress,
            }))

            const datafrom2 = (selectedValue === 'monthly' ?cprogressData.monthplan : cprogressData.weekplan).map(f => ({
                ...f,
                Lv_1_Name: cprogressData.actual.find(sf => sf.uuu_P6ActivityId === f.uuu_P6ActivityId) && cprogressData.actual.find(sf => sf.uuu_P6ActivityId === f.uuu_P6ActivityId)['Lv_1_Name'],
                Lv_2_Name: cprogressData.actual.find(sf => sf.uuu_P6ActivityId === f.uuu_P6ActivityId) && cprogressData.actual.find(sf => sf.uuu_P6ActivityId === f.uuu_P6ActivityId)['Lv_2_Name'],
                Lv_3_Name: cprogressData.actual.find(sf => sf.uuu_P6ActivityId === f.uuu_P6ActivityId) && cprogressData.actual.find(sf => sf.uuu_P6ActivityId === f.uuu_P6ActivityId)['Lv_3_Name'],
                Lv_4_Name: cprogressData.actual.find(sf => sf.uuu_P6ActivityId === f.uuu_P6ActivityId) && cprogressData.actual.find(sf => sf.uuu_P6ActivityId === f.uuu_P6ActivityId)['Lv_4_Name']
            }))

            const total_pv = _.sumBy(datafrom2, 'PV')
            const total_pv2 = _.sumBy(datafrom_source, 'd_weight_value')
            const total_pv3 = _.sumBy(datafrom2, 'PV')


            const dayweight = (datafrom_source.map((v) => ({ ...v, uuu_P6ActivityId: version === 'OLD' ? v.uuu_P6ActivityId : v.uuu_P6ActivityId + v.d_sub_activity_id })))
                .map((v) => {
                    return {
                        uuu_P6ActivityId: v.uuu_P6ActivityId,
                        disc: v.Lv_4_Name,
                        Area: v.Lv_2_Name,
                        Subarea: v.Lv_3_Name,
                        d_evm_plan_start: v.d_evm_plan_start,
                        d_evm_plan_finish: v.d_evm_plan_finish,
                        d_weight_value: v.d_weight_value,
                        // onedayplanvalue: v.d_weight_value / moment(v.d_evm_plan_finish, "MM-DD-YYYY").diff(moment(v.d_evm_plan_start, "MM-DD-YYYY"), 'days'),
                        d_evm_actual_start: v.d_evm_actual_start,
                        d_evm_actual_finish: v.d_evm_actual_finish,
                        // onedayactualvalue:
                        //     v.d_evm_actual_start === null ? 0 : v.d_evm_actual_start !== null && v.d_evm_actual_finish === null ?
                        //         (v.d_weight_value * v.d_actual_progress / 100) / moment().diff(moment(v.d_evm_actual_start, "MM-DD-YYYY"), 'days')
                        //         :
                        //         (v.d_weight_value * v.d_actual_progress / 100) / moment(v.d_evm_actual_finish, "MM-DD-YYYY").diff(moment(v.d_evm_actual_start, "MM-DD-YYYY"), 'days'),

                        // childplan: dateRange(v.d_evm_plan_start, v.d_evm_plan_finish),
                        childactual: getDateRangeWithRatio(new Date(moment(v.d_evm_actual_start).format('YYYY-MM-DD')), v.d_evm_actual_finish !== null ? new Date(moment(v.d_evm_actual_finish).format('YYYY-MM-DD')) : new Date()),
                        // selectedValue ==='monthly' ? dateRange2(v.d_evm_actual_start, v.d_evm_actual_finish) :  weekdateRange(v.d_evm_actual_start, v.d_evm_actual_finish),
                        rate: v.d_actual_progress,
                        d_actual_wv: v.d_actual_progress * v.d_assigned_wv,
                        actualdateList: v._bp_lineitems !== undefined && v._bp_lineitems.filter(v => v.uuu_tab_id === '일일실적물량').map((f, index) => ({
                            date: f.d_reporting_date.slice(0, 10),
                            dayEV: (f.d_today_workdone_qty / v.d_forecast_qty) * (v.d_assigned_wv),

                        })),

                    }
                })





            function getThisWeekFriday(day) {
                var today = new Date(day);
                var year = today.getFullYear();
                var month = today.getMonth();
                var date = today.getDate();
                var dayOfWeek = today.getDay(); // 오늘의 요일 (0부터 일요일, 6까지 토요일)
                var friday = new Date(year, month, date);

                // 만약 오늘이 금요일 이후라면 다음 주 금요일로 설정
                if (dayOfWeek > 5) {
                    friday.setDate(date + (12 - dayOfWeek));
                }
                // 오늘이 금요일 이전이라면 이번 주 금요일로 설정
                else {
                    friday.setDate(date + (5 - dayOfWeek));
                }

                return friday;
            }


            function getLastFridayOfMonth(day) {
                var today = new Date(day);
                var year = today.getFullYear();
                var month = today.getMonth();
                var lastDayOfMonth = new Date(year, month + 1, 0).getDate();
                var lastFriday;

                for (var day = lastDayOfMonth; day >= 1; day--) {
                    var date = new Date(year, month, day);
                    var dayOfWeek = date.getDay();

                    if (dayOfWeek === 5) { // 금요일 (0부터 일요일, 6까지 토요일)
                        lastFriday = date;
                        break;
                    }
                }

                return lastFriday;
            }

            const actual =
                version === 'OLD' ?
                    _.filter(dayweight, function (o) { return typeof o.childactual !== 'undefined'; }).reduce((c, v) => c.concat(v.childactual.map(o => Object.assign(o, {
                        "earnedvalue": o.dayratio * (v.rate * v.d_weight_value) / 100,


                        week: getThisWeekFriday(o.date), month: getLastFridayOfMonth(o.date)
                    }))), [])
                    :
                    _.filter(dayweight, function (o) {
                        return typeof o.actualdateList !== 'undefined' && Array.isArray(o.actualdateList);
                    }).reduce((c, v) => c.concat(v.actualdateList.map(o => Object.assign(o, {
                        "EV": o.dayEV,
                        week: getThisWeekFriday(o.date), month: getLastFridayOfMonth(o.date)
                    }))), []);



            var ev =
                actual &&
                _(actual)
                    .groupBy(selectedValue === 'monthly' ? 'month' : 'week')
                    .map((objs, key) => ({
                        'date': selectedValue === 'monthly' ? objs[0]['month'] : objs[0]['week'],
                        'EV': ((_.sumBy(objs, 'EV')) / total_pv3) * 100,
                        'C_EV': isNaN((_.sumBy(objs, 'EV') / total_pv3) * 100) ? 0 : (_.sumBy(objs, 'EV') / total_pv3) * 100
                    }))
                    .value()


            var ev_old =
                actual &&
                _(actual)
                    .groupBy(selectedValue === 'monthly' ? 'month' : 'week')
                    .map((objs, key) => ({
                        'date': selectedValue === 'monthly' ? objs[0]['month'] : objs[0]['week'],
                        'EV': ((_.sumBy(objs, 'earnedvalue')) / total_pv2) * 100,
                        'C_EV': isNaN((_.sumBy(objs, 'earnedvalue') / total_pv2) * 100) ? 0 : (_.sumBy(objs, 'earnedvalue') / total_pv2) * 100
                    }))
                    .value()



            const ev_sort = _.sortBy(version === 'NEW' ? ev : ev_old, 'date')

            let cumev = 0;
            const cumev_with = ev_sort.map(({ date, EV, C_EV }) => ({ date, EV, CC_EV: Number((cumev += C_EV)) }))


            var pv =
            datafrom2 &&
                _(datafrom2)
                    .groupBy('date')
                    .map((objs, key) => ({
                        'date': new Date(key),
                        'PV': isNaN(_.sumBy(objs, 'PV')) ? 0 : _.sumBy(objs, 'PV'),
                        'C_PV': isNaN((_.sumBy(objs, 'PV') / total_pv) * 100) ? 0 : (_.sumBy(objs, 'PV') / total_pv) * 100,
                    }))
                    .value();
            const pv_sort = _.sortBy(pv, 'date')




            let cumpv = 0;
            let cumpv2 = 0;
            const cumpv_with = pv_sort.map(({ date, PV, C_PV }) => ({ date, PV, CC_PV: Number((cumpv += C_PV)), C_PV2: cumpv2 += PV }))
                .map((v) => ({ ...v, PV: (v.PV / total_pv) * 100 }));

                const n = 5; // 남길 요소의 수

                // 조건을 만족하는 요소들의 인덱스를 찾습니다.
                const indicesToKeep = cumpv_with
                  .map((item, index) => (item.CC_PV >= 100 && item.PV === 0 ? index : -1))
                  .filter(index => index !== -1)
                  .slice(0, n); // 처음 n개의 인덱스만 남깁니다.
                
                // 필터링 과정을 거쳐, 조건을 만족하되 처음 n개 요소는 제외하지 않습니다.
                const filteredResult = cumpv_with.filter((item, index) => {
                  const isConditionMet = item.CC_PV >= 100 && item.PV === 0;
                  // 조건을 만족하지 않거나, 처음 n개 요소 중 하나인 경우 요소를 남깁니다.
                  return !isConditionMet || indicesToKeep.includes(index);
                });
    


            let dateRange = getRangeBetweenDates(cumev_with, filteredResult);

            let dateList = dateRange && dateRange.length
                ? dateRange.map((v) => ({
                    ...v,
                    kdate: moment(v.date).format("YYYY-MM-DD"),
                }))
                : [];


            const result = cumpv_with.length > cumev_with.length ?
                dateList && dateList.map(v => ({ ...v, ...cumpv_with.map((v) => ({ ...v, kdate: moment(v.date).format('YYYY-MM-DD') })).find(sp => sp.kdate === v.kdate) }))
                    .map(f => ({ ...f, ...cumev_with.map((v) => ({ ...v, kdate: moment(v.date).format('YYYY-MM-DD') })).find(sf => sf.kdate === f.kdate), month_date: moment(f.date).format("YYYY-MM") }))

                // .map((v, index)=> ({...v, color:COLORS[index]}))
                :

                dateList && dateList.map(v => ({ ...v, ...cumev_with.map((v) => ({ ...v, kdate: moment(v.date).format('YYYY-MM-DD') })).find(sp => sp.kdate === v.kdate) }))
                    .map(f => ({ ...f, ...cumpv_with.map((v) => ({ ...v, kdate: moment(v.date).format('YYYY-MM-DD') })).find(sf => sf.kdate === f.kdate), month_date: moment(f.date).format("YYYY-MM") }))
           
           

                setresult(  datevalue === null || datevalue.length ===0 ? result: result.filter(item => {
                    const itemDate = new Date(item.kdate);
                    const targetDate = new Date(datevalue.date);
                    return itemDate <= targetDate;
                    }))


            
            const cutoffdays = selectedValue === 'monthly' ?_.uniqBy(cprogressData.monthplan, "date").map((v, index)=>({date:v.date
                , desc: moment(v.date).format("YYYY년 MM월")+" ("+Number(Number(index)+1)+"M: " +moment(v.date).add(-1, "month").format("M/DD") +"~"+ moment(v.date).format("M/DD")+")"
            })):
                _.uniqBy(cprogressData.weekplan, "date").map((v, index)=>({
                    date:v.date, desc:moment(v.date).format("YYYY년 WW주")+" ("+Number(Number(index)+1)+"W: " +moment(v.date).add(-7, "days").format("M/DD") +"~"+ moment(v.date).format("M/DD")+")"}));
    
                setCutOff(_.sortBy(cutoffdays, "date").reverse())


                const emsDataSum_factor = _.sortBy(
                    emsData.map((com) => {
                      return {
                        ...com,
                        PLAN_PROG_FACTOR_VALUE:
                          com.PROG_TYPE === "E"
                            ? com["PLAN_PROG"] * (emsWeightValue.find(v=>v.EPRDiscipline.toUpperCase() === com.DISC_NM.toUpperCase())?.["EDPRRatio"] / 100):
                          com.PROG_TYPE === "R"?
                            com["PLAN_PROG"] * (emsWeightValue.find(v=>v.EPRDiscipline.toUpperCase() === com.DISC_NM.toUpperCase())?.["RPRRatio"] / 100)
                            :com["PLAN_PROG"]
                            ,
                        ACTUAL_PROG_FACTOR_VALUE:
                          com.PROG_TYPE === "E"
                            ? com["ACTUAL_PROG"] *
                              (emsWeightValue.find(v=>v.EPRDiscipline.toUpperCase() === com.DISC_NM.toUpperCase())?.["EDPRRatio"] / 100) :
                              com.PROG_TYPE === "R"?
                            
                              com["ACTUAL_PROG"] *
                              (emsWeightValue.find(v=>v.EPRDiscipline.toUpperCase() === com.DISC_NM.toUpperCase())?.["RPRRatio"] / 100):
                              com["ACTUAL_PROG"],
                      };
                    }),
                    "date"
                  );
          
        
        
                const emsDataSum_factor_e = emsDataSum_factor.filter(
                  (com) => com.PROG_TYPE === "E"
                );
        
                const emsDataSum_factor_r = emsDataSum_factor.filter(
                  (com) => com.PROG_TYPE === "R"
                );
        
                const emsDataSum_factor_p = emsDataSum_factor.filter(
                  (com) => com.PROG_TYPE === "P"
                );
        
                const uniqWeeksData = _.uniqBy(emsData, "CUT_OFF_DT").map((com) => {
                  const cut_off_filter = _.uniqBy(
                    [...emsDataSum_factor_e, ...emsDataSum_factor_r, ...emsDataSum_factor_p].filter(
                      (com2) => com2.CUT_OFF_DT === com.CUT_OFF_DT
                    ),
                    "PROG_TYPE"
                  );
        
                  return {
                    ...com,
                    PLAN_PROG: _.sumBy(cut_off_filter, "PLAN_PROG_FACTOR_VALUE"),
                    ACTUAL_PROG: _.sumBy(cut_off_filter, "ACTUAL_PROG_FACTOR_VALUE"),
                  };
                });
        
        
                const max_actual_data = _.maxBy([...uniqWeeksData].reverse(), "ACTUAL_PROG");
        
        
                const disc_filter_data = uniqWeeksData
                  .map((com, idx, arr) => {
                    if (
                      (idx > 0 && com["ACTUAL_PROG"] === 0) ||
                      (idx > 0 && arr[idx - 1]["ACTUAL_PROG"] > com["ACTUAL_PROG"])
                    ) {
                      const slice_arr = arr.slice(0, idx + 1);
                      const max_obj = _.maxBy(slice_arr, "ACTUAL_PROG")["ACTUAL_PROG"];
        
                      const change_com = {
                        ...com,
                        ACTUAL_PROG: max_obj,
                        act_data: 0,
                      };
        
                      return { ...change_com };
                    } else {
                      return { ...com };
                    }
                  })
                  .map((com, idx, arr) => {
                    if (
                      (idx > 0 && com["PLAN_PROG"] === 0) ||
                      (idx > 0 && arr[idx - 1]["PLAN_PROG"] > com["PLAN_PROG"])
                    ) {
                      const slice_arr = arr.slice(0, idx + 1);
                      const max_obj = _.maxBy(slice_arr, "PLAN_PROG")["PLAN_PROG"];
        
                      const change_com = {
                        ...com,
                        PLAN_PROG: max_obj,
                        plan_data: 0,
                      };
        
                      return { ...change_com };
                    } else {
                      return { ...com };
                    }
                  });
              
        
              const calculation_data = _.sortBy(
                disc_filter_data
                  .map((com, idx) => {
                    if (idx === 0) {
                      return {
                        ...com,
                        plan_data: com.PLAN_PROG === null ? 0 : com.PLAN_PROG,
                        act_data: com.ACTUAL_PROG === null ? 0 : com.ACTUAL_PROG,
                      };
                    } else {
                      return {
                        ...com,
                        plan_data:
                          com.PLAN_PROG === null
                            ? 0
                            : Number(
                                (
                                  com.PLAN_PROG - disc_filter_data[idx - 1]["PLAN_PROG"]
                                ).toFixed(2)
                              ),
                        act_data:
                          com.ACTUAL_PROG === null
                            ? 0
                            : Number(
                                (
                                  com.ACTUAL_PROG -
                                  disc_filter_data[idx - 1]["ACTUAL_PROG"]
                                ).toFixed(2)
                              ),
                      };
                    }
                  })
                  .map((com) => {
                    if (max_actual_data && com.date > max_actual_data.date) {
                      const delete_com = { ...com };
        
                      delete delete_com.act_data;
                      delete delete_com.ACTUAL_PROG;
        
                      return { ...delete_com };
                    } else {
                      return { ...com };
                    }
                  }),
                "date"
              );
        
              const lastData = datevalue === null || datevalue.length === 0 ? calculation_data:calculation_data.filter((com) => Number(com.CUT_OFF_DT) <= Number(datevalue.date))
    
        
              if (lastData.length > 0) {
                const max_actual = _.maxBy(
                  [...lastData].reverse(),
                  "ACTUAL_PROG"
                );
        
              }

              //lastData 설계 구매

              //

              const cresult =  datevalue === null || datevalue.length ===0 ? result: result.filter(item => {
                const itemDate = new Date(item.kdate);
                const targetDate = new Date(datevalue.date);
                return itemDate <= targetDate;
                })

                const  ratio2 =  {e:ratio.EngineeringPCT/100, p:ratio.ProcurementPCT/100, c:ratio.ConstructionPCT/100}

                const cresult2  =  cresult.map(v => {
                    let newObj = { date: v.date };
                    if (v.PV !== undefined && v.PV !== null) {
                      newObj.PV = v.PV * ratio2.c;
                    }
                    if (v.EV !== undefined && v.EV !== null) {
                      newObj.EV = v.EV * ratio2.c;
                    }
                    return newObj;
                  });


                  const newChartData =
                  lastData.map((v) => {
                    // Replace undefined or null with 0, then convert to number
                    const planData = Number(v.plan_data !== undefined && v.plan_data !== null ? v.plan_data : 0);
                    const actData = Number(v.act_data !== undefined && v.act_data !== null ? v.act_data : 0);
                
                    // Calculate the new values using the conditional (ternary) operator
                    const PV = v.PROG_TYPE === "P" ? planData * ratio2.p : planData * ratio2.e;
                    const EV = v.PROG_TYPE === "P" ? actData * ratio2.p : actData * ratio2.e;
                    return { date: v.date, PV, EV };
                  });
    

                  const lastReal= _.sortBy([...newChartData, ...cresult2], "date")

                  var lastReal_group =
                  lastReal &&
                  _(lastReal)
                      .groupBy('date')
                      .map((objs, key) => ({
                          'date':new Date(key),
                          'EV':  _.sumBy(objs, 'EV') || 0, 
                          'PV': _.sumBy(objs, 'PV') || 0, 
                      }))
                      .value()
  
  
                      let cumulpv = 0;
                      let cumulev = 0;
                      const lastDatareal = lastReal_group.map(({ date, PV, EV }) => ({ date, PV, EV,CC_PV: Number((cumulpv += PV)), CC_EV: cumulev += EV }))
                      .map((v)=>(
                        {...v, CC_EV:datevalue === null || datevalue.length ===0 ?v.CC_EV: moment(datevalue.date).isBefore(moment(v.date))? undefined:v.CC_EV}
                        ))



                      setLastData(lastDatareal)

        };

        fetchData();

    }, [project_code, today, progressSource, selectedValue, cprogressData, datevalue, emsData, emsWeightValue, ]);





    function getDateRangeWithRatio(start_date, end_date) {
        // 결과 배열 초기화
        let dateArray = [];

        // start_date와 end_date가 정상적인 날짜 값인지 확인
        if (!start_date || !moment(start_date).isValid() || !end_date || !moment(end_date).isValid()) {
            return dateArray;
        }

        // start_date와 end_date를 moment 객체로 변환
        let startDateMoment = moment(start_date);
        let endDateMoment = moment(end_date).add(1, 'day');

        // start_date와 end_date 사이의 총 날짜 수 계산
        let totalDays = endDateMoment.diff(startDateMoment, 'days');

        // currentDate가 end_date보다 크거나 같으면 루프 중지
        while (startDateMoment < endDateMoment) {
            // 배열에 현재 날짜와 비율 정보 추가
            dateArray.push({
                date: startDateMoment.toDate(),
                dayratio: 1 / totalDays
            });
            // startDateMoment에 1일 추가
            startDateMoment.add(1, 'day');
        }

        return dateArray;
    }



    function getRangeBetweenDates(dateList1, dateList2) {
        // dateList1과 dateList2의 모든 날짜를 하나의 배열에 추가
        let allDates = [...dateList1, ...dateList2];
        // allDates 배열이 빈 배열인 경우 null을 반환
        if (allDates.length === 0) {
            return null;
        }
        // allDates 배열에서 객체의 date 속성이 정의되어 있지 않은 객체가 포함된 경우 null을 반환
        if (allDates.some(d => d.date === undefined)) {
            return null;
        }
        // 모든 날짜 중에서 가장 빠른 날짜와 가장 느린 날짜를 찾음
        let minDate = allDates.reduce((min, d) => (d.date < min.date ? d : min), allDates[0]).date;
        let maxDate = allDates.reduce((max, d) => (d.date > max.date ? d : max), allDates[0]).date;

        // 결과 배열 초기화
        let dateArray = [];
        // minDate 복사
        let currentDate = new Date(minDate);
        // maxDate 다음 날 생성
        let endDate = new Date(maxDate);
        endDate.setDate(endDate.getDate() + 1);

        // currentDate가 maxDate보다 크거나 같으면 루프 중지
        while (currentDate < endDate) {
            // 배열에 현재 날짜 추가
            dateArray.push({ date: new Date(currentDate) });
            // currentDate에 1일 추가
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dateArray;
    }

    const SharedTooltip = (props) => {
        const { points } = props;
        return (
            <div>
                {selectedValue === 'monthly' ? (
                    <div>{moment(points[0].category).format(i18n.language ==="ko"? "YY년 MM월":"MMM YY")}</div>
                ) : (
                    <div>
                        {moment(points[0].category).format(i18n.language ==="ko"? "YY년 MM월":"MMM YY") +
                            " " +
                            Math.ceil(points[0].category.getDate() / 7) +
                            (i18n.language ==="ko"?"주":"week") }
                    </div>
                )}
                {points.map((point, index) => (

                    <div key={index}>
                        {point.value === 0 ?
                            <span>{point.series.name} :0% </span> :
                            <span>{point.series.name === '' ? t("누적 계획") : point.series.name} : {Number.parseFloat(point.value).toFixed(2) + '%'}</span>}
                    </div>

                ))}
            </div>
        );
    };

    const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

    const labelContentAxis = (e) => e.value > 1000000 ? (e.value / 1000000).toFixed(0) + '백만' : e.value;
    const labelContentAxis1 = (e) => e.value > 100 ? '' : e.value + '%';

    const [zoomableyn, setzoomableyn] = useState(false);

    const toggleZoom = () => {
        setzoomableyn(!zoomableyn);
    };

    const chart = useRef(null);

    const onImageExportClick = () => {
        const chartVisual = exportVisual(chart.current);

        if (chartVisual) {
            exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "SCurvechart.png"));
        }
    };




    const charttitle =
            `${t("Overall S-Curve")}`
        

    const planline1 = lastData&&lastData.filter(v => Number(moment(v.date).format('YYYYMMDD')) <= Number(moment().add(1, selectedValue === 'monthly' ? 'month' : 'week').format('YYYYMMDD')))
    const planline2 = lastData && lastData.filter(v => Number(moment(v.date).format('YYYYMMDD')) >= Number(moment().format('YYYYMMDD')))

    const planpercent = _.sortBy(lastData.filter(v => Number(moment(v.date).format('YYYYMMDD')) <= Number(moment().format('YYYYMMDD')) && typeof v.CC_PV !== 'undefined'), 'date').reverse()[0]
        && _.sortBy(lastData.filter(v => Number(moment(v.date).format('YYYYMMDD')) <= Number(moment().format('YYYYMMDD')) && typeof v.CC_PV !== 'undefined'), 'date').reverse()[0]['CC_PV']


    const actualpercent = _.maxBy(lastData.filter(v => typeof v.CC_EV !== 'undefined'), 'CC_EV') &&
        _.maxBy(lastData.filter(v => typeof v.CC_EV !== 'undefined'), 'CC_EV')['CC_EV']


    const [chartRange, setChartRange] = useState({ min: "", max: "" });
    const [dateRangeSlider, setDateRangeSlider] = useState({ min: "", max: "" });


    useEffect(() => {
        const date_arr = result.map((com) => com.date);

        const minDate = new Date(Math.min(...date_arr));
        const maxDate = new Date(Math.max(...date_arr));

        setChartRange({ min: minDate, max: maxDate });

        setDateRangeSlider({
            min: new Date(moment(minDate).add(-1, "days").format("YYYY-MM-DD")),
            max: new Date(moment(maxDate).add(1, "days").format("YYYY-MM-DD")),
        });
    }, [result]);


    const labelContent_0 = (e) => {
        const idx = e.index;

        return e.value === 0 || e.value === null || typeof e.value === "undefined"
            ? ""
            : selectedValue === 'monthly'
                ? i18n.language === "ko"? moment(new Date(e.value)).format("YY년 MM월"):  moment(new Date(e.value)).format("MMM YY")
                : result.length <= 50
                    ? moment(new Date(e.value)).format(i18n.language === "ko"?"YY년 MM월":"MMM YY") +
                    + "\n" +
                    Math.ceil(new Date(e.value).getDate() / 7) +
                    (i18n.language === "ko"?"주":"week")
                    : idx % 4 === 0
                        ? moment(new Date(e.value)).format(i18n.language === "ko"?"YY년 MM월":"MMM YY") +
                        + "\n" +
                        Math.ceil(new Date(e.value).getDate() / 7) +
                        (i18n.language === "ko"?"주":"week")
                        : "";
    };

    const seriesLabels_0 = {
        visible: true,
        // Note that visible defaults to false
        padding: 0,
        font: "0.6rem Arial, sans-serif",
        position: "top",
        background: "none",
        // rotation: { angle: "auto" },
        content: labelContent_0,
    };


    const handledateRange = (e) => {
        const { start, end } = e.value;

        const customStart = new Date(moment(new Date(start)).format("YYYY-MM-DD"));
        const customEnd = new Date(moment(new Date(end)).format("YYYY-MM-DD"));

        setChartRange({ min: customStart, max: customEnd });
    };


    const handleDateChange = (event) => {
        setDateValue(event.target.value);
      };


    return (
        <>
            <GridLayout style={{ height: 234 }}
                rows={[
                    {
                        height: '70%',
                    },

                    {
                        height: '8%',
                    },
                    {
                        height: '8%',
                    },
                    {
                        height: '14%',
                    },
                ]}

                cols={[
                    {
                        width: '18%',
                    }, {
                        width: '82%',
                    },


                ]}
                gap={{
                    rows: 0,
                    cols: 10,
                }}>

                <GridLayoutItem col={1} row={1} rowSpan={2}>
                    <Card style={{ width: '100%', height: 180}}>
                        {/* <CardTitle style={{ textAlign: "left", color: "#363945" , padding:8}}>
                            {t("Overall")} 
                        </CardTitle> */}
                        <CardBody style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '20%', left: '50%', transform: 'translate(-50%, -20%)' }}>
                                <CardTitle style={{ textAlign: 'center', color: '#363945' }}>Dev.</CardTitle>

                                <CardTitle style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}>

                                    {planpercent && actualpercent && actualpercent - planpercent > 0 ?
                                        <span style={{ color: '#00539C' }}>{(actualpercent - planpercent).toFixed(2)}</span> :
                                        <span style={{ color: '#9E1030' }}>{(actualpercent - planpercent).toFixed(2)}</span>}%</CardTitle>
                            </div>
                            <Tooltip anchorElement="target" position="top">
                                <div style={{ position: 'absolute', left: '5px', bottom: '5px' }} title="">

                                    <CardSubtitle style={{ color: '#939597', fontSize: '1rem', textAlign: 'center' }}>Plan</CardSubtitle>

                                    <CardSubtitle style={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }}>  {planpercent && planpercent.toFixed(2)}%</CardSubtitle>
                                </div>
                            </Tooltip>

                            <div style={{ position: 'absolute', right: '5px', bottom: '5px' }}>
                                <CardSubtitle style={{ textAlign: 'center', color: '#939597', fontSize: '1rem' }}>Actual</CardSubtitle>
                                <CardSubtitle style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', color: 'black' }}>{actualpercent && actualpercent.toFixed(2)}%</CardSubtitle>
                            </div>
                        </CardBody>
                    </Card>
                </GridLayoutItem>
                <GridLayoutItem  col={1} row={3} >
                <div style={{ marginLeft: "5px" ,marginTop:10}}>Cut Off</div>
                <ComboBox
                        data={cutOff}
                        textField="desc"
                        dataItemKey="date"
                        value={datevalue}
                        onChange={handleDateChange}
                        placeholder="Please select ..."
                        style={{
                            width: "100%",
                            marginRight: "5px",
                            marginBottom: "10px",
                          }}
                        />

                </GridLayoutItem>


                <GridLayoutItem col={2} row={1} rowSpan={2}>
                    <div style={{ position: 'absolute', top: '10px', right: '80px' }}>
                        <span></span>
                        <ButtonGroup>
                            {/* <Button
                                title="확대/이동 켜기"
                                iconClass='k-icon k-font-icon k-i-zoom-in'
                                themeColor={zoomableyn ? "primary" : null} fillMode="flat"
                                onClick={toggleZoom}
                            >
                            </Button> */}

                            {/* <Button
                                title="이미지로 저장"
                                iconClass='k-icon k-font-icon k-i-image-export'
                                fillMode="flat"
                                onClick={onImageExportClick}
                            >
                            </Button> */}


                        </ButtonGroup>
                    </div>
                    <div style={{ position: 'absolute', right: '12px', zIndex: 9999999, display: 'flex', alignItems: 'center' }} className='manRB'>
                        <ThemeProvider theme={theme}>
                            <ToggleButtonGroup size="small" onChange={handleChangeChart} value={selectedValue} exclusive aria-label="baseunit">

                                <ToggleButton value="weekly" aria-label="weekly">
                                    weekly
                                </ToggleButton>
                                <ToggleButton value="monthly" aria-label="monthly">
                                    monthly
                                </ToggleButton>
                            </ToggleButtonGroup>


                        </ThemeProvider>

                        

                    </div>

                    <Chart ref={chart} pannable={zoomableyn} zoomable={zoomableyn}>
                        <ChartTitle text={charttitle} font="0.8rem pretendard-R" />
                        <ChartArea height={225} margin={{bottom:20}} />
                        <ChartLegend position="bottom" orientation="horizontal" />
                        <ChartValueAxis>
                            <ChartValueAxisItem name='월간' labels={{ content: labelContentAxis1, font: "0.7rem Arial, pretendard-R" }} visible={false} majorTicks={{ step: 100, }} minorTicks={{ step: 100, }} majorGridLines={{ step: 10 }} minorGridLines={{ step: 10 }} />
                            <ChartValueAxisItem name='누적(%)' min={0} labels={{ content: labelContentAxis1, font: "0.7rem Arial, pretendard-R" }} majorGridLines={{ step: 10 }} minorGridLines={{ step: 10 }} visible={true} />
                        </ChartValueAxis>
                        <ChartTooltip shared={true} render={sharedTooltipRender} />

                        <ChartSeries>

                            <ChartSeriesItem
                                color='#DBF3FF'
                                type="column"
                                data={lastData}
                                field="PV"
                                categoryField="date"
                                name={selectedValue === 'monthly' ? t("월간") +" "+t("계획") : t("주간") +" "+t("계획")}
                                autoFit={true}
                                axis='월간'
                            />


                            <ChartSeriesItem
                                color='#42B7F4'
                                type="column"
                                data={lastData}
                                field="EV"
                                categoryField="date"
                                name={selectedValue === 'monthly' ? t("월간") +" "+t("실적") : t("주간") +" "+t("실적")}
                                autoFit={true}
                                axis='월간'


                            />

                            <ChartSeriesItem
                                color='#B1CCE4'
                                type="line"
                                data={planline1}
                                field="CC_PV"
                                categoryField="date"
                                name={t("누적 계획")}
                                autoFit={true}
                                axis='누적(%)'
                                markers={{
                                    visible: selectedValue === 'monthly' ? true : false,
                                }}

                            />
                            <ChartSeriesItem
                                color='#B1CCE4'
                                type="line"
                                data={planline2}
                                field="CC_PV"
                                categoryField="date"
                                name=""
                                autoFit={true}
                                axis='누적(%)'
                                dashType='dash'
                                markers={{
                                    visible: selectedValue === 'monthly' ? true : false,
                                }}


                            />


                            <ChartSeriesItem
                                color='#5E8EFF'
                                type="line"
                                data={lastData}
                                field="CC_EV"
                                categoryField="date"
                                name={t("누적 실적")}
                                autoFit={true}
                                axis='누적(%)'
                                markers={{
                                    visible: selectedValue === 'monthly' ? true : false,
                                }}


                            />

                        </ChartSeries>


                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                                min={chartRange.min}
                                max={chartRange.max}
                                labels={seriesLabels_0}
                                baseUnit={selectedValue !== 'monthly' ? "weeks" : "months"}
                                axisCrossingValue={[0, result.length]}
                                majorGridLines={{ step: 100 }}
                                minorGridLines={{ step: 100 }}
                            />
                        </ChartCategoryAxis>

                    </Chart>
                </GridLayoutItem>

                <GridLayoutItem col={2} row={4} rowSpan={1}>
                    {chartRange.min !== "" && chartRange.max !== "" && (
                        <RangeSlider
                            min={dateRangeSlider.min.getTime()}
                            max={dateRangeSlider.max.getTime()}
                            value={{
                                start: chartRange.min.getTime(),
                                end: chartRange.max.getTime(),
                            }}
                            onChange={(e) => handledateRange(e)}
                            style={{
                                width: "100%",
                                fontSize: "10px",
                            }}
                            labels={{
                                position: "top",
                            }}
                        >
                            {_.uniqBy(result, "month_date").map((com, idx, arr) => {
                                if (idx !== 0 && idx !== arr.length - 1) {
                                    return (
                                        <SliderLabel
                                            key={idx}
                                            position={new Date(`${com.month_date}-01`).getTime()}
                                        >
                                            {moment(com.date).format("YY/MM")}
                                        </SliderLabel>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </RangeSlider>
                    )}
                </GridLayoutItem>
            </GridLayout>
        </>
    );

};

export default OverallProgressChart
