import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
 import axios from "axios";import Cookies from 'js-cookie';
import _ from 'lodash';
import { guid } from "@progress/kendo-react-common";
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import Compress from 'compress.js'
import {
  loadMessages,
} from "@progress/kendo-react-intl";
import moment from 'moment';
import krMessages from "./kr.json";
import { Field, Form } from '@progress/kendo-react-form';
import {
  FormInput,
  FormTextArea,
  FormDatePicker,
} from "./table/form-components";
loadMessages(krMessages, "ko-KR");

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const FORM_DATA_INDEX = "formDataIndex";
const DATA_ITEM_KEY = "ID";

const FormSubmitContext = React.createContext(() => undefined);
const GridEditContext = React.createContext({});

const GridInlineFormRow = (props) => {
  const { onRowAction, editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
  const onSubmit = React.useCallback(
    (e) => {
      onRowAction({
        rowIndex: editIndex,
        operation: "save",
        dataItem: { ...e, photo_date: moment(e.photo_date).format('YYYY-MM-DD') },
      });
    },
    [onRowAction, editIndex]
  );
  if (isInEdit) {
    return (
      <Form
        key={JSON.stringify(props.dataItem)}
        initialValues={{ ...props.dataItem, photo_date: new Date(props.dataItem.photo_date) }}
        onSubmit={onSubmit}
        render={(formRenderProps) => {
          return (
            <FormSubmitContext.Provider value={formRenderProps.onSubmit}>
              {props.children}
            </FormSubmitContext.Provider>
          );
        }}
      />
    );
  }
  return props.children;
};

const DateCell = (props) => {
  const { editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
  return (
    <td>
      {isInEdit ? (
        <Field
          component={FormDatePicker}
          name={`${props.field}`}
          required={true}
        // validator={requiredValidator}
        />
      ) : (
        moment(props.dataItem[props.field || ""]).format('YYYY년 MM월 DD일')
      )}
    </td>
  );
};

const TextCell = (props) => {
  const { editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
  return (
    <td>
      {isInEdit && props.field !== 'projectcode' ? (
        <Field
          component={FormTextArea}
          name={`${props.field}`}
        // validator={requiredValidator}
        />
      ) : (
        props.dataItem[props.field || ""]
      )}
    </td>
  );
};

const InputCell = (props) => {
  const { editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
  return (
    <td>
      {isInEdit && props.field !== 'projectcode' ? (
        <Field
          component={FormInput}
          name={`${props.field}`}
        // validator={requiredValidator}
        />
      ) : (
        props.dataItem[props.field || ""]
      )}
    </td>
  );
};


const CommandCell = (props) => {
  const onSubmit = React.useContext(FormSubmitContext);
  const { onRowAction, setEditIndex, editIndex } =
    React.useContext(GridEditContext);
  const rowIndex = props.dataItem[FORM_DATA_INDEX];
  const rowID = props.dataItem['ID'];
  const isInEdit = rowIndex === editIndex;
  const isNewItem = !props.dataItem[DATA_ITEM_KEY];
  const onRemoveClick = React.useCallback(
    (e) => {
      e.preventDefault();
      onRowAction({
        rowIndex,
        rowID,
        operation: "remove",
      });
    },
    [rowIndex, onRowAction]
  );
  const onSaveClick = React.useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(e);
    },
    [onSubmit]
  );
  const onEditClick = React.useCallback(
    (e) => {
      e.preventDefault();
      setEditIndex(rowIndex);
    },
    [rowIndex, setEditIndex]
  );
  const onCancelClick = React.useCallback(
    (e) => {
      e.preventDefault();
      setEditIndex(undefined);
    },
    [setEditIndex]
  );
  return isInEdit ? (
    <td className="k-command-cell">
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command"
        onClick={onSaveClick}
      >
        {isNewItem ? "추가" : "업데이트"}
      </button>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
        onClick={isNewItem ? onRemoveClick : onCancelClick}
      >
        {isNewItem ? "취소" : "취소"}
      </button>
    </td>
  ) : (
    <td className="k-command-cell">
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
        onClick={onEditClick}
      >
        수정
      </button>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command"
        onClick={onRemoveClick}
      >
        삭제
      </button>
    </td>
  );
};


const rowRender = (row, props) => {
  return <GridInlineFormRow dataItem={props.dataItem}>{row}</GridInlineFormRow>;
};


const PhotoTable = ({ pjtlist ,selected}) => {

  const { project_code } = useParams();
  const [statusdata, setstatusdata] = useState([]);
  const [state, setState] = useState(true)
  const [imageSrc, setImageSrc] = useState([]);


  useEffect(() => {
    const fetchData = async () => {

      const res = await axios.get(`/api/getJSONtotal?path=/${REACT_APP_PATH2}/file/temp/photo/${project_code}.json`)
      setstatusdata(res.data.map((dataItem, idx) => ({
        ...dataItem,
        [FORM_DATA_INDEX]: idx,
      })))

    }
    fetchData();

  }, [project_code, state, selected]);

  const handleSubmit = () => {

    axios.post(`/api/writeJSON?path=/${REACT_APP_PATH2}/file/temp/photo/${project_code}.json`, statusdata)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error(error);
      })
    alert('서버에 저장되었습니다');
    setState(!state)
  }


  const [editIndex, setEditIndex] = React.useState(undefined);

  const onRowAction = React.useCallback(
    (options) => {
      const newDataState = [...statusdata];

      switch (options.operation) {
        case "remove":
          let index2 = statusdata.findIndex((record) => record.ID === options.rowID);
          newDataState.splice(index2, 1);
          break;
        case "save":
          let index = statusdata.findIndex(
            (product) => product.ID === options.dataItem.ID
          );
          newDataState[index] = options.dataItem;
          setEditIndex(undefined);
          break;
        case "add":
          newDataState.unshift({
            [FORM_DATA_INDEX]: options.rowIndex,
            [DATA_ITEM_KEY]: guid(),
            ['projectcode']: project_code,
            ['photo_date']: new Date(),

          });
          setEditIndex(options.rowIndex);
          break;
        default:
      }
      setstatusdata(newDataState);
    },
    [statusdata]
  );

  const onAddClick = React.useCallback(() => {
    onRowAction({
      rowIndex: statusdata.length,
      operation: "add",
    });
  }, [onRowAction, statusdata]);


  const compress = new Compress();

  const encodeFileToBase64 = async (e, id) => {
    // const reader = new FileReader();
    const file = e.target.files[0];

    const resizedfile = await compress.compress([file], {
      size: 2, // the max size in MB, defaults to 2MB
      quality: 1, // the quality of the image, max is 1,
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
      rotate: false, // See the rotation section below
    })

    console.log(resizedfile[0]['prefix']+resizedfile[0]['data'])

    const filename = e.target.value.split('\\').reverse()[0]
    // reader.readAsDataURL(resizedfile[0]);
    // return new Promise((resolve) => {
    //   reader.onload = () => {
        setImageSrc(
          [...imageSrc,
          { name: id, base64:resizedfile[0]['prefix']+resizedfile[0]['data'], filename: filename }
          ]
        );
        // setState2(!state2)
    //     resolve();
    //   };
    // });
  };


  useEffect(() => {
    setstatusdata(statusdata.map((v) => ({ ...v, ...imageSrc.find(vf => vf.name === v.ID) })))
  }, [imageSrc]);


  const ImageUploadCell = (props) => {
    const id = props.dataItem.ID
    const filename = props.dataItem.filename
    const { editIndex } = React.useContext(GridEditContext);
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    return (
      <td>
        {isInEdit ? (
          <>
            <input type="file" onChange={(e) => { encodeFileToBase64(e, id); }} />
          </>
        ) : (
          filename && filename
        )}
      </td>
    );
  };

  const ImgTextCell = (props) => {
    const imgID = props.dataItem.ID
    const defaultvale = imgID && imageSrc.find(v => v.name === imgID) && imageSrc.find(v => v.name === imgID)['base64']
    return (
      <td>
        {defaultvale ? (
          defaultvale
        ) : (props.dataItem[props.field || ""])}
      </td>
    );
  };



  const ImgCell = (props) => {
    const imgID = props.dataItem.base64
    return (
      <td>
        {imgID ? (
          <img src={imgID} width={100} />
        ) : (props.dataItem[props.field || ""])}
      </td>
    );
  };

  
  const [pjtinfo, setpjtinfo] = useState([])

  const fetchData = async () => {
    let body =
    {
        "bpname": "ESG 대시보드 프로젝트 관리",
        "lineitem": "no",
        "filter_criteria": {
            "join": "AND",
            "filter": [
                {
                    "field": "status",
                    "value": "Active",
                    "condition_type": "eq"
                },

                {
                  "field": "ugenProjectNumber",
                  "value": project_code,
                  "condition_type": "eq"
              }
            ]
        }
    }


    const res = await axios.post(`/api/getbprecordtotal?path=SKEC0001`, body)

    setpjtinfo(res.data.data.data)

}
useEffect(() => {
    fetchData();
}, [project_code]);

  return (
    <>

      <div style={{ margin: 30, textAlign: 'center' }}>
        {_.find(pjtlist, { 'ugenProjectNumber': project_code }) && _.find(pjtlist, { 'ugenProjectNumber': project_code })['SKONeUSEPD'] === '운용' ? 'SKONe에 값이 입력되어있습니다' :
          <>



            <GridEditContext.Provider
              value={{
                onRowAction,
                editIndex,
                setEditIndex,
              }}
            >
              <Grid
                className='import inputComponent'
                style={{
                  height: "700px", marginTop: 16
                }}
                data={statusdata} dataItemKey={DATA_ITEM_KEY} rowRender={rowRender}>
                <GridToolbar>
                {pjtinfo[0] && pjtinfo[0]['ugenProjectName']}({pjtinfo[0] && pjtinfo[0]['ugenProjectNumber']})


                  <button
                    title="Add new"
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                    onClick={onAddClick}
                    style={{ position: 'absolute', right: '100px' }}

                  >
                    행추가
                  </button>
                  <button
                    title="save"
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                    onClick={handleSubmit}
                    style={{ position: 'absolute', right: '15px' }}
                  >
                    입력완료
                  </button>

                </GridToolbar>
                <Column
                  field="image"
                  title="사진 업로드"
                  cell={ImageUploadCell}
                  width="200px"

                />

                <Column
                  field="imagesrc"
                  title="사진값"
                  cell={ImgTextCell}
                  width="0px"
                />
                <Column title='사진' field="photo" cell={ImgCell} />

                <Column
                  field="projectcode"
                  title="프로젝트"
                  cell={InputCell}
                  width="150px"

                />

                <Column field="photo_date" title="사진 촬영일"
                  // editor="date"
                  cell={DateCell}
                  width="200px"

                // format="{0:d}"
                />

                <Column
                  field="photo_title"
                  title="사진 제목"
                  width="200px"
                  cell={InputCell}
                />

                <Column
                  field="photo_description"
                  title="사진 설명"
                  width="350px"
                  cell={TextCell}
                />




                <Column cell={CommandCell} width="200px" />
                {/* <Column field="ID" title="Id" width="80px" editable={false} /> */}

              </Grid>
            </GridEditContext.Provider>

          </>
        }
      </div>


    </>


  );

}
export default PhotoTable;
