import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Button, TextField, MenuItem, Select, FormControl, InputLabel, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import _ from 'lodash';
import axios from "axios";
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { Circles } from 'react-loader-spinner';

const InfoBox = ({ selectedRow, username ,pdftronInstance, documenTitle}) => {
    const { project_code } = useParams();
    const [options, setOptions] = React.useState([]);
    const [existData, setExistData] = useState({})
    const [loading, setLoading] = useState(true);

    const [selectedOption, setSelectedOption] = useState(""); // 선택된 옵션 상태 추가
    const [comment, setComment] = useState(""); // 사용자의 코멘트를 위한 상태
    const [isButtonDisabled, setIsButtonDisabled] = useState(true); // 버튼

    console.log(existData)
    console.log(selectedOption)

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
        setIsButtonDisabled(event.target.value.trim() === '' && selectedOption !== ""); // 코멘트가 비어있지 않은 경우에만 버튼을 활성화
    };


    useEffect(() => {
      const fetchData = async () => {
          if (project_code) {
            setLoading(true);
              try {
                  const res = await axios.get(`/api/gettrsimple?path=${project_code}&record=${selectedRow.record_no}&line=${selectedRow.LineAutoSeq}`);
                  if (res.data.success) {
                    setExistData(res.data.data);
                  }
              } catch (error) {
                  console.error("Error fetching data", error);
              }
              setLoading(false);
          }
      };
      fetchData();
  }, [project_code, selectedRow]);
  

  useEffect(() => {
    if (options.length > 0 && existData.DecisionBPK && options.includes(existData.DecisionBPK)) {
        setSelectedOption(existData.DecisionBPK);
    }
    if (existData.DocComment) {
      setComment(existData.DocComment);
    }
  }, [existData, options]);
  

    const sendData = [{
        record_no:selectedRow.record_no, 
        _bp_lineitems:[{
            DecisionBPK:selectedOption, 
            DocComment:comment, 
            Submittedby:username,
            SubmittedDate:dayjs().format("MM-DD-YYYY"),
            LineAutoSeq:selectedRow.LineAutoSeq
        }]

    }]

    const handleSubmitReview = async () => {
        try {
            // SweetAlert2를 사용하여 사용자에게 확인을 요청합니다.
            const willSend = await Swal.fire({
                title: 'Are you sure?',
                text: "Do you really want to send the data?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, send it!'
            });
    
            // 사용자가 'Yes'를 클릭한 경우에만 API 호출을 진행합니다.
            if (willSend.isConfirmed) {
                if (pdftronInstance) {
                    const { documentViewer, annotationManager } = pdftronInstance.Core;
                    const doc = documentViewer.getDocument();
                    const xfdfString = await annotationManager.exportAnnotations();
                    const data = await doc.getFileData({
                        // saves the document with annotations in it
                        xfdfString
                      });
                      const arr = new Uint8Array(data);
                      const blob = new Blob([arr], { type: 'application/pdf' });
          
    
                    const formData = new FormData();
                    formData.append("file", blob, 'annotated-document.pdf');

                    // 전체 데이터 객체를 문자열로 변환하여 추가
                    const dataObject = {
                        record_no: selectedRow.record_no,
                        _bp_lineitems: [{
                            DecisionBPK: selectedOption, 
                            DocComment: comment, 
                            Submittedby: username,
                            SubmittedDate: dayjs().format("MM-DD-YYYY"),
                            LineAutoSeq: selectedRow.LineAutoSeq
                        }]
                    };
                    formData.append("data", JSON.stringify(dataObject));
    
                    // axios를 사용하여 백엔드로 formData를 전송합니다.
                    const response = await axios.post(`/api/updatetr?project=${project_code}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
    
                    // 응답 처리...
                    if (response.data.success) {
                        Swal.fire('Sent!', 'Your data has been sent successfully.', 'success');
                        setComment('');
                        setIsButtonDisabled(true);
                    } else {
                        Swal.fire('Failed!', 'Something went wrong.', 'error');
                    }
                }
            }
        } catch (error) {
            console.error('Error submitting review:', error);
            Swal.fire('Error!', 'An error occurred while sending your data.', 'error');
        }
    };
    
     

    useEffect(() => {
        const fetchData = async () => {
            if (project_code) {
                try {
                    const res = await axios.get(`/api/getreturncode?path=${project_code}`);
                    if (res.data.success) {
                        setOptions(res.data.data.data.map((vf) => vf.SKDMApprovalDecision));
                    }
                } catch (error) {
                    console.error("Error fetching data", error);
                }
            }
        };
        fetchData();
    }, [project_code]);

      
  if (loading) {
    return <div className="loadingContainer">
    <Circles ariaLabel="loading" color="#00BFFF" height={50} width={50} />
  </div>;;
  }

    return (
        <Paper elevation={3} style={{ padding: '16px', width: '300px', backgroundColor: '#f5f5f5' }}>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Document Properties</Typography>
            <Typography variant="body2" gutterBottom>{selectedRow.No}</Typography>
            <Typography variant="body2" gutterBottom>{selectedRow.title}</Typography>
            <Typography variant="body2" gutterBottom>{selectedRow.WBSDiscNM}</Typography>
            <Typography variant="body2" gutterBottom>Rev. {selectedRow.RevNo}</Typography>
            <Typography variant="body2" style={{ color: '#d32f2f' }} gutterBottom>{selectedRow.stageName}</Typography>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginTop: '16px' }}>Your Response</Typography>
            <Typography variant="body2" style={{ color: '#ffa726' }}>Due {dayjs(selectedRow.DueDate).format("MM/DD/YYYY")}</Typography>
         
            <FormControl variant="outlined" size="small" fullWidth margin="dense">
                <InputLabel id="response-label">Decision</InputLabel>
                <Select
                    labelId="response-label"
                    id="response-select"
                    value={selectedOption}
                    label="Decision"
                    onChange={handleChange}
                >
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                label="Add a comment"
                variant="outlined"
                size="small"
                fullWidth
                margin="dense"
                value={comment}
                onChange={handleCommentChange}
                multiline
                rows={4} // 텍스트 필드의 높이 조절
                placeholder="Type your comment here..."
            />
            <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    style={{ margin: '8px 0' }}
                    disabled={isButtonDisabled}
                    onClick={handleSubmitReview}
                >
                    Submit Review
                </Button>
            </Box>
            <Box mt="auto" pt={2}>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Transmittal Properties</Typography>
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>{selectedRow.TR_NO}</Typography>
                <Typography variant="body2" gutterBottom>{selectedRow.TR_Title}</Typography>
                <Typography variant="body2" style={{ color: '#d32f2f' }} gutterBottom>{selectedRow.TR_purpose}</Typography>
            </Box>
        </Paper>
    );
};

export default InfoBox;
