import React, { useEffect, useState } from "react";
 import axios from "axios";import Cookies from 'js-cookie';
import _ from "lodash";
import { Card, CardHeader, CardSubtitle, CardTitle, GridLayout, GridLayoutItem, CardBody } from "@progress/kendo-react-layout";
import { TableBody, TableCell, TableHead, TableRow, Table, TableContainer } from "@mui/material";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from '@progress/kendo-data-query';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const TotalLTIR = ({ positiondata, projectlist, ltirdata }) => {

    const isDPI = useMediaQuery({ query: '(min-resolution: 120dpi)' })

    const [BoxHeight, setBoxHeight] = useState(250);
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        const boxHeight = positiondata[0];
        setBoxHeight(((height - 10) / 7) * (boxHeight && boxHeight.rowSpan) - 80);
    }, [positiondata, height]);


    const [ltidata, setltidata] = useState(250);

    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "LTI 리스트",
                "lineitem": "no",
                "filter_condition": "status=Active",

            }

            const res = await axios.post(`/api/getbprecord?path=SKEC0001`, body  , )

            setltidata(res.data.data.data.map((v) => ({ ...v, month: v.YearText.slice(2, 5) + ' ' + v.MonthText, year: v.YearText.slice(2, 5), uniq: v.YearText.slice(2, 5) + ' ' + v.MonthText + v.SKEcoDivisionSPD })))


        };

        fetchData();
    }, []);


    const thisMonth = moment(new Date()).format('YY년 MM월')
    const lastMonth = moment(new Date()).subtract(1, 'month').format('YY년 MM월')
    const thisYear = moment(new Date()).format('YY년')

    const datawithtime = _.uniqBy(ltirdata.map((v) => ({ ...v, month: v.date.slice(8, 10) + '년 ' + v.date.slice(0, 2) + '월', year: v.date.slice(8, 10) + '년', uniq: v.date.slice(0, 10) + v.ugenProjectNumber })), 'uniq')

    var ltidata_group =
        _(ltidata)
            .groupBy('SKEcoDivisionSPD')
            .map((objs, key) => ({
                'SKEcoDivisionSPD': key,
                'thisMonthLTI': _.sumBy(objs.filter(v => v.month === thisMonth), 'LTIRatioAppDA'),
                'lastMonthLTI': _.sumBy(objs.filter(v => v.month === lastMonth), 'LTIRatioAppDA'),
                'thisyearLTI': _.sumBy(objs.filter(v => v.year === thisYear), 'LTIRatioAppDA'),
            }))
            .value()


    var datawithtime_group =
        _(datawithtime)
            .groupBy('SKEcoDivisionSPD')
            .map((objs, key) => ({
                'SKEcoDivisionSPD': key,
                'thisMonthMH':
                    _.sumBy(
                        _.filter(objs, { record_no: _.sortBy(objs.filter(v => v.month === thisMonth), 'record_no').reverse()[0] && _.sortBy(objs.filter(v => v.month === thisMonth), 'record_no').reverse()[0]['record_no'] })
                        , 'CumulTotalMH'),
                'lastMonthMH':
                    _.sumBy(
                        _.filter(objs, { record_no: _.sortBy(objs.filter(v => v.month === lastMonth), 'record_no').reverse()[0] && _.sortBy(objs.filter(v => v.month === lastMonth), 'record_no').reverse()[0]['record_no'] })
                        , 'CumulTotalMH'),
                'thisyearMH':
                    _.sumBy(
                        _.filter(objs, { record_no: _.sortBy(objs.filter(v => v.year === thisYear), 'record_no').reverse()[0] && _.sortBy(objs.filter(v => v.year === thisYear), 'record_no').reverse()[0]['record_no'] })
                        , 'CumulTotalMH'),
            }))
            .value()


    const lastdata = datawithtime_group.map((v) => ({ ...v, ...ltidata_group.find(vf => vf.SKEcoDivisionSPD === v.SKEcoDivisionSPD) }))
        .map((v) => ({
            ...v,
            thisMonthLTIR: isNaN((v.thisMonthLTI / v.thisMonthMH) * 200000) ? 0 : ((v.thisMonthLTI / v.thisMonthMH) * 200000).toFixed(3),
            lastMonthLTIR: isNaN((v.lastMonthLTI / v.lastMonthMH) * 200000) ? 0 : ((v.lastMonthLTI / v.lastMonthMH) * 200000).toFixed(3),
            thisyearLTIR: isNaN((v.thisyearLTI / v.thisyearMH) * 200000) ? 0 : ((v.thisyearLTI / v.thisyearMH) * 200000).toFixed(3)
        }))


    const thisMonthLTIR = (_.sumBy(lastdata, 'thisMonthLTI') / (_.sumBy(lastdata, 'thisMonthMH')) * 200000).toFixed(3)
    const lastMonthLTIR = (_.sumBy(lastdata, 'lastMonthLTI') / (_.sumBy(lastdata, 'lastMonthMH')) * 200000).toFixed(3)
    const thisyearLTIR = (_.sumBy(lastdata, 'thisyearLTI') / (_.sumBy(lastdata, 'thisyearMH')) * 200000).toFixed(3)


    return (
        <>
            <GridLayout style={{ height: isDPI ? (BoxHeight +20):BoxHeight - 16 }}
                rows={[
                    {
                        height: '100%',
                    },

                ]}

                cols={[
                    {
                        width: '30%',
                    },
                    {
                        width: '69%',
                    },

                ]}
                gap={{
                    rows: 0,
                    cols: 10,
                }}>
                <GridLayoutItem row={1} col={1} rowSpan={1} style={{ position: 'relative' }}>
                    <Card className='esg-card' style={{ width: '100%', height: '100%' }}>
                        <CardBody style={{ position: 'relative' }}>
                            <CardTitle style={{ height: '10%', margin: '0', fontFamily: 'pretendard-B', color: '#F1F2F5' }}>금월 {thisMonth.slice(4, 6)}/01 - {moment(new Date()).format('MM/DD')}</CardTitle>
                            <CardTitle style={{ fontSize: isDPI? '3.2rem':'4em', fontWeight: 'bold', height: '50%', position: 'relative', textAlign: 'center', color: '#F49B42' }}><span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{thisMonthLTIR}</span></CardTitle>
                            <div style={{ height: '30%' }}>
                                <div style={{ position: 'absolute', bottom: 0, left: '5px', fontFamily: 'pretendard-B' }}>
                                    <CardSubtitle style={{ color: '#F1F2F5', marginBottom: 0 }}>{thisYear} 누계</CardSubtitle>
                                    <CardSubtitle style={{ fontSize: isDPI?'1.5rem':'2.5rem', color: '#B0B8C2' }}>{thisyearLTIR}</CardSubtitle>
                                </div>

                                <div style={{ fontFamily: 'pretendard-B', position: 'absolute', bottom: 0, right: '5px' }}>
                                    <CardSubtitle style={{ textAlign: 'right', color: '#F1F2F5', marginBottom: 0 }}>{lastMonth}</CardSubtitle>
                                    <CardSubtitle style={{ textAlign: 'right', color: '#B0B8C2', fontSize: isDPI?'1.5rem':'2.5rem'}}>{lastMonthLTIR}</CardSubtitle>
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                </GridLayoutItem>
                <GridLayoutItem row={1} col={2} rowSpan={1} style={{ position: 'relative' }}>
                    <TableContainer className="ltirtable" style={{ overflow: 'auto', height: isDPI ? (BoxHeight +20):BoxHeight - 16 }} sx={{ border: 1, borderColor: '#8E959F', paddingLeft: '8px', paddingRight: '8px' }}>
                        <Table stickyHeader aria-label="collapsible table" size='small' style={{ borderWidth: '1px', borderColor: '#8E959F' }}>
                            <TableHead>
                                <TableRow sx={{"& th": {fontSize: "0.75rem"}}}>
                                    <TableCell style={{ backgroundColor: '#464B52', textAlign: 'center', paddingBottom: '0px', color: '#B0B8C2', borderColor: '#31363D' }} rowSpan={isDPI?1:2}>구분</TableCell>
                                    <TableCell style={{ backgroundColor: '#464B52', textAlign: 'center', borderBottom: isDPI ?'1px solid rgba(224, 224, 224, 1)':'none', paddingBottom: '0px', color: '#B0B8C2' }}>{lastMonth}</TableCell>
                                    <TableCell style={{ backgroundColor: '#464B52', textAlign: 'center', borderBottom: isDPI ?'1px solid rgba(224, 224, 224, 1)':'none', paddingBottom: '0px', color: '#B0B8C2' }}>{thisMonth}</TableCell>
                                    <TableCell style={{ backgroundColor: '#464B52', textAlign: 'center', borderBottom: isDPI ?'1px solid rgba(224, 224, 224, 1)':'none', paddingBottom: '0px', color: '#B0B8C2' }}>{thisYear} 누계</TableCell>
                                    <TableCell style={{ backgroundColor: '#464B52', textAlign: 'center', borderBottom: isDPI ?'1px solid rgba(224, 224, 224, 1)':'none', paddingBottom: '0px', color: '#B0B8C2' }}>누적 재해</TableCell>
                                </TableRow>
                                {isDPI ? '':
                                <TableRow sx={{"& th": {fontSize: "0.75rem"}}}>
                                    <TableCell style={{ backgroundColor: '#464B52', textAlign: 'center', paddingTop: '0px', color: '#B0B8C2', borderColor: '#31363D', top: 30 }}>({moment(new Date()).subtract(1, 'month').startOf('month').format('MM/DD')} - {moment(new Date()).subtract(1, 'month').endOf('month').format('MM/DD')})</TableCell>
                                    <TableCell style={{ backgroundColor: '#464B52', textAlign: 'center', paddingTop: '0px', color: '#B0B8C2', borderColor: '#31363D', top: 30 }}>({thisMonth.slice(4, 6)}/01 - {moment(new Date()).format('MM/DD')})</TableCell>
                                    <TableCell style={{ backgroundColor: '#464B52', textAlign: 'center', paddingTop: '0px', color: '#B0B8C2', borderColor: '#31363D', top: 30 }}></TableCell>
                                    <TableCell style={{ backgroundColor: '#464B52', textAlign: 'center', paddingTop: '0px', color: '#B0B8C2', borderColor: '#31363D', top: 30 }}>(전월 대비 증감)</TableCell>
                                </TableRow>}
                            </TableHead>
                            <TableBody>
                                {lastdata.map((v, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ textAlign: 'center', color: '#B0B8C2', bordeerColor: '#8E959F' }}>{v.SKEcoDivisionSPD}</TableCell>
                                        <TableCell style={{ textAlign: 'center', color: '#B0B8C2', bordeerColor: '#8E959F' }}>{v.lastMonthLTIR} </TableCell>
                                        <TableCell style={{ textAlign: 'center', color: '#B0B8C2', bordeerColor: '#8E959F' }}>{v.thisMonthLTIR}</TableCell>
                                        <TableCell style={{ textAlign: 'center', color: '#B0B8C2', bordeerColor: '#8E959F' }}>{v.thisyearLTIR} </TableCell>
                                        <TableCell style={{ textAlign: 'center', color: '#B0B8C2', bordeerColor: '#8E959F' }}>{v.thisyearLTI} {isNaN(v.thisMonthLTI - v.lastMonthLTI) ? '-':  `(${v.thisMonthLTI - v.lastMonthLTI})`} </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </GridLayoutItem>


            </GridLayout>


          
        </>
    );
};

export default TotalLTIR;
