import React, { useState, useEffect } from 'react';
import PDFTron from './viewer';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import InfoBox from './infoBox';
import { useParams } from 'react-router-dom';

const MyDocumentViewerModal = ({ open, onClose, documentUrl, documenTitle, docuID, username, selectedRow }) => {
  
  const { project_code } = useParams();
  const [pdftronInstance, setPdftronInstance] = useState(null);

// PDFTron 컴포넌트에서 인스턴스를 업데이트하는 함수
const updatePdftronInstance = (instance) => {
  setPdftronInstance(instance);
};

  return (
<Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
  <Box display="flex" >
    <Box flex={1} mr={1}>
      <PDFTron initialDocUrl={documentUrl} documenTitle={documenTitle} docuID={docuID} username={username} 
       onInstanceReady={updatePdftronInstance}/>
    </Box>
    <InfoBox selectedRow={selectedRow} documenTitle={documenTitle} username={username}  pdftronInstance={pdftronInstance}/>
  </Box>
</Dialog>

  );
};
export default MyDocumentViewerModal;
