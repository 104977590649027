import React, { useEffect, useState } from 'react';
import { Link, } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment-timezone';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { orderBy, filterBy } from "@progress/kendo-data-query";
import krMessages from "./kr.json";
import EditForm from './editForm';
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
import { ColumnMenu } from "./columnMenu";
import { Window } from "@progress/kendo-react-dialogs";
import ResourceGantt from './gantt';
import useDocumentTitle from '../useDocumentTitle';
// import '@progress/kendo-theme-material/dist/all.css';

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);
loadMessages(krMessages, "ko-KR");

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;


const initialSort = [
  {
    field: "record_no",
    dir: "",
  },
];

const initialFilter = {
  logic: "and",
  filters: [
    {
      field: "record_no",
      operator: "contains",
      value: "",
    },
  ],
};

const EditCommandCell = (props) => {
  return (
    <td>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
        onClick={() => props.enterEdit(props.dataItem)}
      >
        할당
      </button>
    </td>
  );
};


const ResourceAssign = () => {

  const [data, setdata] = useState([]);
  const [sort, setSort] = useState(initialSort);
  const [filter, setFilter] = useState(initialFilter);

  useDocumentTitle("S.CON - EMS")

  const fetchData = async () => {

    let body =
    {
      "bpname": "Resource Requests (NWF)",
      "lineitem": "yes",
    }
    const res = await axios.post(`/api/getbprecord?path=SKEC0001`, body
    )
    setdata(res.data.data.data.filter(v => v.status !== 'Terminated'))

  };

  useEffect(() => {
    fetchData();
  }, []);


  const usersAssignment = data.filter(item => typeof item._bp_lineitems !== 'undefined')
    .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { record_no: v.record_no, user: o.emsNameEmploID, 
      uniq: v.ugenProjectNumber +o.UserGroupSPD+ o.emsPPStart1 + o.emsPPFinish1 + o.SKRoleClass1 + o.SKRoleClass2 + o.SKRoleClass3 + o.SKRoleClass4 +o.emsRRPlanDA1 }))), []);


  const expandChange = (event) => {
    let newData = data.map(item => {
      if (item.record_no === event.dataItem.record_no) {
        item.expanded = !event.dataItem.expanded;
      }

      return item;
    });
    setdata(newData);
  };

  const initialFilterD = {
    logic: "and",
    filters: [
      {
        field: "SKRoleClass1",
        operator: "contains",
        value: "",
      },
    ],
  };

  const DetailComponent = props => {
    const [filter, setFilter] = useState(initialFilterD);


    const dataI = props.dataItem._bp_lineitems
      .map((v) => ({
        ...v,
        ugenProjectNumber:
          v.ugenProjectNumber === undefined
            ? props.dataItem.ugenProjectNumber
            : v.ugenProjectNumber,
        uniq:
          props.dataItem.ugenProjectNumber +
          v.UserGroupSPD+
          v.emsPPStart1 +
          v.emsPPFinish1 +
          v.SKRoleClass1 +
          v.SKRoleClass2 +
          v.SKRoleClass3 +
          v.SKRoleClass4+
          v.emsRRPlanDA1,

      }))
      .map((v) => ({ ...v, ...usersAssignment.find((f) => f.uniq === v.uniq) }))
      .map((v) => ({
        ...v,
        startDate: new Date(v.emsPPStart1.slice(0, 10)),
        endDate: new Date(v.emsPPFinish1.slice(0, 10)),
      }))
      .map((v) => ({ ...v, record_no: props.dataItem.record_no }));



    const filterOperators = {
      text: [
        {
          text: "grid.filterContainsOperator",
          operator: "contains",
        },
      ],
      numeric: [
        {
          text: "grid.filterEqOperator",
          operator: "eq",
        },
      ],
      date: [
        {
          text: "grid.filterBeforeOrEqualOperator",
          operator: "lte",
        },
      ],
      boolean: [
        {
          text: "grid.filterEqOperator",
          operator: "eq",
        },
      ],
    };


    const [openForm, setOpenForm] = React.useState(false);
    const [editItem, setEditItem] = React.useState({});
    const [data, setData] = React.useState(dataI);


    const enterEdit = (item) => {
      setOpenForm(true);
      setEditItem(item);
    };



    const handleSubmit = (event) => {
      const uniqPJRecord = _.uniqBy(usersAssignment, "ugenProjectNumber");


      let newData = data
        .map((item) => {
          if (event.uniq === item.uniq) {
            item = event;
          }

          return item;
        })
        .map((f) => ({
          ...f,
          UserNameIdDPK: f.user,
        }));


      setData(newData);
      setOpenForm(false);

      // const bodylist = newData.map((v) => ({
      //   ...v,
      //   record_no: _.find(usersAssignment, v.user),
      // }));



      const item = Object.assign(_.find(usersAssignment, { 'uniq': event.uniq }) === null || _.find(usersAssignment, { 'uniq': event.uniq }) === undefined ? event : _.find(usersAssignment, { 'uniq': event.uniq }), { uuu_tab_id: "Resource Details" })
      const item_record = _.find(data, { 'ugenProjectNumber': event.ugenProjectNumber })['record_no']

      // if (event.user === null) {
      //   let body = {
      //     options: {
      //       bpname: "Resource Requests (NWF)",
      //       LineItemIdentifier: "LineAutoSeq",
      //     },
      //     data: [
      //       {
      //         _delete_bp_lineitems: item['LineAutoSeq'],
      //         record_no: item_record,
      //       },
      //     ],
      //   };

      //   axios
      //     .post(
      //       `${REACT_APP_URI2}/api/updatebprecord?path=SKEC0001`,
      //       body
      //     )
      //     .then(function (response) {
      //       // handle success
      //       if (response.data.data.status === 200) { fetchData() }
      //     });
      // }

      // else {
        // if (usersAssignment.map(v => v.uniq).includes(event.uniq)) {
          let body = {
            options: {
              bpname: "Resource Requests (NWF)",
              LineItemIdentifier: "LineAutoSeq",
            },
            data: [
              {
                _bp_lineitems: 
                [{emsPPStart1:moment(event.startDate).format('MM-DD-YYYY'),
                emsPPFinish1:moment(event.endDate).format('MM-DD-YYYY'), 
                emsRRPlanDA1:event.emsRRPlanDA1, 
                emsNameEmploID: event.user, 
                UserGroupSPD: _.find(usersAssignment, { uniq: event.uniq })['UserGroupSPD'],
                SKRoleClass1: _.find(usersAssignment, { uniq: event.uniq })['SKRoleClass1'],
                SKRoleClass2: _.find(usersAssignment, { uniq: event.uniq })['SKRoleClass2'],
                SKRoleClass3: _.find(usersAssignment, { uniq: event.uniq })['SKRoleClass3'],
                SKRoleClass4: _.find(usersAssignment, { uniq: event.uniq })['SKRoleClass4'],
                LineAutoSeq: _.find(usersAssignment, { uniq: event.uniq })['LineAutoSeq']}],
                record_no: item_record,
              },
            ],
          };

          axios
            .post(
              `${REACT_APP_URI2}/api/updatebprecord?path=SKEC0001`,
              body, 

            )
            .then(function (response) {
              // handle success
              if (response.data.data.status === 200) { 
                fetchData() 
              }
              else
              {console.log(response.data.data)}
            });
        // }

        // else {

        //   let body = {
        //     options: {
        //       bpname: "Resource Requests (NWF)",
        //     },
        //     data: [
        //       {
        //         _bp_lineitems: [Object.assign(item, {  emsNameEmploID: event.user, })],
        //         record_no: item_record,
        //       },
        //     ],
        //   };


        //   axios
        //     .post(
        //       `${REACT_APP_URI2}/api/updatebprecord?path=SKEC0001`,
        //       body
        //     )
        //     .then(function (response) {
        //       // handle success
        //       if (response.data.data.status === 200) { fetchData() }
        //     });
        // }
      // }

    };

    const handleCancelEdit = () => {
      setOpenForm(false);
    };

    const MyEditCommandCell = (props) => (
      <EditCommandCell {...props} enterEdit={enterEdit} />
    );

    return (
      <React.Fragment>

        <LocalizationProvider language="ko-KR">
          <IntlProvider locale="ko-KR">
            <Grid className='assigntable' data={filterBy(data, filter)} filterable={true} onFilterChange={(e) => setFilter(e.filter)}
              // filterOperators={filterOperators}
              filter={filter} style={{ fontSize: 'smaller', width: '98%', float: 'right' }}>
              <Column field="SKRoleClass1" title="대직무" />
              <Column field="SKRoleClass2" title="중직무" />
              <Column field="SKRoleClass3" title="소직무" />
              <Column field="SKRoleClass4" title="기타직무" />
              {/* <Column field="UserGroupSPD" title="그룹명" /> */}
              <Column field="startDate" title="시작일" filter="date" format="{0:d}" />
              <Column field="endDate" title="종료일" filter="date" format="{0:d}" />
              <Column field="emsRRPlanDA1" title="할당률" />
              <Column field="user" title="할당 자원" />
              <Column cell={MyEditCommandCell} filterable={false}/>
              {/* <Column field="uniq" title="uniq" /> */}

            </Grid>
          </IntlProvider>
        </LocalizationProvider>
        {openForm && <EditForm cancelEdit={handleCancelEdit} onSubmit={handleSubmit} item={editItem} />}
      </React.Fragment>

    )
  };



  const count1 = (props) => {
    const projectcode = props.dataItem.ugenProjectNumber
    const count = data.filter(v => v.ugenProjectNumber === projectcode)[0]['_bp_lineitems']&&data.filter(v => v.ugenProjectNumber === projectcode)[0]['_bp_lineitems'].length
    // console.log(data)
    // console.log(projectcode)

    return (

      <td
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
      >
        {count}
      </td>)
  }


  const count2 = (props) => {
    const projectcode = props.dataItem.ugenProjectNumber
    const count_o =data.filter(v => v.ugenProjectNumber === projectcode)[0]['_bp_lineitems']&& data.filter(v => v.ugenProjectNumber === projectcode)[0]['_bp_lineitems'].length

    const count =  data.filter(v => v.ugenProjectNumber === projectcode)[0]&&data.filter(v => v.ugenProjectNumber === projectcode)[0]['_bp_lineitems']&&
    data.filter(v => v.ugenProjectNumber === projectcode)[0]['_bp_lineitems'].filter(v => v.emsNameEmploID !== null).length


    return (

      <td
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
      >
        {count_o - count}
      </td>)
  }


  const [visible, setVisible] = React.useState(false);

  const toggleDialog = () => {
    setVisible(!visible);
  };


  return (<>
    <LocalizationProvider language="ko-KR">
      <IntlProvider locale="ko-KR">
        <Grid data={filterBy(orderBy(data, sort), filter)} detail={DetailComponent} expandField="expanded" onExpandChange={expandChange}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
          filterable={true}
          filter={filter}
          onFilterChange={(e) => setFilter(e.filter)}
          className='ResourceTable'
        >
          <GridToolbar>

      {visible && (
        <Window title={"자원별 할당현황"} onClose={toggleDialog} initialHeight={500} initialWidth={1200}>
          <ResourceGantt/>
        </Window>
      )}
          <Link to='/resource/gantt'  target="_blank" rel="noopener noreferrer"><button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">할당 현황 간트 차트 보기</button></Link>
          </GridToolbar>
          <Column field="ugenProjectNumber" title="프로젝트 코드" columnMenu={ColumnMenu} />
          <Column field="ugenProjectName" title="프로젝트 명" columnMenu={ColumnMenu} />
          <Column title="요구 수" cell={count1} columnMenu={ColumnMenu} />
          <Column title="미할당 수" cell={count2} columnMenu={ColumnMenu} />

        </Grid>
      </IntlProvider>
    </LocalizationProvider>
  </>)

}

export default ResourceAssign

