import React, { useState, useEffect , useMemo} from "react";
import {
  GridLayout,
  GridLayoutItem,
  Card,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  Chart,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartLegend,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
} from "@progress/kendo-react-charts";
import "hammerjs";
 import axios from "axios";import Cookies from 'js-cookie';
import _ from "lodash";
import moment from "moment";
import { useMediaQuery } from 'react-responsive';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const thisYear = moment(new Date()).format("YYYY")

const KPITarget = ({ positiondata, order, blockH }) => {

  const [BoxHeight, setBoxHeight] = useState(250);
  const isDPI = useMediaQuery({ query: '(min-resolution: 120dpi)' })

  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

  const [cardnTableData, setCardnTableData] = useState([]);
  const [yearmonthsales, setyearmonthsales] = useState([]);

  
  const [wfolderlist, setwfolderlist] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/api/getFileFolderList?path=/${REACT_APP_PATH2}/file/allbaro` , 
      )
      const folders = res.data.folderList.map(v => v.name)

    setwfolderlist(folders)

    }
    fetchData();
  }, []);


  const wfolders = useMemo(() => wfolderlist, [wfolderlist]);

  useEffect(() => {
    const fetchData = async () => {

      const fetchData = await axios.get(`/api/getproject` , 
      );

      /**
       * 프로젝트 별 부문 정리
       */
      const projectsShell = fetchData.data.data.data
        .filter((com) => com.shell_location !== null)
        .map((com) => {
          return { ...com, bySector: com.shell_location.split("/")[2] };
        })
        .filter((com) => com.bySector !== undefined)
        .filter((com) => com.bySector !== "반도체");
      
        let pbody = {
          "bpname": "환경 배출물 연간 계획",
          "lineitem": "yes",
          "filter_criteria": {
            "join": "AND",
            "filter": [
              {
      
                "field": "ueffectyearPD",
                "value": thisYear,
                "condition_type": "eq"
              },
      
              {
                "field": "status",
                "value": "Active",
                "condition_type": "eq"
              }
            ]
          }
        };

        const fetchTargetData = await axios.post(
          `/api/getbprecord?path=SKEC0001`,
          pbody , 

        );

        const TargetPlan = fetchTargetData.data.data.data[0]

        const SalsePlan = fetchTargetData.data.data.data[0].EnvPSales

        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      let body = {
        "bpname": "환경배출물 관리",
        "lineitem": "no",
        "filter_criteria": {
          "join": "AND",
          "filter": [
            {
    
              "field": "ugenProjectName",
              "value": 'TEST',
              "condition_type": "neq"
            },
    
            {
              "field": "status",
              "value": "Active",
              "condition_type": "eq"
            }
          ]
        }
      };

      /**
       * 프로젝트 별 에너지, 온실가스, 용수 호출
       */
      const fetchOthersData = await axios.post(
        `/api/getbprecord?path=SKEC0001`,
        body , 

      );

      const SalesActual = _.sortBy(fetchOthersData.data.data.data, 'record_no').reverse()[0]['EnvASales']


      const othersDataYearFilter =fetchOthersData.data.data.data.filter((com) => com.status !== "Inactive")
      .filter(
        (com) => com.ueffectyearPD === String(moment(new Date()).format("YYYY"))
      )

      setyearmonthsales(
        _.sortBy(fetchOthersData.data.data.data
          .map((v)=>({ueffectyearPD:v.ueffectyearPD, umuMonthPD:months.indexOf(v.umuMonthPD)+1, yearmonth:v.ueffectyearPD.slice(2,4)+'년 '+(Number(months.indexOf(v.umuMonthPD))+1)+'월', order:v.ueffectyearPD+ months.indexOf(v.umuMonthPD)+1 === 1 ? 0+months.indexOf(v.umuMonthPD)+1:months.indexOf(v.umuMonthPD)+1})),'order' ).reverse()[0]['yearmonth']
      )


      // const othersDataSum = othersDataYearFilter.map((com) => {
      //   return {
         
      //     Env_GHGsum: _.sumBy(
      //       othersDataYearFilter,
      //       "Env_GHGsum"
      //     ),
      //     Env_energysum: _.sumBy(
      //       othersDataYearFilter,
      //       "Env_energysum"
      //     ),
      //     Env_watersum: _.sumBy(
      //       othersDataYearFilter,
      //       "Env_watersum"
      //     ),
      //   };
      // });

      // const findEnergyIndex = cardDataFormat.findIndex(
      //   (com) => com.tableTitle === "에너지"
      // );

      // const findGHGIndex = cardDataFormat.findIndex(
      //   (com) => com.tableTitle === "온실가스"
      // );

      // const findWaterIndex = cardDataFormat.findIndex(
      //   (com) => com.tableTitle === "용수"
      // );

      // if(findEnergyIndex >=0){
      // cardDataFormat[findEnergyIndex]["actual"] = Math.round(
      //   _.sumBy(othersDataSum, "Env_energysum")
      // );}

      // if(findGHGIndex >=0){
      // cardDataFormat[findGHGIndex]["actual"] = Math.round(
      //   _.sumBy(othersDataSum, "Env_GHGsum")
      // );}

      // if(findWaterIndex >=0){
      // cardDataFormat[findWaterIndex]["actual"] = Math.round(
      //   _.sumBy(othersDataSum, "Env_watersum")
      // );}


              /**
       * Card Data Format
       */
               const cardDataFormat = [
                {
                  title: "에너지 소비량",
                  tableTitle: "에너지",
                  actual: _.sumBy(othersDataYearFilter, 'Env_energysum'),
                  actual_won: (_.sumBy(othersDataYearFilter, 'Env_energysum') /SalesActual)*10 ,
                  actual_take: SalesActual,
                  target: TargetPlan['envpEnergy'],
                  target_won: TargetPlan['envpEnergywon'],
                  target_take: SalsePlan,
                  percent: 0,
                  unit:'(GJ)'
                },
                {
                  title: "온실가스 배출량",
                  tableTitle: "온실가스",
                  actual: _.sumBy(othersDataYearFilter, 'Env_GHGsum'),
                  actual_won: (_.sumBy(othersDataYearFilter, 'Env_GHGsum') / SalesActual)*10,
                  actual_take: SalesActual,
                  target: TargetPlan['envpCO2'],
                  target_won: TargetPlan['envpCO2won'],
                  target_take: SalsePlan,
                  percent: 0,
                  unit:'(tCO2e)'
                },
                {
                  title: "용수 취수량",
                  tableTitle: "용수",
                  actual: _.sumBy(othersDataYearFilter, 'Env_watersum'),
                  actual_won: (_.sumBy(othersDataYearFilter, 'Env_watersum')  / SalesActual)*10,
                  actual_take: SalesActual,
                  target: TargetPlan['envpWater'],
                  target_won: TargetPlan['envpWaterwon'],
                  target_take: SalsePlan,
                  percent: 0,
                  unit:'(m3)'

                },
                {
                  title: "폐기물 배출량",
                  tableTitle: "폐기물",
                  actual: 0,
                  actual_won: 0,
                  actual_take: SalesActual,
                  target: TargetPlan['envpWaste'],
                  target_won: TargetPlan['envpWastewon'],
                  target_take: SalsePlan,
                  percent: 0,
                  unit:'(톤)'
                },
              ];

     const site_development_end_date_body = {
      bpname: "ESG 대시보드 프로젝트 관리",
      lineitem: "no",
      filter_criteria: {
        filter: [
          {
            status: "Active",
          },
        ],
      },
    };

    const site_development_end_date_res = await axios.post(
      `/api/getbprecord?path=SKEC0001`,
      site_development_end_date_body , 

    );


    const requestArr = wfolders.filter(v=>v !== 'AWPTEST').map(async data => {
      return await axios.get(`/api/getJSON?path=/${REACT_APP_PATH2}/file/allbaro/${data}/allbaro.json` , 
      )
  });

  const res = await Promise.all(requestArr)
  const reslist = res.map(v => v.data).flat(1)



  const wastedata_1 =  reslist.map(l => ({ ...l, ...site_development_end_date_res.data.data.data.find(sp => sp.ugenProjectNumber === l.project_projectnumber) }))
  .filter((com) => com.ESG_TRTM_WAYS !== null && Number(com.ESG_GIVE_QUNT) !== 0 && com.ESG_WSTE_CATE !== null)
  .filter(com =>
    com.SiteDevelopmentEndDate
    ? Number(com.ESG_GIVE_DATE.slice(0, 8))> Number(com.SiteDevelopmentEndDate.slice(6,10)+com.SiteDevelopmentEndDate.slice(0,2)+com.SiteDevelopmentEndDate.slice(3,5))
    : com
    )
    .filter(
      (com) =>
        com.ESG_GIVE_DATE.slice(0, 4) ===
        String(moment(new Date()).format("YYYY"))
    )
    .map(com => changeCount(com) );


     const findCardIndex = cardDataFormat.findIndex(
       (com) => com.tableTitle === "폐기물"
     );
     cardDataFormat[findCardIndex]["actual"] = Math.round(_.sum(wastedata_1));
     cardDataFormat[findCardIndex]["actual_won"] = Math.round((_.sum(wastedata_1)/SalesActual)*10);
 
          
      const finalData = cardDataFormat
      .map((com) => {
        return {
          ...com,
          percent: Math.round((com.actual / com.target) * 100),
          actual_e: com.actual > com.target ? com.target : com.actual,
          excess: com.actual > com.target ? com.actual - com.target : 0,
        };
      });

      setCardnTableData(finalData);
    };
    fetchData();
  }, [wfolders]);

  const normalCell = (props) => {
    const { field, dataItem } = props;

    return <td className="uniqTableTd">{dataItem[field]}</td>;
  };



  const normalCellRender = (props) => {
    const dataItem = props.dataItem || "";
    const field = props.field || "";
    const value = props.dataItem[field];

    return (
      <td
        style={{
          fontSize: "0.8rem",
          textAlign: "right",
          // borderBottomWidth:field ==='actual_take'? 0:1,
          borderRightWidth:field ==='target_won'?0:1
        }}
      >
        {Number(value.toFixed(0)).toLocaleString()}{" "}
        {(field === "actual" || field === "target") &&
          (dataItem.tableTitle === "용수"
            ? "m3"
            : dataItem.tableTitle === "온실가스"
            ? "tCO2e"
            : dataItem.tableTitle === "폐기물"
            ? "톤"
            : dataItem.tableTitle === "에너지" && "GJ")}
      </td>
    );
  };

  const cellRender = (cell, props) => {
    const { field, dataItem } = props;

    if (field === "actual_take") {
      const filterSectorRow = cardnTableData.filter(
        (com) => com.actual_take === dataItem[props.field]
      );
      if (dataItem["tableTitle"] === "에너지")
        return (
          <td
            rowSpan={filterSectorRow.length}
            style={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              wordBreak: "break-word",
              borderBottomWidth:0
            }}
          >
            {props.dataItem[props.field].toLocaleString()}억원
          </td>
        );
    } else if (field === "target_take") {
      const filterSectorRow = cardnTableData.filter(
        (com) => com.target_take === dataItem[props.field]
      );
      if (dataItem["tableTitle"] === "에너지")
        return (
          <td
            rowSpan={filterSectorRow.length}
            style={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              wordBreak: "break-word",
              borderBottomWidth:0

            }}
          >
            {props.dataItem[props.field].toLocaleString()}억원
          </td>
        );
    } else {
      return cell;
    }
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const SharedTooltip = (props) => {
    const { points, categoryText } = props;

    const findItem = cardnTableData.find(
      (com) => com.tableTitle === categoryText
    );

    const year = moment(new Date()).format("YY");

    return (
      <div>
        <div>{categoryText}</div>
        {points.map((point, idx) => {
          return (
            <div key={idx}>
              `{year}년{" "}
              {point.series.field === "target"
                ? "KPI Target"
                : point.series.field === "actual_e"
                ? "실적"
                : "실적 초과분"}
              :{" "}
              {point.series.field === "actual_e"
                ? findItem.actual.toLocaleString()
                : point.value.toLocaleString()}
              {categoryText === "용수"
                ? "m3"
                : categoryText === "온실가스"
                ? "tCO2e"
                : categoryText === "폐기물"
                ? "톤"
                : categoryText === "에너지"
                ? "GJ"
                : ""}
            </div>
          );
        })}
      </div>
    );
  };

  const customChartValueAxisItemContent = (e) => {
    return e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : e.value.toLocaleString();
  };

  const customChartValueAxisItemLabel = {
    visible: true,
    font: "0.8rem pretendard-R",
    color:'#B0B8C2',
    content: customChartValueAxisItemContent,
  };

  const labelContent = (e) => e.value ==='에너지'? '에너지 (GJ)':e.value ==='온실가스'?'온실가스 (tCO2e)':e.value ==='용수'?'용수 (m3)':e.value ==='폐기물'?'폐기물 (톤)':'' ;

  const seriesLabels = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    color:'#F1F2F5',
    font: "0.8rem pretendard-R",
    background: "none",
    rotation: { angle: "auto" },
    content:labelContent 
  };


  const seriesLabels2 = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    color:'#F1F2F5',
    font: "0.7rem pretendard-R",
    background: "none",
  };
  return (
      <GridLayout
        style={{
          height: BoxHeight,
          // alignItems: "center",
          // justifyContent: "center",
        }}
        rows={[
          {
            height: "20%",
          },
          {
            height: "45%",
          },
          {
            height: "35%",
          },
        ]}
        cols={[
          {
            width: "25%",
          },
          {
            width: "25%",
          },
          {
            width: "25%",
          },
          {
            width: "25%",
          },
        ]}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >
        {cardnTableData.length > 0 &&
          cardnTableData.map((com, idx) => {
            return (
              <GridLayoutItem row={1} col={idx + 1} key={idx}>
                <div style={{ padding: "10px", height: "100%" }}>
                  <Card className='esg-card'
                    style={{
                      width: "100%",
                      height: "100%",
                      borderColor: "#939393",
                      borderRadius: "10px",
                      boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                    }}
                  >
                    <CardBody
                      style={{
                        height: "100%",
                        padding: 0,
                      }}
                    >
                      <CardTitle
                        style={{
                          height: "100%",
                          fontFamily: "pretendard-B",
                          color: "#707070",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          lineHeight: "1.5",
                        }}
                      >
                        <div style={{ fontSize: isDPI? '0.8rem':"1rem", fontWeight: "600" , color:'#F1F2F5' }}>
                          {com.title} {com.unit}
                        </div>
                        <div style={{ fontSize: isDPI ? '0.6rem': "0.8rem" , color:'#F1F2F5' , fontWeight:'500'}}>
                          (누적 배출량 / Target)
                        </div>
                        <div
                          style={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                            display: "flex",
                            color:'#8E959F'
                          }}
                        >
                          <div
                            style={{
                              color: com.actual-com.target <0 ? "#00B050":'rgb(244, 155, 66)',
                            }}
                          >
                            {Number(com.actual.toFixed(0)).toLocaleString()}/
                          </div>
                          {Number(com.target.toFixed(0)).toLocaleString()}
                        </div>
                      </CardTitle>
                    </CardBody>
                  </Card>
                </div>
              </GridLayoutItem>
            );
          })}

        {/* Chart Area */}
        <GridLayoutItem
          row={2}
          col={1}
          colSpan={4}
          style={{
            position: "relative",
            alignSelf: "center",
            // justifySelf: "stretch",
          }}
        >
          <Chart className="kpichart"  transitions={false}>
            <ChartTooltip shared={true} render={sharedTooltipRender} />
            <ChartArea height={BoxHeight* 0.45} margin={10} background='#464B52'/>
            <ChartLegend position="bottom" orientation="horizontal" labels={seriesLabels2} />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                startAngle={45}
                labels={seriesLabels}
                name="categoryAxis"
                majorGridLines={{color:'#464B52'}}
                color='#8E959F'

              />
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem
                labels={customChartValueAxisItemLabel}
                visible={true}
                majorGridLines={{color:'#8E959F'}}
                color='#8E959F'

              />
            </ChartValueAxis>
            <ChartSeries>
              <ChartSeriesItem
                type="bar"
                field="target"
                data={cardnTableData}
                stack={{ group: "a" }}
                categoryField="tableTitle"
                color="#9BBB59"
                name="Target"
                border={{ width: 0 }}
                spacing={0}
              />
              <ChartSeriesItem
                type="bar"
                field="actual_e"
                data={cardnTableData}
                stack={{ group: "b" }}
                categoryField="tableTitle"
                color="#4F81BD"
                name="누적 배출량"
                border={{ width: 0 }}
                spacing={0}
              />
              <ChartSeriesItem
                type="bar"
                field="excess"
                data={cardnTableData}
                stack={{ group: "b" }}
                categoryField="tableTitle"
                color="#C0504D"
                name="초과 배출량"
                border={{ width: 0 }}
                spacing={0}
              />
            </ChartSeries>
          </Chart>

          {cardnTableData.map((com, idx) => {
            return (
              <React.Fragment key={idx}>
                <div
                  style={{
                    position: "absolute",
                    top:
                      (Math.floor(BoxHeight * 0.45 - 71) /
                        cardnTableData.length) *
                        idx +5,
                    right: "10px",
                    height:isDPI? 
                    (Math.floor(BoxHeight  * 0.45 - 71) /
                      cardnTableData.length)*0.6
                    :
                      Math.floor(BoxHeight  * 0.45 - 71) /
                      cardnTableData.length,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.7rem",
                    fontWeight: "bold",
                    zIndex: "10000",
                    color:'#F1F2F5'
                  }}
                >
                  {com.percent}%
                </div>
              </React.Fragment>
            );
          })}
        </GridLayoutItem>
        {/* Table Area */}
        <GridLayoutItem row={3} col={1} colSpan={4}>

            <Grid
              data={cardnTableData}
              className="esgKPITable"
              cellRender={cellRender}
            >
              <GridColumn field="tableTitle" title="구분" cell={normalCell} />
              <GridColumn
                title={` ${yearmonthsales} 실적`}
              >
                <GridColumn
                  field="actual"
                  title="배출량"
                  cell={normalCellRender}
                />
                <GridColumn field="actual_take" title={`매출액`} />
                <GridColumn
                  field="actual_won"
                  title="원단위"
                  cell={normalCellRender}
                />
              </GridColumn>
              <GridColumn
                title={`${moment(new Date()).format("YY")}년 KPI Target`}
              >
                <GridColumn
                  field="target"
                  title="배출량"
                  cell={normalCellRender}
                />
                <GridColumn field="target_take" title="매출액" />
                <GridColumn
                  field="target_won"
                  title="원단위"
                  cell={normalCellRender}
                />
              </GridColumn>
            </Grid>
        </GridLayoutItem>
      </GridLayout>
  );
};

export default KPITarget;

const changeCount = (baseArr) => {
  if (baseArr.ESG_GIVE_QUNT_UNIT === "Ton") {
    return Number(baseArr.ESG_GIVE_QUNT);
  } else if (baseArr.ESG_GIVE_QUNT_UNIT === "kg") {
    return Number(baseArr.ESG_GIVE_QUNT) / 1000;
  } else if (baseArr.ESG_GIVE_QUNT_UNIT === "g") {
    return Number(baseArr.ESG_GIVE_QUNT) / 1000000;
  }
};
