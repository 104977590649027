import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import _ from "lodash";
import {
    GridLayout,
    GridLayoutItem, 
} from "@progress/kendo-react-layout";
const EngIssue = () => {
    const { project_code } = useParams();
    const [data, setdata] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
            let body =
            {
                "bpname": "Engineering Issue",
                "lineitem": "no",
                "filter_condition": "status=Active",
            }
            const res = await axios.post(`/api/getbprecord?path=${project_code}`, body)
            const sourcedata = res.data.data.data.map((v)=>({...v, genSubmitDO:moment(v.genSubmitDO).format("YYYYMMDD")}));
            setdata( _.sortBy(sourcedata, 'genSubmitDO').reverse()[0]  )
        };

        fetchData();
    }, [project_code]);


return(
    <>
        <GridLayout 
                rows={[
                    {height: '100%',},
                ]}

                cols={[   
                    {width: '100%',},]}

                gap={{
                    rows: 10,
                    cols: 10,
                }}>
                <GridLayoutItem  col={1} row={1}rowSpan={1} style={{backgroundColor:'#fafafa',padding:'16px', overflowY:"auto"}} >
                <div>{data && data.title}</div><br/>
                <div style={{textAlign:'left', fontSize:'1rem'}} dangerouslySetInnerHTML={{ __html:data && data.genIssueDescRT}} className='ment'></div>
                </GridLayoutItem>
        </GridLayout>
    </>
);

}

export default EngIssue
