import React, { useEffect, useState } from "react";
 import axios from "axios";import Cookies from 'js-cookie';
import _ from "lodash";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from '@progress/kendo-data-query';
import moment from 'moment';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const WeldingAll = ({ positiondata, weldingdata,projectlist ,height }) => {
    const [BoxHeight, setBoxHeight] = useState(250);

    useEffect(() => {
        const boxHeight = positiondata[2];
        setBoxHeight(height * (boxHeight && boxHeight.rowSpan) - 80);
    }, [positiondata,height]);


    const [standarddata, setstandarddata] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
        const projectcodes =  projectlist.map(v=>v.projectcode)

            let body=  
            {
              "bpname": "Project Information",
              "lineitem": "no",
              "record_fields": "ProjectTargetWDRate;",
            }
            
            let standardarray = [];
            for (const i of projectcodes ){
                const res = await axios.post(`/api/getbprecord?path=${i}`, body, )
                const info = res.data.data.data.map((v) => ({ target:v.ProjectTargetWDRate, projectcode:i.project}))
                standardarray.push(...info)
            }
            setstandarddata(standardarray)
      
          };
      
          fetchData();
      }, [projectlist]);

    const thismonth = moment(new Date()).format('YY년 MM월')
    const thismonth1 = moment().subtract(1, 'month').startOf('month').format('YY년 MM월')
    const thismonth2 = moment().subtract(2, 'month').startOf('month').format('YY년 MM월')

    const data = _.uniqBy(weldingdata.map((v)=>({...v, uniq:v.projectcode+v.record_no, year:moment(v.DateDO).format('YYYY'), month:moment(v.DateDO).format('YY년 MM월')})), 'uniq')
    .map(v => ({ ...v, ...projectlist.find(sp => sp.projectcode === v.projectcode) }))
    .filter(v=>v.projectcode !== 'AWPTEST' )
    .map(v => ({ ...v, ...standarddata.find(sp => sp.projectcode === v.projectcode) }))
    .map((v)=>({...v, group:v.projectname+'//'+v.target}))
    


    var groupdata =
    _(data)
      .groupBy('group')
      .map((objs, key) => ({
        'projectname': key.split('//')[0],
        '1month': 
        (_.sumBy(objs.filter(v=>v.month=== thismonth2),'FilmReject')+_.sumBy(objs.filter(v=>v.month=== thismonth2),'JointReject'))  
        /(_.sumBy(objs.filter(v=>v.month=== thismonth2),'FilmSum')+_.sumBy(objs.filter(v=>v.month=== thismonth2),'JointSUM'))  ,
        '2month': 
        (_.sumBy(objs.filter(v=>v.month=== thismonth1),'FilmReject')+_.sumBy(objs.filter(v=>v.month=== thismonth1),'JointReject'))  
        /(_.sumBy(objs.filter(v=>v.month=== thismonth1),'FilmSum')+_.sumBy(objs.filter(v=>v.month=== thismonth1),'JointSUM'))  ,
        '3month': 
        (_.sumBy(objs.filter(v=>v.month=== thismonth),'FilmReject')+_.sumBy(objs.filter(v=>v.month=== thismonth),'JointReject'))  
        /(_.sumBy(objs.filter(v=>v.month=== thismonth),'FilmSum')+_.sumBy(objs.filter(v=>v.month=== thismonth),'JointSUM'))  ,
        'target':key.split('//')[1],
      }))
      .value();



    const [sort, setSort] = useState([{}]);

    return (<>
        <Grid
            data={orderBy(groupdata, sort)}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
                setSort(e.sort);
            }}
            style={{maxHeight:BoxHeight}}
            className='esgtable'

        >

            <GridColumn field="projectname" title="프로젝트" />
            <GridColumn field="1month" title={thismonth2.slice(4,7)} />
            <GridColumn field="2month" title={thismonth1.slice(4,7)}/>
            <GridColumn field="3month" title={thismonth.slice(4,7)} />
            <GridColumn field="target" title="용접불량률 기준" />

       

        </Grid>

    </>);

};

export default WeldingAll;
