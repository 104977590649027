import React, { useEffect } from 'react';
import axios from 'axios';
import krMessages from "./kr.json";
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
import { Gantt, GanttWeekView, GanttMonthView,  GanttYearView, filterBy, orderBy,  GanttTextFilter, GanttDateFilter } from '@progress/kendo-react-gantt';
load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);
loadMessages(krMessages, "ko-KR");

const {REACT_APP_URI2} = process.env;

const ganttStyle = {
    height: 800,
    width: '100%'
  };

  const taskModelFields = {
    id: 'id',
    start: 'start',
    end: 'end',
    title: 'title',
    user:'user',
    job:'job',
    rate:'rate',
    isExpanded: 'isExpanded',
  };

  const columns = [{
    field: taskModelFields.id,
    title: 'ID',
    width: 50
  }, {
    field: taskModelFields.title,
    title: 'Project',
    width: 150,
    expandable: true,
    filter: GanttTextFilter
  }, {
    field: taskModelFields.user,
    title: 'Name',
    width: 150,
    expandable: false,
    filter: GanttTextFilter
  }, {
    field: taskModelFields.job,
    title: '직무',
    width: 150,
    expandable: false,
    filter: GanttTextFilter
  }, {
    field: taskModelFields.rate,
    title: '할당률',
    width: 100,
    expandable: false,
    filter: GanttTextFilter
  },{
    field: taskModelFields.start,
    title: 'Start',
    width: 120,
    format: '{0:MM/dd/yyyy}',
    filter: GanttDateFilter
  }, {
    field: taskModelFields.end,
    title: 'End',
    width: 120,
    format: '{0:MM/dd/yyyy}',
    filter: GanttDateFilter
  }];
  

const ResourceGantt = () => {


    const [taskData, settaskData] = React.useState([]);


    useEffect(() => {
        const fetchData = async () => {

            let body3 =
            {
              "bpname": "Resource Requests (NWF)",
              "lineitem": "yes",
            }
            const res3 = await axios.post(`/api/getbprecord?path=SKEC0001`, body3,   )


            const data = res3.data.data.data.filter(v => v.status !== 'Terminated' && typeof v._bp_lineitems !=='undefined')
            .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { title: v.ugenProjectName, start: new Date(o.emsPPStart1.slice(0,10)), end:new Date(o.emsPPFinish1.slice(0,10)), rate:o.emsRRPlanDA1, 'isExpanded':false, user:o.emsNameEmploID, job:o.SKRoleClass4
              }))), []).filter(v=>v.user !== null).map((v, index)=>({...v, id:index+1}))
              

            settaskData(data)

        };

        fetchData();
      }, []);
    
  const [columnsState, setColumnsState] = React.useState(columns);
  const onColumnResize = React.useCallback(event => event.end && setColumnsState(event.columns), [setColumnsState]);
  const onColumnReorder = React.useCallback(event => setColumnsState(event.columns), [setColumnsState]);
  const [dataState, setDataState] = React.useState({
    sort: [{
      field: 'orderId',
      dir: 'asc'
    }],
    filter: []
  });
  const onDataStateChange = React.useCallback(event => setDataState({
    sort: event.dataState.sort,
    filter: event.dataState.filter
  }), [setDataState]);

  const processedData = React.useMemo(() => {
    const filteredData = filterBy(taskData, dataState.filter);
    const sortedData = orderBy(filteredData, dataState.sort);
    return sortedData
  }, [taskData, dataState]);


    return(<>
     <div>
     <LocalizationProvider language="ko-KR">
                    <IntlProvider locale="ko-KR">
        <Gantt style={ganttStyle} taskData={processedData} taskModelFields={taskModelFields} columns={columnsState} resizable={true} reorderable={true} sortable={true} sort={dataState.sort} filter={dataState.filter} onColumnResize={onColumnResize} onColumnReorder={onColumnReorder} onDataStateChange={onDataStateChange}>
          <GanttWeekView />
          <GanttMonthView />
          <GanttYearView slotWidth={50} />
        </Gantt>
        </IntlProvider>
      </LocalizationProvider>
      </div>
    
    </>);
}

export default ResourceGantt
