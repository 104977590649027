import React, { useEffect, useState } from "react";
import _ from "lodash";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { ResponsivePie } from "@nivo/pie";
import { useParams } from "react-router-dom";
import axios from "axios";

import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { generateUpdatedDate } from "../common/datadate";

const { REACT_APP_URI2 } = process.env;

const EAudit = ({ positiondata, order, blockH }) => {
  const { project_code } = useParams();
  const [dataA, setdataA] = useState([]);
  const [dataB, setdataB] = useState([]);
  const [BoxHeight, setBoxHeight] = useState(250);
  const { t, i18n } = useTranslation();

  const ToggleButton = styled(MuiToggleButton)(() => ({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "#4B5335",
    },
    "&.MuiToggleButton-root": {
      fontSize: "0.7rem",
      fontFamily: "Pretendard-R",
      padding: 0,
      width: i18n.language === "ko" ? 50 : 90,
      borderRadius: "5px 5px 5px 5px!important",
      border: "1px solid rgba(0, 0, 0, 0.12)!important",
    },
  }));

  const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    "&.MuiToggleButtonGroup-root": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },
  }));

  const theme = createTheme({
    palette: {
      text: {
        primary: "#00ff00",
      },
    },
  });

  useEffect(() => {
    const boxHeight = positiondata[order];

    // setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 35);
  }, [positiondata, order, blockH]);

  const flat = (obj, out) => {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] == "object") {
        out = flat(obj[key], out); //recursively call for nesteds
      } else {
        out[key] = obj[key]; //direct assign for values
      }
    });
    return out;
  };

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        bpname: "환경심사",
        lineitem: "yes",
        record_fields:
          "EnvAuditClass_spd;AuditStartDateDO;AuditFinishDateDO;QAProgressDA;EvaluationNoSPD",
        filter_criteria: {
          join: "AND",
          filter: [
            {
              field: "status",
              value: "Terminated",
              condition_type: "neq",
            },
            {
              field: "ugenProjectNumber",
              value: project_code,
              condition_type: "eq",
            },
          ],
        },
      };

      const res = await axios.post(`/api/getbprecord?path=SKEC0001`, body);

      const fdata_a = _.sortBy(
        res.data.data.data.filter((v) => v.EnvAuditClass_spd === "정기"),
        "EvaluationNoSPD"
      ).reverse()[0];
      const fdata_b = _.sortBy(
        res.data.data.data.filter((v) => v.EnvAuditClass_spd === "특별"),
        "EvaluationNoSPD"
      ).reverse()[0];

      const line_a =
        fdata_a &&
        Object.assign(fdata_a._bp_lineitems, {
          EnvAuditClass_spd: fdata_a.EnvAuditClass_spd,
          AuditStartDateDO:
            fdata_a.AuditStartDateDO.slice(6, 10) +
            "년 " +
            fdata_a.AuditStartDateDO.slice(0, 2) +
            "월 " +
            fdata_a.AuditStartDateDO.slice(3, 5) +
            "일",
          AuditFinishDateDO:
            fdata_a.AuditFinishDateDO.slice(6, 10) +
            "년 " +
            fdata_a.AuditFinishDateDO.slice(0, 2) +
            "월 " +
            fdata_a.AuditFinishDateDO.slice(3, 5) +
            "일",
          QAProgressDA: fdata_a.QAProgressDA,
          EvaluationNoSPD: fdata_a.EvaluationNoSPD,
        });
      setdataA(line_a);

      const line_b =
        fdata_b &&
        Object.assign(fdata_b._bp_lineitems, {
          EnvAuditClass_spd: fdata_b.EnvAuditClass_spd,
          AuditStartDateDO:
            fdata_b.AuditStartDateDO.slice(6, 10) +
            "년 " +
            fdata_b.AuditStartDateDO.slice(0, 2) +
            "월 " +
            fdata_b.AuditStartDateDO.slice(3, 5) +
            "일",
          AuditFinishDateDO:
            fdata_b.AuditFinishDateDO.slice(6, 10) +
            "년 " +
            fdata_b.AuditFinishDateDO.slice(0, 2) +
            "월 " +
            fdata_b.AuditFinishDateDO.slice(3, 5) +
            "일",
          QAProgressDA: fdata_b.QAProgressDA,
          EvaluationNoSPD: fdata_b.EvaluationNoSPD,
        });
      setdataB(line_b);
    };

    fetchData();
  }, [project_code]);

  const [selectedValue, setSelectedValue] = React.useState("정기");

  // const handleChange = React.useCallback(
  //     (e) => {
  //         setSelectedValue(e.value);
  //     },
  //     [setSelectedValue]
  // );
  const handleChange = (event, selectedValue) => {
    if (selectedValue !== null) {
      setSelectedValue(selectedValue);
    }
  };

  const chartdata = _(selectedValue === "정기" ? dataA : dataB)
    .groupBy("EnvAuditFindCat_spd")
    .map((objs, key) => ({
      id: key,
      value: objs.filter((v) => v.EnvAuditFindCat_spd === key).length,
    }))
    .value()
    .filter((v) => v.value !== 0)
    .map((v) => ({
      ...v,
      color:
        v.id === "시정조치(Corrective Action)"
          ? "#A2242F"
          : v.id === "시정(Correction)"
          ? "#D19C97"
          : v.id === "관찰(Observation)"
          ? "#C48A69"
          : "#DC793E",
    }));

  const lastdate =
    selectedValue === "정기"
      ? dataA &&
        dataA["AuditStartDateDO"] &&
        dataA["AuditStartDateDO"] === dataA &&
        dataA["AuditFinishDateDO"]
        ? dataA && dataA["AuditFinishDateDO"]
        : dataA &&
          `${dataA["AuditStartDateDO"]} ~ ${dataA["AuditFinishDateDO"]}`
      : dataB &&
        dataB["AuditStartDateDO"] &&
        dataB["AuditStartDateDO"] === dataB &&
        dataB["AuditFinishDateDO"]
      ? dataB && dataB["AuditFinishDateDO"]
      : dataB && `${dataB["AuditStartDateDO"]} ~ ${dataB["AuditFinishDateDO"]}`;

  const rate =
    selectedValue === "정기"
      ? dataA && dataA["QAProgressDA"]
      : dataB && dataB["QAProgressDA"];

  return (
    <>
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          // {  height: '10%',},
          {
            height: "100%",
          },
        ]}
        cols={[
          {
            width: "100%",
          },
        ]}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >
        <GridLayoutItem row={1} col={1} style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: "22px",
              right: "15px",
              zIndex: "100",
              color: "snow",
              fontSize: "0.7rem",
            }}
          >
            {lastdate && generateUpdatedDate(lastdate.slice(0, 10))}
          </div>
          <div
            style={{
              position: "absolute",
              right: "5px",
              top: "0px",
              zIndex: 999999,
              width: i18n.language === "ko" ? 100 : 180,
            }}
          >
            <ThemeProvider theme={theme}>
              <ToggleButtonGroup
                size="small"
                onChange={handleChange}
                value={selectedValue}
                exclusive
                aria-label="baseunit"
              >
                <ToggleButton value="정기" aria-label="정기">
                  {t("정기")}
                </ToggleButton>
                <ToggleButton value="특별" aria-label="특별">
                  {t("특별")}
                </ToggleButton>
              </ToggleButtonGroup>
            </ThemeProvider>
          </div>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "999",
              textAlign: "center",
              lineHeight: "100%",
            }}
          >
            <span style={{ fontSize: "2rem" }} className="hovertext">
              {rate}
              <span style={{ fontSize: "1rem" }}>%</span>
              <br />
              <span style={{ fontSize: "0.5rem" }}>{t("조치완료율")}</span>
            </span>
          </div>

          <ResponsivePie
            // colors={['#616CFF', '#429BF4', '#42B7F4', '#9E67FF', '#FF6E6E', '#BC99F2', '#2075A1', '#33B8B4', 'DBF3FF', '#42EEF4']}
            // colors={{ scheme: 'nivo' }}

            colors={{ datum: "data.color" }}
            className="pie"
            data={chartdata}
            margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
            valueFormat=" >-"
            cornerRadius={2}
            innerRadius={0.7}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabel={(d) => (
              <React.Fragment>
                <tspan
                  style={{
                    fontSize: 10,
                    fontWeight:
                      d.id === "시정조치(Corrective Action)" ? 600 : 400,
                    color:
                      d.id === "시정조치(Corrective Action)"
                        ? "#CD212A"
                        : "black",
                  }}
                  x="0"
                  dy="-0.6em"
                >
                  {d.id}
                </tspan>
                <tspan
                  style={{
                    fontSize: 10,
                    fontWeight:
                      d.id === "시정조치(Corrective Action)" ? 600 : 400,
                    color:
                      d.id === "시정조치(Corrective Action)"
                        ? "#CD212A"
                        : "black",
                  }}
                  x="0"
                  dy="1.2em"
                >
                  {"(" +
                    ((d.value / _.sumBy(chartdata, "value")) * 100).toFixed(0) +
                    "%)"}
                </tspan>
              </React.Fragment>
            )}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextOffset={5}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsDiagonalLength={15}
            arcLinkLabelsStraightLength={15}
            arcLinkLabelsColor={{ from: "color" }}
            enableArcLabels={true}
            arcLabel={function (e) {
              return e.value;
            }}
            arcLabelsRadiusOffset={0.5}
            arcLabelsSkipAngle={0}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: {
                  id: "시정조치(Corrective Action)",
                },
                id: "dots",
              },
            ]}
            legends={[]}
          />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default EAudit;
