import React, { useState, useEffect } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
import '@progress/kendo-theme-default/dist/all.css';
import useBreakpoint from "use-breakpoint";
import _ from 'lodash';
import "../kendocustom.css";
import axios from 'axios';
import PJTinfo from './pjtinfo';
import Contructioninfo from './construction';
import Safetyinfo from './safety';
import Qinfo from './quality';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useDocumentTitle from '../useDocumentTitle';
import { useTranslation } from 'react-i18next'
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { logout } from '../common/logout';
import { Button } from "@progress/kendo-react-buttons";
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Circles } from 'react-loader-spinner';

const ToggleButton = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
      color: 'black!important',
      backgroundColor: 'snow',
  },
  '&.MuiToggleButton-root': {
    color:"snow",
      fontSize: '0.7rem',
      fontFamily: 'Pretendard-R',
      padding: 0,
      width: 50,
      borderRadius: '0px 0px 0px 0px!important',
      border: '1px solid #fff!important'

  }
}));


const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > *:not(:last-child)": {
          marginRight: theme.spacing(0)
      }
  }
}));

const theme = createTheme({
  palette: {
      text: {
          primary: '#00ff00',
      },
  },
});

const {REACT_APP_URI2} = process.env;


const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const EntireALL2 =  () => {

  const { t, i18n } = useTranslation();
  const { height, width } = useWindowDimensions();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')
  useDocumentTitle(i18n.language ==="ko"? "S.CON - 통합 Dashboard":"S.CON Company Dashboard")

  const [selectedValue, setSelectedValue] = React.useState("ko");

  const location = useLocation();
  const usernameFromState = location.state?.username;
  

const handleChange = (event, selectedValue) => {
  if (selectedValue !== null) {
      setSelectedValue(selectedValue);
      i18n.changeLanguage(selectedValue);
  }
};

  const GRID_CONFIG = {
    mobile: [
      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },

    ],

    tablet: [

      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 6,
        status: true,
        rowSpan: 4,
      },
      {
        col: 7,
        colSpan: 6,
        status: true,
        rowSpan: 4,
      },
      {
        col: 13,
        colSpan: 6,
        status: true,
        rowSpan: 4,
      },

    ],

    desktop: [

      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 6,
        status: true,
        rowSpan: 4,
      },
      {
        col: 7,
        colSpan: 6,
        status: true,
        rowSpan: 4,
      },
      {
        col: 13,
        colSpan: 6,
        status: true,
        rowSpan: 4,
      },


    ]
  }

  const GRIDCONFIG_filterd = { mobile: _.filter(GRID_CONFIG['mobile'], { 'status': true }), tablet: _.filter(GRID_CONFIG['tablet'], { 'status': true }), desktop: _.filter(GRID_CONFIG['desktop'], { 'status': true }) }
  const [newprojectlist, setnewprojectlist] = useState([]);
  const [envprojectlist, setenvprojectlist] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        bpname: "ESG 대시보드 프로젝트 관리",
        lineitem: "no",
        filter_condition: "status=Active",
      };
      const res = await axios.post(`/api/getbprecordtotal?path=SKEC0001`, body )

      setnewprojectlist(
        _.sortBy(
          _.sortBy(
          res.data.data.data
            .map((v) => ({ ...v, 
              division: v.uuu_shell_location && v.uuu_shell_location.split(v.ugenProjectName)[0].split('/SK 에코엔지니어링/')[1].slice(0, -1), 
              use: v.SKONeUSEPD && v.SKONeUSEPD, 
              projectname: v.ShortPJTNM && v.ugenProjectName&& v.ShortPJTNM !== null? v.ShortPJTNM:v.ugenProjectName, 
              projectcode: v.ugenProjectNumber && v.ugenProjectNumber }))
            ,'projectname').reverse()
            .filter(v => v.division !== null && !v.projectname.includes('TEST') && v.use !== '제외')
            .map((v) => ({ ...v, order: v.division === "Industrial" ? 1 : v.division === 'Gas&Power' ? 2 : v.division === '수소친환경' ? 3 : 4 }))
        
          , 'order')
    
      )


      setenvprojectlist(
        _.sortBy(
          res.data.data.data
            .map((v) => ({ ...v, division: v.uuu_shell_location && v.uuu_shell_location.split(v.ugenProjectName)[0].split('/SK 에코엔지니어링/')[1].slice(0, -1), use: v.SKONeUSEPD && v.SKONeUSEPD, projectname: v.ugenProjectName && v.ugenProjectName, projectcode: v.ugenProjectNumber && v.ugenProjectNumber }))
            .filter(v => v.division !== null && !v.projectname.includes('TEST'))
            .map((v) => ({ ...v, order: v.division === "Industrial" ? 1 : v.division === '배터리' ? 2 : v.division === 'Gas&Power' ? 3 : 4 }))
          , 'order')
      )


    }
    fetchData();
  }, []);


  useEffect(() => {
    setPositionData(GRIDCONFIG_filterd[breakpoint])
  }, [breakpoint]);


  const [positiondata, setPositionData] = useState(GRID_CONFIG['desktop']);
  const [fitlerv, setfiliterv] = useState('');


  const tiles = [{
    header: (<div style={{ position: 'relative' }}>Project Info.</div>),
    body: <PJTinfo positiondata={positiondata} filter={fitlerv} projeclist={newprojectlist} />,
    status: true,
    resizable: false,
    reorderable: false,

  }, {
    header: (<div style={{ position: "relative" }}>Construction</div>),
    body: <Contructioninfo positiondata={positiondata} filter={fitlerv} projeclist={newprojectlist} />,
    status: true,
    resizable: false,
    reorderable: false,
  },
  {
    header: (<div style={{ position: "relative" }}>Safety</div>),
    body: <Safetyinfo positiondata={positiondata} filter={fitlerv} projeclist={newprojectlist} />,
    status: true,
    resizable: false,
    reorderable: false,
  },

  {
    header: (<div style={{ position: 'relative' }}>Quality & Environment</div>),
    body: <Qinfo positiondata={positiondata} filter={fitlerv} projeclist2={_.filter(newprojectlist, { "ESGQualityCB": "Yes" })} projeclist={envprojectlist} />,
    status: true,
    resizable: false,
    reorderable: false,
  },


  ];


  const [tile, settile] = useState(tiles);

  useEffect(() => {
    settile(tiles);
  }, [positiondata, fitlerv, newprojectlist, envprojectlist, i18n.language]);


  const tile_filtered = _.filter(tile, ['status', true]);


  const [open, setOpen] = React.useState([false, false, false, false, false]);

  const handleClick = (index) => {
    setOpen(open.map((v, idx) => (index === idx ? !v : v)));
  };




  const DivisionClick = (e) => {
    setfiliterv(e.target.innerText === "H2/Recycling"? "수소친환경":e.target.innerText)
  }; 

  
  const AllClick = () => {
    setfiliterv('')
    setOpen([false, false, false, false, false])
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleLogoutClick = async () => {
    setIsLoading(true); // 요청이 시작될 때 로딩 상태를 true로 설정
    try {
      const res = await axios.get(`/api/getcheckowner?project=SKEC0001`);
      setIsLoading(false); // 요청이 시작될 때 로딩 상태를 true로 설정
      if (res.data.success) {
        const client = res.data.client; // 예를 들어, API 응답에서 'client' 데이터를 기대한다고 가정
        logout({
          username: usernameFromState,
          project: "total",
          client: client
        });
      } else {
        logout({
          username: usernameFromState,
          project: "total",
          client: false
        });
      }
    } catch (error) {
      setIsLoading(false);
      // 에러가 발생했을 때 SweetAlert를 사용하여 에러 메시지를 표시
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred. Please contact the system administrator.',
        confirmButtonText: 'OK'
      });
    }
  };
  
  if (isLoading) {
    return <div className="loadingContainer">
    <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
  </div>;;
  }

  return (
    <>
<div style={{ position: 'absolute', color: 'snow', top: '5px', right:'12px', zIndex: '999',textAlign: 'center' }}>
<Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
</div>

    <div style={{ position: 'absolute', color: 'snow', top: '35px', zIndex: '999', width: '200px', textAlign: 'center' }}>
      <img src='/image/skonlogo.png' width={100} alt='logo'></img>
    </div>
      
      <div style={{ position: 'absolute', color: 'snow', top: '165px', zIndex: '999', width: '200px', textAlign: 'center' }}>
        <span style={{ fontSize: '1rem', fontWeight: '600' }}>{t("통합 Dashboard")}</span>
      </div>

      <div style={{ position: 'absolute', bottom: '50px',zIndex: '999', width: '200px', textAlign: 'center'}}>
        

        
        <span style={{color:'#9BB7D4', fontSize:'0.8rem', marginBottom:'4px'}}>S.CON {t("운용")}<br/><span style={{color:'#939597'}}>{t("미운용")}</span><br/>
        

        </span>

        <div style={{ position: 'absolute',left:"50%",  transform: "translate(-50%)", zIndex: '999',textAlign: 'center', marginTop:10}}>

<ThemeProvider theme={theme}>
    <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="언어">
        <ToggleButton value="ko" aria-label='한국어' >
            한국어
        </ToggleButton>
        <ToggleButton value="en" aria-label="english">
            ENG
        </ToggleButton>
    </ToggleButtonGroup>
</ThemeProvider>
</div>
     </div>



     
      <List
        sx={{ width: '200px', bgcolor: '#2C2E3D',fontFamily:'Pretendard-R', float: 'left', height:height, overflowY:'auto', color:'#FFFFFF',paddingTop:200/8, marginTop:-20/8}}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader onClick={AllClick} component="div" id="nested-list-subheader" sx={{cursor:'pointer', fontSize:'1rem', fontFamily:'Pretendard-R',bgcolor: '#2C2E3D', color:'#FFFFFF'}}>
            SK ecoengineering
          </ListSubheader>
        }
      >
        {_.uniq(newprojectlist.map(v => v.division)).map((division, index) => (
          <React.Fragment key={index}>
            <ListItemButton onClick={() => handleClick(index)}>
              <ListItemText sx={{margin:'0px'}}  onClick={DivisionClick} primary={<Typography style={{ fontFamily:'Pretendard-R'}} value={division}>
                {division ==="수소친환경"? i18n.language ==="ko"?"수소친환경":"H2/Recycling":division}
                {/* {division} */}
                
                </Typography>} />
              {open[index] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open[index]} timeout="auto" unmountOnExit>
              {_.sortBy(newprojectlist.filter(v => v.division === division),'use').reverse().map((f, idx) => (
                <React.Fragment key={idx}>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 , paddingTop:'4px', paddingBottom:'4px'}}>
                    {f.use === '운용'?

                      <ListItemText  sx={{margin:'0px'}} disableTypography primary={<Typography style={{ fontFamily:'Pretendard-R',fontSize:'0.9rem',color: '#9BB7D4', }}>
                      <Link to={`/${f.projectcode}`} target="_blank" style={{ textDecoration: 'none' , color:'#9BB7D4'}}>
                        {f.projectname}
                      </Link>
  
                        </Typography>} />

                        :
                        
                      <ListItemText sx={{cursor:'none', margin:'0px'}} disableTypography primary={<Typography style={{ fontFamily:'Pretendard-R',fontSize:'0.9rem',color: '#939597' }}>
                        {f.projectname}
  
                        </Typography>} />

              }
                    </ListItemButton>
                  </List>
                </React.Fragment>
              ))}
            </Collapse>


          </React.Fragment>
        ))}
      </List>

      <TileLayout
        columns={18}
        rowHeight={(height - 30) / 6}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tile_filtered}
        autoFlow="row dense"
        className='allpage'
        style={{ height: height - 30, width: 'calc(100% - 210)', marginTop:20 }}
      />

    </>
  )

    ;
};

export default EntireALL2;