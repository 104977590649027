import React, { useEffect, useState ,useRef} from "react";

const Autodesk = window.Autodesk;
const THREE = window.THREE;

const { REACT_APP_URI2 } = process.env; 

function ForgeViewer({ documentId }) {
  const [coordinates, setCoordinates] = useState([]);
  const viewerRef = useRef(null); // Viewer 인스턴스를 저장하기 위한 ref

  useEffect(() => {
      if (documentId) {
        // Viewer를 초기화합니다.
        initViewer(document.getElementById('viewerContainer')).then(viewer => {
          viewerRef.current = viewer; // Viewer 인스턴스 저장
          loadModel(viewer, documentId);
      });
      }
    }, [documentId]);

  // Forge Viewer 초기화

  async function getAccessToken(callback) {
    try {
        const resp = await fetch(`/api/forge/token`);
        if (!resp.ok) {
            throw new Error(await resp.text());
        }
        const { access_token, expires_in } = await resp.json();
        console.log(access_token)
        callback(access_token, expires_in);
    } catch (err) {
        alert('Could not obtain access token. See the console for more details.');
        console.error(err);
    }
}


function initViewer(container) {
    return new Promise(function (resolve, reject) {
        Autodesk.Viewing.Initializer({ getAccessToken }, function () {
            const config = {
                extensions: ['Autodesk.DocumentBrowser']
            };
            const viewer = new Autodesk.Viewing.GuiViewer3D(container, config);
            viewer.start();
            viewer.setTheme('light-theme');
            resolve(viewer);
        });
    });
}

function loadModel(viewer, urn) {
  return new Promise(function (resolve, reject) {
      function onDocumentLoadSuccess(doc) {
          resolve(viewer.loadDocumentNode(doc, doc.getRoot().getDefaultGeometry()));
      }
      function onDocumentLoadFailure(code, message, errors) {
          reject({ code, message, errors });
      }
      viewer.setLightPreset(0);
      Autodesk.Viewing.Document.load('urn:' + urn, onDocumentLoadSuccess, onDocumentLoadFailure);
      viewer.canvas.addEventListener('click', onViewerClick);


      function onViewerClick(event) {

        const screenPoint = {
            x: event.clientX,
            y: event.clientY
        };
        const hitPoint = viewer.hitTest(screenPoint.x, screenPoint.y);  // hitTest 메소드 사용
    
        if (!hitPoint) return; // 클릭 위치에 객체가 없으면 함수 종료
    
        const intersectPoint = hitPoint.intersectPoint;
    
        setCoordinates(prevCoords => {
            if (prevCoords.length < 4) {
                const newCoords = [...prevCoords, intersectPoint];
                if (newCoords.length === 4) {
                    drawRectangleWithVertices(newCoords);
                }
                return newCoords;
            } else {
                // 5번째 클릭을 할 경우
                return [intersectPoint];  // 좌표 배열을 초기화하고 첫 번째 좌표를 설정합니다.
            }
        });
    }
    

      
      function drawRectangleWithVertices(coords) {
        const overlayName = "customOverlay";
        if (!viewer.overlays.hasScene(overlayName)) {
            viewer.overlays.addScene(overlayName);
        }

        console.log("사각형 그리기 시작")
        // 사각형 그리기
        let material = new THREE.MeshBasicMaterial({
            color: 0xff0000,
            side: THREE.DoubleSide
        });
        let geometry = new THREE.Geometry();
        geometry.vertices.push(
            new THREE.Vector3(coords[0].x, coords[0].y, 0),
            new THREE.Vector3(coords[1].x, coords[1].y, 0),
            new THREE.Vector3(coords[2].x, coords[2].y, 0),
            new THREE.Vector3(coords[3].x, coords[3].y, 0)
        );
        geometry.faces.push(new THREE.Face3(0, 1, 2), new THREE.Face3(0, 2, 3));
        let mesh = new THREE.Mesh(geometry, material);
        viewer.overlays.addMesh(mesh, overlayName);

        console.log(geometry)

        // 네 절점에 구를 추가하여 표시하기
        let vertexMaterial = new THREE.MeshBasicMaterial({
            color: 0x0000ff
        });
        coords.forEach(coord => {
            let sphereGeo = new THREE.SphereGeometry(0.5, 32, 32);  // 반지름 0.5의 구
            let sphere = new THREE.Mesh(sphereGeo, vertexMaterial);
            sphere.position.set(coord.x, coord.y, 0);
            viewer.overlays.addMesh(sphere, overlayName);
        });
    }


  });
}

    // 클리어 버튼 클릭 시 실행할 핸들러
    function handleClear() {
      clearShapes();
      setCoordinates([]);
  }

  // 모든 사각형과 점을 제거하는 함수
  function clearShapes() {
      if (viewerRef.current) {
          const overlayName = "customOverlay";
          if (viewerRef.current.overlays.hasScene(overlayName)) {
              viewerRef.current.overlays.removeScene(overlayName);
          }
      }
  }


  return (
    <>
      <div
        id="viewerContainer"
        style={{ width: "800px", height: "600px",position:'absolute' }}
      ></div>
      <div style={{position:'relative', top:600}}>
      {coordinates && coordinates.map((coord, index) => (
          <div key={index}>
            Coordinates {index + 1}: X: {coord.x.toFixed(2)} Y: {coord.y.toFixed(2)} Z: {coord.z.toFixed(2)}
          </div>
        ))}
         <button onClick={handleClear}>Clear</button> {/* 클리어 버튼 추가 */}
    </div>
    </>
  );
}

export default ForgeViewer;
