import React, { useEffect, useState } from "react";
import _ from "lodash";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Chart,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
} from "@progress/kendo-react-charts";
import "hammerjs";
import { Switch } from "@progress/kendo-react-inputs";
import moment from "moment-timezone";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useRecoilState } from 'recoil';
import {selectedValueStateMan, selectedValueStateManPeriod, selectedChartWeek, selectedChartMonth} from '../atom/selectedValue'


const { REACT_APP_URI2, REACT_APP_PATH2 } = process.env;

const ToggleButton = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#4B5335',
  },
  '&.MuiToggleButton-root': {
    fontSize: '0.7rem',
    fontFamily: 'Pretendard-R',
    padding: 0,
    width: 75,
    borderRadius: '5px 5px 5px 5px!important',
    border: '1px solid rgba(0, 0, 0, 0.12)!important'

  }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  }
}));

const theme = createTheme({
  palette: {
    text: {
      primary: '#00ff00',
    },
  },
});


const Man2 = ({
  positiondata,
  data11,
  dailyreport,
  mandata,
  order,
  blockH,
}) => {
  const [BoxHeight, setBoxHeight] = useState(250);
  const { project_code } = useParams();
  const [vendorname, setvendorname] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedJob, setSelectedJob] = useState("");
  const [JobList, setJobList] = useState("");

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {


      let body =
      {
        "bpname": "Business Partner 계약현황 (WF)",
        "lineitem": "no",
      }

      const res = await axios.post(`/api/getemsbprecord?path=${project_code}`, body)
      setvendorname(res.data.data.data.map((v) => ({ fullname: v.d_subcontractor_name, shortname: v.d_company_name_short })))
    };

    fetchData();
  }, [project_code]);


  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 47);
  }, [positiondata, order, blockH]);


  const [chartVendorList, setChartVendorList] = useState([]);
  const [VendorList, setVendorList] = useState([]);
  const [chartColData, setChartColData] = useState([]);
  const [mandata2, setmandata2] = useState([]);
  const [chartLineData, setChartLineData] = useState([]);

  const [baseunit, setbaseunit] = useState('days');
  const [selectedValue, setSelectedValue] = React.useState("daily");
  const [selected, setSelected] = useState(false);
  const [selectedValueMan, setSelectedValueMan] = useRecoilState(selectedValueStateMan);
  const [selectedValueManP, setSelectedValueManP] = useRecoilState(selectedValueStateManPeriod);
  const [ChartWeek, setChartWeek] = useRecoilState(selectedChartWeek);
  const [ChartMonth, setChartMonth] = useRecoilState(selectedChartMonth);




  const handleChange = (event, selectedValue) => {
    if (selectedValue !== null) {
      setSelectedValue(selectedValue);
      setSelectedValueManP(selectedValue);
    }
  };

  const kr_currnet_time_a = moment(new Date()).tz("Asia/Seoul").format("MM-DD-YYYY");

  useEffect(() => {
    const fetchData = async () => {

      let body =
      {
        "bpname": "Daily Manpower Input",
        "lineitem": "no",
        "filter_criteria": {
          "join": "AND",
          "filter": [
            {

              "field": "uuu_record_last_update_date",
              "value": kr_currnet_time_a,
              "value2": kr_currnet_time_a,
              "condition_type": "range"
            },

            {
              "field": "status",
              "value": "Active",
              "condition_type": "eq"
            }
          ]
        }
      }

      const res = await axios.post(`/api/getemsbprecord?path=${project_code}`, body)
      setmandata2(res.data.data.data)

    };

    fetchData();
  }, [project_code, kr_currnet_time_a]);



  useEffect(() => {
    const linedata1 = _.filter(data11, function (o) {
      return typeof o._bp_lineitems !== "undefined" && o.d_reporting_date !== null && o.d_company_name !== null;;
    })
      .reduce(
        (c, v) =>
          c.concat(
            v._bp_lineitems.map((o) =>
              Object.assign(o, {
                d_reporting_date: v.d_reporting_date,
                d_company_name: v.d_company_name,
              })
            )
          ),
        []
      )
      .filter(
        (f) =>
          f.uuu_tab_id === "Manpower(Picker)" ||
          f.uuu_tab_id === "Manpower(Optional)" || f.uuu_tab_id === '투입인력'
      );

    const linedata2 = _.filter(dailyreport, function (o) {
      return typeof o._bp_lineitems !== "undefined" && o.d_reporting_date !== null && o.d_company_name !== null;;
    })
      .reduce(
        (c, v) =>
          c.concat(
            v._bp_lineitems.map((o) =>
              Object.assign(o, {
                d_reporting_date: v.d_reporting_date,
                d_company_name: v.d_company_name,
              })
            )
          ),
        []
      )
      .filter(
        (f) =>
          f.uuu_tab_id === "Manpower(Picker)" ||
          f.uuu_tab_id === "Manpower(Optional)" || f.uuu_tab_id === '투입인력'
      );




    const uniq_mandata = _.uniqBy([...mandata, ...mandata2].map((v) => ({ ...v, uniq: v.d_reporting_date + v.d_company_name })), 'record_no')

    const linedata =_.sortBy( [...linedata1, ...linedata2, ...uniq_mandata].map((v) => {
      const cleanedCompanyName = v.d_company_name.replace(/[.,/^&$@!+=-]/g, '')
      return {
        ...v,
        d_company_name: cleanedCompanyName,
        d_reporting_date: moment.tz(v.d_reporting_date.slice(0, 10), "Asia/Seoul").toDate(),
        uniq: moment.tz(v.d_reporting_date.slice(0, 10), "Asia/Seoul").format("YYYY-MM-DD") + "//" + cleanedCompanyName,
        uniq2: moment.tz(v.d_reporting_date.slice(0, 10), "Asia/Seoul").format("YYYY-MM-DD") + "//" + v.j_jobtitle_bpk,

        uniqday: moment.tz(v.d_reporting_date.slice(0, 10), "Asia/Seoul").format("YYYY-MM-DD") + "//" + cleanedCompanyName,
        uniqday2: moment.tz(v.d_reporting_date.slice(0, 10), "Asia/Seoul").format("YYYY-MM-DD") + "//" + v.j_jobtitle_bpk,
        uniqweek: moment.tz(v.d_reporting_date.slice(0, 10), "Asia/Seoul").format("YY년 WW주") + "//" + cleanedCompanyName,
        uniqweek2: moment.tz(v.d_reporting_date.slice(0, 10), "Asia/Seoul").format("YY년 WW주") + "//" + v.j_jobtitle_bpk,
        uniqmonth: moment.tz(v.d_reporting_date.slice(0, 10), "Asia/Seoul").format("YY년 MM월") + "//" + cleanedCompanyName,
        uniqmonth2: moment.tz(v.d_reporting_date.slice(0, 10), "Asia/Seoul").format("YY년 MM월") + "//" + v.j_jobtitle_bpk,
      };
    }), "d_reporting_date");



    const chartdata = _(linedata)
      .groupBy(selectedValue ==="monthly"?"uniqmonth" :selectedValue ==="weekly"?"uniqweek":"uniqday")
      .map((objs, key) => ({
        date: key.split("//")[0],
        // order:moment(key.split("//")[0]).format('YYMMDD'), 
        vendor: key.split("//")[1],
        count: _.sumBy(objs, (v) => {
          const dDayWork = parseFloat(v.d_day_work) || 0;
          const dTodayTotal = parseFloat(v.d_today_total) || 0;
          return dDayWork + dTodayTotal;
        }),
      }))
      .value(); 

      console.log(chartdata)

    const chartdata2 = selectedVendor && (selectedVendor.vendor || selectedVendor.vendor !== "")
      ? _(linedata.filter(v => v.d_company_name === selectedVendor.vendor))
      .groupBy(selectedValue ==="monthly"?"uniqmonth2" :selectedValue ==="weekly"?"uniqweek2":"uniqday2")

        .map((objs, key) => ({
          date: key.split("//")[0],
          vendor: key.split("//")[1],
          count: _.sumBy(objs, (v) => {
            const dDayWork = parseFloat(v.d_day_work) || 0;
            const dTodayTotal = parseFloat(v.d_today_total) || 0;
            return dDayWork + dTodayTotal;
          }),
        }))
        .value()
      :
      []; // selectedVendor가 없을 경우 빈 배열을 반환합니다.




    const joblists = _.uniqBy(chartdata2.map((v) => ({ job: v.vendor })), "job");

    setJobList(joblists)


    const man_result = selectedJob && selectedJob.job ? _.sortBy(
      (selectedVendor === null || selectedVendor === "" ? chartdata : chartdata2).map(function (obj) {
        var index = obj.vendor;
        obj[index] = obj.count;
        return obj;
      }),
      "date"
    ).filter(v => v.vendor === selectedJob.job) :
      _.sortBy(
        (selectedVendor === null || selectedVendor === "" ? chartdata : chartdata2).map(function (obj) {
          var index = obj.vendor;
          obj[index] = obj.count;
          return obj;
        }),
        "date"
      )


    const vendorlist = _.uniqBy(man_result, "vendor")
      .map((v) => ({
        vendor:
          vendorname.find(v => v.fullname === v.vendor) && vendorname.find(v => v.fullname === v.vendor)['shortname'] !== '' ?
            vendorname.find(v => v.fullname === v.vendor)['shortname']
            :
            v.vendor,


      }));


    setVendorList(_.uniqBy(linedata.filter(v => v.d_subcon_discipline_spd !== undefined).map((v) => ({ vendor: v.d_company_name, vendor2: v.d_company_name + " " + v.d_subcon_discipline_spd })), "vendor"));

    const linechartdata = _(selectedVendor === null || selectedVendor === "" ? chartdata : chartdata2)
      .groupBy("date")
      .map((objs, key) => ({
        date: key,
        count: _.sumBy(objs, "count"),
      }))
      .value();



    man_result.sort((a, b) => new Date(a.date) - new Date(b.date));

    setChartVendorList(vendorlist);
    setChartColData( _.sortBy(man_result, "date"));


    // setSelectedChartData(man_result);
    setChartLineData(_.sortBy(linechartdata, "date"));

    // setbaseunit(selectedValue === 'daily' ? 'days' : selectedValue === 'weekly' ? 'weeks' : selectedValue === 'monthly' ? 'months' : '')


  }, [dailyreport, data11, mandata, mandata2, vendorname, selectedValue, selectedVendor, selectedJob]);



  useEffect(() => {
    if(chartLineData.length >0){

    const latestDate = chartLineData[chartLineData.length-1].date;


    setChartWeek(latestDate)
    setChartMonth(latestDate)
    }

  },[chartLineData])



  const COLORS = [
    "#FFA500",
    "#56C6A9",
    "#6F9FD8",
    "#E8A798",
    "#D9CE52",
    "#D19C97",
    "#ECDB54",
    '#D2C29D',
    '#F0EAD6',
    '#D69C2F',
    '#FFD662',
    '#E8A798',
    '#95DEE3',
    '#ECDB54',
    '#EC9787'
  ];
  // const COLORS = ['#616CFF', '#9E67FF', '#4E3180', ' #69CCD4', '#42B7F4', '#5E8EFF', '##2075A1', '#33B8B4', '#2E8EE2', '#1A56A5', '#1A28A5', '#A51A72', '#A51AA1A','#D94848']

  const labelContent2 = (e) =>
    e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : e.value;

  const seriesLabels2 = {
    visible: true,
    // Note that visible defaults to false
    padding: 3,
    font: "normal 0.8rem Pretendard-R",
    position: "center",
    background: "none",
    content: labelContent2,
  };

  const labelContent = (e) =>
    e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : e.value;

  const seriesLabels = {
    visible: true,
    // Note that visible defaults to false
    padding: 3,
    font: "bold 0.8rem Pretendard-R",
    position: "top",
    background: "none",
    content: labelContent,
  };

  // const labelContent_0 = (e) => {
  //   return e.value === 0 || e.value === null || typeof e.value === "undefined"
  //     ? ""
  //     : selectedValue === 'monthly'
  //       ? moment(new Date(e.value)).format(i18n.language === "ko" ? "YY년 MM월" : "MMM YY") :
  //       selectedValue === 'weekly' ?


  //         // moment(e.value).format("YY년 WW주")

  //         moment(new Date(e.value)).format(i18n.language === "ko" ? "YY년 WW주" : "YYYY WW")
       
  //         : moment(new Date(e.value)).format(i18n.language === "ko" ? "YY년 MM월 DD일" : "MMM DD YYYY")
  // };

  // const seriesLabels_0 = {
  //   visible: true,
  //   // Note that visible defaults to false
  //   padding: 0,
  //   font: "0.8rem Pretendard-R",
  //   position: "top",
  //   background: "none",
  //   rotation: { angle: "auto" },
  //   content: labelContent_0,
  // };

  const legendlabel = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Pretendard-R",
    background: "none",
  };

  const SharedTooltip = (props) => {
    const { points } = props;
    return (
      <div>

          <div>
            {(points[0].category)}
          </div>
     
        {points.map(function (point, index) {
          return point.series.field === "count" ? (
            <div key={index}>
              {point.series.name} 합계: {point.value}
            </div>
          ) : (
            <div key={index}>
              {point.series.name}: {point.value}
            </div>
          );
        })}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;


  const handleComboChange = (event) => {
    setSelectedVendor(event.target.value);
    setSelectedValueMan(event.target.value)
  };

  const handleComboChangeJob = (event) => {
    setSelectedJob(event.target.value);
  };

  return (
    <>
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[

          {
            height: '20px',
          },

          {
            height: "calc(100% - 20px)",
          }
        ]}
        cols={[
          {
            width: "100%",
          },
        ]}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >

        <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
          <div style={{
            position: "absolute", right: "5px", top: "0px", zIndex: 999999,
            display: "flex", // flexbox를 사용하여 내부 요소를 수평으로 배열합니다.
            alignItems: "center" // 요소들을 세로 중앙에 위치시킵니다.
          }}>
            {selectedVendor ?

              <ComboBox
                style={{
                  width: "60%",
                  marginRight: "8px",
                  // marginBottom: "10px",
                }}
                textField="job"
                data={JobList}
                value={selectedJob}
                onChange={handleComboChangeJob}
                className='disc'
                placeholder={t("직종을 고르세요")}
              />
              : ""}
            <ComboBox
              style={{
                width: "100%",
                marginRight: "8px",
                // marginBottom: "10px",
              }}
              textField="vendor"
              data={VendorList}
              value={selectedVendor}
              onChange={handleComboChange}
              className='disc'
              placeholder={t("Select Vendor")}
            />

            <ThemeProvider theme={theme}>
              <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="baseunit">
                <ToggleButton value="daily" aria-label='daily' >
                  daily
                </ToggleButton>
                <ToggleButton value="weekly" aria-label="weekly">
                  weekly
                </ToggleButton>
                <ToggleButton value="monthly" aria-label="monthly">
                  monthly
                </ToggleButton>
              </ToggleButtonGroup>
            </ThemeProvider>
          </div>

          <div style={{ position: "absolute", left: "5px", top: "0px", zIndex: 99999 }}>

            <Switch
              checked={selected}
              size='small'
              onChange={() => setSelected(!selected)}
              thumbRounded='small'
              trackRounded='small'
            />
            &nbsp;&nbsp;{t("차트 툴팁")}
          </div>

        </GridLayoutItem>

        <GridLayoutItem
          row={2}
          col={1}
          style={{ textAlign: "center", position: "relative" }}
        >
          {/* <div style={{ position: "absolute", right: "85px", top: "-30px" }}>
            {selected ? <span>Monthly</span> : <span>Weekly</span>}
          </div> */}
          <Chart
            pannable={{
              lock: "y",
            }}
            zoomable={{
              mousewheel: {
                lock: "y",
              },
              selection: {
                lock: "y",
              },
            }}
          >
            <ChartArea height={BoxHeight - 20} margin={{ top: 40, bottom: 5 }} />
            <ChartLegend position='bottom' labels={legendlabel}></ChartLegend>
            <ChartTooltip shared={true} render={sharedTooltipRender} visible={selected} />
            <ChartSeries>
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  min={chartLineData.length > 10? chartLineData.length -10:5}
                  max={chartLineData.length}
                  // baseUnit={baseunit}

                  // labels={seriesLabels_0}
                />
              </ChartCategoryAxis>
              {chartVendorList.map((v, index) => (
                <ChartSeriesItem
                  // aggregate="sum"
                  key={index}
                  type="column"
                  stack={true}
                  data={chartColData}
                  field={v.vendor}
                  categoryField="date"
                  labels={seriesLabels2}
                  color={COLORS[index]}
                  border={{ width: '0' }}
                  name={v.vendor}
                />
              ))}

              <ChartSeriesItem
                type="line"
                aggregate="sum"
                data={chartLineData}
                field="count"
                categoryField="date"
                labels={seriesLabels}

              // visible = {false}
              />
            </ChartSeries>
          </Chart>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default Man2;
