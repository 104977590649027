import React, { useEffect, useState, useRef , useMemo} from 'react';
import krMessages from "./kr.json";
import _ from 'lodash';
import {
    Card,
    CardTitle,
    CardBody,
    GridLayout, GridLayoutItem
} from "@progress/kendo-react-layout";
 import axios from "axios";import Cookies from 'js-cookie';
import moment from 'moment';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json"
import CustomCalendar from './customcalendar';
import { Swiper, SwiperSlide } from "swiper/react";
import { Checkbox} from "@progress/kendo-react-inputs";
import { FreeMode, Navigation, Thumbs, Mousewheel } from "swiper";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useTranslation } from 'react-i18next';

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);
loadMessages(krMessages, "ko-KR");
const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;


function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const Photos = ({ filter, BoxHeight, projeclist }) => {

    const { t, i18n } = useTranslation();

    const [filelist, setfilelist] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
         const res = await axios.get(`/api/getFileFolderList?path=/${REACT_APP_PATH2}/file/temp/photo`)
          setfilelist(res.data.fileList)
      };
      fetchData();
  }, []);
  
  const filelist2 = useMemo(() => filelist, [filelist]);

   

    const [data, setdata] = useState([]);
    const [tdata, settdata] = useState([]);
    const [prjlist, setprjlist] = useState([]);
    const [list, setlist] = useState([]);
    const [data2, setdata2] = useState([]);
    const defaultvalue = new Date();
    const [value, setValue] = useState(defaultvalue);
    const [indexnum, setindexnum] = useState(0)
    const [valuePJT, setValuePJT] = React.useState([]);
    const [imagesNavSlider, setImagesNavSlider] = useState(null);
    const [Maxdate, setMaxdate] = useState(defaultvalue);

    const handleChange = (event) => {
        setValue(event.target.value);
    };


    useEffect(() => {
        const fetchData = async () => {
            let body =
            {
                "bpname": "현장 사진 업로드",
                "lineitem": "yes",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        },
                    ]
                }
            }

            const requestArr = projeclist.filter(v =>v.division && v.division.includes(filter)).map(async data => {
                return axios.post(`/api/getbprecordtotal?path=${data.ugenProjectNumber}`, body)
            });

            const res = await Promise.all(requestArr)
            const reslist = res.map(v => v.data.data.data).flat(1)
                .filter(v => typeof v._bp_lineitems !== 'undefined')
                .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { record_no: v.record_no, projectname: v.project_projectname, projectcode: v.project_projectnumber, genPhotoTakenDO: v.genPhotoTakenDO }))), [])
                .filter(v => v.genPhotoCatSRB === "주요공정 Weekly")
                .map(v => new Date(v.genPhotoTakenDO))


                // const requestArr2 = filelist2.map((v)=>({...v, ...projeclist.find(vf=>vf.ugenProjectNumber === v.projectcode)})).filter(v =>v.division && v.division.includes(filter)).map(async data => {
                //     return axios.get(`/api/getJSONtotal?path=/${REACT_APP_PATH2}/file/temp/photo/${data.ugenProjectNumber}.json`)
                // });
    
                // const res2 = await Promise.all(requestArr2)
                // const reslist2 = res2.map(v => v.data.data.data).flat(1)
                //     .map(v => new Date(v.photo_date))
            

            var maxDate = new Date(Math.max.apply(null,reslist));

            setValue(maxDate)
            setMaxdate(maxDate)
        };
        fetchData();
    }, [projeclist, filter, filelist2])

            var getDaysArray = function (start, end) {
                for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
                    arr.push(new Date(dt));
                }
                return arr;
            };

    useEffect(() => {
        const fetchData = async () => {


            const from_date = moment(value).startOf('month');
            const to_date = moment(value).endOf('month');



            var daylist = getDaysArray(new Date(from_date), new Date(to_date)).map(v => moment(v).format("MM-DD-YYYY"));


            const newarray = [];
            const listarray = [];
            for (let i of projeclist) {
                let body =
                {
                    "bpname": "현장 사진 업로드",
                    "lineitem": "yes",
                    "filter_criteria": {
                        "join": "AND",
                        "filter": [
                            {
                                "field": "status",
                                "value": "Active",
                                "condition_type": "eq"
                            },
                            {
                                "field": "genPhotoTakenDO",
                                "value": moment(value).startOf('month').format("MM-DD-YYYY"),
                                "value2": moment(value).endOf('month').format("MM-DD-YYYY"),
                                "condition_type": "range"
                            },

                        ]
                    }
                }

                const res = await axios.post(`/api/getbprecordtotal?path=${i.ugenProjectNumber}`, body)
                const res2 = await axios.get(`/api/getFileFolderList?path=/${REACT_APP_PATH2}/weeklyphoto/${i.ugenProjectNumber}/`)


                const bpfiles = res.data.data.data
                    .map((v) => ({ ...v, projectcode: i.ugenProjectNumber, projectname: i.ugenProjectName, division: i.division }))
                    .filter(v => daylist.includes(v.genPhotoTakenDO.slice(0, 10)) && v.division.includes(filter))
                    .filter(v => typeof v._bp_lineitems !== 'undefined' && v._bp_lineitems.some(f => f.genPhotoCatSRB === '주요공정 Weekly'))
                    .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { record_no: v.record_no }))), [])
                    .map(v => ({
                        genPhotoCatSRB: v.genPhotoCatSRB,
                        uniq: v.RecordDate + v.LineAutoSeq, date: v.genPhotoTakenDO.slice(6, 10) + '.' + v.genPhotoTakenDO.slice(0, 2) + '.' + v.genPhotoTakenDO.slice(3, 5), title: v.genPhotoTitle, description: v.genPhotoDesc, weeklyPhotoSeq: v.WeeklyPhotoSeq,
                    }))
                    .filter(v => v.genPhotoCatSRB === '주요공정 Weekly')

                const imageList = res2.data.fileList.filter(v => bpfiles.map(v => v.uniq).includes(v.name.split('_gubun_')[0]))


                const imageList2 = imageList
                    .map((v) => ({
                        filename: v.name,
                        date: bpfiles[
                            _.findIndex(bpfiles, function (o) {
                                return o.uniq === v.name.split('_gubun_')[0];
                            })
                        ]["date"],
                        weeklyPhotoSeq:
                            bpfiles[
                            _.findIndex(bpfiles, function (o) {
                                return o.uniq === v.name.split('_gubun_')[0];
                            })
                            ]["weeklyPhotoSeq"],
                    }))
                    .map((v) => ({ ...v, uniq: v.filename.split('_gubun_')[0] }));

   

                const IMGList = _.sortBy(imageList2.map((v) => ({ ...v, projectcode: i.ugenProjectNumber, projectname: i.ugenProjectName })), "weeklyPhotoSeq").slice(-4)



                const data = _.sortBy(
                    bpfiles.map((v) => ({ ...v, uniq2: v.date + v.uniq, projectcode: i.ugenProjectNumber, projectname: i.ugenProjectName, division: i.division })),
                    "weeklyPhotoSeq"
                ).slice(-4)

                newarray.push(...data)
                listarray.push(...IMGList)

            }

            var daylist2 = getDaysArray(new Date(moment(value).startOf('month').format("YYYY-MM-DD")), new Date(moment(value).endOf('month').format("YYYY-MM-DD"))).map(v => moment(v).format("YYYY-MM-DD"));


            const requestArr2 = filelist2
           .map(async data => {
                return axios.get(`/api/getJSONtotal?path=/${REACT_APP_PATH2}/file/temp/photo/${data.name}`)
            });

            const res2 = await Promise.all(requestArr2)
            const reslist2 =_.sortBy(res2.map(v => v.data).flat(1)
            .map((v)=>({...v, ...projeclist.find(vf=>vf.ugenProjectNumber === v.projectcode)}))
            .filter(v => daylist2.includes(v.photo_date)) 
            .filter(v =>v.division && v.division.includes(filter))
            .map((v)=>({title:v.photo_title,description:v.photo_description ,filename:v.projectcode+v.name, date:v.photo_date, blob:v.base64, projectname:v.projectname, ugenProjectName:v.projectname})) , 'date').reverse()
            

            settdata(reslist2)

            setdata(newarray)
            setlist(listarray)
            setprjlist(_.uniq(_.sortBy([...newarray,...reslist2], 'projectname').map(v => v.projectname)))
            setValuePJT(_.uniq(_.sortBy([...newarray,...reslist2], 'projectname').map(v => v.projectname)[0]))


        };

        fetchData();
    }, [projeclist, value, filter, Maxdate, filelist2])


    useEffect(() => {
        const fetchData = async () => {


            const requestArr = list.map(async data => {
                return axios.get(`/api/getFile?path=/${REACT_APP_PATH2}/weeklyphoto/${data.projectcode}/${data.filename}`, {
                    responseType: 'arraybuffer'
                })
            });

            const res = await Promise.all(requestArr)

            setdata2(
                (_.sortBy(res.map((v) => ({
                    name: v.config.url.split(`/api/getFile?path=/${REACT_APP_PATH2}/weeklyphoto/`)[1],
                    projectcode: v.config.url.split(`/api/getFile?path=/${REACT_APP_PATH2}/weeklyphoto/`)[1].split('/')[0],
                    blob: window.URL.createObjectURL(new Blob([v.data]))
                })), 'filename')).map((v) => ({ ...v, ...projeclist.find(vf => vf.ugenProjectNumber === v.projectcode) }))
            )

        }
        fetchData();
    }, [list, projeclist, Maxdate, ])


    const handleClick = (event, index) => {
        setindexnum(index)
    };



    const handleChangepjt = (event) => {
        setValuePJT(event.value);
    };

    const [checkvalue, setcheckvalue] = useState(true);

    useInterval(() => {
        setValuePJT(prjlist[(_.indexOf(prjlist, valuePJT)+1) >= prjlist.length?0:(_.indexOf(prjlist, valuePJT)+1)]);
    }, checkvalue ? 15000 : null);


      
    const handleCheckChange = (event) => {
        setcheckvalue(event.value);
      };
  

    return (
        <>
            <Card style={{ height: (BoxHeight - 80) / 2, margin: '12px' }}>
                <CardTitle style={{ height: '35px', margin: '6px 0px 0px 12px', fontSize: '0.9rem', fontWeight: '600', color: '#363945', zIndex: '9999999' }}>
                    <span style={{ float: 'left' }}>Photos</span>
                    <div>
                        <span style={{ width: '125px', position: 'absolute', right: '12px' }} >
                        <LocalizationProvider language={i18n.language ==="ko"?"ko-KR":"en"}>
                        <IntlProvider locale={i18n.language ==="ko"?"ko-KR":"en"}>
                            <DatePicker format={i18n.language ==="ko"?"yyyy년 MM월":"MMM yyyy"}  calendar={CustomCalendar} max={Maxdate} defaultValue={defaultvalue} onChange={handleChange} value={value} />
                                </IntlProvider>
                            </LocalizationProvider>
                        </span>
                    </div>
                    <div style={{ position: 'absolute', top: '6px', left: '50%', transform: 'translateX(-50%)', width: '150px' }}>
                        <DropDownList
                            data={prjlist}
                            onChange={handleChangepjt}
                            value={valuePJT}
                            className='projectpicker'
                        // allowCustom={true}
                        // defaultValue={prjlist}
                        />
                    </div>
                </CardTitle>
                <CardBody style={{ height: ((BoxHeight - 70) / 2) - 47, margin: '12px' }}>
                    <GridLayout style={{ width: '100%', height: ((BoxHeight - 70) / 2) - 47 }} rows={[
                        {
                            height: '100%',
                        },

                    ]}
                        cols={[
                            {
                                width: '70%',
                            }, {
                                width: '30%',
                            },
                        ]}
                        gap={{
                            rows: 0,
                            cols: 0,
                        }}>

                        <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
                            <div style={{position:'absolute', left:'0px', bottom:'0px'}}>
                                <Checkbox
                                    value={checkvalue}
                                    onChange={handleCheckChange}
                                    label={t("자동 전환")}
                                    size='small'
                                />
                            </div>


                            <section className="slider" style={{ height: ((BoxHeight - 70) / 2) - 60 }}>
                                <div className="slider__flex">
                                    <div className="slider__col">

                                        <div className="slider__thumbs" style={{ height: (((BoxHeight - 70) / 2) - 60) * 0.8, width: 75, marginRight: 8 }}>
                                            <Swiper
                                                onSwiper={setImagesNavSlider}
                                                direction="vertical"
                                                spaceBetween={4}
                                                slidesPerView={4}
                                                className="swiper-container1"
                                                // breakpoints={{
                                                //     0: {
                                                //         direction: "horizontal"
                                                //     },
                                                //     768: {
                                                //         direction: "vertical"
                                                //     }
                                                // }}
                                                modules={[FreeMode, Navigation, Thumbs]}
                                            // style={{width:'20%', height:'100%'}}
                                            >

                                                {[...data2,...tdata].filter(v => valuePJT && valuePJT.includes(v.ugenProjectName)) && [...data2,...tdata].filter(v => valuePJT && valuePJT.includes(v.ugenProjectName)).map((v, index) => (
                                                    <SwiperSlide key={index}>

                                                        <img src={v.blob} alt={v.ugenProjectName + index} onClick={event => handleClick(event, index)} />
                                                    </SwiperSlide>


                                                ))}
                                            </Swiper>
                                        </div>

                                    </div>

                                    <div className="slider__images" style={{ width: 'calc(100% - 85px)', height: (((BoxHeight - 70) / 2) - 60) }}>
                                        <Swiper
                                            thumbs={{ swiper: imagesNavSlider }}
                                            direction="horizontal"
                                            slidesPerView={1}
                                            navigation={true}
                                            spaceBetween={10}
                                            mousewheel={true}

                                            // breakpoints={{
                                            //     0: {
                                            //         direction: "horizontal"
                                            //     },
                                            //     768: {
                                            //         direction: "horizontal"
                                            //     }
                                            // }}
                                            className="swiper-container2"
                                            modules={[Navigation, Thumbs, Mousewheel, FreeMode]}
                                        >
                                            {[...data2,...tdata].filter(v => valuePJT && valuePJT.includes(v.ugenProjectName)) && [...data2,...tdata].filter(v => valuePJT && valuePJT.includes(v.ugenProjectName)).map((v, index) => (
                                                <SwiperSlide key={index}>
                                                    <Zoom>

                                                        <img src={v.blob} style={{ objectFit: 'cover', height: (((BoxHeight - 70) / 2) - 60), width: '100%' }} alt={v.ugenProjectName + index} />
                                                    </Zoom>
                                                </SwiperSlide>

                                            ))}

                                        </Swiper>
                                    </div>
                                </div>
                            </section>
                        </GridLayoutItem>
                        <GridLayoutItem row={1} col={2} style={{ position: 'relative', overflowY: 'auto', overflowX: 'hidden' }}>
                            <>
                                <div style={{ textAlign: 'left', position: 'absolute', left: '54%', top: '50%', transform: 'translate(-50%, -50%)', width: '92%', maxHeight: '100%' }}>
                                    {/* <div>
                                            {data.filter(v => valuePJT.includes(v.projectname))[indexnum].date && data.filter(v => valuePJT.includes(v.projectname))[indexnum].projectname}
                                        </div> */}
                                    <div style={{ fontSize: '0.8rem' }}>
                                        <span style={{ fontWeight: '600' }}>{[...data,...tdata].filter(v => valuePJT && valuePJT.includes(v.projectname))[indexnum] && [...data,...tdata].filter(v => valuePJT && valuePJT.includes(v.projectname))[indexnum].title && [...data,...tdata].filter(v => valuePJT && valuePJT.includes(v.projectname))[indexnum].title}</span>
                                        <br />
                                        <span style={{ fontWeight: '500' }}>{[...data,...tdata].filter(v => valuePJT && valuePJT.includes(v.projectname))[indexnum] && [...data,...tdata].filter(v => valuePJT && valuePJT.includes(v.projectname))[indexnum].date && [...data,...tdata].filter(v => valuePJT && valuePJT.includes(v.projectname))[indexnum].date}</span>
                                    </div>

                                    <div style={{ fontSize: '0.8rem' }}>
                                        <div dangerouslySetInnerHTML={{ __html: [...data,...tdata].filter(v => valuePJT && valuePJT.includes(v.projectname))[indexnum] && [...data,...tdata].filter(v => valuePJT && valuePJT.includes(v.projectname))[indexnum].description && [...data,...tdata].filter(v => valuePJT && valuePJT.includes(v.projectname))[indexnum].description.replaceAll('\\n', '<br/>') }} />
                                    </div>
                                </div>
                            </>

                        </GridLayoutItem>
                    </GridLayout>
                </CardBody>
            </Card>
        </>
    );

}
export default Photos
