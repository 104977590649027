import { Milestones } from 'react-milestones-vis';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import _ from "lodash";
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";

const PartMilestone = ({ part }) => {

    const { project_code } = useParams();
    const [datap, setdata2] = useState([]);
    const [datao, setdatao] = useState([]);



    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "Project Milestone",
                "lineitem": "no",
                "filter_condition": "status=Active",
            }
            const res = await axios.post(`/api/getemsbprecord?path=${project_code}`, body)

            const sourcedata = res.data.data.data;


            const datao =

                [...sourcedata.filter(v => v.ActMilSRB === "Milestone" && v.plan_date !== null && v.OverallCB === "Yes")
                    .map((v) => ({
                        date: moment(v.plan_date).format('YYYY-MM-DD'),
                        title: v.genMilestoneDesc

                        ,

                        titleStyle: { color: v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) > 0 ? '#A2242F' : v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) <= 0 ? '#00A170' : 'black' },
                    }))
                    ,
                ...sourcedata.filter(v => v.ActMilSRB === "Milestone" && v.plan_date !== null)
                    .map((v) => ({
                        date: moment(v.plan_date).format('YYYY-MM-DD'),
                        title: v.genActualDate !== null ?
                            '(Actual: ' + moment(v.genActualDate).format('YYYY-MM-DD') + ')' : ""
                        ,

                        textStyle: {
                            // 'font-style': 'italic',
                            'font-size': '8px'

                        },
                        titleStyle: { color: v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) > 0 ? '#A2242F' : v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) <= 0 ? '#00A170' : 'black' },
                    }))
                ]



            const data = sourcedata.filter(v => v.ActMilSRB === "Milestone" && v.plan_date !== null)
                .map((v) => ({
                    date: v.genActualDate !== null ? moment(v.genActualDate).format('YYYY-MM-DD') : moment(v.plan_date).format('YYYY-MM-DD'),
                    title:
                        v.genActualDate !== null && moment(v.genActualDate).diff(moment(v.plan_date), "days") !== 0 ? (v.genMilestoneDesc.length > 10 ? v.genMilestoneDesc.slice(0, 10) + "..." : v.genMilestoneDesc) + '\n(Dev: ' + moment(v.genActualDate).diff(moment(v.plan_date), "days") + '일)' :
                            (v.genMilestoneDesc.length > 10 ? v.genMilestoneDesc.slice(0, 10) + "..." : v.genMilestoneDesc),
                    titleStyle: { color: v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) > 0 ? '#A2242F' : v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) <= 0 ? '#00A170' : 'black' },
                }))


            const early = _.sortBy(data, "date")[0];
            const late = _.sortBy(data, "date")[data.length - 1]

            early.title = "";
            late.title = "";

            early.titleStyle = { color: 'snow' }
            late.titleStyle = { color: 'snow' }

            const newArray = [early, late];


            const data2 = 
            
            [...sourcedata.filter(v => v.ActMilSRB === "Milestone" && v.plan_date !== null  && v.MilestonePhase === part)
            .map((v) => ({
                date: moment(v.plan_date).format('YYYY-MM-DD'),
                title: v.genMilestoneDesc

                ,

                titleStyle: { color: v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) > 0 ? '#A2242F' : v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) <= 0 ? '#00A170' : 'black' },
            }))
        //     ,
        // ...sourcedata.filter(v => v.ActMilSRB === "Milestone" && v.plan_date !== null)
        //     .map((v) => ({
        //         date: moment(v.plan_date).format('YYYY-MM-DD'),
        //         title: v.genActualDate !== null ?
        //             '(Actual: ' + moment(v.genActualDate).format('YYYY-MM-DD') + ')' : ""
        //         ,

        //         textStyle: {
        //             // 'font-style': 'italic',
        //             'font-size': '8px'

        //         },
        //         titleStyle: { color: v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) > 0 ? '#A2242F' : v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) <= 0 ? '#00A170' : 'black' },
        //     }))
        ]

            
            
            // [...sourcedata.filter(v => v.ActMilSRB === "Milestone" && v.plan_date !== null && v.MilestonePhase === part)
            //     .map((v) => ({
            //         date: v.genActualDate !== null ? moment(v.genActualDate).format('YYYY-MM-DD') : moment(v.plan_date).format('YYYY-MM-DD'),
            //         title:
            //             v.genActualDate !== null && moment(v.genActualDate).diff(moment(v.plan_date), "days") !== 0 ? (v.genMilestoneDesc.length > 10 ? v.genMilestoneDesc.slice(0, 10) + "..." : v.genMilestoneDesc) + '\n(Dev: ' + moment(v.genActualDate).diff(moment(v.plan_date), "days") + '일)' :
            //                 (v.genMilestoneDesc.length > 10 ? v.genMilestoneDesc.slice(0, 10) + "..." : v.genMilestoneDesc),
            //         titleStyle: { color: v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) > 0 ? '#A2242F' : v.genActualDate !== null && parseFloat(moment(v.genActualDate).diff(moment(v.plan_date), 'days')) <= 0 ? '#00A170' : 'black' },
            //     }))
            // ]

            const mergeArrays = (source, additions) => {
                const mergedArray = source.concat(additions.filter(addition =>
                    !source.some(item => item.date === addition.date)));
                return mergedArray;
            };

            const combinedArray = mergeArrays(data2, newArray);
            const combinedArray1 = mergeArrays(datao, newArray);

            setdatao(combinedArray1);
            setdata2(combinedArray);

        };

        fetchData();
    }, [project_code, part]);



    return (
        <>
            <GridLayout style={{ height: 280 }}
                rows={[
                    {
                        height: 260,
                    },

                    {
                        height: 20,
                    },
                ]}

                cols={[
                    {
                        width: '100%',
                    },
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>
                <GridLayoutItem col={1} row={1} style={{ position: "relative" }}>

                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        right: 0,
                        transform: 'translateY(-50%)',
                        maxHeight: '100%',
                        margin: 'auto',
                        // 나머지 필요한 스타일 추가
                    }}>

                        <div>
                            <Milestones
                                aggregateBy="day"
                                data={datao}
                                mapping={{
                                    text: 'title',
                                    timestamp: 'date'
                                }}
                                optimize={true}
                                parseTime="%Y-%m-%d"
                                distribution='top'
                                autoResize={true}
                            />

                            <div className='partPhase' style={{ marginTop: -150, height: 280 }}>
                                <Milestones
                                    aggregateBy="day"
                                    data={datap}
                                    mapping={{
                                        text: 'title',
                                        timestamp: 'date'
                                    }}
                                    optimize={true}
                                    parseTime="%Y-%m-%d"
                                    distribution='bottom'
                                    autoResize={true}
                                />
                            </div>
                        </div>
                    </div>
                </GridLayoutItem>
                <GridLayoutItem style={{ display: "grid", justifyItems: 'center', width: '100%' }}>

                    <div style={{ display: "flex", justifyContent: 'center', position: 'absolute', bottom: 10, left: "50%", transform: "translate(-50%)" }}>
                        <div style={{ fontWeight: 600 }}><span className="k-icon k-font-icon k-i-minus-sm" style={{ color: "black", fontSize: "32px" }}></span> Overall</div><div> &nbsp; &nbsp; &nbsp;</div><div style={{ fontWeight: 600, color: "#0072B5" }}><span className="k-icon k-font-icon k-i-minus-sm" style={{ color: "#0072B5", fontSize: "32px" }}></span> {part}</div>
                    </div>
                </GridLayoutItem>
            </GridLayout>
        </>
    );

};

export default PartMilestone
