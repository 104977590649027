import React, { useState, useEffect } from "react";
import _ from "lodash";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useParams } from "react-router-dom";
import { ResponsivePie } from "@nivo/pie";
import axios from "axios";
import {
  Chart,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { generateUpdatedDate } from "../common/datadate";
import EmissionRateComponent from "./EmissionRateComponent";
import YearPicker from "./YearPicker";

const GHGStatus = ({ positiondata, order, blockH }) => {
  const { project_code } = useParams();
  const [BoxHeight, setBoxHeight] = useState(250);
  const [yearData, setYearData] = useState(new Date());
  const [originalData, setOriginalData] = useState({
    total: [],
    project: [],
  });
  const [emissionData, setEmissionData] = useState({
    total: 0,
    project: 0,
  });

  const [yearPieChart, setYearPieChart] = useState([
    { id: "Scope1(직접배출)", value: 0, color: "#5B9BD5" },
    { id: "Scope2(간접배출)", value: 0, color: "#ED7D32" },
    { id: "Scope3(기타간접배출)", value: 0, color: "#A5A5A5" },
  ]);

  const [detailPieChartDatas, setDetailPieChartDatas] = useState({
    scope1: [
      { id: "고정연소", value: 0, color: "#5B9BD5" },
      { id: "이동연소_차량", value: 0, color: "#ED7D32" },
      { id: "이동연소_건설기계", value: 0, color: "#A5A5A5" },
    ],
    scope2: [{ id: "구매전력", value: 0, color: "#5B9BD5" }],
    scope3: [
      { id: "이동연소_차량", value: 0, color: "#5B9BD5" },
      { id: "이동연소_건설기계", value: 0, color: "#ED7D32" },
    ],
  });

  const [monthlyChart, setMonthlyChart] = useState(chart_data_base);

  useEffect(() => {
    const boxHeight = positiondata[order];

    // setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 35);
  }, [positiondata, order, blockH]);

  useEffect(() => {
    const fetch_data = async () => {
      try {
        const request_total_data = await axios
          .post(`/api/getbprecord?path=SKEC0001`, request_total_data_body)
          .then((data) => data.data.data.data);

        const request_project_data = await axios
          .post(
            `/api/getbprecord?path=${project_code}`,
            request_project_data_body
          )
          .then((data) => data.data.data.data);

        setOriginalData((prev) => ({
          ...prev,
          total: request_total_data,
          project: request_project_data,
        }));
      } catch (err) {
        console.log(err);
        setOriginalData((prev) => ({ ...prev, total: [], project: [] }));
      }
    };
    fetch_data();
  }, [project_code]);

  useEffect(() => {
    const prevYearText = moment(yearData).subtract(1, "years").format("YYYY년");
    const thisYearText = moment(yearData).format("YYYY년");

    // const prev_year_total_data = originalData.total.filter(
    //   (c) => c.year_from_2021_spd === prevYearText
    // );

    const this_year_total_data = originalData.total.filter(
      (c) => c.year_from_2021_spd === thisYearText
    );

    const prev_year_project_data = originalData.project.filter(
      (c) => c.year_from_2021_spd === prevYearText
    );

    const this_year_project_data = originalData.project.filter(
      (c) => c.year_from_2021_spd === thisYearText
    );

    const this_total_sum = this_year_total_data.reduce((acc, cur) => {
      const { EEIGas08, EEIGas07 } = cur;

      return (acc = acc + EEIGas08 + EEIGas07);
    }, 0);

    const prev_project_sum = prev_year_project_data.reduce((acc, cur) => {
      const { EEIGas08, EEIGas07 } = cur;

      return (acc = acc + EEIGas08 + EEIGas07);
    }, 0);

    const this_project_sum = this_year_project_data.reduce((acc, cur) => {
      const { EEIGas08, EEIGas07 } = cur;

      return (acc = acc + EEIGas08 + EEIGas07);
    }, 0);

    const this_project_sum_detail = this_year_project_data.reduce(
      (acc, cur) => {
        const { EEIGas08, EEIGas07, EEIGas09 } = cur;

        return (acc = acc + EEIGas08 + EEIGas07 + EEIGas09);
      },
      0
    );

    const this_project_sum_obj = {
      scope1: _.sumBy(this_year_project_data, "EEIGas08"),
      scope2: _.sumBy(this_year_project_data, "EEIGas07"),
      scope3: _.sumBy(this_year_project_data, "EEIGas09"),
    };

    setEmissionData((prev) => ({
      ...prev,
      total: returnZeroIfInvalid(
        Number(((this_project_sum / this_total_sum) * 100).toFixed(0))
      ),
      project: returnZeroIfInvalid(
        Number(((this_project_sum / prev_project_sum) * 100).toFixed(0))
      ),
    }));

    setYearPieChart([
      {
        id: "Scope1(직접배출)",
        label: `Scope1(직접배출) ${returnZeroIfInvalid(
          Number(
            (
              (this_project_sum_obj.scope1 / this_project_sum_detail) *
              100
            ).toFixed(0)
          )
        )}%`,
        value: Number(this_project_sum_obj.scope1.toFixed(0)),
        color: "#5B9BD5",
      },
      {
        id: "Scope2(간접배출)",
        label: `Scope2(간접배출) ${returnZeroIfInvalid(
          Number(
            (
              (this_project_sum_obj.scope2 / this_project_sum_detail) *
              100
            ).toFixed(0)
          )
        )}%`,
        value: Number(this_project_sum_obj.scope2.toFixed(0)),
        color: "#ED7D32",
      },
      {
        id: "Scope3(기타간접배출)",
        label: `Scope3(기타간접배출) ${returnZeroIfInvalid(
          Number(
            (
              (this_project_sum_obj.scope3 / this_project_sum_detail) *
              100
            ).toFixed(0)
          )
        )}%`,
        value: Number(this_project_sum_obj.scope3.toFixed(0)),
        color: "#A5A5A5",
      },
    ]);

    setDetailPieChartDatas({
      scope1: [
        {
          id: "고정연소",
          value: Number(_.sumBy(this_year_project_data, "EEIGas02").toFixed(0)),
          color: "#5B9BD5",
        },
        {
          id: "이동연소_차량",
          value: Number(_.sumBy(this_year_project_data, "EEIGas03").toFixed(0)),
          color: "#ED7D32",
        },
        {
          id: "이동연소_건설기계",
          value: Number(_.sumBy(this_year_project_data, "EEIGas05").toFixed(0)),
          color: "#A5A5A5",
        },
      ],
      scope2: [
        {
          id: "구매전력",
          value: Number(_.sumBy(this_year_project_data, "EEIGas01").toFixed(0)),
          color: "#5B9BD5",
        },
      ],
      scope3: [
        {
          id: "이동연소_차량",
          value: Number(_.sumBy(this_year_project_data, "EEIGas04").toFixed(0)),
          color: "#5B9BD5",
        },
        {
          id: "이동연소_건설기계",
          value: Number(_.sumBy(this_year_project_data, "EEIGas06").toFixed(0)),
          color: "#ED7D32",
        },
      ],
    });

    setMonthlyChart(
      chart_data_base.map((c) => {
        const { cate } = c;

        const result = {
          cate,
          scope1: 0,
          scope2: 0,
        };

        const find_target_month = this_year_project_data.find(
          (c) => c.month_spd === cate
        );

        if (find_target_month) {
          result.scope1 = find_target_month.EEIGas08;
          result.scope2 = find_target_month.EEIGas07;
        }

        return { ...result };
      })
    );
  }, [originalData, yearData]);

  const seriesLabels = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Pretendard-R",
    position: "top",
    background: "none",
    rotation: { angle: "auto" },
    // content: labelContent2,
  };

  const legendlabel = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Pretendard-R",
    background: "none",
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const SharedTooltip = (props) => {
    const { points } = props;

    return (
      <div>
        <div>{points[0].category}</div>
        {points.map((point, index) => (
          <div key={index}>
            {point.value === 0 ? (
              <span>{point.series.name} : 0 </span>
            ) : (
              <span>
                {point.series.name} :{" "}
                {Number(point.value).toLocaleString() + ""}
              </span>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <GridLayout
      style={{ height: BoxHeight, position: "relative" }}
      rows={[
        {
          height: 20,
        },
        {
          height: (BoxHeight - 40) / 2 / 2,
        },
        {
          height: (BoxHeight - 40) / 2 / 2,
        },
        {
          height: 20,
        },
        {
          height: (BoxHeight - 40) / 2,
        },
      ]}
      cols={[
        {
          width: "calc(25%)",
        },
        {
          width: "calc(25%)",
        },
        {
          width: "calc(25%)",
        },
        {
          width: "calc(25%)",
        },
      ]}
      gap={{
        rows: 0,
        cols: 0,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: -52,
          right: -10,
        }}
      >
        <YearPicker value={yearData} setValue={setYearData} />
      </div>
      <GridLayoutItem col={1} row={1} rowSpan={2}>
        <EmissionRateComponent
          subTitleBoolean={true}
          year={moment(yearData).format("YY")}
          total={emissionData.total}
          project={emissionData.project}
          type={"GHG"}
        />
      </GridLayoutItem>
      <GridLayoutItem col={1} row={3} rowSpan={3}>
        <div
          style={{
            height: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="envBoldTextStyle"
        >
          {moment(yearData).format("YY")}년 총 배출 현황
        </div>
        <div style={{ height: BoxHeight - 20 - (BoxHeight - 40) / 2 / 2 }}>
          <ResponsivePie
            colors={{ datum: "data.color" }}
            className="pie2"
            data={yearPieChart}
            margin={{ top: 10, right: 40, bottom: 90, left: 40 }}
            valueFormat=" >-"
            cornerRadius={2}
            innerRadius={0.7}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            enableArcLinkLabels={false}
            arcLabel={function (e) {
              return e.value > 0 ? e.value : "";
            }}
            layers={[
              // 가운데 텍스트 레이어 추가
              "arcs",
              "arcLabels",
              "arcLinkLabels",
              (props) => (
                <CustomCenterLabelLayer
                  {...props}
                  totalSum={_.sumBy(yearPieChart, "value")}
                  text={"test"}
                />
              ),
              "legends",
            ]}
            legends={[
              {
                anchor: "bottom",
                direction: "column",
                justify: false,
                translateX: 0,
                translateY: 70,
                itemsSpacing: 5,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "black",
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        col={2}
        colSpan={3}
        row={1}
        className="envCardCenterStyle"
      >
        <div className={"envBoldTextStyle"}>배출원별 배출 현황</div>
      </GridLayoutItem>
      <GridLayoutItem col={2} row={2} rowSpan={2}>
        <PieChartComponent
          height={(BoxHeight - 40) / 2}
          title={"Scope1"}
          data={detailPieChartDatas.scope1}
        />
      </GridLayoutItem>
      <GridLayoutItem col={3} row={2} rowSpan={2}>
        <PieChartComponent
          height={(BoxHeight - 40) / 2}
          title={"Scope2"}
          data={detailPieChartDatas.scope2}
        />
      </GridLayoutItem>
      <GridLayoutItem col={4} row={2} rowSpan={2}>
        <PieChartComponent
          height={(BoxHeight - 40) / 2}
          title={"Scope3(Biz.P)"}
          data={detailPieChartDatas.scope3}
        />
      </GridLayoutItem>
      <GridLayoutItem
        col={2}
        colSpan={3}
        row={4}
        className="envCardCenterStyle"
      >
        <div className={"envBoldTextStyle"}>월별 배출현황(Scope1, 2)</div>
      </GridLayoutItem>
      <GridLayoutItem col={2} colSpan={3} row={5}>
        <Chart>
          <ChartArea height={(BoxHeight - 40) / 2} />
          <ChartLegend position="bottom" labels={legendlabel} />
          <ChartTooltip shared={true} render={sharedTooltipRender} />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              maxDivisions={10}
              startAngle={45}
              labels={seriesLabels}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              labels={{ font: "0.7rem Pretendard-R" }}
              majorGridLines={{ step: 10 }}
              minorGridLines={{ step: 10 }}
            />
          </ChartValueAxis>

          <ChartSeries>
            <ChartSeriesItem
              type="column"
              data={monthlyChart}
              field="scope1"
              stack={true}
              categoryField="cate"
              color={"#5B9BD5"}
              name="Scope 1"
            />
            <ChartSeriesItem
              type="column"
              data={monthlyChart}
              field="scope2"
              stack={true}
              categoryField="cate"
              color={"#ED7D32"}
              name="Scope 2"
            />
          </ChartSeries>
        </Chart>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default GHGStatus;

const PieChartComponent = ({ height, title, data = [] }) => {
  const customData = data.map((c) => {
    return { ...c, value: true, id: null, id_2: c.id, value_2: c.value };
  });

  console.log(data);

  return (
    <>
      <div
        style={{
          height: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "0.8rem",
        }}
        className="envBoldTextStyle"
      >
        {title}
      </div>
      <div style={{ height: height - 20 }}>
        <ResponsivePie
          colors={{ datum: "data.color" }}
          className="pie2"
          data={data}
          margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
          valueFormat=" >-"
          cornerRadius={2}
          innerRadius={0.7}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          // arcLinkLabelsComponent={CustomArcLinkLabel}

          arcLinkLabel={(d) => {
            return (
              <React.Fragment>
                <tspan
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    color: "black",
                  }}
                  x="0"
                  dy="-0.6em"
                >
                  {d.id}
                </tspan>
                <tspan
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    color: "black",
                  }}
                  x="0"
                  dy="1.2em"
                >
                  {"(" +
                    ((d.value / _.sumBy(data, "value")) * 100).toFixed(0) +
                    "%)"}
                </tspan>
              </React.Fragment>
            );
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextOffset={5}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsDiagonalLength={15}
          arcLinkLabelsStraightLength={15}
          arcLinkLabelsColor={{ from: "color" }}
          enableArcLabels={true}
          arcLabel={function (e) {
            return e.value > 0 ? e.value : "";
          }}
          arcLabelsRadiusOffset={0.5}
          arcLabelsSkipAngle={0}
          legends={[]}
        />
      </div>
    </>
  );
};

const chart_data_base = [
  { cate: "01월", scope1: 0, scope2: 0 },
  { cate: "02월", scope1: 0, scope2: 0 },
  { cate: "03월", scope1: 0, scope2: 0 },
  { cate: "04월", scope1: 0, scope2: 0 },
  { cate: "05월", scope1: 0, scope2: 0 },
  { cate: "06월", scope1: 0, scope2: 0 },
  { cate: "07월", scope1: 0, scope2: 0 },
  { cate: "08월", scope1: 0, scope2: 0 },
  { cate: "09월", scope1: 0, scope2: 0 },
  { cate: "10월", scope1: 0, scope2: 0 },
  { cate: "11월", scope1: 0, scope2: 0 },
  { cate: "12월", scope1: 0, scope2: 0 },
];

const request_total_data_body = {
  bpname: "환경실적 입력 요청/조회",
  lineitem: "no",
  filter_criteria: {
    join: "AND",
    filter: [
      {
        field: "status",
        value: "Terminated",
        condition_type: "neq",
      },
    ],
  },
};

const request_project_data_body = {
  bpname: "환경실적 입력",
  lineitem: "no",
  filter_criteria: {
    join: "AND",
    filter: [
      {
        field: "status",
        value: "Terminated",
        condition_type: "neq",
      },
    ],
  },
};

const returnZeroIfInvalid = (value) => {
  if (value === Infinity || isNaN(value) || value === false) {
    return 0;
  }
  return value;
};

const CustomCenterLabelLayer = ({ centerX, centerY, totalSum }) => {
  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: "1rem",
        fontWeight: "bold",
        fontFamily: "pretendard-B",
      }}
    >
      {totalSum.toLocaleString()}
    </text>
  );
};
