import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '../projectLayout';
import { useParams } from 'react-router-dom';
import { TileLayout } from "@progress/kendo-react-layout";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import axios from 'axios';
import _ from 'lodash';
import moment from "moment";
import useBreakpoint from "use-breakpoint";
import "../kendocustom.css";
import '@progress/kendo-theme-default/dist/all.css';
import { useTranslation } from 'react-i18next';
import { logout } from '../common/logout';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Circles } from 'react-loader-spinner';
import html2canvas from "html2canvas";
import EPCMilestone from './total/epcmilestone';
import ProgrssAll from './total/progressall';
import OverallProgressChart from './total/overallProgress';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };
const { REACT_APP_PATH2 } = process.env;

const today = moment(new Date()).format("YYYY-MM-DD");

const TotalProgress = () => {

    const exportAsImage = async () => {
        const canvas = await html2canvas(document.querySelector(".k-tilelayout"));
        const image = canvas.toDataURL("image/png", 1.0);
        downloadImage(image, project_code+'_공정율.png');
        };
      
      const downloadImage = (blob, fileName) => {
          const fakeLink = window.document.createElement("a");
          fakeLink.style = "display:none;";
          fakeLink.download = fileName;
          
          fakeLink.href = blob;
          
          document.body.appendChild(fakeLink);
          fakeLink.click();
          document.body.removeChild(fakeLink);
          
          fakeLink.remove();
          };

    const { t, i18n } = useTranslation();
    const { project_code } = useParams();
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')
    const [progressOnOff, setprogressOnOff] = useState({});
  const [progressSource, setprogressSource]=useState('작업일보 기반');

  console.log(progressOnOff)

    useEffect(() => {
      const fetchData = async () => {
        let body =
        {
          "bpname": "대시보드 설정",
          "lineitem": "yes",
          "filter_condition": "status=Active",
        }

        const res = await axios.post(`/api/getemsbprecord?path=${project_code}`, body)
        setprogressSource(res.data.data.data.filter(v => v.Dashboard_Name === '시공 공정률')[0]['ProgressSourceType'])
      }
    fetchData();
  }, [project_code]);

  const [grid, setgrid] = useState({
    mobile:
    [
      {
        col: 1,
        colSpan: 16,
        rowSpan: 3,
        status: true,
      },
      {
          col: 1,
          colSpan: 16,
          rowSpan: 3,
          status: true,
        },
        {
          col: 1,
          colSpan: 16,
          rowSpan: 9,
          status: true,
        },

      ]
    ,

    tablet:
    [
      {
        col: 1,
        colSpan: 16,
        rowSpan: 3,
        status: true,
      },
      {
          col: 1,
          colSpan: 16,
          rowSpan: 3,
          status: true,
        },
        {
          col: 1,
          colSpan: 16,
          rowSpan: 9,
          status: true,
        },

      ]
    ,

    desktop:
      [
        {
          col: 1,
          colSpan: 16,
          rowSpan: 4,
          status: true,
        },
        {
            col: 1,
            colSpan: 16,
            rowSpan: 3,
            status: true,
          },
          {
            col: 1,
            colSpan: 16,
            rowSpan:8,
            status: true,
          },

      ]
  });


  useEffect(() => {
    const fetchData = async () => {
      
      let body2={
        bpname: "EPC Progress Ratio",
        lineitem: "no",
      };
      const res2 = await axios.post(`/api/getemsbprecord?path=${project_code}`, body2)
      setprogressOnOff({
        "E":res2.data.data.data[0]["EngineeringProgressRB"],
        "P":res2.data.data.data[0]["ProcurementProgressRB"],
        "C":res2.data.data.data[0]["ConstructionProgressRB"],
        "T":res2.data.data.data[0]["OverallProgressRB"]
      })
      if(res2.data.data.data[0]["OverallProgressRB"] === "Off"){
      setgrid({
        mobile:
        [
          {
            col: 1,
            colSpan: 16,
            rowSpan: 3,
            status: true,
          },
         
            {
              col: 1,
              colSpan: 16,
              rowSpan: 6,
              status: true,
            },
    
          ]
        ,
    
        tablet:
        [
          {
            col: 1,
            colSpan: 16,
            rowSpan: 3,
            status: true,
          },
            {
              col: 1,
              colSpan: 16,
              rowSpan: 6,
              status: true,
            },
    
          ]
        ,
    
        desktop:
          [
            {
              col: 1,
              colSpan: 16,
              rowSpan: 3,
              status: true,
            },
              {
                col: 1,
                colSpan: 16,
                rowSpan:6,
                status: true,
              },
    
          ]
      })}
    }
  fetchData();
}, [project_code]);


      
      
  useEffect(() => {
    setPositionData(grid[breakpoint])
  }, [breakpoint, grid]);

  const [fixblock, setfixblock] = useState(false);

  const togglefix = () => {
    setfixblock(!fixblock);
  };
  const [emsData, setEmsData] = useState([]);
  const [emsWeightValue, setEmsWeightValue] = useState([]);

  useEffect(() => {
      const fetchData = async () => {
        let engineering_progress_body = {
          bpname: "Engineering Progress",
          lineitem: "no",
          filter_criteria: {
            join: "AND",
            filter: [
              {
                field: "PJT_CD",
                value: project_code,
                condition_type: "eq",
              },
            ],
          },
        };
  
        const engineering_progress_ratio_body = {
          bpname: "Engineering Progress Ratio",
          lineitem: "yes",
          filter_criteria: {
            join: "AND",
            filter: [
              {
                field: "ugenProjectNumber",
                value: project_code,
                condition_type: "eq",
              },
            ],
          },
        };
  
        const fetch_engineering_progress_ratio_body = await axios
          .post(
            `/api/getemsbprecord?path=SKEC0001`,
            engineering_progress_ratio_body
          )
          .then((com) =>
            com.data.data.data
              .filter((com2) => com2._bp_lineitems !== undefined)
              .reduce(
                (acc, cur) =>
                  acc.concat(
                    Object.assign(cur._bp_lineitems).map((com2) => ({
                      ...com2,
                      ugenProjectNumber: cur.ugenProjectNumber,
                    }))
                  ),
                []
              )
          );
  
        setEmsWeightValue(fetch_engineering_progress_ratio_body);
  
        const fetch_engineering_progress_body_body = await axios.post(`/api/getemsbprecord?path=SKEC0001`, engineering_progress_body)
          .then((com) =>
            com.data.data.data

          );
  
        const sum_date_engineering_progress = _.sortBy(
          fetch_engineering_progress_body_body
            .map((com) => ({
              ...com,
              date: new Date(
                `${com.CUT_OFF_DT.slice(0, 4)}-
              ${com.CUT_OFF_DT.slice(4, 6)}-
              ${com.CUT_OFF_DT.slice(6, 8)}`
              ),
              dev: Number(
                (
                  (com.PLAN_PROG === null ? 0 : com.PLAN_PROG) -
                  (com.ACTUAL_PROG === null ? 0 : com.ACTUAL_PROG)
                ).toFixed(2)
              ),
            }))
            .map((com) => ({
              ...com,
              month_date: moment(com.date).format("YYYY-MM"),
            })),
          "date"
        );
  
        setEmsData(sum_date_engineering_progress);
      };
      fetchData();
    }, [project_code]);

    


  const [cprogressData, setCprogressData] = useState({});

      
  useEffect(() => {
    const fetchData = async () => {
      const resPVM = await axios.get(
        `/api/getemsJSON?path=/${REACT_APP_PATH2}/file/activitypv/${project_code}_M.json`
    );

    const resPVW = await axios.get(
        `/api/getemsJSON?path=/${REACT_APP_PATH2}/file/activitypv/${project_code}_W.json`
    );

    const res = await axios.get(
      `/api/getemsJSON?path=/${REACT_APP_PATH2}/file/activityprogress/${project_code}/${today}_act.json`
  );

      setCprogressData({weekplan:resPVW.data, monthplan:resPVM.data, actual:res.data})
    }
  fetchData();
}, [project_code]);



const tiles = progressOnOff && progressOnOff.T === "Off"?[
  {
    header: (<div style={{ position: 'relative' }}>Milestone</div>),
    body: <EPCMilestone />,
    resizable: fixblock,
    reorderable: fixblock,

  },
    {
      header: (<div style={{ position: 'relative' }}>EPC Progress</div>),
      body: <ProgrssAll cprogressData={cprogressData} emsData={emsData} progressSource={progressSource} emsWeightValue={emsWeightValue} progressOnOff={progressOnOff}/>,
      resizable: fixblock,
      reorderable: fixblock,
  
    },
]
:
[
    {
      header: (<div style={{ position: 'relative' }}>Milestone</div>),
      body: <EPCMilestone />,
      resizable: fixblock,
      reorderable: fixblock,
  
    },
    {
        header: (<div style={{ position: 'relative' }}>Overall Progress</div>),
        body: 
        // "",
        <OverallProgressChart cprogressData={cprogressData} emsData={emsData.filter(v => v.DISC_NM ==="OVERALL")} progressSource={progressSource} emsWeightValue={emsWeightValue}/>,
        resizable: fixblock,
        reorderable: fixblock,
    
      },
      {
        header: (<div style={{ position: 'relative' }}>EPC Progress</div>),
        body: <ProgrssAll cprogressData={cprogressData} emsData={emsData} progressSource={progressSource} emsWeightValue={emsWeightValue} progressOnOff={progressOnOff}/>,
        resizable: fixblock,
        reorderable: fixblock,
    
      },
]

const location = useLocation();
const usernameFromState = location.state?.username;
const [isLoading, setIsLoading] = useState(false);
const [positiondata, setPositionData] = useState(grid['desktop']);


const handleReposition = e => {
  setPositionData(e.value);
  // setPositionData(e.value.map((v)=>{return {col:v.col, row:v.row, rowSpan:v.rowSpan, colSpan:v.colSpan, status: v.status,}}));
};

const handleLogoutClick = async () => {
    setIsLoading(true); // 요청이 시작될 때 로딩 상태를 true로 설정

    try {
      const res = await axios.get(`/api/getcheckowner?project=${project_code}`);
      setIsLoading(false);
      
      if (res.data.success) {
        const client = res.data.client; // 예를 들어, API 응답에서 'client' 데이터를 기대한다고 가정
        logout({
          username: usernameFromState,
          project: project_code,
          client: client
        });
      } else {
        logout({
          username: usernameFromState,
          project: project_code,
          client: false
        });
      }
    } catch (error) {
      setIsLoading(false);

      // 에러가 발생했을 때 SweetAlert를 사용하여 에러 메시지를 표시
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred. Please contact the system administrator.',
        confirmButtonText: 'OK'
      });
    }
  };

  const isNotIframe = window.self === window.top;

  console.log(isNotIframe)

return (
    <>
     {isNotIframe? (
    <DashboardLayout>
    <div>
      <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
        <ButtonGroup>
          <Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
          <Button title={t("이미지로 다운로드")} iconClass="k-icon k-font-icon k-i-image" togglable={false} onClick={exportAsImage}></Button>
          <Button title={t("고정하기")} iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
        </ButtonGroup>
      </div>
      <TileLayout
        columns={12}
        rowHeight={100}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tiles}
        autoFlow="row dense"
        onReposition={handleReposition}
        style={{marginTop:20}}
      />
    </div>
    </DashboardLayout>)
    :
    <div>
    <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
      <ButtonGroup>
        <Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
        <Button title={t("이미지로 다운로드")} iconClass="k-icon k-font-icon k-i-image" togglable={false} onClick={exportAsImage}></Button>
        <Button title={t("고정하기")} iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
      </ButtonGroup>
    </div>
    <TileLayout
      columns={12}
      rowHeight={100}
      positions={positiondata}
      gap={{
        rows: 10,
        columns: 10
      }}
      items={tiles}
      autoFlow="row dense"
      onReposition={handleReposition}
      style={{marginTop:20}}
    />
  </div>
}
    </>
  )

}

export default TotalProgress;
