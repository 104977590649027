import React, { useState, useEffect, useMemo } from "react";
import { TileLayout } from "@progress/kendo-react-layout";
import "@progress/kendo-theme-default/dist/all.css";
import useBreakpoint from "use-breakpoint";
import _ from "lodash";
 import axios from "axios";import Cookies from 'js-cookie';
import moment from "moment";
import ETimeline from "./etimeline"
import KPITarget from "./KPITarget";
import BySectorDetail from "./BySectorDetail";
import ByProjectDetail from "./ByProjectDetail";
import "../../kendocustom.css";
import "./ESGENV.css";

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;


const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
const ESGEnvironment = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");
  const { height, width } = useWindowDimensions();
  const [EnvDataWaste, setEnvData1] = useState([]);
  const [EnvDataAll, setEnvData2] = useState([]);
  const [EnvDataFine, setEnvDataFine] = useState([]);
  const [projectlist, setprojectlist] = useState([]);

  

  const GRID_CONFIG = {
    mobile: [
      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        rowSpan: 6,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        status: true,
        rowSpan: 3,
      },
      {
        col: 1,
        colSpan: 18,
        status: true,
        rowSpan: 3,
      },
    ],

    tablet: [
      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 9,
        rowSpan: 6,
        status: true,
      },
      {
        col: 10,
        colSpan: 9,
        status: true,
        rowSpan: 3,
      },
      {
        col: 10,
        colSpan: 9,
        status: true,
        rowSpan: 3,
      },
    ],

    desktop: [
      {
        col: 1,
        colSpan: 18,
        rowSpan: 3,
        status: true,
      },
      {
        col: 1,
        colSpan: 9,
        rowSpan: 6,
        status: true,
      },
      {
        col: 10,
        colSpan: 9,
        status: true,
        rowSpan: 3,
      },
      {
        col: 10,
        colSpan: 9,
        status: true,
        rowSpan: 3,
      },
    ],
  };

  const GRIDCONFIG_filterd = {
    mobile: _.filter(GRID_CONFIG["mobile"], { status: true }),
    tablet: _.filter(GRID_CONFIG["tablet"], { status: true }),
    desktop: _.filter(GRID_CONFIG["desktop"], { status: true }),
  };

  useEffect(() => {
    setPositionData(GRIDCONFIG_filterd[breakpoint]);
  }, [breakpoint]);


  useEffect(() => {
    const fetchData = async () => {
      const fetchData = await axios.get(`/api/getproject`, );

      /**
       * 프로젝트 별 부문 정리
       */
      const projectsShell = fetchData.data.data.data
        .filter((com) => com.shell_location !== null)
        .map((com) => {
          return { ...com, bySector: com.shell_location.split("/")[2] };
        })
        .filter((com) => com.ugenProjectNumber !== 'AWPTEST')
        .filter((com) => com.bySector !== undefined)
        .filter((com) => com.bySector !== "반도체");

        setprojectlist(projectsShell)

    }

    fetchData();
  }, []);


  const pjtlist = useMemo(() => projectlist, [projectlist]);


  const [wfolderlist, setwfolderlist] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/api/getFileFolderList?path=/${REACT_APP_PATH2}/file/allbaro` , )
      const folders = res.data.folderList.map(v => v.name)

    setwfolderlist(folders)

    }
    fetchData();
  }, []);


  const wfolders = useMemo(() => wfolderlist, [wfolderlist]);


  useEffect(() => {
    const fetchData = async () => {
      const site_development_end_date_body = {
        bpname: "ESG 대시보드 프로젝트 관리",
        lineitem: "no",
        filter_criteria: {
          filter: [
            {
              status: "Active",
            },
          ],
        },
      };

      const site_development_end_date_res = await axios.post(
        `/api/getbprecord?path=SKEC0001`,
        site_development_end_date_body
      );
      
      const requestArr = wfolders.map(async data => {
        return await axios.get(`/api/getJSON?path=/${REACT_APP_PATH2}/file/allbaro/${data}/allbaro.json`    , )
    });

    const res = await Promise.all(requestArr)
    const reslist = res.map(v => v.data).flat(1)


   const unifierData = reslist.filter((com) =>
   site_development_end_date_res.data.data.data.find(v=>v.ugenProjectNumber === com.project_projectnumber)["SiteDevelopmentEndDate"] 
            ? Number(com.ESG_GIVE_DATE.slice(0, 8))> Number(site_development_end_date_res.data.data.data.find(v=>v.ugenProjectNumber === com.project_projectnumber)["SiteDevelopmentEndDate"].slice(6,10)+site_development_end_date_res.data.data.data.find(v=>v.ugenProjectNumber === com.project_projectnumber)["SiteDevelopmentEndDate"].slice(0,2)+site_development_end_date_res.data.data.data.find(v=>v.ugenProjectNumber === com.project_projectnumber)["SiteDevelopmentEndDate"].slice(3,5))
            : com
          )
          .filter(
            (com) =>
              com.ESG_GIVE_DATE.slice(0, 4) ===
              moment(new Date()).format("YYYY")
          )
  .filter((com) => com.ESG_TRTM_WAYS !== null && Number(com.ESG_GIVE_QUNT) !== 0 && com.ESG_WSTE_CATE !== null)


      
      
      setEnvData1(unifierData)
      // setEnvData1(wastearray.filter((com) => Number(com.ESG_GIVE_DATE.slice(0, 4)) === this_year))


            
      const requestArr2 = pjtlist.map(async data => {
        let fineBody = {
          bpname: "환경 벌금/과태료 입력",
          lineitem: "no",
          filter_condition: "status=Active",
        }

        return await axios.post(`/api/getbprecord?path=${data.ugenProjectNumber}`,fineBody     , )
    });

    const res2 = await Promise.all(requestArr2)
    const reslist2 = res2.map(v => v.data.data.data).flat(1)



      setEnvDataFine(reslist2)

      let body = {
        bpname: "환경배출물 관리",
        lineitem: "yes",
        filter_condition: "status=Active",
      };

      /**
       * 프로젝트 별 에너지, 온실가스, 용수 호출
       */
      const fetchOthersData = await axios.post(
        `/api/getbprecord?path=SKEC0001`,
        body    , 
      );
      setEnvData2(fetchOthersData.data.data.data)
    }


    fetchData();
  }, [pjtlist,wfolders]);

  const [positiondata, setPositionData] = useState(GRID_CONFIG["desktop"]);

  const [blockH, setblockH] = useState(95);

  useEffect(() => {
    setblockH((height - 10) / 9);
  }, [height]);

  const tiles = 
    [
      {
        header: <div style={{ position: "relative" }}>환경 심사 일정</div>,
        body: 
        // ''
        <ETimeline />
        ,
        status: true,
        key: "`22년 KPI Target 대비 누적 소비 규모",
        resizable: false,
        reorderable: false,
      },
      {
        header: (
          <div style={{ position: "relative" }}>
            {moment(new Date()).format("YY")}년 KPI Target 대비 누적 소비 규모
          </div>
        ),
        body: (
          // ''
          <KPITarget positiondata={positiondata} blockH={blockH} order={1} />
        ),
        status: true,
        key: "`22년 KPI Target 대비 누적 소비 규모",
        resizable: false,
        reorderable: false,
      },
      {
        header: (
          <div>
            <div style={{ position: "relative" }}>부문별 누적 배출량</div>
          </div>
        ),
        body: (
        // ''
          <BySectorDetail
            positiondata={positiondata}
            blockH={blockH}
            EnvDataWaste={EnvDataWaste}
            EnvDataAll={EnvDataAll}
            projectlist={projectlist}
            order={2}
          />
        ),
        status: true,
        key: "부문별 세부 배출량",
        resizable: false,
        reorderable: false,
      },

      {
        header: <div style={{ position: "relative" }}>PJT별 세부 배출량</div>,
        body: ( 
          // ''
          <ByProjectDetail
            positiondata={positiondata}
            blockH={blockH}
            order={3}
            EnvDataWaste={EnvDataWaste}
            EnvDataAll={EnvDataAll}
            EnvDataFine ={EnvDataFine}
            projectlist={projectlist}
          />
        ),
        status: true,
        key: "PJT별 세부 배출량",
        resizable: false,
        reorderable: false,
      },
    ];

  const [tile, settile] = useState([tiles]);

  useEffect(() => {
    settile(tiles);
  }, [positiondata, blockH, EnvDataAll, EnvDataWaste,EnvDataFine,projectlist]);

  const tile_filtered = _.filter(tile, ["status", true]);

  return (
    <div>
      <TileLayout
        columns={18}
        rowHeight={blockH}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tile_filtered}
        autoFlow="row dense"
        className='esg'
        style={{ height: height - 10 }}
      />
    </div>
  );
};

export default ESGEnvironment;
