import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from "axios";
import { Circles } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { DFormTable } from './table/registertable';

const DRscon = () => {
  const { project_code } = useParams();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [revData, setRevData] = useState([]);
  const [drawingData, setDrawingData] = useState([]);
  const [returnStatus, setReturnStatus] = useState([]);
  const [PMSdata, setPMSdata] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      if (project_code) {
        const res = await axios.get(`/api/getDrawings?path=${project_code}`);
        const res2 = await axios.get(`/api/getrev?path=${project_code}`);
        const res3 = await axios.get(`/api/getalldrawings?path=${project_code}&type=E`);
        const res4 = await axios.get(`/api/getreturncode?path=${project_code}`);
        const res5 = await axios.get(`/api/getPMSData?path=${project_code}&code=E`);


        if (res.data.success&& res2.data.success && res3.data.success&& res4.data.success&& res5.data.success) {

          const drawinData = res.data.data;
          const revData = _.sortBy(res2.data.data, "RevSeqNo")
          const rowData = res3.data.data.filter(v=>v._bp_lineitems !== undefined);
          const PMSdata = res5.data.data.data.map((v)=>({key:v.PMSClassNM+v.PMSStageNM,FormatType:v.FormatType }))

 
          setRevData(revData);
          setTableData(drawinData);
          setDrawingData(rowData);
          setReturnStatus(res4.data.data.data.map(v=>v.SKDMApprovalDecision))
          setLoading(false);
          setPMSdata(PMSdata)

        }
      }
    }
    fetchData()
  }, [project_code])


  if (loading) {
    return <div className="loadingContainer">
      <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
    </div>;;
  }



  return (
    <>
      <DFormTable PMSdata={PMSdata} projectCode={project_code} tabeldata={tableData} revData={revData} drawingData={drawingData} returnStatus={returnStatus}/>
    </>
  );
};
export default DRscon;
