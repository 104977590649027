import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  GridColumn,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { orderBy } from "@progress/kendo-data-query";
 import axios from "axios";import Cookies from 'js-cookie';
import _ from "lodash";
import "./SafetyCustom.css";
import { useTranslation } from 'react-i18next';
import { generateUpdatedDate } from '../common/datadate';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const EmergencyTraining = ({ positiondata, order, blockH }) => {
  const { project_code } = useParams();
  const findParentNode = useRef();
  const { t, i18n } = useTranslation();

  const [tableData, setTableData] = useState([]);
  const [sort, setSort] = useState([{}]);

  const [BoxHeight, setBoxHeight] = useState(250);
  
  useEffect(() => {

    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
}, [positiondata, order, blockH]);


  useEffect(() => {
    const addClassName = " safetyManpowerBody";
    const parentNode = findParentNode.current.parentElement;
    parentNode.className = parentNode.className + addClassName;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        bpname: "분기별 비상대응 실시 현황",
        lineitem: "no",
        filter_condition: "status=Active",
      };

      const res = await axios.post(
        `/api/getbprecord?path=${project_code}`,
        body,
      );

      const unifierData = res.data.data.data;

      const tableDataRendering = _.sortBy(
        unifierData.map((com) => {
          return {
            ...com,
            ueffectyearPD: Number(com.ueffectyearPD),
            date: handleTrainingDate(com),
          };
        }),
        "date"
      );

      setTableData(tableDataRendering);
    };

    fetchData();
  }, [project_code]);

  const CustomCell = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    const tableDataFilter = tableData.filter(
      (com) => com.genDisciplineText === props.dataItem.genDisciplineText
    );

    const tableFindIndex = tableDataFilter.findIndex(
      (com) => com === props.dataItem
    );

    return (
      <td
        style={{
          border: tableFindIndex !== tableDataFilter.length - 1 && "none",
        }}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        {...navigationAttributes}
      >
        <div>
          {field === "d_tr_detail"?     <div  dangerouslySetInnerHTML={{ __html:value.replaceAll('\\n', '<br/>') }} />:value}
          {field === "ueffectyearPD" && "년"}
        </div>
      </td>
    );
  };

  const CustomHeaderCell = (props) => {
    return (
      <div className="safetyManpowerCustomHeaderCell">
        <div className="safetyManpowerCustomHeaderCell_t">{props.title}</div>
      </div>
    );
  };

  const HeaderCell = (props) => {
    const { field, title } = props;

    const handleSort = (field) => {
      const findSortIndex = sort.findIndex((e) => e.field === field);

      const findSort = sort.find((e) => e.field === field);

      if (findSort === undefined) {
        if (sort[0]["field"] === undefined) {
          sort.push({ field: field, dir: "asc" });
          setSort([...sort]);
        }
      } else {
        if (findSort["dir"] === "asc") {
          sort.splice(findSortIndex, 1, { field: field, dir: "desc" });
          setSort([...sort]);
        } else if (findSort["dir"] === "desc") {
          sort.splice(findSortIndex, 1);
          setSort([...sort]);
        }
      }
    };

    const hadleSortArrow = (field) => {
      const findSort = sort.find((e) => e.field === field);

      return findSort !== undefined ? findSort["dir"] : false;
    };

    const hadleSortIndex = (field) => {
      const findSortIndex = sort.findIndex((e) => e.field === field);

      return findSortIndex === -1 ? "" : findSortIndex;
    };

    return (
      <div
        style={{
          cursor: !title.includes("훈련내용") ? "pointer" : "default",
        }}
        onClick={() => {
          handleSort(field);
        }}
        className="safetyManpowerCustomHeaderCell"
      >
        <p style={{ margin: "0" }} className="safetyManpowerCustomHeaderCell_t">
          {title}{" "}
        </p>
        <div
          className={
            hadleSortArrow(field) === "asc"
              ? "k-icon k-font-icon k-i-sort-asc-sm"
              : hadleSortArrow(field) === "desc"
              ? "k-icon k-font-icon k-i-sort-desc-sm"
              : ""
          }
        ></div>
        {!title.includes("훈련내용") && (
          <div style={{ color: "#ff6358" }}>{hadleSortIndex(field)}</div>
        )}
      </div>
    );
  };

  return (
    <>
    <div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>
    {generateUpdatedDate(new Date())}
  </div>
    <div ref={findParentNode} style={{height:BoxHeight, overflow: "scroll" }}>
      <Grid
        data={orderBy(tableData, sort)}
        className="safetyEmergencyTraining"
        sortable={true}
        sort={sort}
        resizable={true}
      >
        <GridColumn
          field="ueffectyearPD"
          title={t("실시년도")}
          cell={CustomCell}
          headerCell={HeaderCell}
          width={60}
        />
        <GridColumn
          field="quarter_pd"
          title={t("실시분기")}
          cell={CustomCell}
          headerCell={HeaderCell}
          width={60}
        />
        <GridColumn
          field="d_tr_detail"
          title={t("훈련내용")}
          cell={CustomCell}
          headerCell={CustomHeaderCell}
        />
        <GridColumn
          field="d_action_yn"
          title={t("실시여부")}
          cell={CustomCell}
          headerCell={HeaderCell}
          width={60}
        />
      </Grid>
    </div>
    </>
  );
};

export default EmergencyTraining;

const handleTrainingDate = (com) => {
  const { quarter_pd, ueffectyearPD } = com;

  switch (quarter_pd) {
    case "1분기":
      return new Date(`${ueffectyearPD}-01-01`);
    case "2분기":
      return new Date(`${ueffectyearPD}-04-01`);
    case "3분기":
      return new Date(`${ueffectyearPD}-07-01`);
    case "4분기":
      return new Date(`${ueffectyearPD}-10-01`);
    default:
      return;
  }
};
