import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from '@progress/kendo-data-query';
import moment from 'moment';
import axios from 'axios';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const PQIAll = ({ positiondata, projectlist, height }) => {
  const [BoxHeight, setBoxHeight] = useState(250);
  const [pqidata, setpqidata] = useState([]);

  useEffect(() => {
    const boxHeight = positiondata[1];
    setBoxHeight(height * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, height]);

  const thismonth = moment(new Date()).format('YY년 MM월')
  const thismonth1 = moment().subtract(1, 'month').startOf('month').format('YY년 MM월')
  const thismonth2 = moment().subtract(2, 'month').startOf('month').format('YY년 MM월')


  useEffect(() => {
    const fetchData = async () => {

        const res = await axios.get(`/api/getJSON?path=/${REACT_APP_PATH2}/file/pqi/project_pqi.json`)
      
      setpqidata(res.data)
    }

    fetchData();
  }, []);

  const pqilist = pqidata.filter(v=> projectlist.some(f=>f.projectcode === v.project))

  var groupdata =
    _(pqilist)
      .groupBy('project')
      .map((objs, key) => ({
        'project': key,
        '1month': _.filter(objs, function (o) { return o.month === thismonth2 })[0] && _.filter(objs, function (o) { return o.month === thismonth2 })[0]['pqi'],
        '2month': _.filter(objs, function (o) { return o.month === thismonth1 })[0] && _.filter(objs, function (o) { return o.month === thismonth1 })[0]['pqi'],
        '3month': _.filter(objs, function (o) { return o.month === thismonth })[0] && _.filter(objs, function (o) { return o.month === thismonth })[0]['pqi'],

      }))
      .value().map(v => ({ ...v, ...projectlist.find(sp => sp.projectcode === v.project) }))


  const [sort, setSort] = useState([{}]);

  const normalCell = (props) => {
    const { field, dataItem } = props;

    return <td style={{ color: parseFloat(dataItem[field]) > 80 ? '#00A170' : parseFloat(dataItem[field]) < 70 ? '#E13D43' : '#F49B42' }}>{dataItem[field]}</td>;
  };


  return (<>
    <div>
      <Grid
        data={orderBy(groupdata, sort)}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
        style={{ maxHeight: BoxHeight - 16 }}
        className='esgtable'


      >

        <GridColumn field="projectname" title="프로젝트" />
        <GridColumn field="1month" title={thismonth2.slice(4, 7)} cell={normalCell} />
        <GridColumn field="2month" title={thismonth1.slice(4, 7)} cell={normalCell} />
        <GridColumn field="3month" title={thismonth.slice(4, 7)} cell={normalCell} />


      </Grid>
    </div>
    <div style={{ fontSize:'1rem' ,position: 'absolute', bottom: '4px', left: '50%', transform: "translateX(-50%)", color: '#B0B8C2', textAlign: 'center', width: '100%' }}>
      <span className='k-icon k-font-icon k-i-circle' style={{ fontSize: '1.5rem', color: '#E13D43' }}></span><span>Bad </span><span> PQI &lt; 70 </span>

      <span className='k-icon k-font-icon k-i-circle' style={{ fontSize: '1.5rem', color: '#F49B42' }}></span><span>Avg. </span><span> 70 ≤ PQI ≤ 80 </span>
      <span className='k-icon k-font-icon k-i-circle' style={{ fontSize: '1.5rem', color: '#00A170' }}></span><span>Good </span><span> PQI &gt; 80</span>
    </div>

  </>);

};

export default PQIAll;
