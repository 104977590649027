import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TileLayout } from "@progress/kendo-react-layout";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import useBreakpoint from "use-breakpoint";
import _ from 'lodash';
import axios from 'axios';
import ActStatus from './actstatus';
import CWPoverview from './actall';
import ActStatus2 from './actstatus2';
import html2canvas from "html2canvas";
import '@progress/kendo-theme-default/dist/all.css';
import "../kendocustom.css";
import { useTranslation } from 'react-i18next';
import { logout } from '../common/logout';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Circles } from 'react-loader-spinner';
import { DashboardLayout } from '../projectLayout';

const { REACT_APP_URI2 } = process.env; 

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

const CWPStatus = () => {
  const { t } = useTranslation();

  const { project_code } = useParams();
  const exportAsImage = async () => {
    const canvas = await html2canvas(document.querySelector(".k-tilelayout"));
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, project_code + '_CWP Status.png');
  };

  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };
  const [grid, setgrid] = useState({
    mobile: [
      {
        col: 1,
        colSpan: 12,
        rowSpan: 1,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        rowSpan: 6,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 6,
      },
    ],

    tablet: [
      {
        col: 1,
        colSpan: 12,
        rowSpan: 1,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        rowSpan: 6,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 6,
      },
    ],

    desktop: [
      {
        col: 1,
        colSpan: 12,
        rowSpan: 8,
        status: true,
      },

    ]
  });
  const [status_grid, setstatusgrid] = useState([{ status: true }, { status: true }, { status: true }]);
  const [lineitem, setlineitem] = useState([]);
  const [columns, setcolumns] = useState(12);
  const [blockH, setblockH] = useState(70);
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')

  const [progressSource, setprogressSource] = useState('작업일보 기반')

  const [value1, setvalue1] = useState("");
  const [value2, setvalue2] = useState("");
  const [value3, setvalue3] = useState("");
  const [value4, setvalue4] = useState("");


  useEffect(() => {
    const fetchData = async () => {
      let body =
      {
        "bpname": "대시보드 설정",
        "lineitem": "yes",
        "filter_condition": "status=Active",
      }
      const res = await axios.post(`/api/getemsbprecord?path=${project_code}`, body)
      if (res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === 'CWP Status').length > 0) {
        const sort = _.sortBy(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === 'CWP Status')[0]['_bp_lineitems'].filter(v => v.Status_CB === 'Yes'), 'Card_Order')
        const GRIDCONFIG = sort.map((v) => ({ colSpan: v.Card_Width, rowSpan: v.Card_Vertical_Width, status: true, col: v.StartingPosition }))
        const GRID_CONFIG = { mobile: GRIDCONFIG.map((v) => ({ colSpan: 16, rowSpan: v.rowSpan, status: v.status, col: 1 })), tablet: GRIDCONFIG, desktop: GRIDCONFIG };

        setlineitem(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === 'CWP Status')[0]['_bp_lineitems'])

        setstatusgrid(_.sortBy(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === 'CWP Status')[0]['_bp_lineitems'].map((v) => ({ ...v, status: v.Status_CB === 'Yes' ? true : false })), 'Card_Order'))

        setblockH(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === 'CWP Status')[0]['default_vertical_height'])
        setcolumns(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v.Dashboard_Name === 'CWP Status')[0]['columns_count'])
        // setgrid(
        //   GRID_CONFIG
        // )
        setprogressSource(res.data.data.data.filter(v => v.Dashboard_Name === '시공 공정률')[0]['ProgressSourceType'])

      }
    }
    fetchData();
  }, [project_code]);

  const [positiondata, setPositionData] = useState(grid['desktop']);

  useEffect(() => {
    setPositionData(grid[breakpoint])
  }, [breakpoint, grid]);


  const [fixblock, setfixblock] = useState(true);

  const togglefix = () => {
    setfixblock(!fixblock);
  };


  const [data1, setdata1] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let body =
      {
        "bpname": "액티비티 관리 (EVM)",
        "lineitem": "no",
        "record_fields": "d_sep_uom_spd;d_remaining_qty;ManualActualStart;ManualActualFinish;ManualActualProgress;uuu_record_last_update_date;d_activity_status;d_evm_plan_start;d_evm_plan_finish;d_evm_actual_start;d_evm_actual_finish;d_activity_status;uuu_P6ActivityName;uuu_P6ActivityId;d_company_name;d_forecast_qty;d_actual_qty;Lv_1_Name;Lv_2_Name;Lv_3_Name;Lv_4_Name;d_actual_progress;d_plan_progress;d_planned_value;d_weight_value;d_actual_wv",
        "filter_criteria": {
          "join": "AND",
          "filter": [
            {
              "field": "status",
              "value": "Inactive",
              "condition_type": "neq"

            }
          ]
        }
      }

      let body_n = {
        "bpname": "Project Activity List",
        "lineitem": "no",
        "record_fields": "d_sep_uom_spd;d_remaining_qty;Act_activity_status;Act_evm_plan_start;Act_evm_plan_finish;Act_evm_actual_start;Act_evm_actual_finish;d_dr_act_bpk;uuu_record_last_update_date;d_activity_status;d_evm_plan_start;d_evm_plan_finish;d_evm_actual_start;d_evm_actual_finish;d_activity_status;uuu_P6ActivityName;uuu_P6ActivityId;d_company_name;d_forecast_qty;d_actual_qty;Lv_1_Name;Lv_2_Name;Lv_3_Name;Lv_4_Name;Act_actual_progress;d_plan_progress;d_planned_value;d_weight_value;d_actual_wvManualActualStart;ManualActualFinish;ManualActualProgress;",
        "filter_criteria": {
          "join": "AND",
          "filter": [
            {
              "field": "status",
              "value": "Active",
              "condition_type": "eq"
            }
          ]
        }
      }

      let sbody = {
        bpname: "ESG 대시보드 프로젝트 관리",
        lineitem: "no",
        "filter_criteria": {
          "join": "AND",
          "filter": [
              {
                  "field": "status",
                  "value": "Active",
                  "condition_type": "eq"
              },
              {
  
                "field": "ugenProjectNumber",
                "value": project_code,
                "condition_type": "eq"
              },
          ]
      }
      };
      const res = await axios.post(`/api/getemsbprecord?path=SKEC0001`, sbody,)
      const dr_criteria = res.data.data.data.filter(v=>v.ugenProjectNumber === project_code)[0]['DRversion']
    



      const res2 = await axios.post(`/api/getemsbprecord?path=${project_code}`,dr_criteria === 'OLD'? body:body_n,)


      setdata1(dr_criteria === 'OLD'? res2.data.data.data :
      
      _.uniqBy(  res2.data.data.data.map((v)=>({...v, order:v.uuu_P6ActivityId+v.Act_actual_wv}))   .map((v)=>({...v,
        d_actual_progress:v.Act_actual_progress ,
        d_activity_status:v.Act_activity_status ,
        d_actual_wv: v.Act_actual_wv,
        d_evm_plan_start:  v.Act_evm_plan_start,
        d_evm_plan_finish: v.Act_evm_plan_finish,
        d_evm_actual_start: v.Act_evm_actual_start,
        d_evm_actual_finish:v.Act_evm_actual_finish,

      })) , 'uuu_P6ActivityId') )

    };
    fetchData();
  }, [project_code]);


  const [datafrom, setdata2] = useState(data1);


  useEffect(() => {
    setdata2(data1)
  }, [data1])

  const [tile1, settile1] = useState(0)
  const [tile2, settile2] = useState(1)
  const [tile3, settile3] = useState(2)

  useEffect(() => {
    settile1(_.get(lineitem.filter(v => v.Card_Name === null)[0], 'Card_Order') - 1)
    settile2(_.get(lineitem.filter(v => v.Card_Name === 'CWP Activity Status')[0], 'Card_Order') - 1)
    settile3(_.get(lineitem.filter(v => v.Card_Name === 'CWP Status (by Disc.)')[0], 'Card_Order') - 1)

  }, [lineitem])

  const tiles = [
    // {

    //   item: <CWPoverview positiondata={positiondata} datafrom={datafrom} blockH={blockH} order={isNaN(tile1) ? 0 : tile1} value1={value1} value2={value2} value3={value3} value4={value4} />,
    //   status: true,
    //   key: 'CWP 전체현황',
    //   resizable: fixblock,
    //   reorderable: fixblock,

    // },
    {
      header: (<div style={{ position: 'relative' }}>CWP Activity Status</div>),
      body: <ActStatus positiondata={positiondata} datafrom={datafrom} blockH={blockH} order={isNaN(tile2) ? 1 : tile2} progressSource={progressSource} value1={value1} value2={value2} value3={value3} value4={value4}
        setvalue1={setvalue1} setvalue2={setvalue2} setvalue3={setvalue3} setvalue4={setvalue4} />,
      status: true,
      key: 'CWP Activity Status',
      resizable: fixblock,
      reorderable: fixblock,

    }, 
    // {
    //   header: (<div style={{ position: "relative" }}>CWP Status (by Disc.)</div>),
    //   body: <ActStatus2 positiondata={positiondata} datafrom={datafrom} blockH={blockH} order={isNaN(tile3) ? 2 : tile3} progressSource={progressSource} />,
    //   status: true,
    //   key: 'Package Status (by Disc.)',
    //   resizable: fixblock,
    //   reorderable: fixblock,
    // },

  ];




  const [tile, settile] = useState(tiles);

  useEffect(() => {
    settile(tiles.map((v, index) => ({ ...v, status: _.get(status_grid[index], 'status') })));
  }, [positiondata, datafrom, status_grid, tile1, tile2, tile3, fixblock, progressSource, value1, value2, value3, value4]);

  const tile_filtered = _.filter(tile, ['status', true]);

  const handleReposition = e => {
    setPositionData(e.value);
    // setPositionData(e.value.map((v)=>{return {col:v.col, row:v.row, rowSpan:v.rowSpan, colSpan:v.colSpan, status: v.status,}}));
  };

  const location = useLocation();
  const usernameFromState = location.state?.username;
  const [isLoading, setIsLoading] = useState(false);
  
  const handleLogoutClick = async () => {
    setIsLoading(true); // 요청이 시작될 때 로딩 상태를 true로 설정

    try {
      const res = await axios.get(`/api/getcheckowner?project=${project_code}`);
      setIsLoading(false);
   
      if (res.data.success) {
        const client = res.data.client; // 예를 들어, API 응답에서 'client' 데이터를 기대한다고 가정
        logout({
          username: usernameFromState,
          project: project_code,
          client: client
        });
      } else {
        logout({
          username: usernameFromState,
          project: project_code,
          client: false
        });
      }
    } catch (error) {
      setIsLoading(false);

      // 에러가 발생했을 때 SweetAlert를 사용하여 에러 메시지를 표시
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred. Please contact the system administrator.',
        confirmButtonText: 'OK'
      });
    }
  };

  if (isLoading) {
    return <div className="loadingContainer">
    <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
  </div>;;
  }

  const isNotIframe = window.self === window.top;

  return (
    <>
    {isNotIframe? (
    <DashboardLayout>
    <div>
    <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
        <ButtonGroup>
          <Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
          <Button title={t("이미지로 다운로드")} iconClass="k-icon k-font-icon k-i-image" togglable={false} onClick={exportAsImage}></Button>
          <Button title={t("고정하기")} iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
        </ButtonGroup>
      </div>

      <TileLayout
        columns={columns}
        rowHeight={blockH}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tile_filtered}
        autoFlow="row dense"
        onReposition={handleReposition}
        style={{marginTop:20}}

      />
    </div>
    </DashboardLayout>)
    :
    <div>
    <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
        <ButtonGroup>
          <Button onClick={handleLogoutClick} style={{color:'black', fontSize:'0.7rem'}} >DashBoard Log out</Button>
          <Button title={t("이미지로 다운로드")} iconClass="k-icon k-font-icon k-i-image" togglable={false} onClick={exportAsImage}></Button>
          <Button title={t("고정하기")} iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
        </ButtonGroup>
      </div>

      <TileLayout
        columns={columns}
        rowHeight={blockH}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tile_filtered}
        autoFlow="row dense"
        onReposition={handleReposition}
        style={{marginTop:20}}

      />
    </div>
}
</>
  );
};

export default CWPStatus;