import axios from "axios";

export const getSessionUsername = async () => {
    try {
      const response = await fetch('/api/getsessionusername');
      if (response.ok) {
        const data = await response.json();


        return data.username;
      } else {
        throw new Error('Failed to fetch username');
      }
    } catch (error) {
      console.error(error);
      return "Invalid";
    }
  };
  