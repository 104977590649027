import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Input } from "@progress/kendo-react-inputs";
import { Hint } from "@progress/kendo-react-labels";
import { useTranslation } from 'react-i18next'
import { useLocation,useHistory  } from 'react-router-dom';
import Swal from 'sweetalert2';

const {REACT_APP_URI2} = process.env;

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.skecoengineering.com/">
                SK Ecoengineering
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const ToggleButton = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
      color: 'snow!important',
      backgroundColor: 'black',
  },
  '&.MuiToggleButton-root': {
    color:"black",
      fontSize: '0.7rem',
      fontFamily: 'Pretendard-R',
      padding: 0,
      width: 50,
      borderRadius: '0px 0px 0px 0px!important',
      border: '1px solid black!important'

  }
}));


const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > *:not(:last-child)": {
          marginRight: theme.spacing(0)
      }
  }
}));

const theme = createTheme({
  palette: {
      text: {
          primary: '#00ff00',
      },
  },
});

const defaultTheme = createTheme();

export default function SignUp() {
  const { t, i18n } = useTranslation();

    const [username, setUsername] = useState('');
    const [checkresult, setcheckresult] = useState('');
    const [message, setMessage] = useState('');
    const [message2, setMessage2] = useState('');
    const { state } = useLocation();

    const [display, setdisplay] = useState(false);


    React.useEffect(() => {
        if (state && state.language) {
            i18n.changeLanguage(state.language);
        }
    }, [state, i18n]);

    
    useEffect(() => {
      if (checkresult) {
          setMessage2(
              <span>
                  {t("You have the S.CON ID, and the following email is registered.")} <span style={{color: 'red'}}>{checkresult}</span>
              </span>
          );
      } else if (checkresult === undefined) {
          setMessage2(t('You do not have the S.CON ID.'));
      } else {
          setMessage2('');
      }
  }, [checkresult]);
      const checkID = async (e) => {
        setcheckresult("")
        if(username){
          const res = await axios.get(`/api/getuser?user=${username}`);
          alert(t('User Info checked'));
          setcheckresult(res?.data[0]?.uuu_user_email)

      }else{
        alert(t('Input S.CON Login Name'));
      }}

      const handleSubmit = async (e) => {
        e.preventDefault();
        const email = checkresult;
        const language = i18n.language;
        if(checkresult){
          try {
            const response = await axios.post(`/api/forgot-password`, { username, email, language });
            // Use SweetAlert (swal) to show success message with a confirmation button
            Swal.fire({
              title: "Success!",
              text: response.data.message,
              icon: "success",
              button: "OK",  // Custom text for the confirmation button
            });
          } catch (error) {
            let errorMessage = '';
            // Use SweetAlert (swal) to show error message with a confirmation button
            if (error.response) {
              errorMessage = error.response.data.error;
            } else {
              errorMessage = t('Error sending email.');
            }
            Swal.fire({
              title: "Error!",
              text: errorMessage,
              icon: "error",
              button: "Try Again",  // Custom text for the confirmation button
            });
          }
        }
      };
      
      useEffect(() => {
        const email = checkresult;
        if (username && checkresult && checkresult !== "") {
            // 먼저 해당 username이 이미 존재하는지 확인합니다.
            axios.post(`/api/checkUsername`,{ username } )
                .then(response => {
                    if (response.data.exists) {
                        setdisplay(true)
                    } else {
                        // username이 존재하지 않으면 사용자를 생성합니다.
                        try {
                            axios.post(`/api/register`, { username});
                            setdisplay(true)
                        } catch (err) {
                            err.response.data.error ? 
                            alert(err.response.data.error)
                            :
                            alert('Error');
                        }
                    }
                })
                .catch(err => {
                    // checkUsername API 요청에서 오류가 발생한 경우
                    alert(t("Error checking username."));
                });
        }
    }, [username, checkresult]);



    
    const [selectedValue, setSelectedValue] = React.useState(state ?state.language:'en');

    const handleChange = (event, selectedValue) => {
      if (selectedValue !== null) {
          setSelectedValue(selectedValue);
          i18n.changeLanguage(selectedValue);
      }
    };

    const history = useHistory();
    const goBack = () => {
      history.goBack(); // This will take you back to the previous page
    };
  return (
    <>
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
  <Grid >
                            <img src='/image/sconlogoH.png' width={200} />
                        </Grid>
          <Typography component="h1" variant="h5">
          {t("DashBoard Password")}
          </Typography>

          {/* <Typography component="h6" variant="h6">
          You can log in to the S.CON dashboard using the S.CON login name. After entering the login name and clicking the "Check" button, you can find out if that login name is registered with S.CON.
          </Typography> */}
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* <TextField
                  required
                  fullWidth
                  id="username"
                  label="S.CON Login Name"
                  name="username"
                  autoComplete="username"
                  onChange={e => setUsername(e.target.value)}
                /> */}
              <div>{t("S.CON Login Name")}</div>
              <Input
                name="username"
                style={{
                  width: "100%",
                }}
                // label="S.CON Login Name"
                placeholder={t("loginplaceholder")}
                // pattern={"[A-Za-z]+"}
                minLength={2}
                required={true}
                onChange={e => setUsername(e.target.value)}
              />
              <Hint> {t("You can log in to the S.CON dashboard using the S.CON login name. After entering the login name and clicking the 'Check' button, you can find out if that login name is registered with S.CON.")}</Hint>

              <Button
              // type="submit"
              fullWidth
              color="success"
              sx={{ mt: 3, mb: 2, display:username.length>0?"display":"none"}}
              onClick={checkID}
              style={{marginTop:8, marginBottom:4}}
            >
              <span style={{fontWeight:600}}>{t("Check")}</span>
            </Button>
            
              </Grid>
              <Grid item xs={12}>
               {message2 && message2}
              </Grid>


            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, display: display && (typeof message2 !== "string") ? "block":'none' }}
            >
              {t("Send Email to Reset Password")}
            </Button>
                        <Grid item>
            {message}
            </Grid>
            
            <Grid container justifyContent="flex-end">

              <Grid item>
                <Link onClick={goBack} variant="body2">
                  {t("Already know password? Sign in")}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
    <div style={{ position: 'absolute',left:"50%",  transform: "translate(-50%)", zIndex: '999',textAlign: 'center', marginTop:10}}>

<ThemeProvider theme={theme}>
    <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="언어">
        <ToggleButton value="ko" aria-label='한국어' >
            한국어
        </ToggleButton>
        <ToggleButton value="en" aria-label="english">
            ENG
        </ToggleButton>
    </ToggleButtonGroup>
</ThemeProvider>
</div>
    </>
  );
}