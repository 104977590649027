import React, { useEffect, useState } from "react";
import _ from "lodash";
import krMessages from "./kr.json";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import "hammerjs";
import { ResponsivePie } from "@nivo/pie";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import "./Quality.css";
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import { DateRangePicker  } from "@progress/kendo-react-dateinputs";
import moment from 'moment';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
import { useTranslation } from 'react-i18next';
import { generateUpdatedDate } from '../common/datadate';
import { useParams } from "react-router-dom";
 import axios from "axios";import Cookies from 'js-cookie';
load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);
loadMessages(krMessages, "ko-KR");

const { REACT_APP_URI2, REACT_APP_PATH2 } = process.env;;

const Punch = ({ positiondata, order, blockH}) => {
  const [BoxHeight, setBoxHeight] = useState(250);
  const { t, i18n } = useTranslation();
  const { project_code } = useParams();

  const [data, setdata] = useState([]);
  useEffect(() => {
    const fetchData = async () => {

      const requestArr4 = await axios.get(
        `/api/getJSON?path=/${REACT_APP_PATH2}/file/qualitydaily/punch/${project_code}.json`,
      );
      const reslist4 = requestArr4.data;
      setdata(reslist4);
    };

    fetchData();
  }, [project_code]);

  const defaultvalue = 
  {
      start:new Date(Date.now() - 864000000),
      end: new Date(moment().add(-1, 'days')),
    };
  const [value, setValue] = useState(defaultvalue);

  
  var getDaysArray = function (start, end) {
      for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
          arr.push(new Date(dt));
      }
      return arr;
  };


  var daylist = getDaysArray(new Date(value.start), new Date(value.end)).map(v => moment(v).format("MM-DD-YYYY"));

  const data_fitler = _.filter(data, function (o) { return  daylist.includes(o.d_punch_issue_date.slice(0,10))  })

  const handleChange = (event) => {
    setValue(event.target.value);
};

const max = new Date(moment().add(-1, 'days'));

  useEffect(() => {

    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
}, [positiondata, order, blockH]);
  const chartdata = [
    {
      id: "Close",
      value: _.filter(data_fitler, { status: "Approved" }).length,
    },
    {
      id: "Open",
      value:
        _.filter(data_fitler,  function(o) { return o.status !== 'Approved' && o.status !== 'Regrade'; }).length 
    },
  ];

  const tabledata = _(data_fitler)
    .groupBy("Subcontractor_dpk")
    .map((objs, key) => ({
      vendor: key,
      Close: _.filter(objs, function(o) { return o.status === 'Approved'}).length,
      Open: _.filter(objs, function(o) { return o.status !== 'Approved' && o.status !== 'Regrade';}).length,
      total: _.filter(objs, function(o) { return o.status === 'Approved'}).length +  _.filter(objs, function(o) { return o.status !== 'Approved' && o.status !== 'Regrade';}).length,
    }))
    .value();

  const COLORS = ["#FDAC53", "#9BB7D4", "#B55A30", " #F5DF4D", "#0072B5"];

  const [sort, setSort] = useState([]);

  const SimpleSumCell = (props) => {
    const field = props.field || "";
    const total = tabledata.reduce((acc, current) => acc + current[field], 0);
    return (
      <td
        colSpan={props.colSpan}
        style={{ fontSize: "0.8rem", textAlign: "center" }}
      >
        {total}
      </td>
    );
  };
  const normalcell = (props) => {
    const field = props.field || "";
    const cell = props.dataItem[field];
    return <td style={{ fontSize: "0.8rem", textAlign: "center" }}>{cell}</td>;
  };

  const HeaderCell = (props) => {
    return (
      <span
        style={{ fontSize: "0.8rem", textAlign: "center", margin: "0 auto" }}
      >
        {props.title}
      </span>
    );
  };

  return (
    <>
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          {
            height: "100%",
          },
        ]}
        cols={[
          {
            width: "35%",
          },
          {
            width: "65%",
          },
        ]}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >
                        <div style={{ position: 'absolute', top: '-10px', right: '235px' }}>
                    <LocalizationProvider language={i18n.language ==="ko"?"ko-KR":"en"}>
                        <IntlProvider locale={i18n.language ==="ko"?"ko-KR":"en"}>
                            <div className="datepick"><DateRangePicker max={max} format={i18n.language ==="ko" ?"yy년 MM월 dd일":"MMM dd yyyy"} defaultValue={defaultvalue} defaultShow={false} onChange={handleChange} value={value} /></div>
                        </IntlProvider>
                    </LocalizationProvider>
                </div>
        <GridLayoutItem>
          <ResponsivePie
            className="pie"
            data={chartdata}
            margin={{ top: 40, right: 10, bottom: 20, left: 10 }}
            valueFormat=" >-"
            cornerRadius={2}
            innerRadius={0}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            // colors={COLORS}
            colors={{ scheme: "blues" }}
            arcLinkLabel={function (e) {
              return (
                e.id +
                "(" +
                ((e.value / _.sumBy(chartdata, "value")) * 100).toFixed(0) +
                "%)"
              );
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextOffset={5}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsDiagonalLength={5}
            arcLinkLabelsStraightLength={5}
            arcLinkLabelsColor={{ from: "color" }}
            enableArcLabels={true}
            arcLabel={function (e) {
              return e.value;
            }}
            arcLabelsRadiusOffset={0.5}
            arcLabelsSkipAngle={0}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            legends={
              [
                // {
                //     anchor: 'top',
                //     direction: 'row',
                //     justify: false,
                //     translateX: 40,
                //     translateY: -35,
                //     itemsSpacing: 0,
                //     itemWidth: 100,
                //     itemHeight: 18,
                //     itemTextColor: '#999',
                //     itemDirection: 'left-to-right',
                //     itemOpacity: 1,
                //     symbolSize: 18,
                //     symbolShape: 'circle',
                //     effects: [
                //         {
                //             on: 'hover',
                //             style: {
                //                 itemTextColor: '#000'
                //             }
                //         }
                //     ]
                // }
              ]
            }
          />
        </GridLayoutItem>
        <GridLayoutItem>
          <Grid
            // data={tabledata}
            data={orderBy(tabledata, sort)}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            style={{ height: BoxHeight, width: "100%" }}
            className="punchTable"
          >
            <GridColumn
              field="vendor"
              title={t("업체명")}
              cell={normalcell}
              headerCell={HeaderCell}
            />
            <GridColumn
              field="Open"
              title="Open"
              footerCell={SimpleSumCell}
              cell={normalcell}
              headerCell={HeaderCell}
            />
            <GridColumn
              field="Close"
              title="Close"
              footerCell={SimpleSumCell}
              cell={normalcell}
              headerCell={HeaderCell}
            />
            <GridColumn
              field="total"
              title="Total"
              footerCell={SimpleSumCell}
              cell={normalcell}
              headerCell={HeaderCell}
            />
          </Grid>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default Punch;
