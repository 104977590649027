import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { TileLayout } from "@progress/kendo-react-layout";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ListView } from "@progress/kendo-react-listview";
import { Switch } from "@progress/kendo-react-inputs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import axios from "axios";
import useBreakpoint from "use-breakpoint";
import _ from "lodash";
import Intro from "../pjtoverview/projectintro";
import Milestone from "../pjtoverview/milestone";

import "@progress/kendo-theme-default/dist/all.css";
import "../kendocustom.css";
import LessonLearn from "./LessonLearn/LessonLearn";
import EQC from "./EQC/EQC";
import KERCER from "./KERCER/KERCER";
import EQA from "./EQA/EQA";
import ProjectInfo from "./ProjectInfo/ProjectInfo";
import EProgress from "./EProgress/EProgress";
import CriticalProcess from "./CriticalProcess/CriticalProcess";

const { REACT_APP_URI2 } = process.env;

const url = `${REACT_APP_URI2}/api`;

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const ProjectOverviewTest = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");
  const { height } = useWindowDimensions();
  const GRID_CONFIG = {
    mobile: [
      {
        col: 1,
        colSpan: 4,
        rowSpan: 2,
        status: true,
      },
      {
        col: 5,
        colSpan: 4,
        status: true,
        rowSpan: 2,
      },

      {
        col: 9,
        colSpan: 4,
        rowSpan: 2,
        status: true,
      },
      {
        col: 13,
        colSpan: 4,
        status: true,
        rowSpan: 2,
      },
      {
        col: 1,
        colSpan: 16,
        status: true,
        rowSpan: 2,
      },
      {
        col: 1,
        colSpan: 8,
        status: true,
        rowSpan: 2,
      },
      {
        col: 9,
        colSpan: 8,
        status: true,
        rowSpan: 2,
      },
    ],

    tablet: [
      {
        col: 1,
        colSpan: 4,
        rowSpan: 2,
        status: true,
      },
      {
        col: 5,
        colSpan: 4,
        status: true,
        rowSpan: 2,
      },

      {
        col: 9,
        colSpan: 4,
        rowSpan: 2,
        status: true,
      },
      {
        col: 13,
        colSpan: 4,
        status: true,
        rowSpan: 2,
      },
      {
        col: 1,
        colSpan: 16,
        status: true,
        rowSpan: 2,
      },
      {
        col: 1,
        colSpan: 8,
        status: true,
        rowSpan: 2,
      },
      {
        col: 9,
        colSpan: 8,
        status: true,
        rowSpan: 2,
      },
    ],

    desktop: [
      {
        col: 1,
        colSpan: 4,
        rowSpan: 2,
        status: true,
      },
      {
        col: 5,
        colSpan: 4,
        status: true,
        rowSpan: 2,
      },

      {
        col: 9,
        colSpan: 4,
        rowSpan: 2,
        status: true,
      },
      {
        col: 13,
        colSpan: 4,
        status: true,
        rowSpan: 2,
      },
      {
        col: 1,
        colSpan: 16,
        status: true,
        rowSpan: 2,
      },
      {
        col: 1,
        colSpan: 8,
        status: true,
        rowSpan: 2,
      },
      {
        col: 9,
        colSpan: 8,
        status: true,
        rowSpan: 2,
      },
    ],
  };

  const [GRIDCONFIG, setGRIDCONFIG] = useState(GRID_CONFIG);

  const GRIDCONFIG_filterd = useMemo(
    () => ({
      mobile: _.filter(GRIDCONFIG["mobile"], { status: true }),
      tablet: _.filter(GRIDCONFIG["tablet"], { status: true }),
      desktop: _.filter(GRIDCONFIG["desktop"], { status: true }),
    }),
    [GRIDCONFIG]
  );

  const [positiondata, setPositionData] = useState(GRID_CONFIG["desktop"]);
  const [fixblock, setfixblock] = useState(true);
  const [columns, setcolumns] = useState(16);
  const [blockH, setblockH] = useState(140);

  useEffect(() => {
    setPositionData(GRIDCONFIG_filterd[breakpoint]);
  }, [breakpoint, GRIDCONFIG_filterd]);

  const togglefix = () => {
    setfixblock(!fixblock);
  };

  const [visibleDialog, setVisibleDialog] = useState(true);

  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
  };
  const iframe_boolean = window.self === window.top;

  const tiles = [
    {
      header: <div style={{ position: "relative" }}>Project Info</div>,
      body: (
        <ProjectInfo positiondata={positiondata} blockH={blockH} order={0} />
      ),
      status: true,
      key: "PJT 개요",
      resizable: false,
      reorderable: false,
    },
    {
      header: <div style={{ position: "relative" }}>Eng'g Progress</div>,
      body: <EProgress positiondata={positiondata} blockH={blockH} order={1} />,
      status: true,
      key: "Eng'g Process",
      resizable: false,
      reorderable: false,
    },

    {
      header: <div style={{ position: "relative" }}>Lessons Learn</div>,
      body: (
        <LessonLearn positiondata={positiondata} blockH={blockH} order={2} />
      ),
      status: true,
      key: "Lesson Learn",
      resizable: false,
      reorderable: false,
    },
    {
      header: <div style={{ position: "relative" }}>EQC</div>,
      body: <EQC positiondata={positiondata} blockH={blockH} order={3} />,
      status: true,
      key: "EQC",
      resizable: false,
      reorderable: false,
    },

    {
      header: (
        <div style={{ position: "relative" }}>Quality Critical Process</div>
      ),
      body: (
        <CriticalProcess
          positiondata={positiondata}
          blockH={blockH}
          order={4}
        />
      ),
      status: true,
      key: "Critical Process",
      resizable: false,
      reorderable: false,
    },
    {
      header: <div style={{ position: "relative" }}>KER/CER</div>,
      body: <KERCER positiondata={positiondata} blockH={blockH} order={5} />,
      status: true,
      key: "KER/CER",
      resizable: false,
      reorderable: false,
    },
    {
      header: <div style={{ position: "relative" }}>EQA</div>,
      body: <EQA positiondata={positiondata} blockH={blockH} order={6} />,
      status: true,
      key: "EQA",
      resizable: false,
      reorderable: false,
    },
  ];

  const [tile, settile] = useState(tiles);

  const handleReposition = (e) => {
    setPositionData(e.value);
    // setPositionData(e.value.map((v)=>{return {col:v.col, row:v.row, rowSpan:v.rowSpan, colSpan:v.colSpan, status: v.status,}}));
  };

  return (
    <div>
      {/* {visibleDialog && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            right: "20px",
            zIndex: "100",
          }}
        >
          <ButtonGroup>
            <Button
              title="블록 온오프"
              iconClass="k-icon k-font-icon k-i-grid"
              onClick={toggleDialog}
            ></Button>
            <Button
              title="고정하기"
              iconClass={fixblock ? "k-icon k-font-icon k-i-unpin" : "k-icon k-font-icon k-i-pin"}
              togglable={true}
              onClick={togglefix}
            ></Button>
          </ButtonGroup>
        </div>
      )} */}

      <TileLayout
        columns={columns}
        rowHeight={blockH}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tile}
        onReposition={handleReposition}
        autoFlow="row dense"
        height={iframe_boolean ? height - 32 : height - 210 - 32}
      />
    </div>
  );
};

export default ProjectOverviewTest;
