import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import {
    GridLayout,
    GridLayoutItem,
    Card,
    CardTitle,
    CardBody,
    CardSubtitle,
} from "@progress/kendo-react-layout";
import { generateUpdatedDate } from '../common/datadate';
import moment from 'moment';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const SProgress = ({ positiondata , order, blockH}) => {

    const { project_code } = useParams();
    const [lastdate, setlastdate1] = useState('');
    const [data, setdata] = useState([]);
    const [updateddate, setupdateddate] = useState("")


    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "트랜잭션_EPC Progress",
                "lineitem": "no",
                "filter_condition": "status=Active",
                "record_fields": "Epc_reportdate;epc_category_pd;epc_plan;epc_Actual;epc_Dev"
            }
            const res = await axios.post(`/api/getbprecord?path=${project_code}`, body)
            
            const last_data_o = _.sortBy(res.data.data.data.filter(item => item.Epc_reportdate !== null), "record_no")

            const last_date = _.get(last_data_o.slice(-1)[0], 'Epc_reportdate')
            const last_data = _.filter(last_data_o, { Epc_reportdate: last_date }).slice(-5)
            const updateddate =moment(_.max(res.data.data.data.map(v=>moment(v.Epc_reportdate).valueOf()))).format("MM-DD-YYYY")
            setupdateddate(updateddate)
            setlastdate1(last_date)
            setdata(last_data)


        };

        fetchData();
    }, [project_code]);

    const [BoxHeight, setBoxHeight] = useState(250);


    useEffect(() => {

        const boxHeight = positiondata[order];
        setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
    }, [positiondata, order, blockH]);

    const totalplan = (_.filter(data, { 'epc_category_pd': 'Overall' }).map(v => v.epc_plan));
    const totalactul = (_.filter(data, { 'epc_category_pd': 'Overall' }).map(v => v.epc_Actual));
    const totaldev = ((_.filter(data, { 'epc_category_pd': 'Overall' }).map(v => v.epc_Actual)) - (_.filter(data, { 'epc_category_pd': 'Overall' }).map(v => v.epc_plan)))

    const eactul = (_.filter(data, { 'epc_category_pd': 'Engineering' }).map(v => v.epc_Actual));
    const edev = ((_.filter(data, { 'epc_category_pd': 'Engineering' }).map(v => v.epc_Actual)) - (_.filter(data, { 'epc_category_pd': 'Engineering' }).map(v => v.epc_plan)))

    const pactul = (_.filter(data, { 'epc_category_pd': 'Procurement' }).map(v => v.epc_Actual));
    const pdev = ((_.filter(data, { 'epc_category_pd': 'Procurement' }).map(v => v.epc_Actual)) - (_.filter(data, { 'epc_category_pd': 'Procurement' }).map(v => v.epc_plan)))

    const cactul = (_.filter(data, { 'epc_category_pd': 'Construction' }).map(v => v.epc_Actual));
    const cdev = ((_.filter(data, { 'epc_category_pd': 'Construction' }).map(v => v.epc_Actual)) - (_.filter(data, { 'epc_category_pd': 'Construction' }).map(v => v.epc_plan)))


    return (
        <>
            <GridLayout style={{ height: BoxHeight }}
                rows={[
                    {
                        height: '100%',
                    },
                ]}

                cols={[
                    {
                        width: '49%',
                    }, {
                        width: '49%',
                    },


                ]}
                gap={{
                    rows: 0,
                    cols: 10,
                }}>
            <div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>
        { generateUpdatedDate(updateddate)}
        </div>


                <GridLayoutItem col={1} row={1}>
                    <Card style={{ width: '100%', height: BoxHeight }}>
                        <CardBody>
                            <CardTitle style={{ textAlign: 'center', height: '20%' , margin:'0' ,fontFamily:'pretendard-B', color:'#707070'}}>Total Progress (EPC)</CardTitle>
                            <CardTitle style={{ fontSize: '1.5rem', color: '#616CFF', fontWeight: 'bold', height: '25%', position: 'relative' }}><span style={{ position: 'absolute', bottom: '0', width: '100%', textAlign: 'center' }}>{totalactul}%</span></CardTitle>
                            <CardTitle style={{ textAlign: 'center', height: '25%' }}>Dev. {totaldev > 0 ? <span style={{ color: '#006B54', fontWeight: 'bold' }}>{totaldev.toFixed(2)}%</span> : totaldev < 0 ? <span style={{ color: '#9E1030', fontWeight: 'bold' }}>{totaldev.toFixed(2)}%</span> : <span style={{ fontWeight: 'bold' }}>{totaldev.toFixed(2)}%</span>}</CardTitle>
                            <div style={{ height: '10%' }}>
                                <div style={{ float: 'left', color: '##939CA8' ,fontFamily:'pretendard-B'}}>
                                    <CardSubtitle>Plan</CardSubtitle>
                                    <CardSubtitle>{totalplan}%</CardSubtitle>
                                </div>

                                <div style={{ float: 'right' , color: '##939CA8',fontFamily:'pretendard-B'}}>
                                    <CardSubtitle style={{ textAlign: 'right' }}>Actual</CardSubtitle>
                                    <CardSubtitle style={{ textAlign: 'right' }}>{totalactul}%</CardSubtitle>           
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                </GridLayoutItem>

                <GridLayoutItem col={2} row={1}>
                    <Card style={{ width: '100%', height: BoxHeight }}>
                        <CardBody>
                            <div style={{ height: '40%' }}>
                                <CardTitle style={{ float: 'left', fontSize: '0.9rem', height: '50%',color:'#939CA8',fontFamily:'pretendard-B' }}>Engineering</CardTitle>
                                <CardTitle style={{ float: 'right', fontSize: '1rem', height: '50%',color:'#939CA8'  }}>{eactul}%</CardTitle>
                                <CardTitle style={{ position: 'relative', height: '55%' }} > <span style={{ position: 'absolute', right: '0', bottom: '0' }}><span style={{ fontSize: '0.8rem', color: '#939597' }}>Dev. </span>
                                    {edev > 0 ? <span style={{ fontSize: '0.8rem', color: '#006B54', fontWeight: 'bold' }}>{edev.toFixed(2)}%</span> :
                                        edev < 0 ? <span style={{ fontSize: '0.8rem', color: '#9E1030', fontWeight: 'bold' }}>{edev.toFixed(2)}%</span> : <span style={{ fontSize: '0.8rem' }}>{edev.toFixed(2)}%</span>
                                    } </span></CardTitle>
                            </div>

                            <div style={{ height: '40%' }}>
                                <CardTitle style={{ float: 'left', fontSize: '0.9rem', height: '50%',color:'#939CA8',fontFamily:'pretendard-B'  }}>Procurement</CardTitle>
                                <CardTitle style={{ float: 'right', fontSize: '1rem', height: '50%',color:'#939CA8'  }}>{pactul}%</CardTitle>
                                <CardTitle style={{ position: 'relative', height: '55%' }} > <span style={{ position: 'absolute', right: '0', bottom: '0' }}><span style={{ fontSize: '0.8rem', color: '#939597' }}>Dev. </span>
                                    {pdev > 0 ? <span style={{ fontSize: '0.8rem', color: '#006B54', fontWeight: 'bold' }}>{pdev.toFixed(2)}%</span> :
                                        pdev < 0 ? <span style={{ fontSize: '0.8rem', color: '#9E1030', fontWeight: 'bold' }}>{pdev.toFixed(2)}%</span> : <span style={{ fontSize: '0.8rem' }}>{pdev.toFixed(2)}%</span>
                                    } </span></CardTitle>
                            </div>


                            <div style={{ height: '40%' }}>
                                <CardTitle style={{ float: 'left', fontSize: '0.9rem', height: '50%' ,color:'#939CA8' ,fontFamily:'pretendard-B'}}>Construction</CardTitle>
                                <CardTitle style={{ float: 'right', fontSize: '1rem' ,height: '50%' ,color:'#939CA8' }}>{cactul}%</CardTitle>
                                <CardTitle style={{ position: 'relative', height: '55%' }} > <span style={{ position: 'absolute', right: '0', bottom: '0' }}><span style={{ fontSize: '0.8rem', color: '#939597' }}>Dev. </span>
                                    {cdev > 0 ? <span style={{ fontSize: '0.8rem', color: '#006B54' }}>{cdev.toFixed(2)}%</span> :
                                        cdev < 0 ? <span style={{ fontSize: '0.8rem', color: '#9E1030', fontWeight: 'bold' }}>{cdev.toFixed(2)}%</span> : <span style={{ fontSize: '0.8rem' }}>{cdev.toFixed(2)}%</span>
                                    } </span></CardTitle>
                            </div>
                        </CardBody>


                    </Card>


                </GridLayoutItem>

            </GridLayout>

        </>
    );




};

export default SProgress
