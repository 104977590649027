import React, { useEffect, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import BarChart from "../Component/BarChart";
import BarChart100 from "../Component/BarChart100";
import styled from "styled-components";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { useParams } from "react-router-dom";
import axios from "axios";
import _ from "lodash";

const { REACT_APP_URI2 } = process.env;

const url = `${REACT_APP_URI2}/api`;

const EQA = ({ positiondata, blockH, order }) => {
  const { project_code } = useParams();
  const [BoxHeight, setBoxHeight] = useState(250);
  const [visible, setVisible] = useState(false);
  const [barChartData, setBarChartData] = useState([]);
  const [total, setTotal] = useState(0);
  const toggleDialog = () => {
    setVisible(!visible);
  };
  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

  useEffect(() => {
    const fetchData = async () => {
      let eqa_result_body = {
        //EQA Response
        bpname: "EQA Response",
        lineitem: "yes",
        filter_criteria: {
          join: "AND",
          filter: [
            {
              field: "status",
              value: "Terminated",
              condition_type: "neq",
            },
          ],
        },
      };

      const fetch_eqa_result_body = await axios
        .post(`${url}/getemsbprecord?path=${project_code}`, eqa_result_body)
        .then((com) => {
          return com.data.data.data;
        })
        .catch([]);

      const lineItemPlat = fetch_eqa_result_body.reduce((acc, cur) => {
        const { EBPQAFunctuin } = cur;
        const _bp_lineitems = cur?._bp_lineitems ?? [];

        const lineItemDataAdd = _bp_lineitems
          .filter((c) => c.uuu_tab_id === "Checklist")
          .map((c) => {
            return { ...c, EBPQAFunctuin };
          });

        return acc.concat(lineItemDataAdd);
      }, []);

      const group_disc = _(lineItemPlat)
        .groupBy("EBPQAFunctuin")
        .map((objs, key) => {
          const result = {
            disc: key,
            S: objs.filter((c) => c.EBPQAGrade === "S").length,
            CA: objs.filter((c) => c.EBPQAGrade === "CA").length,
            C: objs.filter((c) => c.EBPQAGrade === "C").length,
            O: objs.filter((c) => c.EBPQAGrade === "O").length,
            open: objs.filter((c) => c.EQADicision !== "Closed").length,
            closed: objs.filter((c) => c.EQADicision === "Closed").length,
          };

          return { ...result };
        })
        .value();

      setTotal(lineItemPlat.length);
      setBarChartData(group_disc);

      // const total_lineitems = fetch_eqa_result_body
      //   .map((c) => c._bp_lineitems)
      //   .flat();
      // setTotal(
      //   total_lineitems.filter((o) => o.uuu_tab_id === "Finding Items").length
      // );

      // const uniq_disc = _.uniqBy(total_lineitems, "EMSQualDisciplineSPD")
      //   .map((com) => com.EMSQualDisciplineSPD)
      //   .sort()
      //   .map((com) => {
      //     const filter_table_data_arr = total_lineitems.filter(
      //       (com2) => com2.EMSQualDisciplineSPD === com
      //     );

      //     const finding = filter_table_data_arr.filter(
      //       (o) => o.uuu_tab_id === "Finding Items"
      //     );

      //     const obj = {
      //       disc: com,
      //       B: finding.filter((com) => com.QADFindingCatSPD === "우수사항")
      //         .length,
      //       CA: finding.filter((com) => com.QADFindingCatSPD === "시정조치")
      //         .length,
      //       C: finding.filter((com) => com.QADFindingCatSPD === "시정").length,
      //       O: finding.filter((com) => com.QADFindingCatSPD === "관찰 사항")
      //         .length,
      //     };

      //     return { ...obj };
      //   });

      // const uniq_disc2 = _.uniqBy(total_lineitems, "EMSQualDisciplineSPD")
      //   .map((com) => com.EMSQualDisciplineSPD)
      //   .sort()
      //   .map((com) => {
      //     const filter_table_data_arr = total_lineitems.filter(
      //       (com2) => com2.EMSQualDisciplineSPD === com
      //     );

      //     const finding = filter_table_data_arr.filter(
      //       (o) => o.uuu_tab_id === "Finding Items"
      //     );

      //     const result = filter_table_data_arr.filter(
      //       (o) => o.uuu_tab_id === "Results"
      //     );

      //     const obj2 = {
      //       disc: com,
      //       open:
      //         finding.length -
      //         result.filter((com) => com.EQAStatus === "Closed").length,
      //       closed: result.filter((com) => com.EQAStatus === "Closed").length,
      //     };

      //     return { ...obj2 };
      //   });
      // setBarChartData(uniq_disc);
      // setBarChartData2(uniq_disc2);
    };
    fetchData();
  }, [project_code]);

  return (
    <>
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          {
            height: "10%",
          },
          {
            height: "90%",
          },
        ]}
        cols={[
          {
            width: "100%",
          },
        ]}
        gap={{
          rows: 10,
          cols: 0,
        }}
      >
        <GridLayoutItem row={1} col={1}>
          <TitleDiv>
            <Title style={{ fontSize: "16px", textAlign: "center" }}>
              품질 점검 결과 {total ? total : "0"}건의 Findings 확인
            </Title>
            <ButtonDiv>
              <CustomButton
                title="블록 온오프"
                iconClass="k-icon k-i-grid"
                onClick={toggleDialog}
              ></CustomButton>
            </ButtonDiv>
          </TitleDiv>
        </GridLayoutItem>
        <GridLayoutItem row={2} col={1}>
          <BarChart height={BoxHeight * 0.9} data={barChartData} />
          {visible && (
            <Dialog
              title={"EQA"}
              onClose={toggleDialog}
              width="1000px"
              height="600px"
            >
              <div
                style={{
                  fontFamily: "Pretendard-B",
                  fontSize: "16px",
                  textAlign: "center",
                  marginTop: "50px",
                }}
              >
                공종별 조치 현황
              </div>
              <BarChart100 data={barChartData} />
            </Dialog>
          )}
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default EQA;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;
const ButtonDiv = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
`;

const Title = styled.div`
  flex: 1;
  font-family: "Pretendard-B";
  font-size: "16px";
  text-align: center;
`;

const CustomButton = styled(Button)`
  background-color: #7f84ab;
  border: none;
  box-shadow: none;
  color: white;
`;
