import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const Issue = ({ positiondata , order, blockH }) => {

    const [BoxHeight, setBoxHeight] = useState(250);
    const [data, setdata] = useState([])
    const { project_code } = useParams();

    useEffect(() => {

        const boxHeight = positiondata[order];
        setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
    }, [positiondata, order, blockH]);

   useEffect(() => {
        const fetchData = async () => {

            let body=  
            {
                "bpname": "TOP 3 이슈사항",
                "lineitem": "no",
                "filter_condition": "status=Active",
            
            }
          
          const res = await axios.post(`/api/getbprecord?path=${project_code}`, body    , )

            setdata(_.sortBy(res.data.data.data,'record_no').reverse()[0])


        };

        fetchData();
    }, [project_code]);



    return (
        <>
            <GridLayout style={{ height: BoxHeight }}
                rows={[
                    {
                        height: '100%',
                    },
                    
                ]}

                cols={[
                    {
                        width: '100%',
                    }
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>

                <GridLayoutItem row={1} col={1} style={{overflow:'auto'}}>
                {data && 
                <>
                <div dangerouslySetInnerHTML={{ __html:data.genIssueDescRT && data.genIssueDescRT.replaceAll('<br/>&nbsp;','')  }} className='ment'></div>
                <div dangerouslySetInnerHTML={{ __html:data.genIssueDescRT2 && data.genIssueDescRT2.replaceAll('<br/>&nbsp;','')  }} className='ment'></div>
                <div dangerouslySetInnerHTML={{ __html:data.genIssueDescRT3 && data.genIssueDescRT3.replaceAll('<br/>&nbsp;','')  }} className='ment'></div>
                </>
                }
                </GridLayoutItem>
        
            </GridLayout>

        </>
    );

}

export default Issue
