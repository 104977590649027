import React, { useEffect, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import BarChart100 from "../Component/BarChart100";
import BarChart from "../Component/BarChart";
import { Dialog } from "@progress/kendo-react-dialogs";
import styled from "styled-components";
import { Button } from "@progress/kendo-react-buttons";
import axios from "axios";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { unifier_date_format_transducer } from "../functions/functions";

const { REACT_APP_URI2 } = process.env;

const url = `/api`;

const KERCER = ({ positiondata, blockH, order }) => {
  const { project_code } = useParams();
  const [BoxHeight, setBoxHeight] = useState(250);
  const [visible, setVisible] = useState(false);
  const [barChartData, setBarChartData] = useState([]);
  const [barChartData2, setBarChartData2] = useState([]);
  const [total, setTotal] = useState();

  const toggleDialog = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

  useEffect(() => {
    const fetchData = async () => {
      // 전사
      let record_no_list = await axios
        .post(`${url}/getemsbprecord?path=SKEC0001`, {
          bpname: "KER/CER Auditor",
          lineitem: "no",
          record_fields: "record_no;AuditDateDO;ugenProjectNumber",
          filter_criteria: {
            join: "AND",
            filter: [
              {
                field: "ugenProjectNumber",
                value: project_code,
                condition_type: "eq",
              },
            ],
          },
        })
        .then((com) => {
          return com.data.data.data.map((com2) => {
            return {
              ...com2,
              AuditDateDO_2: unifier_date_format_transducer(com2.AuditDateDO),
            };
          });
        });

      const latest_date_record = _.maxBy(record_no_list, "AuditDateDO_2");

      const record_no = latest_date_record?.record_no;

      if (record_no) {
        const ker_result_body = {
          bpname: "KERCER Gen",
          lineitem: "yes",
          filter_criteria: {
            join: "AND",
            filter: [
              {
                field: "ugenProjectNumber",
                value: project_code,
                condition_type: "eq",
              },
              {
                field: "KERAuditorDPK",
                value: record_no,
                condition_type: "eq",
              },
            ],
          },
        };

        const fetch_key_event_review = await axios
          .post(`${url}/getemsbprecord?path=SKEC0001`, ker_result_body)
          .then((com) => {
            return com.data.data.data;
          });

        const filter_arr = [
          "우수사항",
          "우수 사항",
          "시정조치",
          "시정",
          "관찰 사항",
        ];

        const total_lineitems = fetch_key_event_review
          .filter((c) => c._bp_lineitems)
          .map((c) => c._bp_lineitems)
          .flat()
          .filter((c) => filter_arr.includes(c.EQAFindingCatSPD));

        setTotal(
          total_lineitems.filter((o) => o.uuu_tab_id === "Finding Items").length
        );

        const uniq_disc = _.uniqBy(
          fetch_key_event_review,
          "EMSQualDisciplineSPD"
        )
          .map((com) => com.EMSQualDisciplineSPD)
          .sort()
          .map((com) => {
            const filter_table_data_arr = total_lineitems.filter(
              (com2) => com2.EMSQualDisciplineSPD === com
            );

            const finding = filter_table_data_arr.filter(
              (o) => o.uuu_tab_id === "Finding Items"
            );

            const obj = {
              disc: com,
              B: finding.filter(
                (com) =>
                  com.EQAFindingCatSPD === "우수사항" ||
                  com.EQAFindingCatSPD === "우수 사항"
              ).length,
              CA: finding.filter((com) => com.EQAFindingCatSPD === "시정조치")
                .length,
              C: finding.filter((com) => com.EQAFindingCatSPD === "시정")
                .length,
              O: finding.filter((com) => com.EQAFindingCatSPD === "관찰 사항")
                .length,
            };

            return { ...obj };
          });

        const uniq_disc2 = _.uniqBy(
          fetch_key_event_review,
          "EMSQualDisciplineSPD"
        )
          .map((com) => com.EMSQualDisciplineSPD)
          .sort()
          .map((com) => {
            const filter_table_data_arr = total_lineitems.filter(
              (com2) => com2.EMSQualDisciplineSPD === com
            );

            const finding = filter_table_data_arr.filter(
              (o) => o.uuu_tab_id === "Finding Items"
            );

            const result = filter_table_data_arr.filter(
              (o) => o.uuu_tab_id === "Results"
            );

            const obj2 = {
              disc: com,
              open:
                finding.length -
                result.filter((com) => com.ResultCheckRB === "승인").length,
              closed: result.filter((com) => com.ResultCheckRB === "승인")
                .length,
            };

            return { ...obj2 };
          });
        setBarChartData(uniq_disc);
        setBarChartData2(uniq_disc2);
      }
    };
    fetchData();
  }, [project_code]);

  return (
    <>
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          {
            height: "10%",
          },
          {
            height: "90%",
          },
        ]}
        cols={[
          {
            width: "100%",
          },
        ]}
        gap={{
          rows: 10,
          cols: 0,
        }}
      >
        <GridLayoutItem row={1} col={1}>
          <TitleDiv>
            <Title style={{ fontSize: "16px", textAlign: "center" }}>
              품질 점검 결과 {total ? total : "0"}건의 Findings 확인
            </Title>

            <ButtonDiv>
              <CustomButton
                title="블록 온오프"
                iconClass="k-icon k-font-icon k-i-grid"
                onClick={toggleDialog}
              ></CustomButton>
            </ButtonDiv>
          </TitleDiv>
        </GridLayoutItem>
        <GridLayoutItem row={2} col={1}>
          <BarChart height={BoxHeight * 0.9} data={barChartData} />
          {visible && (
            <Dialog
              title={"KER/CER"}
              onClose={toggleDialog}
              width="1000px"
              height="600px"
            >
              <div
                style={{
                  fontFamily: "Pretendard-B",
                  fontSize: "16px",
                  textAlign: "center",
                  marginTop: "50px",
                }}
              >
                공종별 조치 현황
              </div>
              <BarChart100 data={barChartData2} />
            </Dialog>
          )}
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default KERCER;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;
const ButtonDiv = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
`;

const Title = styled.div`
  flex: 1;
  font-family: "Pretendard-B";
  font-size: "16px";
`;

const CustomButton = styled(Button)`
  background-color: #7f84ab;
  border: none;
  box-shadow: none;
  color: white;
`;
