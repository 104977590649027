import React, { useState, useEffect } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import OverallResourcePlanChart from "./Components/OverallResourcePlanChart";
import DetailResourcePlanChart from "./Components/DetailResourcePlanChart";

const { REACT_APP_URI2, REACT_APP_PATH2 } = process.env;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const iframe_boolean = window.self === window.top;

const SkonEmsResourcePlan = () => {
  const { height } = useWindowDimensions();
  const [selected, setSelected] = useState(0);
  const [currentStaff, setCurrentStaff] = useState([]);
  const [planStaff, setPlanStaff] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const fetch_current_staff = await axios
        .get(
          `/api/getemsJSON?path=/${REACT_APP_PATH2}/file/resourceplan/group_user_class2.json`
        )
        .then((data) => data.data);

      const fetch_plan_staff = await axios
        .get(
          `/api/getemsJSON?path=/${REACT_APP_PATH2}/file/resourceplan/flat_manhour_months.json`
        )
        .then((data) =>
          data.data.map((com) => ({
            ...com,
            months: new Date(com.months),
            months_moment: moment(new Date(com.months)).format("YYYY-MM"),
          }))
        );

      setCurrentStaff(fetch_current_staff);
      setPlanStaff(fetch_plan_staff);
    };
    fetchData();
  }, []);

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  return (
    <SkonEmsResourcePlanDiv>
      <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab title="Overall">
          <OverallResourcePlanChart
            planStaff={planStaff}
            height={iframe_boolean ? height - 32 : height - 210 - 32}
            color_list={color_list}
            bgColor={bgColor}
          />
        </TabStripTab>
        <TabStripTab title="Detail">
          <DetailResourcePlanChart
            planStaff={planStaff}
            currentStaff={currentStaff}
            height={iframe_boolean ? height - 32 : height - 210 - 32}
            color_list={color_list}
            bgColor={bgColor}
          />
        </TabStripTab>
      </TabStrip>
    </SkonEmsResourcePlanDiv>
  );
};

export default SkonEmsResourcePlan;

const SkonEmsResourcePlanDiv = styled.div`
  .k-tabstrip > .k-content > .k-animation-container {
    width: 100%;
  }

  .k-tabstrip-items-wrapper .k-item:active,
  .k-tabstrip-items-wrapper .k-item.k-state-active,
  .k-tabstrip-items-wrapper .k-item.k-active,
  .k-tabstrip-items-wrapper .k-item.k-state-selected,
  .k-tabstrip-items-wrapper .k-item.k-selected {
    color: #ff6358;
  }

  .k-tabstrip-items-wrapper .k-item {
    color: #ff6358;
  }

  width: 100%;
`;

const color_list = [
  "#BFBFBF",
  "#808080",
  "#D6DCE4",
  "#8497B0",
  "#222B35",
  "#D9E1F2",
  "#8EA9DB",
  "#203764",
  "#FCE4D6",
  "#F4B084",
  "#833C0C",
  "#FFF2CC",
  "#FFD966",
  "#806000",
  "#E2EFDA",
  "#A9D08E",
  "#375623",
  "#C00000",
  "#FFC000",
  "#00B050",
  "#00B0F0",
  "#0070C0",
  "#7030A0",
  "#FF00FF",
  "#FF3399",
  "#660033",
];

const bgColor = (color, i) => {
  if (i >= color.length) {
    return color[i % color.length];
  } else {
    return color[i];
  }
};
