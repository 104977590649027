import React, { useEffect, useState } from 'react';
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import axios from 'axios';
import moment from 'moment';
import { filterBy } from "@progress/kendo-data-query";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import _ from 'lodash';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const minValueValidator = value => value >= 0 && value <= 1 ? "" : "할당률은 0이상 1이하로 입력하셔야합니다.";

const NonNegativeNumericInput = fieldRenderProps => {
  const {
    validationMessage,
    visited,
    ...others
  } = fieldRenderProps;
  return <div>
    <NumericTextBox {...others} />
    {visited && validationMessage && <Error>{validationMessage}</Error>}
  </div>;
};

const EditForm = props => {
  const dataCaching = React.useRef([]);
  const pendingRequest = React.useRef();
  const requestStarted = React.useRef(false);
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(props.item.user);
  const [value2, setValue2] = React.useState(props.item.emsRRPlanDA1);
  const [filter, setFilter] = React.useState("");
  const skipRef = React.useRef(0);
  const [Datevalue, setDateValue] = React.useState(new Date(props.item.startDate));
  const [Datevalue2, setDateValue2] = React.useState(new Date(props.item.endDate));



  const resetCach = () => {
    dataCaching.current.length = 0;
  };

  const requestData = React.useCallback(async () => {
    if (requestStarted.current) {
      clearTimeout(pendingRequest.current);
      pendingRequest.current = setTimeout(() => {
        requestData(filter);
      }, 50);
      return;
    }
    

    var getDaysArray = function(start, end) {
      for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
          arr.push(new Date(dt));
      }
      return arr;
  }; 

    let body2 =
    {
      "bpname": "유저 정보",
      "lineitem": "yes",
    }
    const res2 = await axios.post(`/api/getbprecord?path=SKEC0001`, body2,     )

    let body3 =
    {
      "bpname": "Resource Requests (NWF)",
      "lineitem": "yes",
    }
    const res3 = await axios.post(`/api/getbprecord?path=SKEC0001`, body3,   )

    let body4 =
    {
      "bpname": "Job Mapping",
      "lineitem": "no",
    }
    const res4 = await axios.post(`/api/getbprecord?path=SKEC0001`, body4,   )


    const userinfo = res2.data.data.data.filter(v => v.status !== 'Terminated').map((v) => ({ ...v, user: v.emsNameEmploID }))
    .map((v)=>({...v, ...res4.data.data.data.find(vf=>vf['JOBNAME'] === v['JOBNAME'])}))


    const assignedlines = res3.data.data.data.filter(v=> typeof v._bp_lineitems !== 'undefined')
      .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { user: o.emsNameEmploID }))), []).map(v => v.user);


    const assigninfo = res3.data.data.data.filter(v=> typeof v._bp_lineitems !== 'undefined')
      .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { user: o.emsNameEmploID }))), [])
    const user_available = assigninfo.map((v) => ({ user: v.user, availalbefrom: moment(v.emsPPFinish1).add(1, 'days').format('YYYY-MM-dd'), availalbetill: moment(v.emsPPStart1).add(-1, 'days').format('YYYY-MM-dd'), }))
      .filter(v => (moment(v.availalbefrom) >= moment(Datevalue) && moment(v.availalbetill) >= moment(props.item.Datevalue)) || (moment(v.availalbetill) <= moment(Datevalue2) && moment(v.availalbefrom) <= moment(Datevalue2)))



    const usercandidate = userinfo.filter(v => v.SKRoleClass1 === props.item.SKRoleClass1 && v.SKRoleClass2 === props.item.SKRoleClass2)
    const users_new = usercandidate.filter(v => !assignedlines.includes(v.emsNameEmploID))


    const rateDateArray = getDaysArray(props.item.startDate, props.item.endDate).map((v)=>({rate:props.item.emsRRPlanDA1, requestdate:v}))


    const user_available2 = _.differenceBy(assigninfo,user_available, 'user' ).map((v)=>({...v, array:getDaysArray(v.emsPPStart1, v.emsPPFinish1).map((f)=>({rate:v.emsRRPlanDA1, requestdate:f}))}))
    .reduce((c, v) => c.concat(v.array.map(o => Object.assign(o, { user: v.user, "planvalue": _.find(rateDateArray,o.requestdate)['rate'] + o.rate <= 1? 0:-1000000 }))), []);

    
    var user_available_g =
        _(user_available2)
            .groupBy('user')
            .map((objs, key) => ({
                'user': key,
                'value': _.sumBy(objs, 'planvalue'),
            }))
            .value();

    const user_available_rate = user_available_g.filter(v=>v.value >=0)

    
    const user_candidate = _.sortBy([...users_new, ...user_available, ...user_available_rate],'user').map((v)=>({...v, record_no:props.item.record_no}))



    setData(user_candidate.filter(v => v.user.includes(filter)));
    // setTotal(total);

    requestStarted.current = false;

  }, [filter, Datevalue, Datevalue2]);

  React.useEffect(() => {
    requestData();
    return () => {
      resetCach();
    };
  }, [filter, requestData]);



  const onFilterChange = React.useCallback(event => {
    const filter = event.filter.value;
    resetCach();
    requestData(filter);
    setData();
    skipRef.current = 0;
    setFilter(filter);
  }, []);


  const onChange = React.useCallback(event => {
    const value = event.target.value;

    // if (value && value[textField] === emptyItem[textField]) {
    //   return;
    // }

    setValue(value);
  }, []);


  
  const onChange2 = React.useCallback(event => {
    const value = event.target.value;

    // if (value && value[textField] === emptyItem[textField]) {
    //   return;
    // }

    setValue2(value2);
  }, []);




  const changeDate = (event) => {
    setDateValue(event.value);
  };


  const changeDate2 = (event) => {
    setDateValue2(event.value);
  };

  return <Dialog
    //   title={`Edit ${props.item.ProductName}`} 
    onClose={props.cancelEdit}>
    <Form onSubmit={props.onSubmit} initialValues={props.item} render={formRenderProps => <FormElement style={{
      maxWidth: 650
    }}>

      <fieldset className={"k-form-fieldset"}>
        <div className="mb-3">
          <Field name={"startDate"} component={DatePicker} defaultValue={Datevalue} label={"시작일"} onChange={changeDate} />
        </div>

        <div className="mb-3">
          <Field name={"endDate"} component={DatePicker} defaultValue={Datevalue2} label={"종료일"} onChange={changeDate2} />
        </div>
        <div className="mb-3">

            <Field
                  name={"user"}
                  component={ComboBox}
                  data={data !== undefined ? data.map((com) => com.user) : []}
                  value={value}
                  onChange={onChange}
                  label={"할당 자원명"}
                  filterable={true}
                  onFilterChange={onFilterChange}
                />
        </div>
        <div className="mb-3">
        <Field name={"emsRRPlanDA1"} component={NonNegativeNumericInput} validator={minValueValidator} defaultValue={Datevalue2} label={"할당률"} step={0.25}  onChange={onChange2} />
        </div>


      </fieldset>
      <div className="k-form-buttons">
        <button type={"submit"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary" disabled={!formRenderProps.allowSubmit}>
          Update
        </button>
        <button type={"submit"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={props.cancelEdit}>
          Cancel
        </button>
      </div>
    </FormElement>} />
  </Dialog>;
};

export default EditForm;