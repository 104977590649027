import React, { useState, useEffect } from "react";
import _ from "lodash";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Chart,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { generateUpdatedDate } from "../common/datadate";
import EmissionRateComponent from "./EmissionRateComponent";
import YearPicker from "./YearPicker";

const WasteStatus = ({ positiondata, order, blockH }) => {
  const { project_code } = useParams();
  const [BoxHeight, setBoxHeight] = useState(250);
  const [yearData, setYearData] = useState(new Date());
  const [originalData, setOriginalData] = useState({
    total: [],
    project: [],
  });
  const [wasteData, setWasteData] = useState({
    total: 0,
    landfill: 0,
    incineration: 0,
    recycle: 0,
    recycleRate: 0,
    totalRate: 0,
    prevRate: 0,
  });
  const [chartData, setChartData] = useState(chart_data_base);

  useEffect(() => {
    const boxHeight = positiondata[order];

    // setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 35);
  }, [positiondata, order, blockH]);

  useEffect(() => {
    const fetch_data = async () => {
      const request_project_waste_data = await axios
        .get(`/api/getWaste?path=${project_code}`)
        .then((data) => data.data);

      // console.log(request_project_waste_data);

      setOriginalData((prev) => ({ ...prev, ...request_project_waste_data }));
    };
    fetch_data();
  }, [project_code]);

  useEffect(() => {
    const prevYearText = moment(yearData).subtract(1, "years").format("YYYY년");
    const thisYearText = moment(yearData).format("YYYY년");

    const this_year_total_data = originalData.total.filter(
      (c) => c.NEW_ESG_GIVE_DATE_YEAR === thisYearText
    );

    const prev_year_project_data = originalData.project.filter(
      (c) => c.NEW_ESG_GIVE_DATE_YEAR === prevYearText
    );

    const this_year_project_data = originalData.project.filter(
      (c) => c.NEW_ESG_GIVE_DATE_YEAR === thisYearText
    );

    const this_year_total_data_sum = _.sumBy(
      this_year_total_data,
      "NEW_ESG_GIVE_QUNT"
    );

    const prev_year_project_data_sum = _.sumBy(
      prev_year_project_data,
      "NEW_ESG_GIVE_QUNT"
    );

    const this_year_project_data_sum = _.sumBy(
      this_year_project_data,
      "NEW_ESG_GIVE_QUNT"
    );

    const this_year_waste_type_sum = this_year_project_data.reduce(
      (acc, cur) => {
        const { ESG_TRTM_WAYS, NEW_ESG_GIVE_QUNT } = cur;
        if (ESG_TRTM_WAYS.includes("매립")) {
          acc.landfill = acc.landfill + NEW_ESG_GIVE_QUNT;
        }
        if (ESG_TRTM_WAYS.includes("소각")) {
          acc.incineration = acc.incineration + NEW_ESG_GIVE_QUNT;
        }
        if (
          !ESG_TRTM_WAYS.includes("매립") &&
          !ESG_TRTM_WAYS.includes("소각")
        ) {
          acc.recycle = acc.recycle + NEW_ESG_GIVE_QUNT;
        }
        return acc;
      },
      {
        landfill: 0,
        incineration: 0,
        recycle: 0,
      }
    );

    const chart_data = chart_data_base.map((c) => {
      const { cate } = c;

      const find_target_month_data = this_year_project_data.filter(
        (c2) => c2.NEW_ESG_GIVE_DATE_MONTH === cate
      );

      const waste_cate_sum = find_target_month_data.reduce(
        (acc, cur) => {
          const { ESG_WSTE_CATE, NEW_ESG_GIVE_QUNT } = cur;

          if (ESG_WSTE_CATE === "사업장폐기물") {
            acc.business = acc.business + NEW_ESG_GIVE_QUNT;
          }
          if (ESG_WSTE_CATE === "지정폐기물") {
            acc.appoint = acc.appoint + NEW_ESG_GIVE_QUNT;
          }
          if (
            !(ESG_WSTE_CATE === "사업장폐기물") &&
            !(ESG_WSTE_CATE === "지정폐기물")
          ) {
            acc.con = acc.con + NEW_ESG_GIVE_QUNT;
          }
          return acc;
        },
        {
          con: 0,
          business: 0,
          appoint: 0,
        }
      );

      return {
        ...c,
        con: returnZeroIfInvalid(Number(waste_cate_sum.con.toFixed(0))),
        business: returnZeroIfInvalid(
          Number(waste_cate_sum.business.toFixed(0))
        ),
        appoint: returnZeroIfInvalid(Number(waste_cate_sum.appoint.toFixed(0))),
      };
    });

    setWasteData((prev) => ({
      ...prev,
      total: this_year_project_data_sum,
      ...this_year_waste_type_sum,
      recycleRate: returnZeroIfInvalid(
        Number(
          (
            (this_year_waste_type_sum.recycle / this_year_project_data_sum) *
            100
          ).toFixed(0)
        )
      ),
      totalRate: returnZeroIfInvalid(
        Number(
          (
            (this_year_project_data_sum / this_year_total_data_sum) *
            100
          ).toFixed(0)
        )
      ),
      prevRate: returnZeroIfInvalid(
        Number(
          (
            (this_year_project_data_sum / prev_year_project_data_sum) *
            100
          ).toFixed(0)
        )
      ),
    }));

    setChartData(chart_data);
  }, [originalData, yearData]);

  const seriesLabels = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Pretendard-R",
    position: "top",
    background: "none",
    rotation: { angle: "auto" },
    // content: labelContent2,
  };

  const legendlabel = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Pretendard-R",
    background: "none",
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const SharedTooltip = (props) => {
    const { points } = props;

    return (
      <div>
        <div>{points[0].category}</div>
        {points.map((point, index) => (
          <div key={index}>
            {point.value === 0 ? (
              <span>{point.series.name} : 0 ton</span>
            ) : (
              <span>
                {point.series.name} :{" "}
                {Number(point.value).toLocaleString() + " ton"}
              </span>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <GridLayout
      style={{ height: BoxHeight, position: "relative" }}
      rows={[
        {
          height: BoxHeight - 80,
        },
        {
          height: 20,
        },
        {
          height: 20,
        },
        {
          height: 40,
        },
      ]}
      cols={[
        {
          width: 80,
        },
        {
          width: "calc((100% - 80px) / 10 * 2.5) ",
        },
        {
          width: "calc((100% - 80px) / 10 * 2.5) ",
        },
        {
          width: "calc((100% - 80px) / 10 * 5) ",
        },
      ]}
      gap={{
        rows: 0,
        cols: 0,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: -52,
          right: -10,
        }}
      >
        <YearPicker value={yearData} setValue={setYearData} />
      </div>
      <GridLayoutItem
        row={1}
        col={1}
        colSpan={4}
        // className="envCardCenterStyle"
      >
        <Chart>
          <ChartArea height={BoxHeight - 80} />
          <ChartLegend position="bottom" labels={legendlabel} />
          <ChartTooltip shared={true} render={sharedTooltipRender} />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              maxDivisions={10}
              startAngle={45}
              labels={seriesLabels}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              labels={{ font: "0.7rem Pretendard-R" }}
              majorGridLines={{ step: 10 }}
              minorGridLines={{ step: 10 }}
            />
          </ChartValueAxis>

          <ChartSeries>
            <ChartSeriesItem
              type="column"
              data={chartData}
              field="con"
              stack={true}
              categoryField="cate"
              color={"#5B9BD5"}
              name="건설"
            />
            <ChartSeriesItem
              type="column"
              data={chartData}
              field="business"
              stack={true}
              categoryField="cate"
              color={"#ED7D32"}
              name="사업장"
            />
            <ChartSeriesItem
              type="column"
              data={chartData}
              field="appoint"
              stack={true}
              categoryField="cate"
              color={"#A5A5A5"}
              name="지정"
            />
          </ChartSeries>
        </Chart>
      </GridLayoutItem>
      <GridLayoutItem
        row={2}
        rowSpan={3}
        col={1}
        className="envCardCenterStyle"
      >
        <div className="envBoldTextStyle" style={{ textAlign: "center" }}>
          폐기물
          <br />
          처리현황
          <br />({moment(yearData).format("YY")}년)
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        row={2}
        rowSpan={3}
        col={2}
        className="envCardCenterStyle"
        style={{ flexDirection: "column", lineHeight: 2 }}
      >
        <div className="envSpaceBetween">
          <div className="envNormalTextStyle">• 매립</div>
          <div className="envNormalTextStyle">
            {Number(wasteData.landfill.toFixed(0)).toLocaleString()} ton
          </div>
        </div>
        <div className="envSpaceBetween">
          <div className="envNormalTextStyle">• 소각</div>
          <div className="envNormalTextStyle">
            {Number(wasteData.incineration.toFixed(0)).toLocaleString()} ton
          </div>
        </div>
        <div className="envSpaceBetween">
          <div className="envNormalTextStyle">• 재활용</div>
          <div className="envNormalTextStyle">
            {Number(wasteData.recycle.toFixed(0)).toLocaleString()} ton
          </div>
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        row={2}
        rowSpan={2}
        col={3}
        className="envCardCenterStyle"
      >
        <div className="envBoldTextStyle">Recycle Rate</div>
      </GridLayoutItem>
      <GridLayoutItem row={4} col={3} className="envCardCenterStyle">
        <div className="envPercentStyle">{wasteData.recycleRate}%</div>
      </GridLayoutItem>
      <GridLayoutItem
        row={2}
        rowSpan={4}
        col={4}
        className="envCardCenterStyle"
        style={{ justifyContent: "flex-end", alignItems: "flex-end" }}
      >
        <EmissionRateComponent
          subTitleBoolean={false}
          year={moment(yearData).format("YY")}
          total={wasteData.totalRate}
          project={wasteData.prevRate}
          type={"waste"}
        />
      </GridLayoutItem>
    </GridLayout>
  );
};

export default WasteStatus;

const chart_data_base = [
  { cate: "01월", con: 0, business: 0, appoint: 0 },
  { cate: "02월", con: 0, business: 0, appoint: 0 },
  { cate: "03월", con: 0, business: 0, appoint: 0 },
  { cate: "04월", con: 0, business: 0, appoint: 0 },
  { cate: "05월", con: 0, business: 0, appoint: 0 },
  { cate: "06월", con: 0, business: 0, appoint: 0 },
  { cate: "07월", con: 0, business: 0, appoint: 0 },
  { cate: "08월", con: 0, business: 0, appoint: 0 },
  { cate: "09월", con: 0, business: 0, appoint: 0 },
  { cate: "10월", con: 0, business: 0, appoint: 0 },
  { cate: "11월", con: 0, business: 0, appoint: 0 },
  { cate: "12월", con: 0, business: 0, appoint: 0 },
];

const returnZeroIfInvalid = (value) => {
  if (value === Infinity || isNaN(value) || value === false) {
    return 0;
  }
  return value;
};
