import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import * as mnode from '../../nodelibrary'
import axios from 'axios';
import {
    Card,
    CardTitle,
    CardBody,
    GridLayout, GridLayoutItem
} from "@progress/kendo-react-layout";
import { ListView } from '@progress/kendo-react-listview';
import './bootstrap.min.css'
import { useTranslation } from 'react-i18next';
import { generateUpdatedDate } from '../../common/datadate';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const PTW = ({ filter, projeclist, BoxHeight }) => {
    const { t, i18n } = useTranslation();

    const [safefolderlist, setsafefolderlist] = useState([]);
    const [safefilelist, setsafefilelist] = useState([]);
    const [safetodayfolderlist, setsafetodayfolderlist] = useState([]);

    const getFolder = () => {
        mnode.getFolderList(`/${REACT_APP_PATH2}/file/safetyhistory/`, setsafefolderlist);
        mnode.getFileFolderList(`/${REACT_APP_PATH2}/file/safety/`, setsafetodayfolderlist);
    }

    useEffect(getFolder, []);

    useEffect(() => {
        const fetchData = async () => {

            let safefileslists = [];
            for (let i of safefolderlist) {
                const ressafeh = await axios.get(`/api/getFileFolderList?path=/${REACT_APP_PATH2}/file/safetyhistory/${i}`)

                // if (res.data.length >0){
                const safefiles = ressafeh.data.fileList.map((v) => { return { project: i, files: v.name } })
                safefileslists.push(...safefiles)
            }

            setsafefilelist(safefileslists.filter(v => v.project !== '18154D' && v.project !== '20027D' && v.project !== '21036D' && v.project !== '21077D'))
        }

        fetchData();
    }, [safefolderlist]);

    const safehistoylist = useMemo(() => safefilelist, [safefilelist]);

    const [safetyddata, setsafetyddata] = useState([]);
    const [listviewdata, setlistdata] = useState([]);


    useEffect(() => {
        const fetchData = async () => {

            // let safetyharray = [];
            // for (const i of safehistoylist) {
            //     const safetyres = await axios.get(`/api/getJSONtotal?path=/${REACT_APP_PATH2}/file/safetyhistory/${i.project}/${i.files}`)
            //     const safeinfo = safetyres.data.map((v) => {
            //         return {
            //             WorkType:
            //                 i.project === '22105D' || i.project === '22106D' ? v.d_cwp_check_list_dpk : v.asWorkType, WorkType2: i.project === '22105D' || i.project === '22106D' ? '' : v.asTargetHazardNM, IssueDate: i.project === '22105D' || i.project === '22106D' ? v.d_work_start.slice(0, 10) : v.asWorkDate.slice(0, 10),
            //             projectcode: i.project, count: 1,
            //             type: (i.project === '22105D' && v.d_cwp_check_list_dpk === 'General Work Permit') || (i.project === '22106D' && v.d_cwp_check_list_dpk === 'General Work Permit') || (i.project !== '22106D' && i.project !== '22105D' && v.asWorkType !== '특별 유해위험') ? '일반' : '특별',
            //             workercount: i.project === '22105D' || i.project === '22106D' ? Number(v.d_number_of_person) : Number(v.asWorkNo),
            //             record_no: v.record_no,

            //         }
            //     })

            //     safetyharray.push(...safeinfo)
            // }

            let safetytarray = [];
            for (const j of _.map(safetodayfolderlist.fileList, function (o) { return o.name }).filter(v => v.split('_')[0] !== '18154D' && v.split('_')[0] !== '20027D' && v.split('_')[0] !== '21036D' && v.split('_')[0] !== '21077D')) {
                const safetytres = await axios.get(`/api/getJSONtotal?path=/${REACT_APP_PATH2}/file/safety/${j}`)
                const safelatestinfo = safetytres.data.map((v) => {
                    return {
                        WorkType: j.slice(0, 6) === '22105D' || j.slice(0, 6) === '22106D' ? v.d_cwp_check_list_dpk : v.asWorkType,
                        WorkType2: j.slice(0, 6) === '22105D' || j.slice(0, 6) === '22106D' ? '' : v.asTargetHazardNM, IssueDate: j.slice(0, 6) === '22105D' || j.slice(0, 6) === '22106D' ? v.d_work_start.slice(0, 10) : v.asWorkDate.slice(0, 10), projectcode: j.slice(0, 6), count: 1
                        , type: (j.slice(0, 6) === '22105D' && v.d_cwp_check_list_dpk === 'General Work Permit') || (j.slice(0, 6) === '22106D' && v.d_cwp_check_list_dpk === 'General Work Permit') || (j.slice(0, 6) !== '22106D' && j.slice(0, 6) !== '22105D' && v.asWorkType === "일상 유해위험") || (j.slice(0, 6) !== '22106D' && j.slice(0, 6) !== '22105D' && v.asWorkType === "중점 유해위험") ? '일반' : '특별',
                        workercount: j.slice(0, 6) === '22105D' || j.slice(0, 6) === '22106D' ? Number(v.d_number_of_person) : Number(v.asWorkNo),
                        record_no: v.record_no,
                    }
                })

                safetytarray.push(...safelatestinfo)
            }


            const lastdata = _.uniqBy([...safetytarray].map((v) => ({ ...v, uniq: v.record_no + v.projectcode })), 'uniq').map((v) => ({ ...v, ...projeclist.find(sp => sp.ugenProjectNumber === v.projectcode) }))
            .filter(v=>v.division&&v.division.includes(filter))

            setsafetyddata(lastdata)

            var listdata_division =
                _(lastdata.filter(v => v.division.includes(filter)))
                    .groupBy('division')
                    .map((objs, key) => ({
                        'group': key,
                        '일반': _.filter(objs.filter(v=>v.type==='일반'), { 'division': key }).length,
                        '특별':_.filter(objs.filter(v=>v.type==='특별'), { 'division': key }).length, 
                    }))
                    .value();

            var listdata_pjt =
                _(lastdata.filter(v => v.division.includes(filter)))
                    .groupBy('ugenProjectName')
                    .map((objs, key) => ({
                        'group': key,
            
                        '일반':_.filter(objs.filter(v=>v.type==='일반'), { 'ugenProjectName': key }).length, 
                        '특별':_.filter(objs.filter(v=>v.type==='특별'), { 'ugenProjectName': key }).length, 
                    }))
                    .value();

            setlistdata(filter === '' ? listdata_division : listdata_pjt)

        }

        fetchData();
    }, [safehistoylist, safetodayfolderlist, projeclist, filter]);


    const MyItemRender = props => {
        let item = props.dataItem;
        return <div className='row p-2 border-bottom align-middle' style={{
            margin: 0, verticalAlign:'', borderBottom:'1px solid lightgray', padding:'5px 0 5px 0'
        }}>

            <div className='col-6' style={{paddingLeft:0, paddingRight:0}}>
                <h2 style={{
                    fontSize: 14,
                    color: '#707070',margin: 0
                }} >{i18n.language!=="ko"&& item.group ==="수소친환경"? "H2/Recycling":item.group}</h2>

            </div>
            <div className='col-6'>
                        <span style={{color: '#FA7A35'}}>{item.특별}</span> {t("개")} / <span>{item.일반} </span>{t("개")}
            </div>
        </div>;
    };

    return (
        <>
            <Card style={{ height: (BoxHeight - 70) / 2, margin: '12px', fontSize: '12px' }}>
                <CardTitle style={{ height: '35px', margin: '6px 0px 0px 12px', fontSize: '0.9rem', fontWeight: '600', color: '#363945', zIndex: '9999999' }}>
                    <span style={{ float: 'left' }}>{t("위험 작업 현황")}<span style={{fontSize:'smaller'}}> ({t("작업허가서 기준")})</span></span>
                    <span style={{ position: 'absolute', fontSize:"0.8rem" , fontWeight: '500', right: '12px' }}>{generateUpdatedDate(new Date())}</span>
              
                </CardTitle>
                <CardBody style={{ height: ((BoxHeight - 70) / 2) - 47, margin: '12px' }}>
                    <GridLayout style={{ width: '100%', height: ((BoxHeight - 70) / 2) - 47 }} rows={[
                        {
                            height: '100%',
                        },



                    ]}
                        cols={[
                            {
                                width: '50%',
                            },    {
                                width: '50%',
                            },


                        ]}
                        gap={{
                            rows: 0,
                            cols: 0,
                        }}>
                        <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', width:'100%' }}>
                                <div><span style={{ color: '#FA7A35' }}>{t("특별위험작업")}</span> / <span style={{ color: '#707070' }}>{t("중점위험작업")}</span></div>
                                <div style={{ fontSize: '2rem' }}><span style={{ color: '#FA7A35' }}>{_.sumBy(safetyddata.filter(v => v.type === '특별'), 'count').toLocaleString()}</span><span style={{ fontSize: '1rem' }}>{t("개")}</span> / <span style={{ color: '#363945' }}>{_.sumBy(safetyddata.filter(v => v.type !== '특별'), 'count').toLocaleString()}</span><span style={{ fontSize: '1rem' }}>{t("개")}</span></div>
                            </div>
                        </GridLayoutItem>
                        <GridLayoutItem row={1} col={2} style={{ position: 'relative' }}>
                            <ListView data={listviewdata} item={MyItemRender} style={{ width: "100%" ,position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center'}} />
                        </GridLayoutItem>

                    </GridLayout>
                </CardBody>
            </Card>
        </>
    );
}

export default PTW;