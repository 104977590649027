import React, { useState, useEffect } from 'react';
import { Paper, Button, Typography, TextField, List, ListItem, ListItemText, Box, Select, MenuItem, FormControl } from '@mui/material';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import axios from "axios";
import dayjs from 'dayjs';
import { useFormControl } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

const InfoBoxSquad = ({ group, duedate, documentList, SQDTitle, onSelectDocument, autoseq, recordNo }) => {
  const [selectedDocument, setSelectedDocument] = useState('');
  const [selectedStatus, setSelectedStatus] = useState({});
  // const [comment, setComment] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { project_code } = useParams();
  const [codeList, setCodeList] = useState([]);
  const [comments, setComments] = useState({}); // This will hold comments keyed by document ID

  const newDocumentList = documentList && documentList.map(doc => {
    return {
      ...doc,
      status: selectedStatus[doc.id] || doc.docuStatus.find(vf => vf.disiciple === group)?.documentstatus || 'No selection', // selectedStatus에 doc.id가 없다면 'No selection'을 기본값으로 사용
      comments: comments[doc.id] || doc.docuStatus.find(vf => vf.disiciple === group)?.comment || 'No Comments' // selectedStatus에 doc.id가 없다면 'No selection'을 기본값으로 사용
    };
  });


  useEffect(() => {
    // 초기 선택 및 버튼 활성화 상태 검사
    if (documentList && documentList.length > 0 && !selectedDocument) {
      setSelectedDocument(documentList[0].id);
      onSelectDocument(documentList[0].id, documentList[0].title);
    }
    checkAllConditions();
  }, [documentList, selectedStatus]);

  useEffect(() => {
    const fetchData = async () => {
      if (project_code) {
        const res = await axios.get(`/api/getsquadcode?path=${project_code}`);
        if (res.data.success) {
          setCodeList(res.data.data.data.map(v => v.SKDMApprovalDecision))
        }
      }
    }
    fetchData()
  }, [project_code])

  const handleListItemClick = (event, id, title) => {
    setSelectedDocument(id);
    onSelectDocument(id, title);
  };

  const handleStatusChange = (event, id) => {
    setSelectedStatus({ ...selectedStatus, [id]: event.target.value });
  };


  // const handleCommentChange = (event) => {
  //   setComment(event.target.value);
  // };

  // 모든 조건을 확인하는 함수
  const checkAllConditions = (status = selectedStatus) => {
    const allDocumentsSelected = documentList && documentList.every(doc => status[doc.id]);
    // const isCommentValid = newComment.trim() !== '';
    setIsButtonDisabled(!(allDocumentsSelected));
  };

  const handleSubmitReview = async () => {
    const willSend = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to send the data?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, send it!'
    });

    if (willSend.isConfirmed) {
      const bodyData = {
        group: group,
        // comment: comment,
        consolidated: newDocumentList.map(v => v.title + "(" + v.status + ")\n" + "comment: " + v.comments).join("\n\n"),
        autoseq: autoseq,
        record: recordNo
      };

      try {
        const response = await axios.post(`/api/updatesqd?project=${project_code}`, bodyData);

        if (response.data.success) {
          // If the server responded with success true, show a success message
          await Swal.fire({
            title: 'Success!',
            text: 'Your data has been successfully sent.',
            icon: 'success',
            confirmButtonText: 'OK'
          });
        } else {
          // If the server responded with success false, show the error message(s)
          await Swal.fire({
            title: 'Failed to send data',
            text: response.data.message,
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        // Handle any errors that occur during the POST request
        console.error('An error occurred:', error);
        await Swal.fire({
          title: 'Error!',
          text: 'An unexpected error occurred: ' + error.message,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    }
  };

  const isDuedatePassed = () => {
    const today = new Date();
    const dueDate = new Date(dayjs(duedate, "YYYYMMDD").format("YYYY-MM-DD"));
    return dueDate < today;
  };

  function MyFormHelperText() {
    const { focused } = useFormControl() || {};

    const helperText = React.useMemo(() => {
      if (focused) {
        return 'This field is being focused';
      }

      return ' Input Comments';
    }, [focused]);

    return <FormHelperText sx={{marginLeft:1}}>{helperText}</FormHelperText>;
  }

  function MyFormHelperText2() {
    const { focused } = useFormControl() || {};

    const helperText = React.useMemo(() => {
      if (focused) {
        return 'This field is being focused';
      }

      return 'Select Status';
    }, [focused]);

    return <FormHelperText sx={{marginLeft:1}}> {helperText}</FormHelperText>;
  }

  return (
    <Paper elevation={3} style={{ padding: '16px', width: '500px', height: '100vh', backgroundColor: '#f5f5f5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box style={{ marginBottom: '16px' }}>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>{recordNo}</Typography>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>{SQDTitle}</Typography>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>{group}</Typography>
        <Typography
          variant="subtitle1"
          style={{ color: isDuedatePassed() ? '#CD212A' : 'black' }}
        >
          Due Date: {duedate && dayjs(duedate, "YYYYMMDD").format('YYYY년 MM월 DD일')}
        </Typography>
      </Box>

      <List component="nav" aria-label="document list" sx={{ height: "73vh", overflowY: 'auto' }}>
        {documentList && documentList
          .map((v) => ({ ...v, newTitle: v.docuNo + "\n" + v.docuTitle + "\n" + v.docuRev }))
          .map((doc) => {
            // disiciple이 group과 일치하는 documentstatus 값을 찾습니다.
            const defaultStatus = doc.docuStatus.find(vf => vf.disiciple === group)?.documentstatus || 'No selection';
            const defaultComments = doc.docuStatus.find(vf => vf.disiciple === group)?.comment || 'No Comments';

            return (
              <>
                <ListItem
                  button
                  key={doc.id}
                  selected={selectedDocument === doc.id}
                  onClick={(event) => handleListItemClick(event, doc.id, doc.title)}
                >
                  <ListItemText
                    primary={<React.Fragment>
                      <Typography component="span" variant="body2" color="text.primary">
                        No: {doc.docuNo}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="text.primary">
                        Title: {doc.docuTitle}
                      </Typography>
                      <br />
                      Rev: {doc.docuRev}
                    </React.Fragment>}
                    //  secondary={(selectedStatus[doc.id] || defaultStatus)}
                    secondary={
                      <React.Fragment>
                        <Typography component="span" variant="body2" color={selectedDocument === doc.id ? '#34568B' : 'black'}>
                          Status: {selectedStatus[doc.id] || defaultStatus}
                        </Typography>
                        <br />
                        <Typography component="span" variant="body2" color={selectedDocument === doc.id ? '#34568B' : 'black'}>
                          Comments: {comments[doc.id] || defaultComments}
                        </Typography>
                      </React.Fragment>
                    }


                  />


                </ListItem>
                {selectedDocument === doc.id && (
                  <>
                    <br />
                    <FormControl fullWidth>

                      <Select
                        value={selectedStatus[doc.id] || defaultStatus}
                        onChange={(event) => handleStatusChange(event, doc.id)}
                        size="small"
                        displayEmpty
                      >
                        {codeList.map((v, index) =>
                          <MenuItem key={index} value={v}>{v}</MenuItem>
                        )}
                      </Select>
                    <MyFormHelperText2 />

                    </FormControl>
                    <br />
                    <br />
                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      rows={2}
                      maxRows={4}
                      value={comments[doc.id] ?? (defaultComments === "No Comments" ? "" : defaultComments)}
                      onChange={(e) => setComments({ ...comments, [doc.id]: e.target.value })}
                    // onBlur={() => setEditableDocId(null)} // Optional: close edit mode on blur
                    />
                    <MyFormHelperText />
                  </>
                )}
              </>
            );
          })}

      </List>
      <Box sx={{ padding: '8px', height: "15vh" }}>
        {/* <FormControl fullWidth >
          <Select
            className='decision'
            value={comment}
            onChange={handleCommentChange}
            // size="small"
            displayEmpty
            label="도서 전체에 대한 결정 사항"

          >
            {codeList.map((v, index) =>
              <MenuItem key={index} value={v}>{v}</MenuItem>
            )}
          </Select>
        </FormControl> */}
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            size="small"
            // disabled={isButtonDisabled}
            onClick={handleSubmitReview}
          >
            Submit Review
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default InfoBoxSquad;
