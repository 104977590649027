import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem
} from "@progress/kendo-react-layout";
import 'hammerjs';
import { Chart, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartLegend, ChartValueAxisItem, ChartTooltip,  ChartArea, ChartCategoryAxis, ChartCategoryAxisItem } from "@progress/kendo-react-charts";
import 'hammerjs';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { generateUpdatedDate } from '../common/datadate';

const {REACT_APP_URI2,} = process.env;


const Sinmungo = ({  positiondata, order, blockH  }) => {
    const [BoxHeight, setBoxHeight] = useState(250);
    const { project_code } = useParams();
    const { t } = useTranslation();
 

    useEffect(() => {

        const boxHeight = positiondata[order];
        setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
    }, [positiondata, order, blockH]);


    const [data, setdata] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "안전신문고",
                "lineitem": "no",
                "record_fields": "asBPName;",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
         
                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        },
                      
                    ]

                }
            }
            const res = await axios.post(`/api/getbprecord?path=${project_code}`, body)
            setdata(res.data.data.data)

        };

        fetchData();
    }, [project_code]);


    const chartdata =
        _(_.filter(data, function (o) { return o.asBPName !== null; }))
            .groupBy('asBPName')
            .map((objs, key) => ({
                'vendor': key.slice(0,5),
                'count': _.filter(objs, { 'asBPName': key }).length

            }))
            .value();


    const axisCrossingValue = [0, chartdata.length];

    const labelContent2 = (e) => e.value === 0 || e.value === null || typeof e.value === 'undefined' ? '' : e.value;
    const seriesLabels2 = {
        visible: true,
        // Note that visible defaults to false
        padding: 3,
        font: "bold 0.8rem Arial, sans-serif",
        position: 'insideBase',
        background: 'none',
        content: labelContent2
    };

    return (
        <>
            <GridLayout style={{ height: BoxHeight }}
                rows={[
                    {
                        height: '100%',
                    }
                ]}

                cols={[
                    {
                        width: '40%',
                    },
                    {
                        width: '60%',
                    },
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>
                              <div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>
          {generateUpdatedDate(new Date())}
        </div>
                <GridLayoutItem row={1} col={1} style={{ position: 'relative', fontSize: '2rem' }}>
                    <span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>{data.length}<span style={{ fontSize: '1rem' }}>{t("건")}</span></span>
                </GridLayoutItem>
                <GridLayoutItem row={1} col={2} >
                    <Chart>
                        {/* <ChartTitle text='TOP 3' font="0.9rem KoPub" /> */}
                        <ChartArea height={BoxHeight * 1.1} />
                        <ChartLegend position="bottom" orientation="horizontal" />

                        <ChartTooltip />

                        <ChartSeries>

                            <ChartSeriesItem
                                // color='#E9897E'
                                type="column"
                                data={chartdata}
                                field="count"
                                categoryField="vendor"
                                name={t("발행건수")}
                                autoFit={true}
                                labels={seriesLabels2}
                            />

                        </ChartSeries>
                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem labels={{ font: "0.6rem Arial, KoPub", rotation: 'auto' }} axisCrossingValue={axisCrossingValue} majorGridLines={{ step: 100 }} minorGridLines={{ step: 100 }} >
                            </ChartCategoryAxisItem>
                        </ChartCategoryAxis>

                        <ChartValueAxis>
                            <ChartValueAxisItem visible={false} majorGridLines={{ step: 100 }} minorGridLines={{ step: 100 }} ></ChartValueAxisItem>
                        </ChartValueAxis>

                    </Chart>

                </GridLayoutItem>

            </GridLayout>
        </>
    )
}

export default Sinmungo