import React, { useEffect, useState } from 'react';
import krMessages from "./kr.json";
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem
} from "@progress/kendo-react-layout";
import { Chart, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip, ChartArea,ChartCategoryAxis, ChartCategoryAxisItem} from "@progress/kendo-react-charts";
import 'hammerjs';
import { ResponsivePie } from '@nivo/pie';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import { DateRangePicker  } from "@progress/kendo-react-dateinputs";
import moment from 'moment';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
import { useTranslation } from 'react-i18next';
load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);
loadMessages(krMessages, "ko-KR");

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const Check = ({ positiondata , order, blockH}) => {

const { project_code } = useParams();
    
  const [data, setdata] = useState([]);

  useEffect(() => {
    const fetchData = async () => {

      const requestArr3 = await axios.get(
        `/api/getJSON?path=/${REACT_APP_PATH2}/file/qualitydaily/check/${project_code}.json`,
      );
      const reslist3 = requestArr3.data;

      setdata(reslist3);
    };

    fetchData();
  }, [project_code]);


    const defaultvalue = 
    {
        start:new Date(Date.now() - 864000000),
        end: new Date(moment().add(-1, 'days')),

      };
    const [value, setValue] = useState(defaultvalue);
    const { t, i18n } = useTranslation();

    
    var getDaysArray = function (start, end) {
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    };

    var checkdatau =
  _.uniqBy(
    _.sortBy(
        data.map((v) => ({
        ...v,
        order:
          v.record_no +
          v.uuu_record_last_update_date.slice(6, 10) +
          v.uuu_record_last_update_date.slice(0, 2) +
          v.uuu_record_last_update_date.slice(3, 5) +
          v.uuu_record_last_update_date.slice(11, 19),
      })),
      "order"
    ).reverse(),
    "record_no"
  )
    .filter((v) => v.status !== "Terminated"&& v.InspItem250 !== "Test" && v.InspItem250 !== "Terminated" && !v.status.includes('Rejected'))

    var daylist = getDaysArray(new Date(value.start), new Date(value.end)).map(v => moment(v).format("MM-DD-YYYY"));

    const data_fitler = _.filter(checkdatau, function (o) { return  daylist.includes(o.d_inspection_date.slice(0,10))  })

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const max = new Date(moment().add(-1, 'days'));

    const [BoxHeight, setBoxHeight] = useState(250);
    const [vendorname, setvendorname] = useState([]);
  
  
    useEffect(() => {
      const fetchData = async () => {
  
  
        let body =
        {
          "bpname": "Business Partner 계약현황 (WF)",
          "lineitem": "no",
        }
  
        const res = await axios.post(`/api/getbprecord?path=${project_code}`, body)
        setvendorname(res.data.data.data.map((v)=>({fullname:v.d_subcontractor_name, shortname:v.d_company_name_short})))
      };
  
      fetchData();
    }, [project_code]);

    useEffect(() => {
        const boxHeight = positiondata[order];
        setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
    }, [positiondata, order, blockH]);

    const chartdata = 
    _(data_fitler.filter(v=>v.d_test_result_spd !== null))
    .groupBy('d_test_result_spd')
    .map((objs, key) => ({
        'id': i18n.language === "ko"? key: key === "승인"?"Approved":"Cancelled",
        'value': _.filter(objs, { 'd_test_result_spd': key }).length
    

    }))
    .value();


    const chartdata2 =  
    _(data_fitler.filter(v=>v.d_test_result_spd !== null).map((v)=>({...v, uniq:v.Subcontractor_dpk+"//"+v.d_test_result_spd})))
    .groupBy('uniq')
    .map((objs, key) => ({
        'vendor': key.split('//')[0], 
        'status':key.split('//')[1],
        'count': _.filter(objs,{'uniq':key}).length  
     
    }))
    .value()

    const chartresult = chartdata2.map(function (obj) {
        var index = obj.status;
        obj[index] = obj.count;
        return obj;
    })

    const statuslist = _.uniqBy(chartresult, 'status')

    
    const labelContent2 = (e) => e.value ===0 || e.value === null || typeof e.value === 'undefined'? '' :  e.value;
    const categorylabelcon = (e) => vendorname.find(v=>v.fullname === e.value) && vendorname.find(v=>v.fullname === e.value)['shortname'] && vendorname.find(v=>v.fullname === e.value)['shortname'] !==''?vendorname.find(v=>v.fullname === e.value)['shortname'] : e.value;


    const seriesLabels2 = {
        visible: true,
        // Note that visible defaults to false
        padding: 3,
        font: "bold 0.8rem Arial, sans-serif",
        position: 'insideBase',
        background: 'none',
        content: labelContent2
    };

    const categorylabels = {
        visible: true,
        // Note that visible defaults to false
        padding: 3,
        font: "0.8rem Arial, sans-serif",
        // position: 'insideBase',
        background: 'none',
        rotation:{angle:'auto'},
        content: categorylabelcon
    };
  
  
    const SharedTooltip = (props) => {
        const { points } = props;
        return (
            <div>
                <div>{points[0].category}</div>
                {points.map(function(point, index) { 
                    return (point.series.field === 'count')?
                        <div key={index}>
                            {point.series.name} 합계: {point.value} 
                        </div> 
                        :
                        <div key={index}>
     {i18n.language === "ko"? point.series.field: point.series.field === "승인"? "Apporved":"Cancelled"}: {point.value} 
                        </div> 
                }
                
                )}
            </div>
        );
    };

    const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

    const COLORS = ['rgb(247, 251, 255)', 'rgb(222, 235, 247)' ]


    return(
    <>
         <GridLayout style={{ height: BoxHeight }}
                rows={[
                    {
                        height: '100%',
                    }
                ]}

                cols={[
                    {
                        width: '35%',
                    },
                    {
                        width: '65%',
                    },
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>
                <div style={{ position: 'absolute', top: '-10px', right: '235px' }}>
                    <LocalizationProvider language={i18n.language ==="ko"?"ko-KR":"en"}>
                        <IntlProvider locale={i18n.language ==="ko"?"ko-KR":"en"}>
                            <div className="datepick"><DateRangePicker max={max} format={i18n.language ==="ko" ?"yy년 MM월 dd일":"MMM dd yyyy"} defaultValue={defaultvalue} defaultShow={false} onChange={handleChange} value={value} /></div>
                        </IntlProvider>
                    </LocalizationProvider>
                </div>
                <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
                <ResponsivePie
                        className='pie'
                        data={chartdata}
                        margin={{ top: 40, right: 10, bottom: 20, left: 10 }}
                        valueFormat=" >-"
                        cornerRadius={2}
                        innerRadius={0.7}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.2
                                ]
                            ]
                        }}
                        colors={COLORS}                        
                        arcLinkLabel={function (e) { return e.id + "(" + ((e.value / _.sumBy(chartdata,'value')) * 100).toFixed(0) + "%)" }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextOffset={5}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsDiagonalLength={5}
                        arcLinkLabelsStraightLength={5}
                        arcLinkLabelsColor={{ from: 'color' }}
                        enableArcLabels={true}
                        arcLabel={function (e) { return e.value }}
                        arcLabelsRadiusOffset={0.5}
                        arcLabelsSkipAngle={0}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    2
                                ]
                            ]
                        }}

                        // legends={[
                        //     {
                        //         anchor: 'top',
                        //         direction: 'row',
                        //         justify: false,
                        //         translateX: 0,
                        //         translateY: -30,
                        //         itemsSpacing: 0,
                        //         itemWidth: 100,
                        //         itemHeight: 18,
                        //         itemTextColor: '#999',
                        //         itemDirection: 'left-to-right',
                        //         itemOpacity: 1,
                        //         symbolSize: 18,
                        //         symbolShape: 'circle',
                        //         effects: [
                        //             {
                        //                 on: 'hover',
                        //                 style: {
                        //                     itemTextColor: '#000'
                        //                 }
                        //             }
                        //         ]
                        //     }
                        // ]}
                    />
                </GridLayoutItem>
                <GridLayoutItem row={1} col={2} style={{ position: 'relative' }}>
                <Chart>
                        <ChartArea height={BoxHeight}  margin={20}/>
                        <ChartTooltip shared={true} render={sharedTooltipRender} />
                        <ChartSeries>
                            {statuslist.map((v, index) => (
                            <ChartSeriesItem key={index} type="column" stack={true} data={chartresult}  field={v.status} categoryField="vendor" labels={seriesLabels2} color={COLORS[index]}/>
                            ))}

                        </ChartSeries>

                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                                labels={categorylabels}
                                majorGridLines={{ step: 100 }} minorGridLines={{ step: 100 }}
                            />
                        </ChartCategoryAxis>

                        <ChartValueAxis>
                            <ChartValueAxisItem visible={false} majorGridLines={{ step: 10 }} minorGridLines={{ step: 10}} ></ChartValueAxisItem>
                        </ChartValueAxis>
                    </Chart>
                </GridLayoutItem>

            </GridLayout>
    </>)
};

export default Check