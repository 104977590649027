import React, { useState, useEffect, useMemo } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
import '@progress/kendo-theme-default/dist/all.css';
import useBreakpoint from "use-breakpoint";
import _ from 'lodash';
import "../../kendocustom.css";
import * as mnode from '../../nodelibrary'
import axios from 'axios';
import CheckAll from './checkall';
import PQIAll from './pqiall';
import NCRAll from './ncrall';
import WeldingAll from './wall';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
      width,
      height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
      function handleResize() {
          setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

const QualityALL = () => {

  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')
  const { height, width } = useWindowDimensions();

  const GRID_CONFIG = {
    mobile: [
      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 18,
        rowSpan: 2,
        status: true,
      },
     
    ],

    tablet:  [
      {
        col: 1,
        colSpan: 18,
        rowSpan: 4,
        status: true,
      },
      {
        col: 1,
        colSpan: 6,
        status: true,
        rowSpan: 4,
      },
      {
        col: 7,
        colSpan: 6,
        status: true,
        rowSpan: 4,
      },
      {
        col: 13,
        colSpan: 6,
        status: true,
        rowSpan: 4,
      },
    ],

    desktop: [
      {
        col: 1,
        colSpan: 18,
        rowSpan: 4,
        status: true,
      },
      {
        col: 1,
        colSpan: 6,
        status: true,
        rowSpan: 3,
      },
      {
        col: 7,
        colSpan: 6,
        status: true,
        rowSpan: 3,
      },
      {
        col: 13,
        colSpan: 6,
        status: true,
        rowSpan: 3,
      },
    ]
  }


  const GRIDCONFIG_filterd = {mobile:_.filter(GRID_CONFIG['mobile'], {'status':true}), tablet:_.filter(GRID_CONFIG['tablet'], {'status':true}),desktop:_.filter(GRID_CONFIG['desktop'], {'status':true})}


  useEffect(() => {
    setPositionData(GRIDCONFIG_filterd[breakpoint])
  }, [breakpoint]);


  const [positiondata, setPositionData] = useState(GRID_CONFIG['desktop']);


  const [ncrfolderlist, setncrfolderlist] = useState([]);
  const [weldingfolderlist, setweldingfolderlist] = useState([]);


const getFolder = () => {
    mnode.getFolderList(`/${REACT_APP_PATH2}/file/quality/ncr/`, setncrfolderlist);
    mnode.getFolderList(`/${REACT_APP_PATH2}/file/quality/welding/`, setweldingfolderlist);
}
useEffect(getFolder, []);

const [ncrfileslist, setncrfileslists] = useState([]);
const [weldingfileslist, setweldingfileslists] = useState([]);




useEffect(() => {
  const fetchData = async () => {

    const requestArr3 = ncrfolderlist.map(async data => {
      return axios.get(`/api/getFileFolderList?path=/${REACT_APP_PATH2}/file/quality/ncr/${data}`, )
    });
    const res3 = await Promise.all(requestArr3)
    const reslist3 = res3.map((v) => ({ data: v.data.fileList, projectcode: v.config.url.split(`${REACT_APP_URI2}/api/getFileFolderList?path=/${REACT_APP_PATH2}/file/quality/ncr/`)[1] }))
    .reduce((c, v) => c.concat(v.data.map(o => Object.assign(o, { project: v.projectcode, files:o.name}))), [])

    setncrfileslists(reslist3)


    
    const requestArr5 = weldingfolderlist.map(async data => {
      return axios.get(`/api/getFileFolderList?path=/${REACT_APP_PATH2}/file/quality/welding/${data}`, )
    });
    const res5 = await Promise.all(requestArr5)
    const reslist5 = res5.map((v) => ({ data: v.data.fileList, projectcode: v.config.url.split(`${REACT_APP_URI2}/api/getFileFolderList?path=/${REACT_APP_PATH2}/file/quality/welding/`)[1] }))
    .reduce((c, v) => c.concat(v.data.map(o => Object.assign(o, { project: v.projectcode, files:o.name}))), [])
    setweldingfileslists(reslist5)



  }

  fetchData();
}, [ ncrfolderlist,  weldingfolderlist]);



const ncrfiles = useMemo(() => ncrfileslist, [ncrfileslist]);
const weldingfiles = useMemo(() => weldingfileslist, [weldingfileslist]);


const [ncrdata, setncrdata] = useState([]);
const [weldingdata, setweldingdata] = useState([]);

useEffect(() => {
  const fetchData = async () => {

 
         const requestArr3 = ncrfiles.map(async data => {
        return axios.get(`/api/getJSON?path=/${REACT_APP_PATH2}/file/quality/ncr/${data.project}/${data.files}`, )
      });
      const res3 = await Promise.all(requestArr3)
      const reslist3 = res3.map((v) => ({ data: v.data, projectcode: v.config.url.split(`${REACT_APP_URI2}/api/getJSON?path=/${REACT_APP_PATH2}/file/quality/ncr/`)[1].split('/')[0] })).filter(v => v.data.length > 0)
      .reduce((c, v) => c.concat(v.data.map(o => Object.assign(o, { projectcode: v.projectcode, }))), [])

      setncrdata(reslist3)


      const requestArr5 = weldingfiles.map(async data => {
        return axios.get(`/api/getJSON?path=/${REACT_APP_PATH2}/file/quality/welding/${data.project}/${data.files}`, )
      });
      const res5 = await Promise.all(requestArr5)
      const reslist5 = res5.map((v) => ({ data: v.data, projectcode: v.config.url.split(`${REACT_APP_URI2}/api/getJSON?path=/${REACT_APP_PATH2}/file/quality/welding/`)[1].split('/')[0] })).filter(v => v.data.length > 0)
      .reduce((c, v) => c.concat(v.data.map(o => Object.assign(o, { projectcode: v.projectcode, }))), [])

      setweldingdata(reslist5)
  }

  fetchData();
}, [ncrfiles,  weldingfiles]);

const [projectlist, setrprojectlist] = useState([]);

useEffect(() => {
    const fetchData = async () => {
      let body = {
        bpname: "ESG 대시보드 프로젝트 관리",
        lineitem: "no",
        filter_condition: "status=Active",
      };
        const res = await axios.post( `${REACT_APP_URI2}/api/getbprecord?path=SKEC0001`,body, )
        const pjtList = _.filter(res.data.data.data, { "ESGQualityCB": "Yes" }).map((v) => { return { projectcode: v.ugenProjectNumber, projectname: v.ugenProjectName, division: v.uuu_shell_location.slice(v.uuu_shell_location.lastIndexOf('/SK 에코엔지니어링/') + 12) } })
        .map((v)=>({...v, division:v.division.slice(0,v.division.indexOf(v.projectname)-1)}))
        setrprojectlist(pjtList)

        
    }
        fetchData();
    }, []);


  const tiles = [{
    header: (<div style={{ position: 'relative' }}>품질 심사 일정 및 조치현황</div>),
    body:<CheckAll positiondata={positiondata} height={height/7} projectlist={projectlist}/>,
    status: true,
    resizable: false,
    reorderable: false,

  }, {
    header: (<div style={{ position: "relative" }}>PQI</div> ),
    body:<PQIAll positiondata={positiondata} height={height/7} projectlist={projectlist}/>,
    status: true,
    resizable: false,
    reorderable: false,
  }, 
  
  {
    header: (<div style={{ position: 'relative' }}>품질활동 (NCR/CAR/PAR)</div>),
    body:<NCRAll positiondata={positiondata} height={height/7} ncrdata={ncrdata} projectlist={projectlist}/>,
    status: true,
    resizable: false,
    reorderable: false,
  }, 
  
  {
    header: (<div style={{ position: "relative" }}>용접불량율</div> ),
    body:<WeldingAll positiondata={positiondata} height={height/7} weldingdata={weldingdata}projectlist={projectlist}/>,
    status: true,
    resizable: false,
    reorderable: false,
  }, 
  

  ];


  const [tile, settile] = useState(tiles);

  useEffect(() => {
    settile(tiles);
  }, [positiondata, weldingdata,projectlist,ncrdata]);

  const tile_filtered = _.filter(tile, ['status', true]);


  return (
    <div>

      <TileLayout
        columns={18}
        rowHeight={140}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tile_filtered}
        autoFlow="row dense"
        className='esg'
        style={{height:height-10}}

      />


    </div>
  )

    ;
};

export default QualityALL;