import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import {Circles} from 'react-loader-spinner';
import { logout } from './common/logout';

const { REACT_APP_URI2 } = process.env; 

const checkSessionAndProjectValidity = async ( projectCode, owner) => {
  try {
    const response = await axios.post(`/api/checkproject`, {
      projectCode,
      owner
    },
    {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    );

    return {
      isValidSession: response.data.isValidSession,  // 백엔드에서 반환된 세션 유효성
      isValidProject: response.data.isValidProject   // 백엔드에서 반환된 프로젝트 코드 유효성
    };
  } catch (error) {
    console.error("Error verifying session and project:", error);
    return { isValidSession: false, isValidProject: false };
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isValidSession, setIsValidSession] = useState(false);
  const [projectCode, setprojectCode] = useState("");
  const [owner, setowner] = useState("false");
  const [isValidProject, setIsValidProject] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      
      const url = new URL(window.location.href);
      const pathSegments = url.pathname.split('/').filter(segment => segment !== '');
      const owner = pathSegments[1];

      let projectCode;
      if (rest.path === '/total') {
        projectCode = 'total'; // Set a default value for the total route
      } else {
        projectCode =  pathSegments[0];
      }

      const validity = await checkSessionAndProjectValidity(projectCode, owner);
      setIsValidSession(validity.isValidSession);
      setIsValidProject(validity.isValidProject);
      setIsLoading(false); 
      setowner(owner);
      setprojectCode(projectCode);

    }

    fetchData();
  }, []);

  return (
    <Route {...rest} render={props => {
        if (isLoading) {
          return <div className="loadingContainer"><Circles  ariaLabel="loading" color="#00BFFF" height={100} width={100}  /></div>;
      }

      if (isValidSession && isValidProject) {
        return <Component {...props} />;
      } else {
        logout();
        const redirectPath = encodeURIComponent(props.location.pathname + props.location.search);
        return <Redirect 
          to={{
            pathname: owner === "client" ? `/${projectCode}/client/login` : `/${projectCode}/login`,
            search: `?redirect=${redirectPath}`,
            state: { from: props.location }
          }} 
        />;
      }
    }} />
  );
};
export default PrivateRoute;