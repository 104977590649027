import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation,useHistory  } from 'react-router-dom';
import Swal from 'sweetalert2';
import { t } from 'i18next';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.skecoengineering.com/">
                SK Ecoengineering
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function ResetPassword () {
  const history = useHistory();  // useHistory 훅 추가

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [buttononoff, setbuttononoff] = useState(true);


  
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    const username = new URLSearchParams(search).get('username');

    // const buttononoff = password === confirmPassword ? false:true

    useEffect(() => {
      setbuttononoff(password ===""?true : password === confirmPassword ? false:true) 
      setMessage( password !== confirmPassword?'Passwords do not match':"")
    },[confirmPassword,password])

    const handleSubmit = async (e) => {
      e.preventDefault();
    
      if (password !== confirmPassword) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: t("Passwords do not match")
        });
        return;
      }
    
      axios.post(`/api/reset-password`, { token, password })
        .then(response => {
          Swal.fire({
            icon: 'success',
            title: 'Done',
            text: t('Password has been reset'),
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.value) {
              window.close(); 
            }
          });
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.error) {
            // 서버에서 반환하는 에러 메시지를 사용자에게 보여줍니다.
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.error
            });
          } else {
            // 에러 메시지가 없는 경우 일반 에러 메시지를 보여줍니다.
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: t('An error occurred. Please try again.')
            });
          }
        });
    };
    
  function isValidPassword(password) {
    const upperCaseRegex = /[A-Z]/;
    const lowerCaseRegex = /[a-z]/;
    const numberRegex = /\d/;
    const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  
    const checks = [
        upperCaseRegex.test(password),
        lowerCaseRegex.test(password),
        numberRegex.test(password),
        specialCharRegex.test(password)
    ];
  
    const trueCounts = checks.filter(Boolean).length;
  
    if (trueCounts >= 3 && password.length >= 8) return true;
    if (trueCounts >= 2 && password.length >= 10) return true;
  
    return false;
  }
  
       // 제약 조건을 검사하는 Helper 함수들
  const hasSequentialCharacters = str => /(012|123|234|345|456|567|678|789|890|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|qwe|wer|ert|rty|tyu|yui|uio|iop|asd|sdf|dfg|fgh|ghj|hjk|jkl|zxc|xcv|cvb|vbn|bnm)/i.test(str);

  const hasRepeatedCharacters = str => /(.)\1\1/.test(str);

  const isOnlyNumericOrAlpha = str => /^[0-9]+$|^[a-zA-Z]+$/.test(str);

  const validatePassword = (passwordValue) => {
    if (passwordValue === username) return "패스워드가 사용자 계정과 동일합니다.";  // 1번 제약 조건
    if (hasRepeatedCharacters(passwordValue)) return "동일한 문자 또는 숫자가 3자 이상 사용되었습니다.";  // 2번 제약 조건
    if (hasSequentialCharacters(passwordValue)) return "주기성 문자나 키보드상의 연속된 배열이 3개 이상 사용되었습니다.";  // 3번 제약 조건
    // 4번 제약 조건은 추가 정보(전화번호, 생년월일 등)가 필요하여 여기서 처리하지 않음
    if (isOnlyNumericOrAlpha(passwordValue)) return "문자 또는 숫자만으로 구성된 패스워드는 허용되지 않습니다.";  // 6번 제약 조건
    if (passwordValue === '') return "Null 패스워드는 허용되지 않습니다.";  // 7번 제약 조건

    if (!isValidPassword(passwordValue)) {
      return "패스워드는 다음의 문자 종류 중 2종류 이상을 조합하여 최소 10자리 이상 또는 3종류 이상을 조합하여 최소 8자리 이상의 길이로 작성하여야 합니다. * 영문 대문자(26개), 영문 소문자(26개), 숫자(10개), 특수문자(32개)";
    }
  

    return null;  // 유효한 패스워드
}

const handlePasswordChange = e => {
    const newPassword = e.target.value;
    const validationResult = validatePassword(newPassword);

    if (validationResult === null) {
      setPassword(newPassword);
    } else {
      // validationResult에 담긴 에러 메시지를 화면에 표시
      setMessage(validationResult);  // 예: alert를 사용한 간단한 메시지 표시
      // 실제 환경에서는 더 사용자 친화적인 UI 요소 (예: Snackbar, Toast 메시지 등)를 사용하여 에러 메시지를 표시할 수 있습니다.
    }
};

const handlePasswordChange2 = e => {
  const newPassword = e.target.value;
  const validationResult = validatePassword(newPassword);

  if (validationResult === null) {
    setConfirmPassword(newPassword);
  } else {
    // validationResult에 담긴 에러 메시지를 화면에 표시
    setMessage(validationResult);  // 예: alert를 사용한 간단한 메시지 표시
    // 실제 환경에서는 더 사용자 친화적인 UI 요소 (예: Snackbar, Toast 메시지 등)를 사용하여 에러 메시지를 표시할 수 있습니다.
  }
};

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
                                  <Grid >
                            <img src='/image/sconlogoH.png' width={200} />
                        </Grid>

          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={handlePasswordChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password Confirm"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={handlePasswordChange2}
                />
              </Grid>

            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={buttononoff}
            >
              Reset Password
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                {message && <p>{message}</p>}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}