import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import axios from "axios";
import {
  GridLayout,
  GridLayoutItem,
  Card,
  CardTitle,
  CardBody,
  CardSubtitle,
} from "@progress/kendo-react-layout";
import moment from "moment";

const { REACT_APP_URI2 } = process.env;

const url = `/api`;

const today = new Date();

const EProgress = ({ positiondata, order, blockH }) => {
  const { project_code } = useParams();
  const [lastdate, setlastdate1] = useState("");
  const [data, setdata] = useState([]);

  const [BoxHeight, setBoxHeight] = useState(250);
  const [emsData, setEmsData] = useState([]);
  const [emsWeightValue, setEmsWeightValue] = useState([]);

  const [cardData, setCardData] = useState({
    actualpercent: 0,
    planpercent: 0,
  });
  const [discData, setDiscData] = useState([]);
  const [typeData, setTypeData] = useState([]);

  const [discDataValue, setDiscDataValue] = useState("OVERALL");
  const [typeDataValue, setTypeDataValue] = useState("E + R");
  const [chartData, setChartData] = useState([]);
  const [selected, setSelected] = useState(true);

  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        bpname: "트랜잭션_EPC Progress",
        lineitem: "no",
        filter_condition: "status=Active",
        record_fields:
          "Epc_reportdate;epc_category_pd;epc_plan;epc_Actual;epc_Dev",
      };
      const res = await axios.post(
        `/api/getemsbprecord?path=${project_code}`,
        body
      );

      const last_data_o = _.sortBy(
        res.data.data.data.filter((item) => item.Epc_reportdate !== null),
        "record_no"
      );

      const last_date = _.get(last_data_o.slice(-1)[0], "Epc_reportdate");
      const last_data = _.filter(last_data_o, {
        Epc_reportdate: last_date,
      }).slice(-5);

      setlastdate1(last_date);
      setdata(last_data);
    };

    fetchData();
  }, [project_code]);

  useEffect(() => {
    const fetchData = async () => {
      let engineering_progress_body = {
        bpname: "Engineering Progress",
        lineitem: "no",
        filter_criteria: {
          join: "AND",
          filter: [
            {
              field: "PJT_CD",
              value: project_code,
              condition_type: "eq",
            },
          ],
        },
      };

      const engineering_progress_ratio_body = {
        bpname: "Engineering Progress Ratio",
        lineitem: "yes",
        filter_criteria: {
          join: "AND",
          filter: [
            {
              field: "ugenProjectNumber",
              value: project_code,
              condition_type: "eq",
            },
          ],
        },
      };

      const fetch_engineering_progress_ratio_body = await axios
        .post(
          `${url}/getemsbprecord?path=SKEC0001`,
          engineering_progress_ratio_body
        )
        .then((com) =>
          com.data.data.data
            .filter((com2) => com2._bp_lineitems)
            .reduce(
              (acc, cur) =>
                acc.concat(
                  Object.assign(cur._bp_lineitems).map((com2) => ({
                    ...com2,
                    ugenProjectNumber: cur.ugenProjectNumber,
                  }))
                ),
              []
            )
        );

      setEmsWeightValue(fetch_engineering_progress_ratio_body);

      const fetch_engineering_progress_body_body = await axios
        .post(`${url}/getemsbprecord?path=SKEC0001`, engineering_progress_body)
        .then((com) =>
          com.data.data.data.filter(
            (com2) => com2.PROG_TYPE === "E" || com2.PROG_TYPE === "R"
          )
        );

      const sum_date_engineering_progress = _.sortBy(
        fetch_engineering_progress_body_body
          .map((com) => ({
            ...com,
            date: new Date(
              `${com.CUT_OFF_DT.slice(0, 4)}-
            ${com.CUT_OFF_DT.slice(4, 6)}-
            ${com.CUT_OFF_DT.slice(6, 8)}`
            ),
            dev: Number(
              (
                (com.PLAN_PROG === null ? 0 : com.PLAN_PROG) -
                (com.ACTUAL_PROG === null ? 0 : com.ACTUAL_PROG)
              ).toFixed(2)
            ),
          }))
          .map((com) => ({
            ...com,
            month_date: moment(com.date).format("YYYY-MM"),
          })),
        "date"
      );

      setEmsData(sum_date_engineering_progress);
    };
    fetchData();
  }, [project_code]);

  useEffect(() => {
    const fetchData = async () => {
      //ComboBoxValues
      const disc_combobox_valuse = _.uniqBy(emsData, "DISC_NM").map(
        (com) => com.DISC_NM
      );

      const type_combobox_valuse = _.uniqBy(emsData, "PROG_TYPE").map(
        (com) => com.PROG_TYPE
      );

      type_combobox_valuse.unshift("E + R");

      setDiscData(disc_combobox_valuse);
      setTypeData(type_combobox_valuse);

      let disc_filter_data = [];

      let max_actual_data = { date: today };

      if (typeDataValue !== "E + R") {
        const ems_filter_data = emsData
          .filter((com) => com.DISC_NM === discDataValue)
          .filter((com) => com.PROG_TYPE === typeDataValue);

        max_actual_data = _.maxBy(
          [...ems_filter_data].reverse(),
          "ACTUAL_PROG"
        );

        disc_filter_data = ems_filter_data.map((com, idx, arr) => {
          if (
            (idx > 0 && com["ACTUAL_PROG"] === 0) ||
            (idx > 0 && arr[idx - 1]["ACTUAL_PROG"] > com["ACTUAL_PROG"])
          ) {
            const slice_arr = arr.slice(0, idx + 1);
            const max_obj = _.maxBy(slice_arr, "ACTUAL_PROG")["ACTUAL_PROG"];

            const change_com = {
              ...com,
              ACTUAL_PROG: max_obj,
              act_data: 0,
            };

            return { ...change_com };
          } else {
            return { ...com };
          }
        });
      } else {
        const find_weight_value = emsWeightValue.find(
          (com) =>
            com.EPRDiscipline.toUpperCase() === discDataValue.toUpperCase()
        );

        const emsDataSum = emsData
          .filter((com) => com.DISC_NM === discDataValue)
          .filter((com) => com.PROG_TYPE === "E" || com.PROG_TYPE === "R");

        const emsDataSum_factor = _.sortBy(
          emsDataSum.map((com) => {
            return {
              ...com,
              PLAN_PROG_FACTOR_VALUE:
                com.PROG_TYPE === "E"
                  ? com["PLAN_PROG"] * (find_weight_value?.["EDPRRatio"] / 100)
                  : com["PLAN_PROG"] * (find_weight_value?.["RPRRatio"] / 100),
              ACTUAL_PROG_FACTOR_VALUE:
                com.PROG_TYPE === "E"
                  ? com["ACTUAL_PROG"] *
                    (find_weight_value?.["EDPRRatio"] / 100)
                  : com["ACTUAL_PROG"] *
                    (find_weight_value?.["RPRRatio"] / 100),
            };
          }),
          "date"
        );

        const emsDataSum_factor_e = emsDataSum_factor
          .filter((com) => com.PROG_TYPE === "E")
          .map((com, idx, arr) => {
            return { ...com };
          });

        const emsDataSum_factor_r = emsDataSum_factor
          .filter((com) => com.PROG_TYPE === "R")
          .map((com, idx, arr) => {
            return { ...com };
          });

        const uniqWeeksData = _.uniqBy(emsDataSum, "CUT_OFF_DT").map((com) => {
          const cut_off_filter = _.uniqBy(
            [...emsDataSum_factor_e, ...emsDataSum_factor_r].filter(
              (com2) => com2.CUT_OFF_DT === com.CUT_OFF_DT
            ),
            "PROG_TYPE"
          );

          return {
            ...com,
            PLAN_PROG: _.sumBy(cut_off_filter, "PLAN_PROG_FACTOR_VALUE"),
            ACTUAL_PROG: _.sumBy(cut_off_filter, "ACTUAL_PROG_FACTOR_VALUE"),
          };
        });

        max_actual_data = _.maxBy([...uniqWeeksData].reverse(), "ACTUAL_PROG");

        disc_filter_data = uniqWeeksData
          .map((com, idx, arr) => {
            if (
              (idx > 0 && com["ACTUAL_PROG"] === 0) ||
              (idx > 0 && arr[idx - 1]["ACTUAL_PROG"] > com["ACTUAL_PROG"])
            ) {
              const slice_arr = arr.slice(0, idx + 1);
              const max_obj = _.maxBy(slice_arr, "ACTUAL_PROG")["ACTUAL_PROG"];

              const change_com = {
                ...com,
                ACTUAL_PROG: max_obj,
                act_data: 0,
              };

              return { ...change_com };
            } else {
              return { ...com };
            }
          })
          .map((com, idx, arr) => {
            if (
              (idx > 0 && com["PLAN_PROG"] === 0) ||
              (idx > 0 && arr[idx - 1]["PLAN_PROG"] > com["PLAN_PROG"])
            ) {
              const slice_arr = arr.slice(0, idx + 1);
              const max_obj = _.maxBy(slice_arr, "PLAN_PROG")["PLAN_PROG"];

              const change_com = {
                ...com,
                PLAN_PROG: max_obj,
                plan_data: 0,
              };

              return { ...change_com };
            } else {
              return { ...com };
            }
          });
      }

      const calculation_data = _.sortBy(
        disc_filter_data
          .map((com, idx) => {
            if (idx === 0) {
              return {
                ...com,
                plan_data: com.PLAN_PROG === null ? 0 : com.PLAN_PROG,
                act_data: com.ACTUAL_PROG === null ? 0 : com.ACTUAL_PROG,
              };
            } else {
              return {
                ...com,
                plan_data:
                  com.PLAN_PROG === null
                    ? 0
                    : Number(
                        (
                          com.PLAN_PROG - disc_filter_data[idx - 1]["PLAN_PROG"]
                        ).toFixed(2)
                      ),
                act_data:
                  com.ACTUAL_PROG === null
                    ? 0
                    : Number(
                        (
                          com.ACTUAL_PROG -
                          disc_filter_data[idx - 1]["ACTUAL_PROG"]
                        ).toFixed(2)
                      ),
              };
            }
          })
          .map((com) => {
            if (com.date > max_actual_data.date) {
              const delete_com = { ...com };

              delete delete_com.act_data;
              delete delete_com.ACTUAL_PROG;

              return { ...delete_com };
            } else {
              return { ...com };
            }
          }),
        "date"
      );

      if (calculation_data.length > 0) {
        const max_actual = _.maxBy(
          [...calculation_data].reverse(),
          "ACTUAL_PROG"
        );

        setCardData({
          actualpercent: max_actual?.["ACTUAL_PROG"] ?? 0,
          planpercent: max_actual?.["PLAN_PROG"] ?? 0,
        });
      } else {
        setCardData({
          actualpercent: 0,
          planpercent: 0,
        });
      }

      setChartData(calculation_data);
    };

    fetchData();
  }, [discDataValue, typeDataValue, emsData, selected, emsWeightValue]);

  const totalplan = _.filter(data, { epc_category_pd: "Overall" }).map(
    (v) => v.epc_plan
  );
  const totalactul = _.filter(data, { epc_category_pd: "Overall" }).map(
    (v) => v.epc_Actual
  );

  const totaldev =
    _.filter(data, { epc_category_pd: "Overall" }).map((v) => v.epc_Actual) -
    _.filter(data, { epc_category_pd: "Overall" }).map((v) => v.epc_plan);

  return (
    <>
      <GridLayout
        style={{ height: BoxHeight, position: "relative" }}
        rows={[
          {
            height: "100%",
          },
        ]}
        cols={[
          {
            width: "49%",
          },
          {
            width: "49%",
          },
        ]}
        gap={{
          rows: 0,
          cols: 10,
        }}
      >
        <GridLayoutItem col={1} row={1}>
          <Card style={{ width: "100%", height: BoxHeight }}>
            <CardBody>
              <CardTitle
                style={{
                  textAlign: "center",
                  height: "20%",
                  margin: "0",
                  fontFamily: "pretendard-B",
                  color: "#707070",
                }}
              >
                Total Progress <br />
                (EPC)
              </CardTitle>
              <CardTitle
                style={{
                  fontSize: "1.5rem",
                  color: "#616CFF",
                  fontWeight: "bold",
                  height: "25%",
                  position: "relative",
                }}
              >
                {totalactul > 0 ? (
                  <span
                    style={{
                      position: "absolute",
                      bottom: "0",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {totalactul}%
                  </span>
                ) : (
                  <span
                    style={{
                      position: "absolute",
                      bottom: "0",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    0%
                  </span>
                )}
              </CardTitle>
              <CardTitle style={{ textAlign: "center", height: "25%" }}>
                Dev.{" "}
                {totaldev > 0 ? (
                  <span style={{ color: "#006B54" }}>
                    {totaldev.toFixed(2)}%
                  </span>
                ) : totaldev < 0 ? (
                  <span style={{ color: "#9E1030" }}>
                    {totaldev.toFixed(2)}%
                  </span>
                ) : (
                  <span>{totaldev.toFixed(2)}%</span>
                )}
              </CardTitle>
              <div style={{ height: "10%" }}>
                <div
                  style={{
                    float: "left",
                    color: "#939CA8",
                    fontFamily: "pretendard-B",
                  }}
                >
                  <CardSubtitle>Plan</CardSubtitle>
                  <CardSubtitle>{totalplan > 0 ? totalplan : 0}%</CardSubtitle>
                </div>

                <div
                  style={{
                    float: "right",
                    color: "#939CA8",
                    fontFamily: "pretendard-B",
                  }}
                >
                  <CardSubtitle style={{ textAlign: "right" }}>
                    Actual
                  </CardSubtitle>
                  <CardSubtitle style={{ textAlign: "right" }}>
                    {totalactul > 0 ? totalactul : 0}%
                  </CardSubtitle>
                </div>
              </div>
            </CardBody>
          </Card>
        </GridLayoutItem>
        <GridLayoutItem col={2} row={1}>
          <Card style={{ width: "100%", height: BoxHeight }}>
            <CardBody>
              <CardTitle
                style={{
                  textAlign: "center",
                  height: "20%",
                  margin: "0",
                  fontFamily: "pretendard-B",
                  color: "#707070",
                }}
              >
                Eng'g Progress <br />
                (Engineering)
              </CardTitle>
              <CardTitle
                style={{
                  fontSize: "1.5rem",
                  color: "#616CFF",
                  fontWeight: "bold",
                  height: "25%",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    bottom: "0",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {cardData.actualpercent && cardData.actualpercent.toFixed(2)}%
                </span>
              </CardTitle>
              <CardTitle style={{ textAlign: "center", height: "25%" }}>
                Dev.{" "}
                <span
                  style={{
                    color:
                      cardData.actualpercent - cardData.planpercent >= 0
                        ? "#00539C"
                        : "#9E1030",
                  }}
                >
                  {(cardData.actualpercent - cardData.planpercent).toFixed(2)}%
                </span>
              </CardTitle>
              <div style={{ height: "10%" }}>
                <div
                  style={{
                    float: "left",
                    color: "#939CA8",
                    fontFamily: "pretendard-B",
                  }}
                >
                  <CardSubtitle>Plan</CardSubtitle>
                  <CardSubtitle>
                    <span>
                      {cardData.planpercent && cardData.planpercent.toFixed(2)}%
                    </span>
                  </CardSubtitle>
                </div>

                <div
                  style={{
                    float: "right",
                    color: "#939CA8",
                    fontFamily: "pretendard-B",
                  }}
                >
                  <CardSubtitle style={{ textAlign: "right" }}>
                    Actual
                  </CardSubtitle>
                  <CardSubtitle style={{ textAlign: "right" }}>
                    <span>
                      {cardData.actualpercent &&
                        cardData.actualpercent.toFixed(2)}
                      %
                    </span>
                  </CardSubtitle>
                </div>
              </div>
            </CardBody>
          </Card>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default EProgress;
