import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import moment from 'moment-timezone';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const WeeklyImg = ({ positiondata , order, blockH}) => {

  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [imgsrc, setimgsrc] = useState("");
  const { project_code } = useParams();

  useEffect(() => {
    const fetchData = async () => {

      let body =
      {
        "bpname": "현장 사진 업로드",

        "lineitem": "yes",
        "filter_criteria": {
          "join": "AND",
          "filter": [
            {
              "field": "status",
              "value": "Active",
              "condition_type": "eq"
            }
          ]
        }
      }

      const res = await axios.post(`/api/getbprecord?path=${project_code}`, body)

      const res2 = await axios.get(`/api/getFileFolderList?path=/${REACT_APP_PATH2}/weeklyphoto/${project_code}/`)


      const regExp = /[\{\}\[\]\/?;|*~`!^\+<>@\#$%&\\\=\'\"]/g;
      const imageListN = res2.data.fileList.map(v=>v.name.replaceAll(regExp, ""))

      let imagesrc = [];
      for (const image of imageListN) {
        const dr = await axios.get(`/api/getFile?path=/${REACT_APP_PATH2}/weeklyphoto/${project_code}/${image}`, {
          responseType: 'arraybuffer', 
        })
        const dr_data = window.URL.createObjectURL(new Blob([dr.data]))
        imagesrc.push({blob:dr_data, filename:image.split('_gubun_')[0]})
      }


      const bpfiles = res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined' && v._bp_lineitems.some(f => f.genPhotoCatSRB === '주요공정 Weekly'))
      .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { record_no: v.record_no }))), []).map((v)=>({...v, filename:v.RecordDate+v.LineAutoSeq
        }))


      const imgdata = _.sortBy(imagesrc.map((v)=>({...v, ...bpfiles.find(vf => vf.filename === v.filename)})).map((v)=>({...v, order:Number(moment(v.genPhotoTakenDO).format('YYMMDD')+100-Number(v.WeeklyPhotoSeq))})).filter(v=>!isNaN(v.order)), 'order').reverse()
        
  
      setimgsrc(imgdata)



    };

    fetchData();
  }, [project_code]);



  const [BoxHeight, setBoxHeight] = useState(250);

  useEffect(() => {

    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
}, [positiondata, order, blockH]);

  const [indexnum, setindexnum] = useState(0)


  const handleClick = (event, index) => {
    setindexnum(index)
  };

  // console.log(imagemeta)

  return (
    <>

      <GridLayout style={{ height: BoxHeight }}
        rows={[
          {
            height: '100%',
          }

        ]}

        cols={[
          {
            width: '70%',
          },
          {
            width: '30%',
          },
        ]}
        gap={{
          rows: 0,
          cols: 0,
        }}>
        <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
          <Swiper
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            className="mySwiper2"
            

          >
            {imgsrc && imgsrc.map((v, index) => (
              <SwiperSlide key={index}>
                <Zoom>
                  <img src={v.blob} style={{ height: BoxHeight * 0.8, objectFit: 'contain' }} alt={index} />
                </Zoom>
              </SwiperSlide>


            ))}

          </Swiper>

          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
          >

            {imgsrc && imgsrc.map((v, index) => (
              <SwiperSlide key={index}>

                <img src={v.blob} style={{ height: BoxHeight * 0.8, objectFit: 'contain' }} alt={index} onClick={event => handleClick(event, index)} />
              </SwiperSlide>


            ))}
          </Swiper>


        </GridLayoutItem>

        <GridLayoutItem row={1} col={2} style={{ position: 'relative', overflow: 'auto' }}>
          {imgsrc.length > 0 ?
            <>
              <Typography.h3>
                {imgsrc[indexnum].genPhotoTitle && imgsrc[indexnum].genPhotoTitle}
              </Typography.h3>

              <Typography.h5>
                {imgsrc[indexnum].genPhotoTakenDO && imgsrc[indexnum].genPhotoTakenDO.slice(0,10)}
              </Typography.h5>

              <Typography.h5>
                <div dangerouslySetInnerHTML={{ __html: imgsrc[indexnum].genPhotoDesc && imgsrc[indexnum].genPhotoDesc.replaceAll('\\n', '<br/>') }} />
              </Typography.h5>
            </>

            : ''}

        </GridLayoutItem>


      </GridLayout>



    </>

  );
};

export default WeeklyImg;