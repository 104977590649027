import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import { useTranslation } from 'react-i18next';
import { generateUpdatedDate } from '../common/datadate';


const { REACT_APP_URI2, REACT_APP_PATH2 } = process.env;

const QIssue = ({ positiondata, order, blockH }) => {

    const [BoxHeight, setBoxHeight] = useState(250);
    const [data, setdata] = useState([])
    const { project_code } = useParams();

    useEffect(() => {

        const boxHeight = positiondata[order];
        setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
    }, [positiondata, order, blockH]);


    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "품질 이슈사항",
                "lineitem": "no",
                "filter_condition": "status=Active",

            }

            const res = await axios.post(`/api/getemsbprecord?path=${project_code}`, body)
            setdata(_.sortBy(res.data.data.data, 'record_no').reverse()[0])


        };

        fetchData();
    }, [project_code]);

    console.log(BoxHeight)

    return (
        <>
            <GridLayout style={{ height: BoxHeight }}
                rows={[
                    {
                        height: '100%',
                    },

                ]}

                cols={[
                    {
                        width: '100%',
                    }
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>
                <div style={{ position: "absolute", top: "22px", right: "15px", zIndex: "100", color: 'snow', fontSize: "0.7rem" }}>
                    {data && data.genSubmitDO && generateUpdatedDate(data.genSubmitDO.slice(0, 19))}
                </div>

                <GridLayoutItem row={1} col={1} style={{ overflowY: 'scroll' , height: BoxHeight-120 }}>
                    {data &&
                        <>

                            <div dangerouslySetInnerHTML={{ __html: data.genIssueDescRT }} className='ment'></div>
                        </>}
                </GridLayoutItem>

            </GridLayout>

        </>
    );

}

export default QIssue
