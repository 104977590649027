import axios from 'axios';

const {REACT_APP_URI2} = process.env;

export const logout = async ({ username, project ,client, tr=false}) => {
  try {
    // 서버에 로그아웃 요청
    await axios.get(`/api/logout`, {
      params: {
        username,
        project
      },
      withCredentials: true
    });

    const redirectPath =tr?`/${project}/clientTR`: (client? `/${project}/client/login`:`/${project}/login`)
    // const redirectPath = `/${project}/login`

    // 로그인 페이지로 리다이렉트
    window.location = redirectPath;
  } catch (error) {
    console.error("Error during logout:", error);
  }
};