import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem
} from "@progress/kendo-react-layout";
import 'hammerjs';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import 'hammerjs';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ResponsivePie } from '@nivo/pie';
import { Dialog } from "@progress/kendo-react-dialogs";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { generateUpdatedDate } from '../common/datadate';

const {REACT_APP_URI2, REACT_APP_PATH2} = process.env;

const Remain = ({ positiondata, order, blockH }) => {
    const { project_code } = useParams();
    const [data, setdata] = useState([]);
    const [BoxHeight, setBoxHeight] = useState(250);
    const { t, i18n } = useTranslation();

    const kr_currnet_time = moment(new Date()).format("MM-DD-YYYY 00:00:00");
    const kr_currnet_time_a = moment(new Date()).format("MM-DD-YYYY HH:mm:ss");

    const today = moment(new Date()).format("YYYY년 MM월 DD일");
    const today2 = moment(new Date()).format("MM-DD-YYYY");

    useEffect(() => {

        const boxHeight = positiondata[order];
        setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan)-80);
    }, [positiondata, order, blockH]);

    useEffect(() => {
        const fetchData = async () => {
            let body =
            {
                "bpname": "출입기록",
                "lineitem": "no",
                "record_fields": "d_company_name;d_job_title_pd;uuu_record_last_update_date;site_in_date;j_jobtitle_bpk",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "site_in_check_text",
                            "value": "OUT",
                            "condition_type": "neq"
                        },
                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        },
                        {
                            "field": "uuu_record_last_update_date",
                            "value": kr_currnet_time,
                            "value2": kr_currnet_time_a,
                            "condition_type": "range"
                        },
                    ]

                }
            }
            const res = await axios.post(`/api/getbprecord?path=${project_code}`, body)
            setdata(res.data.data.data
                .filter(v=>v.site_in_date.slice(0,10) ===today2)
                .map((v)=>({...v,d_job_title_pd:v.d_job_title_pd === null ? v.j_jobtitle_bpk :v.d_job_title_pd }))
                )

        }

        fetchData();
    }, [project_code,kr_currnet_time_a, kr_currnet_time, today2]);

    const chartdata =
    _(_.filter(data))
        .groupBy('d_job_title_pd')
            .map((objs, key) => ({
                'id': key,
                'value': objs.filter(v => v.d_job_title_pd === key).length,

            }))
            .value().filter(v => v.value !== 0);

    const tabledata =
        _(_.filter(data))
            .groupBy('d_company_name')
            .map((objs, key) => ({
                'd_company_name': key,
                'count': objs.filter(v => v.d_company_name === key).length,

            }))
            .value()

    const [visibleDialog, setVisibleDialog] = useState(true);

    const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
    };

    const [sort, setSort] = useState([{}]);


    return (
        <>
              <GridLayout style={{ height: BoxHeight }}
                rows={[
                    // {  height: '10%',},
                    {
                        height: '100%',
                    },

                ]}

                cols={[
                    {
                        width: '100%',
                    },
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>
          <div style={{ position: "absolute", top: "22px",right: "15px",zIndex: "100",color:'snow' ,fontSize:"0.7rem"}}>
          {generateUpdatedDate(new Date())}
        </div>
                <GridLayoutItem row={1} col={1} style={{ position: 'relative'}}>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' ,zIndex:'999'}}><span style={{ fontSize:_.sumBy(tabledata, 'count').length >3?'2rem' :'2.5rem' }}className='hovertext' onClick={toggleDialog}>{_.sumBy(tabledata, 'count')}</span></div>
                    <ResponsivePie
                        // colors={['#616CFF', '#429BF4', '#42B7F4', '#9E67FF', '#FF6E6E', '#BC99F2', '#2075A1', '#33B8B4', 'DBF3FF', '#42EEF4']}
                        colors={{ scheme: 'blues' }}
                        className='pie'
                        data={chartdata}
                        margin={{ top: 10, right: 80, bottom: 10, left: 80 }}
                        valueFormat=" >-"
                        cornerRadius={2}
                        innerRadius={0.7}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.2
                                ]
                            ]
                        }}
                        arcLinkLabel={function (e) { return e.id + "(" + ((e.value / _.sumBy(chartdata,'value')) * 100).toFixed(0) + "%)" }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextOffset={5}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsDiagonalLength={5}
                        arcLinkLabelsStraightLength={5}
                        arcLinkLabelsColor={{ from: 'color' }}
                        enableArcLabels={true}
                        arcLabel={function (e) { return e.value }}
                        arcLabelsRadiusOffset={0.5}
                        arcLabelsSkipAngle={0}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    2
                                ]
                            ]
                        }}

                        legends={[]}
                    />
                </GridLayoutItem>


            </GridLayout>



            {!visibleDialog && (
                <Dialog title={"인원상세현황"} onClose={toggleDialog} width='40%' height= {tabledata.length >10? "600px":"auto"}>
                    <Grid

                        data={orderBy(tabledata, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                        className='mancount'
                        style={{height:"100%", overflowY:'auto', overflowX:'hidden'}}
                    >
                        <GridColumn field="d_company_name" title="업체명" />
                        <GridColumn field="count" title="출력 인원" />

                    </Grid>
                </Dialog>
            )}
        </>
    )

}

export default Remain
