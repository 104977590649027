import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { useParams, useLocation } from 'react-router-dom';
import PDFTronSquad from './viewerSquad';
import InfoBoxSquad from './infoBox2';
import JSZip from 'jszip';
import Swal from 'sweetalert2';
import axios from "axios";
import { getSessionUsername } from '../../common/username';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const MyDocumentViewerModalSquad = ({ }) => {
  let query = useQuery();
  const { project_code } = useParams();

  const recordNo = query.get("recordNo");
  const group = query.get("group");
  const duedate = query.get("duedate");
  const autoseq = query.get("autoseq");
  const SQDTitle = query.get("title");

  const [username, setUsername] = useState(null);

  useEffect(() => {
    const fetchUsername = async () => {
      const name = await getSessionUsername();
      setUsername(name);
    };

    fetchUsername();
  }, []);

  const [pdftronInstance, setPdftronInstance] = useState(null);

  // PDFTron 컴포넌트에서 인스턴스를 업데이트하는 함수
  const updatePdftronInstance = (instance) => {
    setPdftronInstance(instance);
  };

  const [documentList, setDocumentList] = useState(null);
  const [selectedDocId, setSelectedDocId] = useState('');
  const [selectedDocTitle, setSelectedDocTitle] = useState('');
  const [selectedDocBlob, setSelectedDocBlob] = useState(null);

  console.log(documentList)

  useEffect(() => {
    async function fetchData() {
      try {
        if (project_code && recordNo) {
          const res = await axios.get(`/api/getrecordfilelist?project=${project_code}&record=${recordNo}`);
          if (res.data.success) {
            setDocumentList(res.data.data);
            if (res.data.data.length > 0) {
              selectDocument(res.data.data[0].id, res.data.data[0].title);
            }
          }
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `An error occurred. Please contact the system administrator.${error}`,
          confirmButtonText: 'OK'
        });
        // Handle error appropriately
        setDocumentList([]);
      }
    }
    fetchData();
  }, [project_code, recordNo]); // Correct dependency array

  const selectDocument = async (docId, docTitle) => {
    setSelectedDocId(docId);
    setSelectedDocTitle(docTitle);
    const docBlob = await getDocumentBlob(docId);
    setSelectedDocBlob(docBlob);
  };

  const getDocumentBlob = async (docId) => {
    const response = await axios.get(`/api/download/${docId}`, { responseType: 'blob' });

    // ZIP 파일 압축 해제
    const jszip = new JSZip();
    const zipContent = await jszip.loadAsync(response.data); // ZIP 파일 로드

    // 첫 번째 파일의 내용을 얻음
    const fileNames = Object.keys(zipContent.files);
    if (fileNames.length === 0) {
      throw new Error('ZIP file is empty');
    }

    const firstFileName = fileNames[0];
    const firstFile = zipContent.files[firstFileName];
    return await firstFile.async('blob');
  };

  return (
      <Box display="flex" >
        <Box flex={1} mr={1} sx={{marginRight:0}}> 
          {/* {selectedDocBlob && */}
          <PDFTronSquad initialDocBlob={selectedDocBlob} documenTitle={selectedDocTitle} docuID={selectedDocId} username={username}
            onInstanceReady={setPdftronInstance} />
            {/* } */}
        </Box>
        <InfoBoxSquad recordNo={recordNo} SQDTitle={SQDTitle} autoseq={autoseq} group={group} duedate={duedate} documentList={documentList} onSelectDocument={selectDocument} />
      </Box>

  );
};
export default MyDocumentViewerModalSquad;
