import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { RadialGauge } from "@progress/kendo-react-gauges";
import axios from 'axios';
import {
    GridLayout,
    GridLayoutItem,
    Card,
    CardTitle,
    CardBody
  } from "@progress/kendo-react-layout";

const {REACT_APP_URI2} = process.env;

const LTIR = ({boxHeight, sendDataToParent}) => {
    const { project_code } = useParams();
    const [data, setdata] = useState([]);

  useEffect(() => {
    const fetchData = async () => {

      const res = await axios.get(`/api/getltir?path=${project_code}`)
      setdata(res.data.data)
    };

    fetchData();
}, []);

const actulval =data.actulval
const companyval = data.companyval
const targetval = data.targetval

const radialOptions = {
    pointer:[ {value: actulval, color:actulval > targetval && actulval > targetval ? '#CD212A':'#00A170'},{value: companyval, color:'#707070'}],
    shape: "arrow",
    scale: {
      minorUnit: 0.5,
      majorUnit: 0.5,
      max: 1.5,
      startAngle: 0,
      endAngle: 180,
      ranges: [
        {
          from: 0,
          to: targetval,
          color: "#238AE3",
        }
      ],
    },
  };

return(
<>
<GridLayout style={{height:boxHeight*0.9}}
      rows={[
        {
          height: '20%',
        },
        {
          height: '80%',
        }


      ]}

      cols={[
        {
          width: '100%',
        },
        

      ]}
      gap={{
        rows: 0,
        cols: 0,
      }}>


      <GridLayoutItem row={1}  col={1} colSpan={1}   style={{fontSize:'0.8rem' , margin:'0 auto'}}>
        <div className='k-card-group' style={{width:'100%'}}>
        <Card style={{width:'33%', textAlign:'center', fontSize:'0.7rem', borderWidth:0}}>
          <CardBody>
          <CardTitle style={{fontSize:'0.7rem', color:'#707070', marginBottom:'0'}}>Company</CardTitle>
          <CardTitle style={{fontSize:'0.8rem', color:'#707070'}}>{companyval}</CardTitle>
          </CardBody>
        </Card>
        <Card style={{width:'33%', textAlign:'center', fontSize:'0.7rem', borderWidth:0}}>
          <CardBody>
          <CardTitle style={{fontSize:'0.7rem', color:'#238AE3', marginBottom:'0'}}>Target</CardTitle>
          <CardTitle style={{fontSize:'0.8rem', color:'#238AE3'}}>{targetval}</CardTitle>
          </CardBody>
        </Card>
        <Card style={{width:'33%', textAlign:'center', fontSize:'0.7rem', borderWidth:0}}>
          <CardBody>
          <CardTitle style={{fontSize:'0.7rem', color:actulval > targetval && actulval > targetval ? '#CD212A':'#00A170',marginBottom:'0'}}>Actual</CardTitle>
          <CardTitle style={{fontSize:'0.8rem', color:actulval > targetval && actulval > targetval ? '#CD212A':'#00A170'}}>{actulval}</CardTitle>
          </CardBody>
        </Card>
        </div>
      </GridLayoutItem>
      <GridLayoutItem row={2} col={1} colSpan={1}  style={{fontSize:'0.8rem' , margin:'0 auto'}}>
      <RadialGauge {...radialOptions} style={{width:'97%'}} />
      </GridLayoutItem>
</GridLayout>

</>);

}

export default LTIR
