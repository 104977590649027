import React, { useState, useEffect, useMemo, useRef } from "react";
import { TileLayout } from "@progress/kendo-react-layout";
import { useParams } from "react-router-dom";
import useBreakpoint from "use-breakpoint";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import EMSSCurveChart from "./Components/EMSSCurveChart";

const { REACT_APP_URI2 } = process.env;

const url = `/api`;

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const SkoneEMSProgress = () => {
  const testIMG = useRef();

  const { height } = useWindowDimensions();

  const { project_code } = useParams();

  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");

  const [positiondata, setPositionData] = useState(grid["desktop"]);

  const [emsData, setEmsData] = useState([]);
  const [emsWeightValue, setEmsWeightValue] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let engineering_progress_body = {
        bpname: "Engineering Progress",
        lineitem: "no",
        filter_criteria: {
          join: "AND",
          filter: [
            {
              field: "PJT_CD",
              value: project_code,
              condition_type: "eq",
            },
          ],
        },
      };

      const engineering_progress_ratio_body = {
        bpname: "Engineering Progress Ratio",
        lineitem: "yes",
        filter_criteria: {
          join: "AND",
          filter: [
            {
              field: "ugenProjectNumber",
              value: project_code,
              condition_type: "eq",
            },
          ],
        },
      };

      const fetch_engineering_progress_ratio_body = await axios
        .post(
          `${url}/getemsbprecord?path=SKEC0001`,
          engineering_progress_ratio_body
        )
        .then((com) =>
          com.data.data.data
            .filter((com2) => com2._bp_lineitems !== undefined)
            .reduce(
              (acc, cur) =>
                acc.concat(
                  Object.assign(cur._bp_lineitems).map((com2) => ({
                    ...com2,
                    ugenProjectNumber: cur.ugenProjectNumber,
                  }))
                ),
              []
            )
        );

      setEmsWeightValue(fetch_engineering_progress_ratio_body);

      const fetch_engineering_progress_body_body = await axios
        .post(`${url}/getemsbprecord?path=SKEC0001`, engineering_progress_body)
        .then((com) =>
          com.data.data.data.filter(
            (com2) => com2.PROG_TYPE === "E" || com2.PROG_TYPE === "R"
          )
        );

      const sum_date_engineering_progress = _.sortBy(
        fetch_engineering_progress_body_body
          .map((com) => ({
            ...com,
            date: new Date(
              `${com.CUT_OFF_DT.slice(0, 4)}-
            ${com.CUT_OFF_DT.slice(4, 6)}-
            ${com.CUT_OFF_DT.slice(6, 8)}`
            ),
            dev: Number(
              (
                (com.PLAN_PROG === null ? 0 : com.PLAN_PROG) -
                (com.ACTUAL_PROG === null ? 0 : com.ACTUAL_PROG)
              ).toFixed(2)
            ),
          }))
          .map((com) => ({
            ...com,
            month_date: moment(com.date).format("YYYY-MM"),
          })),
        "date"
      );

      setEmsData(sum_date_engineering_progress);
    };
    fetchData();
  }, [project_code]);

  useEffect(() => {
    setPositionData(grid[breakpoint]);
  }, [breakpoint]);

  const tiles = useMemo(
    () => [
      {
        header: (
          <div style={{ position: "relative" }}>Eng'g Progress S-Curve</div>
        ),
        body: (
          <EMSSCurveChart
            emsData={emsData}
            emsWeightValue={emsWeightValue}
            height={(height - 20) * 0.9}
          />
        ),
        key: "EMS Progress",
        status: true,
        resizable: false,
        reorderable: false,
      },
    ],
    [emsData, emsWeightValue, height]
  );

  const [tile, settile] = useState(tiles);

  useEffect(() => {
    settile(tiles);
  }, [tiles]);

  const handleReposition = (e) => {
    setPositionData(e.value);
  };

  return (
    <div className="skoneEMSProgress" ref={testIMG}>
      <TileLayout
        columns={16}
        rowHeight={(height - 210) / 6}
        style={{ height: height - 10, width: "calc(100% - 210)" }}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tile}
        autoFlow="row dense"
        onReposition={handleReposition}
      />
    </div>
  );
};

export default SkoneEMSProgress;

const grid = {
  mobile: [
    {
      col: 1,
      colSpan: 18,
      rowSpan: 12,
    },
  ],

  tablet: [
    {
      col: 1,
      colSpan: 18,
      rowSpan: 12,
    },
  ],

  desktop: [
    {
      col: 1,
      colSpan: 18,
      rowSpan: 12,
    },
  ],
};
